import React from 'react';
import './component/index.css';
import { RecentRunStat } from './component/RecentRunStat';
import TableItems from './component/TableItems';
import TableColumnName from '../vulnerability-details/component/TableColumnName';
import IssuesChart from './component/IssuesChart';
import ViewIssueButton from './component/ViewIssueButton';

const FuzzingTest = () => {
  return (
    <div className="w-full mb-7">
      <div className="card rounded-[20px] px-3 py-2 w-full h-full">
        <RecentRunStat />
        <section className="flex items-center  issuesChart__container w-full">
          <div className="issuesChart__chart ">
            <IssuesChart chartColor={'#F90808'}  value={5}/>
            <ViewIssueButton name={'View critical issues'} />
          </div>
          <div className="issuesChart__chart">
            <IssuesChart chartColor={'#FAC710'} value={23} />
            <ViewIssueButton name={'View  medium issues'} />
          </div>
          <div className="issuesChart__chart">
            <IssuesChart chartColor={'#652CB3'} value={47} />
            <ViewIssueButton name={'View low isssue'} />
          </div>
        </section>
      </div>

      <div className="card w-full  rounded-[20px] mt-4 px-3 py-2">
        <h2 className="text-base md:text-[20px] flex flex-wrap text-black">
          Critical issues found
        </h2>
        <table className="table ">
          <thead className="flex items-center py-3 bg-[#d9d9d980]">
            <TableColumnName name={'ID'} />
            <TableColumnName name={'Issues'} />
            <TableColumnName name={'Endpoint'} />
            <TableColumnName name={'Risk'} />
            <TableColumnName name={'Status'} />
            <TableColumnName name={'Assign'} />
            <TableColumnName name={'Report'} />
          </thead>
          <div className="w-full">
            <TableItems
            // loading={loading}
            // error={error}
            // data={data}
            // setPage={setPage}
            />
          </div>
        </table>
      </div>
    </div>
  );
};

export default FuzzingTest;
