import React from 'react';
import FormInput from './FormInput';
import UpdateButton from './UpdateButton';
import Schedule from './Schedule';
import ExpiresAt from './ExpiresAt';

const OauthForm = ({
  handleSubmit,
  handleInputChange,
  formData,
  handleCheckboxChange,
  isLoading,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        label={'Enter Access Token'}
        value={formData.auth_token}
        required={true}
        handleInputChange={(event) =>
          handleInputChange('auth_token', event.target.value)
        }
        placeholder={'some-long-auth-token'}
      />
      <Schedule handleInputChange={handleInputChange} formData={formData} />
      <ExpiresAt
        handleCheckboxChange={handleCheckboxChange}
        formData={formData}
      />

      <div className="w-full my-7 flex justify-end">
        <UpdateButton isLoading={isLoading} />
      </div>
    </form>
  );
};

export default OauthForm;
