import React, { useState } from "react";
import Input from "../../components/inputs/Input";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../config";

const RequestService = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [otherService, setOtherService] = useState("");

  const handleTextChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };
  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    const services = [...(data?.services || [])];
    if (services?.includes(value)) {
      // remove from list
      // get index
      const indexOfValue = services.indexOf(value);
      services.splice(indexOfValue, 1);
    } else {
      services.push(value);
    }
    setData({ ...data, services });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let services = [...data?.services];
      if (services?.includes("others")) {
        const indexOfValue = services.indexOf("others");
        services.splice(indexOfValue, 1);
        services = [
          ...services,
          ...otherService.split(",").map((item) => item.trim()),
        ];
      }
      const res = await fetch(`${BASE_URL}/public/request_service/`, {
        method: "POST",
        body: JSON.stringify({
          ...data,
          services,
        }),
      });
      setLoading(false);
      if (!res?.ok) throw new Error(res?.status);
      toast.success(
        "Rquest sent successfully. The admin will reach out to you very soon."
      );
    } catch (err) {
      toast.error(
        "Something went wrong. Try again! If the issue persists, contact the admin."
      );
    }
  };

  // constants
  const services = [
    { name: "QA Service", key: "qa_services" },
    { name: "Penetration Testing", key: "pentest" },
    { name: "Threat Intelligence", key: "threat_intelligence" },
    { name: "Takedown Services", key: "takedown" },
    { name: "Blockchain Testing", key: "blockchain" },
    { name: "Training", key: "smart_contract_audit" },
    { name: "Others", key: "others" },
  ];

  return (
    <div>
      <h2 className="text-center text-secondary">Enquire About our Services</h2>

      <p className="text-center text-gray font-bold">
        Get to see what
        <span className="text-secondary"> Wired Assurance</span> can do
      </p>

      <form className="bg-white md:p-[64px] p-[20px] mb-[32px] text-primary rounded" onSubmit={handleSubmit}>
        <div className="form-body">
          <div className="grid md:grid-cols-2 md:gap-4">
            <div className="">
              <Input
                label="First Name"
                name="first_name"
                id="first_name"
                value={data?.first_name || ""}
                onChange={handleTextChange}
                required
              />
            </div>
            <div className="">
              <Input
                label="Last Name"
                name="last_name"
                id="last_name"
                value={data?.last_name || ""}
                onChange={handleTextChange}
                required
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-4">
          <div className="">
            <Input
              label="Business Name"
              name="company_name"
              id="company_name"
              value={data?.company_name || ""}
              onChange={handleTextChange}
              required
            />
          </div>
          <div className="">
            <Input
              label="Business Email"
              type="email"
              name="work_email"
              id="work_email"
              value={data?.work_email || ""}
              onChange={handleTextChange}
              required
            />
          </div>
          </div>
          <div className="input-field-grid">
            <div className="radio md:mt-1 mt-2 mb-3">
              <span className="label font-bold">What kind of service do you need?</span>
              <div className="grid md:grid-cols-2 gap-x-4 gap-y-2 mt-3">
                {services.map((service) => {
                  return (
                    <div className="radio-item" key={service?.key}>
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="services"
                        id={service?.key}
                        onChange={handleCheckboxChange}
                        value={service?.key}
                      />
                      <label htmlFor={service.key}>
                        {service?.name}
                        {service?.key === "others" && (
                          <>
                            :
                            <input
                              type="text"
                              className="border border-[#ccd4da] ml-2 p-1 rounded w-[150px]"
                              disabled={!data?.services?.includes("others")}
                              value={otherService}
                              onChange={(e) => setOtherService(e.target.value)}
                            />
                          </>
                        )}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
         
          <div className="input-field-grid">
            <Input
              label="Additional Information"
              type="textarea"
              name="extra_information"
              id="extra_information"
              value={data?.extra_information || ""}
              onChange={handleTextChange}
            />
          </div>
        </div>
        <footer>
          <button className="btn btn-secondary px-8 text-[16px] mt-4" disabled={loading}>
            {loading ? "loading..." : "Submit"}
          </button>
        </footer>
      </form>
    </div>
  );
};

export default RequestService;
