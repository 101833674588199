import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";

const data01 = [{ name: "Active Campaigns", value: 90 }];

const IssuesChart = ({ chartColor, value }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleResize = () => {
    const width = document.getElementById("chart-container").offsetWidth;
    const height = document.getElementById("chart-container").offsetHeight;
    setDimensions({ width, height });
  };

  useEffect(() => {
    handleResize(); // initial dimensions
    window.addEventListener("resize", handleResize); // dimensions update  on resize
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { width, height } = dimensions;
  const cx = width / 2;
  const cy = height / 2;
  const innerRadius = Math.min(width, height) / 4;
  const outerRadius = Math.min(width, height) / 3;

  return (
    <div
      id="chart-container"
      style={{ width: "100%", height: "clamp(200px, 30vw, 300px)" }}
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data01}
            dataKey="value"
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
          >
            {data01.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={chartColor} />
            ))}

            <Label
              content={<CustomLabel labelText="Issues found" value={value} />}
              position="center"
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#000000"
        fontWeight={700}
        fontFamily="Nunito"
        fontSize="26"
      >
        {value}
      </text>
      <text
        x={cx}
        y={cy + 20}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontFamily="Nunito"
        fontSize="15"
        fontWeight="600"
      >
        {labelText}
      </text>
    </g>
  );
};

export default IssuesChart;
