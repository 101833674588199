import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ViewIssuesModal({ show, handleClose, data }) {
  return (
    <div>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title className="red-test text-primary">
            View Issue
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-[#09294d]">Path</h4>
          <p className="text-[#09294d]">{data?.endpoint__path}</p>
          <h4 className="text-[#09294d]">Issue</h4>
          <p className="text-[#09294d]">{data?.issue}</p>
          <h4 className="text-[#09294d]">Description</h4>
          <p className="text-[#09294d]">{data?.description}</p>
          <h4 className="text-[#09294d]">Risk</h4>
          <p className="text-[#09294d]">{data?.risk}</p>
          <h4 className="text-[#09294d]">Severity</h4>
          <p className="text-[#09294d]">{data?.severity}</p>
          <h4 className="text-[#09294d]">Remediation</h4>
          <p className="text-[#09294d]">{data?.remediation}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewIssuesModal;
