import React, { useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import ViewIssuesModal from "../modals/viewIssuesModal";
import ErrorDisplay from "../../components/Error";

const TableData = ({
  analytics,
  setFilterIssueType,
  setThreatLevel,
  setInput,
  isLoading,
}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="flex flex-col space-y-2  lg:flex-row lg:space-x-8 my-2 lg:items-center justify-between">
        <div className="float-left">
        <select
          className="border border-grey-500 rounded p-2 text-[12px]"
          onChange={(e) => setFilterIssueType(e.target.value)}
        >
          <option value="">Filter by Issue Type</option>
          <option value="Schema does not define the type of JSON values.">
            Schema does not define the type of JSON values
          </option>
          <option value="No maximum number of items defined for a string in response schema">
            No maximum number of items defined for a string in response schema
          </option>
          <option value="No Schema defined for GET">
            No Schema defined for GET
          </option>
          <option value="No Schema defined for POST">
            No Schema defined for POST
          </option>
        </select>
        </div>
        <div className="float-right ">
          <select
            className="border border-grey-500 rounded p-2  text-[12px] mr-2"
            onChange={(e) => setThreatLevel(e.target.value)}
          >
            <option value="">Filter by Threat Level</option>
            <option value="low">low</option>
            <option value="medium">medium</option>
            <option value="high">high</option>
            <option value="critical">critical</option>
          </select>
          <input
            type="text"
            placeholder="Search by Endpoint"
            className="border border-grey-500 rounded p-2  text-[12px]"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
      </div>

      <div className="relative overflow-x-auto">
        <table className="w-full bg-white">
          <thead>
            <tr className="text-[#09294d] bg-[#00000010]">
              <th
                scope="col"
                className="px-6 py-3 text-nowrap w-[200px] max-width-column"
              >
                Path
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-nowrap w-[200px] max-width-column"
              >
                Issue
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-nowrap w-[200px] max-width-column"
              >
                Severity
              </th>
            </tr>
          </thead>

          <tbody className="bg-white text-[14px]">
            {!isLoading &&
              analytics?.length > 0 &&
              analytics?.map((item, key) => (
                <tr
                  className="text-[#09294d] cursor-pointer hover:bg-[#D9D9D9]"
                  key={item.id}
                  onClick={() => {
                    handleShow();
                    setData(item);
                  }}
                >
                  <td className="px-6 py-[10px] w-[200px] text-nowrap ">
                    {item.endpoint__path}
                  </td>
                  <td className="px-6 py-[10px] w-[200px] text-nowrap ">
                    {item.issue}
                  </td>
                  <td className="px-6 py-[10px] w-[200px] font-bold">
                    <Badge
                      className={
                        item.severity === "high"
                          ? "bg-danger text-white p-2"
                          : item.severity === "critical"
                          ? "bg-danger text-white p-2"
                          : item.severity === "medium"
                          ? "bg-warning text-white p-2"
                          : "bg-info text-white p-2"
                      }
                    >
                      {item.severity}
                    </Badge>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {isLoading && (
          <div className="w-[full] flex justify-center mx-auto text-center h-[300px] pt-[50px]">
            <Spinner
              animation="border"
              variant="secondary"
              className="text-[50px]"
            />
          </div>
        )}
        {!isLoading && analytics?.length === 0 && (
          <div className="text-center">
            <ErrorDisplay errorMessage="No Data Found" errorCode={404} />
          </div>
        )}
      </div>
      <ViewIssuesModal
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        data={data}
      />
    </div>
  );
};

export default TableData;
