import React from 'react';

const ViewIssueButton = ({ name, handleClick }) => {
  return (
    <button className="rounded-[4px] border-none w-[200px] bg-transparent px-[5px] py-[5px]">
      <span className="text-black font-bold text-sm font-[Poppins]">
        {name}
      </span>
    </button>
  );
};

export default ViewIssueButton;
