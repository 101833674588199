import './index.css';
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';

export function PaginationWithButton({ pageCount, handlePageClick }) {
  return (
    <>
      <ReactPaginate
        containerClassName={'pagination-container'}
        pageClassName={'pagination-page'}
        activeClassName={'pagination-page__active'}
        nextLinkClassName={'pagination-next'}
        previousLinkClassName={'pagination-prev'}
        disabledClassName={'pagination__btn-disabled'}
        breakLabel="..."
        previousLabel={
          pageCount > 1 ? (
            <IconContext.Provider value={{ color: '#344054', size: '36px' }}>
              <span className="mr-3 text-gray-500 text-sm font-medium font-['Inter'] leading-tight">
                Prev
              </span>
            </IconContext.Provider>
          ) : null
        }
        nextLabel={
          pageCount > 1 ? (
            <IconContext.Provider value={{ color: '#344054', size: '36px' }}>
              <span className="ml-3  text-gray-500 text-sm font-medium font-['Inter'] leading-tight">
                Next
              </span>
            </IconContext.Provider>
          ) : null
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
      />
    </>
  );
}
