import React, { useState, useEffect, useContext } from "react";
import { useFetch } from "../context";
import { useCollectionContext } from "./Collection";

const VulContext = React.createContext();

export const VulProvider = ({ children }) => {
  const { getEndpointDetails, getEndPointsAnalyticsDetails } = useFetch();
  const [vulnerabilities, setVulnerabilities] = useState([]);
const [dast, setDast] = useState([])
const [sast, setSast] = useState([])
const [iast, setIast] = useState([])
const { selectedCollectionId } = useCollectionContext();

  const [endpoint_id, setEndpointId] = useState(0);

  const retrieveVul = () => {
    let data = JSON.parse(localStorage.getItem("selected_endpoint"))
    let dynamic_result = parseDynamic(data?.security_scan_details?.dynamic_scan);
    let static_result = parseConformance(data?.conformance_details)
    let runtime_result = parseRuntime(data?.security_scan_details?.runtime_scan)
    const vul_array = [...dynamic_result, ...static_result, ...runtime_result];

    return vul_array
  }

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const data = await getEndpointDetails(selectedCollectionId);
  //       setDast(data?.security_scan_details?.dynamic_scan)
  //       setSast(data?.conformance_details)
  //       setIast(data?.security_scan_details?.runtime_scan)
  //            } catch (err) {
  //       console.log("err");
  //     }
  //   };

  //   getData();
  // }, [getEndpointDetails, selectedCollectionId]);


  const parseDynamic = (data) => {
    let parsedData = [];
    for (let index = 0; index < data?.length; index++) {
      let parse_log = {
        threat_name: data[index]?.threat_name,
        attack_group: data[index]?.attack_group,
        category: "Dynamic Scan",
        severity: data[index]?.severity,
        description: data[index]?.description,
        remediation: data[index]?.remediation,
        risk: data[index]?.risk,
        execution_time: "",
        request_header: data[index]?.request_header,
        request_body: data[index]?.request_payload,
        issue_type: "",
        response_code: data[index]?.response_code,
        response_header: data[index]?.response_header,
        response_body: data[index]?.response_payload,
        count: data[index]?.count,
        cve_id: data[index]?.cve_id,
        cve_score: data[index]?.cve_score,
        environment: "",
        references: data[index]?.references,
      };
      parsedData.push(parse_log);
    }
   return parsedData
  };

  const parseRuntime = (data) => {
    console.log(data)
    let parsedData = [];
    for (let index = 0; index < data?.length; index++) {
      let parse_log = {
        threat_name: data[index]?.attack_type,
        attack_group: data[index]?.category,
        category: "Detected Attack",
        severity: data[index]?.severity,
        description: data[index]?.message,
        remediation: ``,
        risk: data[index]?.risk,
        execution_time: data[index]?.created_at,
        request_header: data[index]?.suspicious_requests?.request_header,
        request_body: data[index]?.suspicious_requests?.request_payload,
        issue_type: "",
        response_code: data[index]?.activities?.response_code,
        response_header: data[index]?.response_header,
        response_body: data[index]?.response_payload,
        count: data[index]?.count,
        cve_id: data[index]?.cve_id,
        cve_score: data[index]?.cve_score,
        environment: "",
        references: data[index]?.references,
        activities: data[index]?.activities
      };
      parsedData.push(parse_log);
    }
   return parsedData
  };

  // const parseStatic = (data) => {
  //   let parsedData = []
  //   for (let index = 0; index < data.length; index++) {
  //      let parse_log = {
  //       "threat_name": data?.issue,
  //       "attack_group": "",
  //       "category": "Static Scan",
  //       "severity": "",
  //       "description": "",
  //       "remediation": "",
  //       "risk": "",
  //       "execution_time": "",
  //       "request_header": "",
  //       "request_body": "",
  //       "issue_type": "",
  //       "response_code": "",
  //       "response_header": "",
  //       "response_body": ""
  //      }
  //       parsedData.push(parse_log)
  //   }
  //   setVulnerabilities(prevVulnerabilities => [
  //       ...prevVulnerabilities,
  //       ...parsedData
  //     ]);
  // }

  const parseConformance = (data) => {
    let parsedData = [];
    for (let index = 0; index < data?.length; index++) {
      let parse_log = {
        threat_name: data[index]?.issue,
        attack_group: "vulnerability",
        category: "Conformance Scan",
        severity: data[index]?.severity?.toLowerCase(),
        description: data[index]?.description,
        remediation: data[index]?.remediation,
        risk: data[index]?.risk,
        execution_time: data[index]?.created_at,
        request_header: "",
        request_body: "",
        issue_type: "",
        response_code: "",
        response_header: "",
        response_body: "",
      };
      parsedData.push(parse_log);
    }
    return parsedData
  };
  
  const contextValue = {
    vulnerabilities,
    setEndpointId,
    retrieveVul
  };

  return (
    <VulContext.Provider value={contextValue}>{children}</VulContext.Provider>
  );
};

export const useVulContext = () => {
  const context = useContext(VulContext);
  if (context === undefined) {
    throw new Error("useVulContext must be used within a VulProvider");
  }
  return context;
};
