import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
  Tspan,
} from "@react-pdf/renderer";
import logo from "./logo-dark.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "100%",
    padding: "5%",
    fontSize: "12px",
    height: "100%",
    backgroundImage: `url(${logo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: "14px",
    color: "#09294d",
    fontWeight: "800",
  },
  header: {
    fontSize: "14px",
    color: "#4465be",
    fontWeight: "900",
    marginBottom: "20px",
  },
});

// TODO ADD ENVIRONMENT URL AND PATH TO COVER PAGE AND SCOPE
// WRITE EXECUTIVE SUMMARY
// Create Document Component
const VulReport = (props) => {
  return (
    <Document>
      <Page style={[styles.body, { padding: "5%", fontSize: "12px" }]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", position: 'absolute', top: 0, padding: '5px', width: '100%' }}>
          <Text style={{ fontSize: "10px", color: "#e8362a", left: 0 }} fixed>
            Confidential
          </Text>
          <Image
            style={{ width: "30px", height: "30px", right: 0 }}
            fixed
            src={`${logo}`}
          />
        </View>

        <View style={{ margin: "auto", width: '50%' }}>
          <Image
            style={{ width: "100px", height: "100px"}}
            src={`${logo}`}
          />
           <Text style={{color: '#e8362a', margin: '10px 0'}}>Vulnerability Assessment Report</Text>
          {/* <Text style={{color: '##417bbc', margin: '10px 0'}}>{localStorage.getItem('collection_name')}</Text> */}

        </View>
      </Page>
      <Page style={[styles.body, { padding: "5%", fontSize: "10px" }]}>
      <View style={{ flexDirection: "row", justifyContent: "space-between", position: 'absolute', top: 0, padding: '5px', width: '100%' }}>
          <Text style={{ fontSize: "10px", color: "#e8362a", left: 0 }} fixed>
            Confidential
          </Text>
          <Image
            style={{ width: "30px", height: "30px", right: 0 }}
            fixed
            src={`${logo}`}
          />
         
        </View>

        <View style={{ marginBottom: "10px" }}>
          <Text style={styles.title}>Scope</Text>
          <Text style={styles.text}>
            Vulnerability Assessment for Endpoint Route{" "}
            <Link>{localStorage.getItem("endpoint_name")}</Link>{" "}
          </Text>
        </View>
        <View style={{ marginBottom: "10px" }}>
          <Text style={styles.title}>Limitation</Text>
          <Text style={styles.text}>
            1. The entire test was carried out with no prior knowledge of the
            systems and applications.{" "}
          </Text>
          <Text style={styles.text}>
            2. All test were carried out without any known credentials to
            systems and applications.{" "}
          </Text>
          <Text style={styles.text}>
            3. Attacks carried out such as  DoS attacks can affect systems availability.
          </Text>
        </View>

        <View style={{ marginBottom: "10px" }}>
          <Text style={styles.title}>Confidentiality</Text>
          <Text style={styles.text}>
            This document contains sensitive and/or confidential information, do
            not distribute, email, fax or transfer via any electronic mechanism
            without proper authorization. Information contained with in this
            document should be handled with appropriate caution. While
            reasonable attempts have been made to confirm the accuracy of the
            data contained herein, Wired Assurance, assumes no liability for the
            completeness, use of, or conclusions drawn from such data.
          </Text>
        </View>

        <View style={{ marginBottom: "10px" }}>
          <Text style={styles.title}>Disclaimer</Text>
          <Text style={styles.text}>
            This, or any other, Security Audit cannot and does not guarantee
            security. Wired Assurance makes no warranty or claim of any kind,
            whatsoever, about the accuracy or usefulness of any information
            provided herein. By using this information you agree that Wired Assurance shall be held harmless in any event. Indusface WAS makes this
            information available solely under its Terms of Service Agreement
            published at wiredassurance.com/termsandconditions.
          </Text>
        </View>

        <View style={{ marginBottom: "10px" }}>
          <Text style={styles.title}>Executive Summary</Text>
          <Text style={styles.text}>
          A comprehensive vulnerability assessment and intrusive attack were conducted on the {localStorage.getItem("collection_name")} platform, accessible through the URL prod URL. The purpose of this assessment was to identify potential security weaknesses within the system and evaluate its resilience against intrusive attacks.

Throughout the assessment, vulnerabilities were identified within the {localStorage.getItem("endpoint_name")} path, a critical component of the system. These vulnerabilities pose potential risks to the security and integrity of the system, including unauthorized access, data leakage, and potential service disruption.

Detailed findings, including vulnerability descriptions, associated risks, and recommended remediation strategies, have been meticulously documented in the vulnerability assessment report. The report provides a comprehensive overview of the security landscape and outlines actionable steps to mitigate the identified vulnerabilities, ensuring the continued security and stability of the {localStorage.getItem("collection_name")} platform.
          </Text>
        </View>
      </Page>
      <Page style={[styles.body, { padding: "5%", fontSize: "10px" }]}>
      <View style={{ flexDirection: "row", justifyContent: "space-between", position: 'absolute', top: 0, padding: '5px', width: '100%' }}>
          <Text style={{ fontSize: "10px", color: "#e8362a", left: 0 }} fixed>
            Confidential
          </Text>
          <Image
            style={{ width: "30px", height: "30px", right: 0 }}
            fixed
            src={`${logo}`}
          />
        </View>
        <Text style={styles.header}>Vulnerability Summary</Text>

        <View style={{ marginBottom: "10px",  flexDirection: "row", justifyContent: "space-between", }}>
          <Text style={styles.title}>Total Vulnerabilities Found: </Text>
          <Text style={styles.text}>{props?.data.length}</Text>
        </View>
        <Text style={{fontSize: '8px', fontStyle: 'italic', 'color': '#4a9ad5',  marginBottom: "20px",}}>The total number of vulnerabilities found on this endpoint</Text>

        <View style={{ marginBottom: "10px",  flexDirection: "row", justifyContent: "space-between", }}>
          <Text style={styles.title}>Total Issues found from Dynamic Scan: </Text>
          <Text style={styles.text}>{props?.data?.filter((d) => d.category === "Dynamic Scan").length}</Text>
        </View>
        <Text style={{fontSize: '8px', fontStyle: 'italic', 'color': '#4a9ad5',  marginBottom: "20px",}}>The total number of vulnerabilities found from dynamic scans made to this endpoint</Text>


        <View style={{ marginBottom: "10px",  flexDirection: "row", justifyContent: "space-between", }}>
          <Text style={styles.title}>Total Issues found from Static Scan: </Text>
          <Text style={styles.text}>{props?.data?.filter((d) => d.category === "Conformance Scan").length}</Text>
        </View>
        <Text style={{fontSize: '8px', fontStyle: 'italic', 'color': '#4a9ad5',  marginBottom: "20px",}}>The total number of vulnerabilities found from static code analysis excluding source code reviews</Text>


        <View style={{ marginBottom: "10px",  flexDirection: "row", justifyContent: "space-between", }}>
          <Text style={styles.title}>Total Critical Risk Issues: </Text>
          <Text style={styles.text}>{props?.data?.filter((d) => d.severity === "high").length + props?.data?.filter((d) => d.severity === "critical").length}</Text>
        </View>
        <Text style={{fontSize: '8px', fontStyle: 'italic', 'color': '#4a9ad5',  marginBottom: "20px",}}>The total number of critical vulnerabilities found on this endpoint</Text>


        <View style={{ marginBottom: "10px",  flexDirection: "row", justifyContent: "space-between", }}>
          <Text style={styles.title}>Total Medium Risk Issues: </Text>
          <Text style={styles.text}>{props?.data?.filter((d) => d.severity === "medium").length}</Text>
        </View>
        <Text style={{fontSize: '8px', fontStyle: 'italic', 'color': '#4a9ad5',  marginBottom: "20px",}}>The total number of medium risk vulnerabilities found on this endpoint</Text>


        <View style={{ marginBottom: "10px",  flexDirection: "row", justifyContent: "space-between", }}>
          <Text style={styles.title}>Total Low Risk Issues: </Text>
          <Text style={styles.text}>{props?.data?.filter((d) => d.severity === "low").length}</Text>
        </View>
        <Text style={{fontSize: '8px', fontStyle: 'italic', 'color': '#4a9ad5',  marginBottom: "20px",}}>The total number of low or informational vulnerabilities found on this endpoint</Text>


      </Page>
      <Page style={[styles.body, { padding: "5%", fontSize: "10px" }]}>
      <View style={{ flexDirection: "row", justifyContent: "space-between", position: 'absolute', top: 0, padding: '5px', width: '100%' }}>
          <Text style={{ fontSize: "10px", color: "#e8362a", left: 0 }} fixed>
            Confidential
          </Text>
          <Image
            style={{ width: "30px", height: "30px", right: 0 }}
            fixed
            src={`${logo}`}
          />
        </View>
        <Text style={styles.header}>Vulnerability Details</Text>
        {props?.data?.map((v, index) => (
          <View  style={{
            marginBottom: "20px"
          }}
          key={index}>
            <View>
            <View
              style={{
                marginBottom: "20px",
                backgroundColor: "#edf0ff",
                borderRadius: '7px',
                padding: '5px',
              }}
            >
              <View
                style={{ flexDirection: "row", justifyContent: "space-between", marginRight: '50px', marginBottom: '20px' }}
              >
                <Text style={{fontSize: '10px', fontWeight: '800', color:'#09294d' }}>Endpoint ID:</Text>
                <Text style={{fontSize: '10px', fontWeight: '800' }}>{props?.endpoint_id}</Text>
              </View>
              <View
                style={{ flexDirection: "row", justifyContent: "space-between", marginRight: '50px',  marginBottom: '20px'  }}
              >
                <Text style={{fontSize: '10px', fontWeight: '800', color:'#09294d' }}>Found On:</Text>
                <Text style={{fontSize: '10px', fontWeight: '800' }}>{v.execution_time}</Text>
              </View>
              <View
                style={{ flexDirection: "row", justifyContent: "space-between", marginRight: '50px' ,  marginBottom: '20px' }}
              >
                <Text style={{fontSize: '10px', fontWeight: '800', color:'#09294d' }}>Severity: </Text>
                <Text style={{fontSize: '10px', fontWeight: '800', color: v.severity === "low"? "#0153cc": v.severity === "medium"? "#ffc000" : '#e8362a'}}>{v.severity}</Text>
              </View>
            </View>
            <View
                style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: '10px' }}
              >
                <Text style={{fontSize: '10px', fontWeight: '800', color:'#09294d', marginRight: '10px' }}>Issue Name:</Text>
                <Text style={{fontSize: '10px', fontWeight: '800' }}>{v.threat_name}</Text>
              </View>
              <View
                style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: '10px' }}
              >
                <Text style={{fontSize: '10px', fontWeight: '800', color:'#09294d', marginRight: '10px' }}>Issue Category:</Text>
                <Text style={{fontSize: '10px', fontWeight: '800' }}>{v.category}</Text>
              </View>
              <View
                style={{ flexDirection: "row", justifyContent: "space-between", marginBottom: '10px' }}
              >
                <Text style={{fontSize: '10px', fontWeight: '800', color:'#09294d', marginRight: '10px' }}>Issue Group:</Text>
                <Text style={{fontSize: '10px', fontWeight: '800' }}>{v.attack_group}</Text>
              </View>
            </View>

            <View style={{ marginBottom: "10px" }}>
              <Text style={[styles.title, { marginBottom: "10px" }]}>Description</Text>
              <Text style={styles.text}>
                {v.description}
              </Text>
            </View>

            <View style={{ marginBottom: "10px" }}>
              <Text style={[styles.title, { marginBottom: "10px" }]}>Risk</Text>
              <Text style={styles.text}>
                {v.risk}
              </Text>
            </View>

            <View style={{ marginBottom: "10px" }}>
              <Text style={[styles.title, { marginBottom: "10px" }]}>Remediation</Text>
              <Text style={styles.text}>
                {v.remediation}
              </Text>
            </View>

            <View style={{ marginBottom: "10px" }}>
              <Text style={[styles.title, { marginBottom: "10px" }]}>References</Text>
              <Text style={styles.text}>
               {typeof(v.references) === "object" ? "Request full report for this reference" : v.references}
              </Text>
            </View>
          </View>
        ))}

        <View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
};

export default VulReport;
