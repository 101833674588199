import { CiSearch } from 'react-icons/ci';

export default function SearchBar({
  queryTestCaseByPathBody,
  setQueryTestCaseByPathBody,
  handleSubmitQueryTestCaseByPath,
  setShouldSkipAllTestCasesQuery,
  setShouldReturnAllTestCaseData,
}) {
  const isEmptyPathBody = queryTestCaseByPathBody.length === 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEmptyPathBody) return;
    setShouldSkipAllTestCasesQuery(true);
    setShouldReturnAllTestCaseData(false);
    handleSubmitQueryTestCaseByPath();
  };

  const onChange = (e) => setQueryTestCaseByPathBody(e.target.value);

  return (
    <div className="w-full">
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="relative w-full">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <CiSearch />
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full p-[10px] ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Search..."
          required
          value={queryTestCaseByPathBody}
          onChange={onChange}
        />
        <button
          onClick={handleSubmit}
          type="submit"
          disabled={isEmptyPathBody}
          className="text-white absolute end-1 md:end-2.5 bottom-1 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[10px] md:text-sm px-[2px] py-[2px] md:px-2 md:py-1 btn btn-secondary"
        >
          Search
        </button>
      </div>
    </div>
  );
}
