import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFetch } from "../../../context";

function RunDynamicScanModal(props) {
  const [show, setShow] = useState(false);
  const [scan_type, setScanType] = useState("lite");
  const [env, setEnv] = useState("prod");
  const [success, setSuccessful] = useState("test");

  const handleClose = () => setShow(false);
  const { runDynamicResult } = useFetch();

  const setup = () => {
    runDynamicResult(env, scan_type);
    setSuccessful(true)
    toast("Vulnerability scan running, You will be notified when complete")
    handleClose()
  };
  const handleShow = () => setShow(true);

  return (
    <div>
      <button type="button" className="btn btn-primary" onClick={handleShow}>
        Run Vulnerability Scan
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="red-test">
            Endpoint Vulnerability Scan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="text-primary">
              <Form.Group>
                <label htmlFor="selectscantype">
                  What kind of scan would you want to run?
                </label>
                <select
                  className="form-control"
                  id="selectscantype"
                  onChange={(e) => setScanType(e.target.value)}
                >
                  <option value="lite">Lite</option>
                  <option value="deep">Deep</option>
                </select>
              </Form.Group>

              {scan_type === "lite" ? (
                <p className="text-warning strong mt-4 mb-4">
                  <i className="mdi mdi-alert-circle-outline" /> Lite Scans can
                  take up to 2 hours
                </p>
              ) : scan_type === "deep" ? (
                <p className="text-warning strong mt-4 mb-4">
                  <i className="mdi mdi-alert-circle-outline" /> Deep Scans can
                  take up to 2 days
                </p>
              ) : null}

              <Form.Group>
                <label htmlFor="selectscantype">
                  What environment will you like to scan?
                </label>
                <select
                  className="form-control"
                  id="selectscantype"
                  onChange={(e) => setEnv(e.target.value)}
                >
                  <option value="prod">Prod</option>
                  {/* <option value="test">Test</option>
                  <option value="other">Other</option> */}
                </select>
              </Form.Group>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel{" "}
          </Button>
          <Button variant="secondary" onClick={setup}>
            Run {scan_type} Scan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RunDynamicScanModal;
