import React, { useState, useEffect, useCallback } from 'react';
import { geoWinkel3 } from 'd3-geo-projection';

import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Line,
  Marker,
  Sphere,
} from 'react-simple-maps';
import { geoPath, geoMercator } from 'd3-geo';

const geoUrl = 'https://unpkg.com/world-atlas@2.0.2/countries-110m.json';

export function EmptyMapChart() {
  const width = 700;
  const height = 600;

  const projection = geoWinkel3()
    .translate([width / 2, height / 2])
    .scale(150);

  return (
    <div className="map mb-4 lg:mb-0 w-full lg:mr-5 lg:w-[75%] card">
      <ComposableMap
        width={width}
        height={height}
        projection={projection}
        fill="#023673"
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography key={geo.rsmKey} geography={geo} />
            ))
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}

const LINE_ANIMATION_INTERVAL = 1;
const MARKER_RADIUS = 7;
const MARKER_FONT_SIZE = 17;
const LINE_STROKE_WIDTH = 5;
const CONTROL_POINT_1_RATIO = 0.5;
const CONTROL_POINT_2_RATIO = 0.9;
const INTERVAL_DURATION = 50;

export const MapChart = ({ threatMapData }) => {
  const [lineCoordinates, setLineCoordinates] = useState([]);
  const [renderedCities, setRenderedCities] = useState(new Set());
  const [error, setError] = useState(null);

  const formatLabelName = (name) => {
    let countryName;

    if (!name || name === 'null' || name === undefined) return '';

    if (name.split(' ')?.length > 1) {
      const splitName = name.split(' ');
      const firstTwoWords = splitName.slice(0, 2);
      countryName = firstTwoWords.join(' ');
    } else countryName = name;

    return countryName;
  };

  useEffect(() => {
    const initialLineCoordinates = threatMapData?.map((route) => ({
      start: route.from_info.coord,
      end: route.to_info.coord,
      progress: 0,
      duration: calculateDuration(route.from_info.coord, route.to_info.coord),
      currentPosition: route.from_info.coord,
    }));

    setLineCoordinates(initialLineCoordinates);

    const animationInterval = setInterval(() => {
      setLineCoordinates((prevCoordinates) => {
        const nextCoordinates = prevCoordinates?.map((coordinate) => {
          const { start, end, progress, duration } = coordinate;
          const newProgress = progress + LINE_ANIMATION_INTERVAL / duration;

          if (newProgress >= 1) {
            // Reset progress and current position to start
            return {
              start,
              end,
              progress: 0,
              duration,
              currentPosition: start,
            };
          } else {
            const newPosition = interpolateCoordinates(start, end, newProgress);
            return {
              start,
              end,
              progress: newProgress,
              currentPosition: newPosition,
              duration,
            };
          }
        });

        return nextCoordinates;
      });
    }, LINE_ANIMATION_INTERVAL);

    return () => clearInterval(animationInterval);
  }, [threatMapData]);

  const width = 700;
  const height = 600;

  const projection = geoWinkel3()
    .translate([width / 2, height / 2])
    .scale(150);

  return (
    <div className="map w-full mb-4 lg:mb-0 lg:mr-5 lg:w-[75%] card">
      <ComposableMap width={width} height={height} projection={projection}>
        {/* <Graticule stroke="#999" />
        <Sphere stroke="#06F" strokeWidth={0.5} /> */}
        <Geographies
          geography={geoUrl}
          style={{
            default: {
              fill: '#EEE',
            },
            hover: {
              fill: '#F53',
            },
            pressed: {
              fill: '#E42',
            },
          }}
        >
          {({ geographies }) =>
            geographies?.map((geo) => (
              <Geography key={geo.rsmKey} geography={geo} fill="#023673" />
            ))
          }
        </Geographies>
        {lineCoordinates.map(({ start, end, currentPosition }, index) => (
          <React.Fragment key={index}>
            {/* line from start to current position */}
            {/* <Line
              from={start}
              to={currentPosition}
              stroke="yellow"
              strokeWidth={LINE_STROKE_WIDTH}
              strokeLinecap="round"
            /> */}
            {/* line from current position to end */}
            {/* <Line
              from={currentPosition}
              to={end}
              stroke="yellow"
              strokeWidth={LINE_STROKE_WIDTH}
              strokeLinecap="round"
            /> */}
            <Marker coordinates={currentPosition}>
              <circle r={MARKER_RADIUS} fill="red" />
            </Marker>
            <Marker coordinates={start}>
              <text
                fontSize="14"
                textAnchor="middle"
                fill="red"
                fontWeight="bold"
                dy="-10px"
                dx="-5px"
              >
                {formatLabelName(threatMapData[index]?.from_info?.city)}
              </text>
            </Marker>
            <Marker coordinates={end}>
              <text
                fontSize="14"
                textAnchor="middle"
                fill="red"
                fontWeight="bold"
                dy="-25px"
                dx="-10px"
              >
                {formatLabelName(threatMapData[index]?.to_info?.city)}
              </text>
            </Marker>
          </React.Fragment>
        ))}
      </ComposableMap>
    </div>
  );
};

const calculateDuration = ([x1, y1], [x2, y2]) => {
  const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  return distance * LINE_ANIMATION_INTERVAL;
};

const interpolateCoordinates = ([x1, y1], [x2, y2], t) => {
  const cx1 = x1 + (x2 - x1) * CONTROL_POINT_1_RATIO;
  const cy1 = y1 + (y2 - y1) * 0.1;
  const cx2 = x1 + (x2 - x1) * CONTROL_POINT_2_RATIO;
  const cy2 = y1 + (y2 - y1) * 0.9;
  const x =
    x1 * Math.pow(1 - t, 3) +
    3 * cx1 * Math.pow(1 - t, 2) * t +
    3 * cx2 * (1 - t) * t * t +
    x2 * Math.pow(t, 3);
  const y =
    y1 * Math.pow(1 - t, 3) +
    3 * cy1 * Math.pow(1 - t, 2) * t +
    3 * cy2 * (1 - t) * t * t +
    y2 * Math.pow(t, 3);
  return [x, y];
};
