import React from "react";

const CountBox = ({ title, value, icon, theme }) => {
  function formatNumber(number) {
    if (typeof number !== "number") {
      throw new Error("Invalid input. Please provide a number.");
    }

    if (Math.abs(number) >= 1e9) {
      return (number / 1e9).toFixed(2) + "B";
    }

    if (Math.abs(number) >= 1e6) {
      return (number / 1e6).toFixed(2) + "M";
    }

    if (Math.abs(number) >= 1e3) {
      return (number / 1e3).toFixed(2) + "K";
    }
    if (number % 1 !== 0) {
      return number.toFixed(2);
    }
    return number;
  }

  return (
    <div className="card p-2 w-full mr-2 mb-2">
      <div className="w-full p-2">
        <div className="text-right">
          <i className="mdi mdi-alert-circle text-[20px] text-secondary"></i>
        </div>
        <div className="mb-4 mt-2">
          <p className="font-epilogue font-bold text-[12px] overflow-hidden text-gray-600  w-full ">
            {title}
          </p>
        </div>
        <h4
          className={`font-epilogue font-bold text-[20px] text-${theme} rounded-t-[10px] w-full truncate absolute bottom-0`}
        >
          {formatNumber(value)}
        </h4>
      </div>
    </div>
  );
};

export default CountBox;
