// import React from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const user = () => {
  return localStorage.getItem("watoken");
};
const checkResponse = (response, action) => {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 204
  ) {
    return {
      message: `${action} Successful`,
      data: response.data,
      success: true,
      error: false,
      code: response.status
    };
  }
  if (response?.status === 400 || response?.request?.status === 400 || response?.response?.status === 400) {
    return {
      message: `${action}`,
      data: [],
      success: false,
      error: response?.response?.data?.message,
      code: 400
    };
  }
  if (response?.status === 401 || response?.request?.status === 401 || response?.response?.status === 401) {
    return {
      message: `${action}`,
      data: [],
      success: false,
      error: response?.response?.data?.message,
      code: 401

    };
  }
  if (response?.status === 404 || response?.request?.status === 404 || response?.response?.status === 404) {
    return {
      message: `${action}`,
      data: [],
      success: false,
      error: response?.response?.data?.message,
      code: 404

    };
  }
  if (response?.status === 500 || response?.request?.status === 500 || response?.response?.status === 404) {
    return {
      message: `Failed: ${action}`,
      data: [],
      success: false,
      error: `Failed: ${action}`,
      code: 500
    };
  }
  return {
    message: `Failed: ${action}`,
    data: [],
    success: false,
    error: response?.response?.data?.message,
    code: response?.status || response?.request?.status
  };
};
export const onboard = async (
  first_name,
  last_name,
  gender,
  phone_number,
  company_name,
  company_size,
  industry,
  position,
  source,
  selectedCountry
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/account/register_user/`,
      {
        first_name,
        last_name,
        gender,
        phone_number,
        company_name,
        company_size,
        industry,
        position,
        source,
        country: selectedCountry,
      },
      {
        headers: {
          Authorization: `Token ${user()}`,
        },
      }
    );

    return checkResponse(response, "Onboarding");
  } catch (error) {
    const res = JSON.stringify(
      "An unexpected error occurred, please contact us"
    );
    return { success: false, error: res };
  }
};

export const getUser = () => {};

// API Collections
export const GetCollections = async () => {
  try {
    const response = await axios.get(`${apiUrl}/scan/collections/`, {
      headers: { Authorization: `Token ${user()}` },
    });
    return checkResponse(response, "Retrieve Collections");
  } catch (error) {
    return checkResponse(error, "Retrieve Collections");
  }
};

export const GetSingleCollections = async () => {
  const document_id = localStorage.getItem("tempId")
    ? localStorage.getItem("tempId")
    : localStorage.getItem("collection_id");
  try {
    const response = await axios.get(
      `${apiUrl}/scan/collections/${document_id}/`,
      {
        headers: { Authorization: `Token ${user()}` },
      }
    );
    return checkResponse(response, "Retrieve Collections");
  } catch (error) {
    return checkResponse(error, "Retrieve Collections");
  }
};

export const AddUserAccess = async (user_email, access_level) => {
  const document_id = localStorage.getItem("collection_id")
  try {
    const response = await axios.post(
      `${apiUrl}/scan/share_collection/`,
      { user_email, access_level, document_id },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Add User Access");
  } catch (error) {
    return checkResponse(error, "Add User Access");
  }
};

export const DeleteUserAccess = async (user_id,) => {
  const document_id = localStorage.getItem("collection_id")

  try {
    const response = await axios.get(
      `${apiUrl}/scan/delete_access/?user_id=${user_id}&document_id=${document_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Delete User Access");
  } catch (error) {
    return checkResponse(error, "Delete User Access");
  }
};

export const UploadCollections = async (formData) => {
  try {
    const response = await axios.post(`${apiUrl}/scan/collections/`, formData, {
      headers: {
        Authorization: `Token ${user()}`,
        "Content-Type": "multipart/form-data",
      },
    });
    localStorage.setItem("collection_id", response?.data?.id);
    localStorage.setItem("collection_name", response?.data?.name);
    localStorage.setItem("collection_token", response?.data?.collection_token);

    return checkResponse(response, "Upload New Collection");
  } catch (error) {
    return { success: false, error: error?.response?.data };
  }
};

export const CreateProject = async (name, description) => {
  try {
    const response = await axios.post(`${apiUrl}/scan/create_project/`, {name, description}, {
      headers: {
        Authorization: `Token ${user()}`,
        "Content-Type": "application/json",
      },
    });
    return checkResponse(response, "Upload New Collection");
  } catch (error) {
    return { success: false, error: error?.response?.data?.message };
  }
};


export const DeleteCollection = async (document_id) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/scan/collections/${document_id}/`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Delete Collection");
  } catch (error) {
    return checkResponse(error, "Delete Collection");
  }
};

export const PatchCollection = async (formData, id) => {
  if (!id || id === undefined) {
    id = localStorage.getItem("collection_id")
  }
  try {
    const response = await axios.patch(
      `${apiUrl}/scan/collections/${id}/`,
      formData,
      {
        headers: {
          Authorization: `Token ${user()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return checkResponse(response, "Update Collection");
  } catch (error) {
    return checkResponse(error, "Update Collection");
  }
};

// Conformance Scans
export const GetConformance = async (environment) => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/conformance_scan/?document_id=${collection_id}&environment=${environment}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Conformance Scan");
  } catch (error) {
    return checkResponse(error, "Retrieve Conformance Scan");
  }
};

export const RunConformance = async (environment) => {
  try {
    const document_id = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/conformance_scan/`,
      { document_id, environment },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Run Conformance Scan");
  } catch (error) {
    return checkResponse(error, "Run Conformance Scan");
  }
};

// Security Scans
export const GetSecurity = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/security_scan/?document_id=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Security Scan");
  } catch (error) {
    return checkResponse(error, "Retrieve Security Scan");
  }
};

export const RunSecurity = async () => {
  try {
    const document_id = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/security_scan/`,
      { document_id },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Run New Security Scan");
  } catch (error) {
    return checkResponse(error, "Run New Security Scan");
  }
};

export const GetDetectableThreats = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/detections_analytics/?collection_id=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Get Detectable Threats");
  } catch (error) {
    return checkResponse(error, "Get Detectable Threats");
  }
};

export const GetRealTimeThreats = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/security_analytics/?collection_id=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Get Real Time Threats");
  } catch (error) {
    return checkResponse(error, "Get Real Time Threats");
  }
};

// Automated Scans
export const GetAutomatedTestDetails = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/r_automated_test/?collection=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Automated Scan");
  } catch (error) {
    return checkResponse(error, "Retrieve Automated Scan");
  }
};

// Analytic Scans
export const GetAnalyticsDetails = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/main_analytics/?collection=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Analytics");
  } catch (error) {
    return checkResponse(error, "Retrieve Analytics");
  }
};

// Functional Scans
export const GetFunctional = async (env) => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/functional_scan/?document_id=${collection_id}&environment=${env}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Functional Scan");
  } catch (error) {
    return checkResponse(error, "Retrieve Functional Scan");
  }
};

export const RunFunctional = async (
  environment,
  authorization_type,
  api_key,
  username,
  password,
  access_token,
  email,
  token_type
) => {
  try {
    const document_id = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/functional_scan/`,
      {
        document_id,
        environment,
        authorization_type,
        api_key,
        username,
        password,
        access_token,
        email,
        token_type,
      },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Run New Functional Scan");
  } catch (error) {
    return {
      error: error?.response?.data?.message
        ? error?.response?.data?.message
        : "Missing Info in your collection, please fix conformance scan and try again or contact support for help",
    };
  }
};

// Environments
export const CreateEnvironment = async (prod, test, other) => {
  try {
    const document_id = localStorage.getItem("tempId")
      ? localStorage.getItem("tempId")
      : localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/environments/`,
      {
        collection_id: document_id,
        prod: prod,
        test: test,
        other: other,
      },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Create New Environment");
  } catch (error) {
    return checkResponse(error, "Create New Environment");
  }
};

export const ConfirmEnvironment = async (environment) => {
  try {
    const document_id = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/confirm_env/`,
      {
        document_id,
        environment,
      },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Confirm Environments");
  } catch (error) {
    return checkResponse(error, "Confirm Environments");
  }
};

export const GetEnvironment = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/environments/${collection_id}/`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Security Scan");
  } catch (error) {
    return checkResponse(error, "Retrieve Security Scan");
  }
};

export const UpdateEnvironment = async (prod, test, other, id) => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.patch(
      `${apiUrl}/scan/environments/${id}/`,
      {
        collection_id,
        prod,
        test,
        other,
      },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Update New Environment");
  } catch (error) {
    return checkResponse(error, "Update New Environment");
  }
};

// SetUP VulScan
export const CreateVulScan = async (
  allow_prod_scan,
  allow_test_scan,
  allow_other_scan,
  allow_active_scan_on_prod,
  allow_active_scan_on_test,
  allow_active_scan_on_other
) => {
  try {
    const document_id = localStorage.getItem("tempId")
      ? localStorage.getItem("tempId")
      : localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/setup_vulscan/`,
      {
        document_id,
        allow_prod_scan,
        allow_test_scan,
        allow_other_scan,
        allow_active_scan_on_prod,
        allow_active_scan_on_test,
        allow_active_scan_on_other,
      },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Vulnerability Scan Setup");
  } catch (error) {
    return checkResponse(error, "Vulnerability Scan Setup");
  }
};

export const GetVulSetup = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/setup_vulscan/${collection_id}/`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Vulnerability Scan Set Up Record");
  } catch (error) {
    return checkResponse(error, "Vulnerability Scan Set Up Record");
  }
};

// Get Vulnerability Scan

export const GetVul = async (environment) => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/vulnerable_scan/?collection_id=${collection_id}&environment=${environment}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Scan");
  } catch (error) {
    return checkResponse(error, "Retrieve Scan");
  }
};

export const RunVul = async (environment) => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/vulnerable_scan/`,
      {
        collection_id,
        environment,
      },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Run Vulnerability Scan");
  } catch (error) {
    return checkResponse(
      error,
      "Run Vulnerability Scan, Ensure that Environment is set and Scan is Allowed"
    );
  }
};

// Request for Service
export const RequestPentest = async (domains) => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    let getuser = localStorage.getItem("wauser");
    let user = JSON.parse(getuser);
    const response = await axios.post(`${apiUrl}/public/request_service/`, {
      first_name: user.first_name,
      last_name: user.last_name,
      work_email: user.email,
      phone: null,
      extra_information: domains,
      company_name: user.company_name,
      services: ["pentest"],
    });
    if (response.status === 201) {
      const request = await axios.post(
        `${apiUrl}/scan/pentest/`,
        {
          domains,
          collection_id,
        },
        { headers: { Authorization: `Token ${user()}` } }
      );
      return checkResponse(request, "Penetration Test");
    } else {
      return checkResponse(response, "Penetration Test");
    }
  } catch (error) {
    return checkResponse(error, "Penetration Test");
  }
};

export const GetPentest = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/pentest/?collection_id=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Scan");
  } catch (error) {
    return checkResponse(error, "Retrieve Scan");
  }
};

// Endpoints
export const CreateEndpoint = async (
  path,
  method,
  is_sensitive,
  is_login,
  description,
  allowed_response_codes
) => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/endpoints/`,
      {
        path,
        is_login: false,
        is_sensitive: false,
        method,
        description,
        allowed_response_codes,
        collection_id,
      },
      { headers: { Authorization: `Token ${user()}` } }
    );
    if (response.status === 201) {
      return checkResponse(response, "Create Endpoint");
    } else {
      return checkResponse(response, "Create Endpoint");
    }
  } catch (error) {
    return checkResponse(error, "Create Endpoint");
  }
};

export const GetEndpoint = async (collection_id) => {
  try {
    // const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/endpoints/?collection_id=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Endpoint");
  } catch (error) {
    return checkResponse(error, "Retrieve Endpoint");
  }
};

export const DeleteEndpoint = async (id) => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.delete(
      `${apiUrl}/scan/endpoints/${id}/?collection_id=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Endpoint");
  } catch (error) {
    return checkResponse(error, "Retrieve Endpoint");
  }
};

// Activities
export const GetActivities = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/activities/?collection_id=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Retrieve Endpoint");
  } catch (error) {
    return checkResponse(error, "Retrieve Endpoint");
  }
};

export const setUpProxy = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/pending-collection-proxy/`,
      { collection: `${collection_id}` },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "SetUp Proxy");
  } catch (error) {
    return checkResponse(error, "SetUp Proxy");
  }
};

export const ConfirmProxy = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/scan/pending-collection-proxy/?collection=${collection_id}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Confirm Proxy");
  } catch (error) {
    return checkResponse(error, "Confirm Proxy");
  }
};

export const CreateGitTokem = async (github_url, path_token) => {
  try {
    const collection = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/code_analysis/create_git_links/`,
      { github_url, path_token, collection },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Confirm Proxy");
  } catch (error) {
    return checkResponse(error, "Confirm Proxy");
  }
};

export const RunCodeReview = async () => {
  try {
    const collection = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/code_analysis/run_analysis/`,
      { collection },
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Run Source Code Review");
  } catch (error) {
    return checkResponse(error, "Run Source Code Review");
  }
};

export const GetResultReview = async () => {
  try {
    const collection = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/code_analysis/run_analysis/?collection=${collection}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Return Source Code Review");
  } catch (error) {
    return checkResponse(error, "Return Source Code Review");
  }
};

export const GetDependenciesAlerts = async () => {
  try {
    const collection = localStorage.getItem("collection_id");
    const response = await axios.get(
      `${apiUrl}/code_analysis/check_dependencies/?collection=${collection}`,
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Return Dependency Alert");
  } catch (error) {
    return checkResponse(error, "Return Dependency Alert");
  }
};

export const ScheduleDependency = async () => {
  try {
    const collection = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/code_analysis/check_dependencies/`, {collection},
      { headers: { Authorization: `Token ${user()}` } }
    );
    return checkResponse(response, "Return Dependency Alert");
  } catch (error) {
    return checkResponse(error, "Return Dependency Alert");
  }
};

export const TestDependency = async () => {
  try {
    const collection_id = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/code_analysis/test_dependency_mgt/`, {collection_id},
      { headers: { Authorization: `Token ${user()}` } }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const ConfirmDNS = async (domain) => {
  try {
    const collection = localStorage.getItem("collection_id");
    const response = await axios.post(
      `${apiUrl}/scan/confirm-dns/`, {domain, collection},
      { headers: { Authorization: `Token ${user()}` } }
    );
    return response;
  } catch (error) {
    return error;
  }
};
