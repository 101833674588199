import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Spinner } from 'react-bootstrap';
import RenderVulPDF from '../reports/view_pdf';
import RunDynamicScanModal from '../../modals/dynamic_scan/runDynamicScan';
import ViewScanModel from '../../modals/dynamic_scan/viewscan';
import { useGetEndPointScansQuery } from '../../../../redux/services/endPointScansApi';
import errorImg from '../../../../assets/images/searching-error.png';

const SecurityScan = ({ id }) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  // const [data, setData] = useState([]);

  const {
    data: endPointScansData,
    isLoading: endPointScansDataIsLoading,
    isError: endPointScansIsError,
  } = useGetEndPointScansQuery(id);

  // useEffect(() => {
  //   if (endPointScansData) {
  //     setData(endPointScansData?.securityScanData);
  //   }
  // }, [endPointScansData, endPointScansDataIsLoading]);

  const riskRenderer = (value) => {
    return (
      <span>
        <Badge
          pill
          className="text-white p-2"
          bg={
            value === 'critical'
              ? 'danger'
              : value === 'medium'
              ? 'warning'
              : value === 'Low'
              ? 'primary'
              : value?.length === 0
              ? 'danger'
              : 'info'
          }
        >
          <i className="mdi mdi-security"> </i>
          {value}
        </Badge>
      </span>
    );
  };

  return (
    <div style={containerStyle}>
      <div className="flex justify-between mb-4">
        <RenderVulPDF
          data={endPointScansData?.securityScanData}
          endpoint_id={id}
        />
        <div>
          <RunDynamicScanModal />
        </div>
      </div>
      {endPointScansDataIsLoading && (
        <div className="w-[100%]   flex justify-center mx-auto  h-[300px] pt-[50px]">
          <Spinner
            animation="border"
            variant="secondary"
            className="text-[50px] block"
          />
        </div>
      )}
      {endPointScansIsError && (
        <div
          style={{ width: '50%', margin: 'auto', textAlign: 'center' }}
          className="mt-5  w-[100%]  py-6 flex flex-col justify-center mx-auto font-medium text-slate-400 text-xl"
        >
          <img src={errorImg} alt="" />
          <p>An error occured</p>
        </div>
      )}

      {!endPointScansDataIsLoading &&
        endPointScansData &&
        endPointScansData?.securityScanData?.length === 0 && (
          <div className=" w-[70%] justify-center mx-auto text-center">
          <h4 className='text-primary'> No Threat Found  <i className="mdi mdi-alert-circle-outline"></i></h4> 
         <button className='btn-primary btn' disabled >Run Vulnerability Scan Above</button>
        </div>
        )}
      {endPointScansData && endPointScansData?.securityScanData?.length > 0 && (
        <table className="table table-hover">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Investigate
              </th>
              <th scope="col" className="px-6 py-3">
                Threat Name
              </th>
              <th scope="col" className="px-6 py-3">
                Attack Group
              </th>
              <th scope="col" className="px-6 py-3">
                Attack Category
              </th>
              <th scope="col" className="px-6 py-3">
                Severity
              </th>
            </tr>
          </thead>
          <tbody>
            {endPointScansData &&
              endPointScansData?.securityScanData?.length > 0 &&
              endPointScansData?.securityScanData?.map((item) => (
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                  >
                    <ViewScanModel data={item} />
                  </th>
                  <th scope="row" className="px-6  py-4 font-medium w-[40px] ">
                    {item?.threat_name}
                  </th>

                  <td className="px-6 py-4 text-primary">
                    {item.attack_group}
                  </td>
                  <td className="px-6 py-4">
                    <Badge pill className="text-white p-2" bg="secondary">
                      {item.category}
                    </Badge>
                  </td>
                  <td>{riskRenderer(item.severity)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SecurityScan;
