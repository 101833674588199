import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

function ScanDetails(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {props?.data?.result?.length === 0 ? (
        <i className="mdi mdi-eye-off text-gray-300"></i>
      ) : (
        <i
          className="mdi mdi-eye cursor-pointer"
          title="View"
          onClick={handleShow}
        ></i>
      )}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-secondary">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h6 className="text-primary">Description: Retrieve all products</h6> */}
          <h5 className="text-secondary">
            GET <span>{props.c_url}</span>
          </h5>

          <div className="mt-4">
            <h5 className="text-dark">Assertions</h5>
            <p className="text-primary">
              <i
                className={
                  props?.data?.result?.[0]?.response_code.startsWith('2')
                    ? 'mdi mdi-check-circle-outline text-success'
                    : 'mdi mdi-minus-circle-outline text-danger'
                }
              ></i>
              {props?.data?.result?.[0]?.response_code.startsWith('2')
                ? `Received Expected Status Code: returned ${props?.data?.result?.[0]?.response_code}`
                : `Expected Status Code Failed: returned ${props?.data?.result?.[0]?.response_code} `}
            </p>
            <p className="text-primary">
              <i
                className={
                  props?.data?.result?.[0]?.valid_elapsed_time
                    ? 'mdi mdi-check-circle-outline text-success'
                    : 'mdi mdi-minus-circle-outline text-danger'
                }
              ></i>{' '}
              {props?.data?.result?.[0]?.valid_elapsed_time
                ? `Received Expected Response Time: ${parseFloat(
                    props?.data?.result?.[0]?.elapsed_time
                  ).toFixed(3)} Ms`
                : `Response Time Took Too Long: ${parseFloat(
                    props?.data?.result?.[0]?.elapsed_time
                  ).toFixed(3)} Ms`}
            </p>
            <p className="text-primary">
              <i
                className={
                  props?.data?.result?.[0]?.valid_response_schema
                    ? 'mdi mdi-check-circle-outline text-success'
                    : 'mdi mdi-minus-circle-outline text-danger'
                }
              ></i>{' '}
              {props?.data?.result?.[0]?.valid_response_schema
                ? `Received Expected Response Body Schema`
                : `Received Unexpected Response Body Schema`}
            </p>
          </div>

          <div className="mt-4">
            <h5 className="text-secondary">Request Header</h5>
            <div className="p-2" style={{ border: '1px solid' }}>
              <AceEditor
                mode="json"
                theme="monokai"
                value={JSON.stringify(
                  props?.data?.result?.[0]?.request_headers,
                  null,
                  2
                )}
                style={{ width: '100%', height: '100px' }}
                name="ace-editor_req_header"
              />
              {/* <p>{props.c_request_header}</p> */}
            </div>
          </div>
          <div className="mt-4">
            <h5 className="text-secondary">Request Body</h5>
            <div className="p-2" style={{ border: '1px solid' }}>
              <AceEditor
                mode="json"
                theme="monokai"
                value={JSON.stringify(
                  props?.data?.result?.[0]?.request_body_sent,
                  null,
                  2
                )}
                style={{ width: '100%', height: '100px' }}
                name="ace-editor_req_header"
              />
              {/* <p>{props.c_request_body}</p> */}
            </div>
          </div>
          <div className="mt-4">
            <h5 className="text-secondary">Response Header</h5>
            <div className="p-2" style={{ border: '1px solid' }}>
              <AceEditor
                mode="json"
                theme="monokai"
                value={JSON.stringify(
                  props?.data?.result?.[0]?.response_headers,
                  null,
                  2
                )}
                style={{ width: '100%', height: '100px' }}
                name="ace-editor_req_header"
              />
              {/* <p>{props.c_response_header}</p> */}
            </div>
          </div>
          <div className="mt-4">
            <h5 className="text-secondary">Response Body</h5>
            <div className="p-2" style={{ border: '1px solid' }}>
              <AceEditor
                mode="json"
                theme="monokai"
                value={JSON.stringify(
                  props?.data?.result?.[0]?.response_content,
                  null,
                  2
                )}
                style={{ width: '100%', height: '100px' }}
                name="ace-editor_req_header"
              />
              {/* <p>{props.c_response_body}</p> */}
            </div>
          </div>
          {/* <div className="mt-4">
            <h5>Detected Isuues</h5>

            <i style={{ width: "10px", height: "10px" }}></i>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ScanDetails;
