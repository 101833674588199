import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { UpdateProject } from "./updateProject";
import ManageEnvironments from "./environment";
import AccessControl from "./access";
import { ThreatSet } from "./threat";
import { useCollectionContext } from "../../providers/Collection";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { Activities } from "./activity_logs";
import { ProjectAccess } from "./project_access";
import { Spinner } from "react-bootstrap";
import Integrations from "./integrations";
import ThreatMonitoring from "./threat-monitoring";

function ProjectConfiguration() {
  // const [selectedCollection, setCollections] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    history.push("/login");
  }

  const queryParams = new URLSearchParams(location.search);
  const defaultParam = queryParams.get("default");

  const { selectedCollection } = useCollectionContext();

  // useEffect(() => {
  //   const retrieveCollection = () => {
  //     let collection = collections.filter(
  //       (c) => c.collections.id === localStorage.getItem("collection_id")
  //     );
  //     setCollections(collection?.[0]?.collections);
  //   };
  //   retrieveCollection();
  // });
  return (
    <>
        <Tabs
          defaultActiveKey={defaultParam || "home"}
          id="justify-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Project Settings">
            <UpdateProject collection={selectedCollection} />
          </Tab>
          <Tab eventKey="environment" title="Environment Setup">
            <ManageEnvironments />
          </Tab>
          {/* <Tab eventKey="access" title="Access Control Settings">
            <AccessControl />
          </Tab> */}

          <Tab eventKey="threat_monitoring" title="Threat Monitoring Setup">
            <ThreatMonitoring />
          </Tab>
          <Tab eventKey="profile" title="Activity Logs" >
           <Activities />
          </Tab>
          <Tab eventKey="longer-tab" title="Integrations"><Integrations/></Tab>
          <Tab eventKey="contact" title="Project Access" >
            <ProjectAccess collection={selectedCollection?.[0]?.collections}/>
          </Tab>
        </Tabs>
      
    </>
  );
}

export default ProjectConfiguration;
