import React, { useState, useEffect } from "react";
import { LogTable } from "./LogTable";
// import { MyButton } from "../../components";
import Analytics from "./Analytics";
import Detail from "./detail";
import {
  GetResultReview,
  PatchCollection,
  RunCodeReview,
  ScheduleDependency,
  CreateGitTokem,
} from "../../API";
import { toast } from "react-toastify";
import { useCollectionContext } from "../../providers/Collection";
import Spinner from "../../shared/Spinner";
import { Form } from "react-bootstrap";
import ToolTip from "../../shared/Tooltip.js";

import {
  useGetSourceCodeReviewQuery,
  useRunCodeReviewMutation,
} from "../../../redux/services/codeAnalysisApi.js";
import ErrorDisplay from "../../components/Error.js";

const SourceCodeReview = () => {
  const [currentView, setCurrentView] = useState("analytics");
  const [config, setConfig] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [setID] = useState(0);
  const [data, setdata] = useState({});
  const [rowdata, setRowData] = useState([]);

  //form
  const [errMessage, setErrMsg] = useState("");
  const [git_url, setGit] = useState("");
  const { selectedCollectionId } = useCollectionContext();
  const [github_token, setGithubToken] = useState("");

  const collectionId = localStorage.getItem("collection_id");

  const {
    data: sourceCodeReviewData,
    isLoading: sourceCodeReviewIsLoading,
    error: sourceCodeReviewError,
  } = useGetSourceCodeReviewQuery(collectionId);

  const [
    runCodeReview,
    {
      data: runSouceCodeReviewData,
      isLoading: runCodeReviewIsLoading,
      error: runCodeReviewError,
    },
  ] = useRunCodeReviewMutation();

  useEffect(() => {
    if (!sourceCodeReviewIsLoading) setLoading(false);

    if (sourceCodeReviewData) setRowData(sourceCodeReviewData?.result);

    if (sourceCodeReviewError) {
      setConfig(true);
      const defaultErrMsg =
        "source code review has not been configured for this project";
      toast.error(sourceCodeReviewError.data.message || defaultErrMsg);
    }
  }, [sourceCodeReviewData, sourceCodeReviewIsLoading, sourceCodeReviewError]);

  useEffect(() => {
    if (runSouceCodeReviewData) toast.success(runSouceCodeReviewData?.message);

    if (runCodeReviewError) toast.error(runCodeReviewError?.data?.message);
  }, [runCodeReviewError, runSouceCodeReviewData]);
  const CodeReview = () => {
    runCodeReview({ collection: collectionId });
  };
  /// Not a good approach, backend may have to use a better approach
  const updateCollection = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const formData = new FormData();
    formData.append("git_url", git_url);
    const { success, error, message } = await PatchCollection(
      formData,
      selectedCollectionId
    );
    if (success) {
      const { success } = await CreateGitTokem(git_url, github_token);
      if (success) {
        await ScheduleDependency();
        const { error } = await RunCodeReview();
        if (error) {
          toast.error(
            "Please review your Github Token or URL, it is either incorrect or does not have enough access"
          );
        }
        window.location.reload();
      }
    }
    setSubmit(false);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {/* <ToastContainer /> */}
          {config ? (
            // <div className="card p-4">
            //   <div className="w-[50%] m-auto pt-4">
            //     <h4 className="text-primary pb-4">
            //       Configure Source Code Review
            //     </h4>
            //     <Form.Group>
            //       <label htmlFor="git_link" className="text-primary">
            //         Git URL <span className="text-danger">*</span>
            //         <span className="text-danger">
            //           <ToolTip description="Required for Source Code Review" />
            //         </span>
            //       </label>
            //       <Form.Control
            //         type="text"
            //         className="form-control"
            //         id="git_link"
            //         placeholder="https://github.com/owner/repository.git"
            //         required
            //         onChange={(e) => {
            //           const value = e.target.value;
            //           if (!value.endsWith('.git')) {
            //             setErrMsg('Github URL must end with a .git');
            //             return;
            //           }
            //           setErrMsg('');
            //           setGit(value);
            //         }}
            //       />
            //       {errMessage ? (
            //         <label className="mt-1 text-danger">{errMessage}</label>
            //       ) : null}
            //     </Form.Group>
            //     <Form.Group>
            //       <label htmlFor="document_url" className="text-primary pt-4">
            //         Github Token
            //         <span className="text-danger">
            //           <ToolTip description="Will be encrypted at rest" />
            //         </span>
            //         <span
            //           className="btn text-secondary ml-2 pointer-cursor"
            //           onClick={() =>
            //             window.open(
            //               'https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens',
            //               '_blank'
            //             )
            //           }
            //         >
            //           <i className="mdi mdi-circle"></i>manage tokens
            //         </span>
            //       </label>
            //       <Form.Control
            //         type="text"
            //         className="form-control"
            //         id="document_url"
            //         placeholder="ghp_0a0b0c0d0e0f0g0h0i"
            //         onChange={(e) => {
            //           const value = e.target.value;
            //           if (!value.startsWith('ghp_')) {
            //             setErrMsg('Github Token Must Begin With A GHP');
            //             return;
            //           }
            //           setErrMsg('');
            //           setGithubToken(value);
            //         }}
            //       />
            //       {errMessage ? (
            //         <label className="mt-1 text-danger">{errMessage}</label>
            //       ) : null}
            //     </Form.Group>
            //     <button
            //       className="btn btn-primary float-right mt-4"
            //       onClick={updateCollection}
            //     >
            //       {submit ? <Spinner /> : 'Submit'}
            //     </button>
            //   </div>
            // </div>
            <div className="p-4 card">
              <ErrorDisplay errorMessage="Git Not Configured" errorCode="400" />
            </div>
          ) : rowdata.length === 0 ? (
            <div>
              <button
                className="btn btn-primary float-left w-[100px] mb-4"
                onClick={CodeReview}
                disabled={runCodeReviewIsLoading}
              >
                Run New Analysis
              </button>
              <div className="p-4 card w-[100%]">
                <ErrorDisplay
                  errorMessage="No Issues Found Or Git Not Configured"
                  errorCode="404"
                />
              </div>
            </div>
          ) : (
            <Analytics
              setID={setID}
              setView={setCurrentView}
              setdata={setdata}
              data={rowdata}
              selectedCollectionId={selectedCollectionId}
              runCodeReview={runCodeReview}
              collectionId={collectionId}
              runCodeReviewIsLoading={runCodeReviewIsLoading}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SourceCodeReview;
