import React from 'react';

const SelectAuthenticationType = ({
  handleChange,
  selectedAuthType,
  authTypes,
}) => {
  return (
    <div>
      <label
        htmlFor="authentication-type"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Select Authentication Type
      </label>
      <select
        id="authentication-type"
        onChange={handleChange}
        value={selectedAuthType}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        {/* <option disabled>Select</option> */}
        {authTypes.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectAuthenticationType;
