import React, { useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { Badge } from 'react-bootstrap';
import SourceCodeModal from '../modals/source_code';

export const LogTable = (props) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const data = props?.data;
  // const data = [{
  //   issue_name: "Reentracy Attack",
  //   risk_level: "critical",
  //   category: "Injection",
  //   url: "https://github.com/anexistingorg/somerepository/example.txt",
  //   assigned_to: "Mary Slessor",
  //   status: "ongoing",
  //   resolution_time: "3 days"
  // },
  // {
  //   issue_name: "Reentracy Attack",
  //   risk_level: "low",
  //   category: "Injection",
  //   url: "https://github.com/anexistingorg/somerepository/example.txt",
  //   assigned_to: "",
  //   status: "new",
  //   resolution_time: ""
  // },
  // ]

  const buttonRenderer = (param) => {
    return (
      <span>
        <SourceCodeModal data={param.data} />
      </span>
    );
  };
  const badgeRenderer = ({ value }) => {
    return (
      <span>
        <Badge
          pill
          className="text-white p-2"
          bg={
            value === 'new'
              ? 'secondary'
              : value === 'in_progress'
              ? 'info'
              : value === 'resolved'
              ? 'success'
              : value === 'put' || value === 'patch'
              ? 'primary'
              : 'secondary'
          }
        >
          {value}
        </Badge>
      </span>
    );
  };

  const assignRenderer = ({ value }) => {
    return <span>{!value ? 'Not Assigned' : value}</span>;
  };

  const riskRenderer = ({ value }) => {
    return (
      <span>
        <Badge
          pill
          className="text-white p-2"
          bg={
            value === 'High'
              ? 'danger'
              : value === 'Medium'
              ? 'warning'
              : value === 'Low'
              ? 'primary'
              : value?.length === 0
              ? 'danger'
              : 'info'
          }
        >
          <i className="mdi mdi-security"> </i>
          {value}
        </Badge>
      </span>
    );
  };

  const URLRenderer = ({ value }) => {
    // Extract parts of the URL
    const parts = value.split('/');
    const usernameRepo = parts[3] + '/' + parts[4];
    const filePath = parts.slice(5, parts.length - 1).join('/');
    const filename = parts[parts.length - 1].split('?')[0];

    // Construct the new formatted URL
    const formattedUrl = `https://github.com/${usernameRepo}/blob/${filePath}/${filename}`;

    return (
      <a href={value} target="_blank" rel="noreferrer">
        {formattedUrl}
      </a>
    );
  };

  const [columnDefs] = useState([
    {
      field: 'id',
      type: 'numberColumn',
      cellRenderer: buttonRenderer,
      headerName: 'Action',
    },

    {
      field: 'issue',
      minWidth: 500,
      editable: false,
      headerName: 'Issue',
      cellClass: 'text-[12px] text-primary',
    },
    {
      field: 'severity',
      cellRenderer: riskRenderer,
      headerName: 'Risk Level',
      editable: false,
    },
    {
      field: 'issue_category',
      editable: false,
      headerName: 'Category',
      cellClass: 'text-[12px] text-primary',
      minWidth: 200,
    },
    // {
    //   field: "url_path",
    //   cellRenderer: URLRenderer,
    //   headerName: "URL",
    //   editable: false,
    //         minWidth: 400,

    // },
    {
      field: 'status',
      cellClass: `text-[12px] text-gray-600`,
      cellRenderer: badgeRenderer,
      // headerName: "Resolution Time",
      editable: false,
    },
    {
      field: 'assigned_to',
      cellClass: `text-[12px] text-gray-600`,
      cellRenderer: assignRenderer,
      // headerName: "Resolution Time",
      editable: false,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      // set the default column widthf
      width: 150,
      // make every column editable
      editable: true,
      // make every column use 'text' filter by default
      filter: 'agTextColumnFilter',
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      //   tooltipComponent: CustomTooltip,
    };
  }, []);
  const defaultColGroupDef = useMemo(() => {
    return {
      marryChildren: true,
    };
  }, []);
  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
      medalColumn: { width: 100, columnGroupShow: 'open', filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: 'agDateColumnFilter',
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split('/');
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  //   const onGridReady = useCallback((params) => {
  //     console.log('ready')
  //     const {success, data} = GetEndpoint()
  //     if (success) {
  //         if (data.length === 0) {

  //         }
  //         setRowData(data)
  //     }
  //  } );
  return (
    <div style={containerStyle}>
      <div style={{ height: '800px', width: '100%', boxSizing: 'border-box' }}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            rowData={data ? data.filter((d) => d.issue !== '') : []}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            defaultColGroupDef={defaultColGroupDef}
            columnTypes={columnTypes}
            // onGridReady={onGridReady}

            sideBar={'columns'}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};
