import { useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';

import { useLocation } from 'react-router-dom';
import { otherRoutes } from '../app/AppRoutes';

// Events that indicate user activity
const events = [
  'load',
  'click',
  'mousemove',
  'mousedown',
  'keypress',
  'touchstart',
];

const useIdleTimer = () => {
  const timerId = useRef(null);
  const warningTimerId = useRef(null);
  const lastActivityTime = useRef(new Date().getTime());
  const location = useLocation();

  const TIMEOUT = 1800000; // 30 minutes;

  const WARNING_TIME = TIMEOUT - 300000; // 5 minutes ;

  const handleLogout = useCallback(() => {
    toast.dismiss();
    localStorage.removeItem('watoken');
    window.location.href = '/login';
  }, []);

  const startTimers = useCallback(() => {
    clearTimeout(timerId.current);
    clearTimeout(warningTimerId.current);

    if (otherRoutes.includes(location.pathname)) {
      return;
    }

    timerId.current = setTimeout(handleLogout, TIMEOUT);

    warningTimerId.current = setTimeout(() => {
      toast.info(
        'You have been idle for too long. You will be logged out soon.'
      );
    }, WARNING_TIME);
  }, [location.pathname, handleLogout, WARNING_TIME]);

  const handleActivity = useCallback(() => {
    lastActivityTime.current = new Date().getTime();
    clearTimeout(timerId.current);
    clearTimeout(warningTimerId.current);
    startTimers();
  }, [startTimers]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    startTimers();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, [handleActivity, startTimers]);

  return null;
};

export default useIdleTimer;
