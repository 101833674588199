import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Spinner } from 'react-bootstrap';
import RealtimeMonitoringModal from '../modals/api_security/realtime_monitoring';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import './index.css';
import {
  useBlockIpAdressMutation,
  useGetFilteredLogDataQuery,
} from '../../../redux/services/endPointScansApi';
import { useGetThreatMonitoringEndpointDetailsQuery } from '../../../redux/services/collectionApi';
import { toast } from 'react-toastify';
import errorImg from '../../../assets/images/searching-error.png';
import AdvancedFilterModal from './components/AdvancedFilterModal';
import moment from 'moment-timezone';
import ErrorDisplay from '../../components/Error';
import ReactPaginate from 'react-paginate';
import "../../../assets/styles/pagination.css";

const LogTable = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({ name: '' });
  const [formData, setFormData] = useState({
    startTime: '',
    endTime: '',
    ipAddress: '',
    vpn: '',
    threatLevel: '',
    ipLocation: '',
    suspicious: '',
    responseCode: '',
    unreported: '',
    reported: '',
    requestStatus: '',
    botStatus: ''
  });
  const [threatFilterBy, setThreatFilterBy] = useState(null);

  const history = useHistory();
  const [filtershow, setFilterShow] = useState(false);
  const [shouldSkipFilterQueryFetch, setShouldSkipFilterQueryFetch] =
    useState(true);

  const [shouldSkipThreatMonitoringFetch, setShouldSkipThreatMonitoringFetch] =
    useState(true);

  const { pathname } = useLocation();
  const pathnameList = pathname?.split('/');
  const pathnameLastVal = pathnameList?.[pathnameList?.length - 1];

  const [modalStates, setModalStates] = useState({});

  const collectionId = localStorage.getItem('collection_id');
  const filterThreatDataBy = localStorage.getItem('filterThreatDataBy');
  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  
  const {
    data: logData,
    isLoading: logDataIsLoading,
    error: logDataError,
  } = useGetThreatMonitoringEndpointDetailsQuery(
    {
      collectionId,
      pathnameLastVal,
      page: currentPage,
    },
    {
      skip: shouldSkipThreatMonitoringFetch,
    }
  );

  const [
    blockIpAdress,
    {
      isLoading: blockIpIsLoading,
      error: blockIpError,
      isSuccess: blockIpIsSuccess,
    },
  ] = useBlockIpAdressMutation();

  const {
    data: filteredLogData,
    isLoading: filteredLogDataIsLoading,
    error: filteredLogDataError,
  } = useGetFilteredLogDataQuery(
    {
      ...formData,
      ipLocation: !selectedCountry?.name ? '' : selectedCountry?.name,
      // startTime: dateFormaterWithOffset(startDate),
      // endTime: dateFormaterWithOffset(endDate),

      startTime: startDate,
      endTime: endDate,
      collectionId,
      page: currentPage,
    },
    {
      skip: shouldSkipFilterQueryFetch,
    }
  );

  const toggleModal = (index) => {
    setModalStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShouldSkipFilterQueryFetch(false);
    setFilterShow(false);
  };

  const handleFilterModal = () => {
    setFilterShow(true);
  };

  useEffect(() => {
    if (filterThreatDataBy === 'requestStatusBlocked') {
      setFormData({
        ...formData,
        requestStatus: 'blocked',
      });
      setShouldSkipThreatMonitoringFetch(true);
      setShouldSkipFilterQueryFetch(false);
    } else if (filterThreatDataBy === 'countryName') {
      const countryName = localStorage.getItem('filterThreatDataCountry');
      setSelectedCountry({
        name: countryName,
      });
      setShouldSkipThreatMonitoringFetch(true);
      setShouldSkipFilterQueryFetch(false);
    } else if (filterThreatDataBy === 'ipAddress') {
      const ipAddress = localStorage.getItem('filterThreatDataIpAddress');
      setFormData({
        ...formData,
        ipAddress,
      });
      setShouldSkipThreatMonitoringFetch(true);
      setShouldSkipFilterQueryFetch(false);
    } else if (filterThreatDataBy === 'responseCode') {
      const responseCode = localStorage.getItem('filterThreatDataResponseCode');
      setFormData({
        ...formData,
        responseCode: 200,
      });
      setShouldSkipThreatMonitoringFetch(true);
      setShouldSkipFilterQueryFetch(false);
    }else if (filterThreatDataBy === 'botStatus') {
      const botStatus = localStorage.getItem('filterThreatDataBotStatus');
      setFormData({
        ...formData,
        botStatus: 'True'
      });
      setShouldSkipThreatMonitoringFetch(true);
      setShouldSkipFilterQueryFetch(false);
    } else if (filterThreatDataBy === 'suspicious') {
      const suspicious = localStorage.getItem('filterThreatDataSuspicious');
      setFormData({
        ...formData,
        suspicious: 'True',
      });
      setShouldSkipThreatMonitoringFetch(true);
      setShouldSkipFilterQueryFetch(false);
    }   else {
      setShouldSkipFilterQueryFetch(true);
      setShouldSkipThreatMonitoringFetch(false);
    }
  }, [filterThreatDataBy]);

  useEffect(() => {
    if (blockIpIsSuccess) {
      toast.success('IP Address blocked!');
    }
    if (blockIpError) {
      toast.error(
        blockIpError?.data?.error
          ? blockIpError?.data?.error
          : 'An error occurred'
      );
    }
  }, [blockIpIsSuccess, blockIpError]);

  useEffect(() => {
    if (!collectionId) {
      const redirectTimeout = setTimeout(() => {
        history.push('/dashboard/collections');
      }, 4000);
      return () => clearInterval(redirectTimeout);
    }

    if (filteredLogDataIsLoading || logDataIsLoading) {
      setData([]);
      setError(null);
      setLoading(true);
    }

    if (logData && shouldSkipFilterQueryFetch) {
      setLoading(false);
      setError(null);
      setData(logData?.results);
    }

    if (logDataError && shouldSkipFilterQueryFetch) {
      setLoading(false);
      setError(
        logDataError?.data?.error
          ? logDataError?.data?.error
          : 'An error occurred'
      );
    }

    if (filteredLogData && !shouldSkipFilterQueryFetch) {
      setLoading(false);
      setError(null);
      setData([]);
      setData(filteredLogData.results);
    }

    if (filteredLogDataError && !shouldSkipFilterQueryFetch) {
      setLoading(false);
      setError(
        filteredLogDataError?.data?.error
          ? filteredLogDataError?.data?.error
          : 'An error occurred'
      );
    }
  }, [
    collectionId,
    history,
    blockIpIsSuccess,
    blockIpError,
    filteredLogData,
    logData,
    logDataIsLoading,
    filteredLogDataIsLoading,
    filteredLogDataError,
    logDataError,
    shouldSkipFilterQueryFetch,
  ]);

  const closeModal = () => {
    setShowModal(false);
  };

  // function dateFormaterWithOffset(date) {
  //   if (date === '' || date === null || !date) return '';
  //   const formattedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
  //   const offset = moment(date).format('Z');
  //   const formattedWithOffset = formattedDate + '%2B' + offset;
  //   return formattedWithOffset;
  // }

  const totalPages = logData?.total_pages || filteredLogData?.total_pages;

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // Adjust for page index starting from 1
  };

  return (
    <div>
      <h4 className="text-secondary">Recent Traffic Logs</h4>
      <div className="flex justify-between">
        <CSVLink data={data || []} className="btn btn-secondary mb-2">
          Download CSV
        </CSVLink>
        <div className="flex justify-between">
          <button className="btn btn-primary" onClick={handleFilterModal}>
            Advanced Filter
          </button>
        </div>
      </div>
      {/* Advanced Filter Modal */}
      <div>
        <AdvancedFilterModal
          filtershow={filtershow}
          setFilterShow={setFilterShow}
          handleSubmit={handleSubmit}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          formData={formData}
          setFormData={setFormData}
          logData={logData}
          setData={setData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
      {loading && (
        <div className="w-50 flex justify-center m-auto text-center h-[300px] pt-[50px]">
          <Spinner
            animation="border"
            variant="secondary"
            className="text-[50px]"
          />
        </div>
      )}
      {collectionId && error && (
        <div
          style={{ width: '50%', margin: 'auto', textAlign: 'center' }}
          className="mt-5 flex flex-col justify-center"
        >
          <img src={errorImg} alt="" />
          <p>{error}</p>
        </div>
      )}
      {!collectionId && (
        <div className="flex flex-col items-center mb-1">
          <ErrorDisplay errorMessage='No Project Selected' errorCode={404}/>
          <h4 className='text-primary mt-4 mb-4'>Redirecting Shortly</h4>
        </div>
      )}
      {collectionId && !loading && !error && data?.length === 0 && (
        <ErrorDisplay errorMessage='No Data Found' errorCode={404}/>
      )}
      {!loading && !error && data?.length > 0 && (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-4">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Time Stamp
                </th>
                <th scope="col" className="px-6 py-3">
                  Ip Address
                </th>
                <th scope="col" className="px-6 py-3">
                  Location
                </th>
                <th scope="col" className="px-6 py-3">
                  Threat Level
                </th>
                <th scope="col" className="px-6 py-3">
                  URL
                </th>
                <th scope="col" className="px-6 py-3">
                  Method
                </th>
                <th scope="col" className="px-6 py-3">
                  Request Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Response Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Investigate
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                !error &&
                data?.map((item, index) => (
                  <tr className="bg-white border-b " key={index}>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {item.timestamp.substring(0, 10)}
                    </th>
                    <td className="px-6 py-4">{item.ipAddress}</td>
                    <td className="px-6 py-4">{item.location}</td>
                    <td className="px-6 py-4">
                      <Badge
                        pill
                        className="text-white p-2"
                        bg={
                          item.threat_level === 'critical'
                            ? 'danger'
                            : item.threat_level === 'medium'
                            ? 'warning'
                            : item.threat_level === 'low'
                            ? 'primary'
                            : 'info'
                        }
                      >
                        <i className="mdi mdi-security"> </i>
                        {item.threat_level}
                      </Badge>
                    </td>
                    <td className="px-6 py-4 text-primary max-width-column">
                      {item.path}
                    </td>
                    <td
                      className={
                        item.method === 'GET'
                          ? 'px-6 py-4 text-success'
                          : item.method === 'POST'
                          ? 'px-6 py-4 text-primary'
                          : item.method === 'PATCH'
                          ? 'px-6 py-4 text-secondary'
                          : item.method === 'PUT'
                          ? 'px-6 py-4 text-warning'
                          : item.method === 'DELETE'
                          ? 'px-6 py-4 text-danger'
                          : 'px-6 py-4 text-info'
                      }
                    >
                      {item.method}
                    </td>
                    <td
                      className={
                        item?.request_status === 'allowed'
                          ? 'px-6 py-4 text-success'
                          : 'px-6 py-4 text-danger'
                      }
                    >
                      {item?.request_status?.toUpperCase()}
                    </td>
                    <td className="px-6 py-4">
                      <button
                        className={
                          item?.response_code?.toString()?.startsWith('5')
                            ? 'btn btn-sm btn-outline-danger'
                            : item?.response_code?.toString()?.startsWith('4')
                            ? 'btn btn-sm btn-outline-danger'
                            : item?.response_code?.toString()?.startsWith('3')
                            ? 'btn btn-sm btn-outline-info'
                            : item?.response_code?.toString()?.startsWith('2')
                            ? 'btn btn-sm btn-outline-success'
                            : 'text-primary'
                        }
                        disabled
                      >
                        {item.response_code}
                      </button>
                    </td>
                    <td
                      className="px-6 py-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        // height: "30vh",
                        justifyContent: 'center',
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={() => toggleModal(index)}
                      >
                        <i className="mdi mdi-eye cursor-pointer"></i>
                      </button>
                      {modalStates[index] && (
                        <div className="flex justify-between">
                          <RealtimeMonitoringModal data={item} />
                          <button
                            disabled={blockIpIsLoading}
                            className="btn btn-outline-danger btn-sm text-[8px] w-[70px]"
                            style={{ fontSize: '8px' }}
                            onClick={() =>
                              blockIpAdress({
                                ipAddress: item?.ipAddress,
                                collectionId,
                              })
                            }
                          >
                            Block IP
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-2"></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {data?.length > 0 && (
        <ReactPaginate
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          activeClassName={"active-page"}
          pageCount={totalPages}
          onPageChange={handlePageChange}
          forcePage={currentPage - 1} // Adjust for 0-based indexing of ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
        />
      )}
    </div>
  );
};

export default LogTable;
