import React, { useState } from "react";
import { Button, Modal, Badge } from "react-bootstrap";
import AlertBadge from "./alert";

function DependencyAlertModal({ data }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const jsonStr =
    '[{"url": "https://github.com/andialbrecht/sqlparse/security/advisories/GHSA-rrm6-wvj7-cwh2"}, {"url": "https://nvd.nist.gov/vuln/detail/CVE-2023-30608"}, {"url": "https://github.com/andialbrecht/sqlparse/commit/c457abd5f097dd13fb21543381e7cfafe7d31cfb"}, {"url": "https://github.com/andialbrecht/sqlparse/commit/e75e35869473832a1eb67772b1adfee2db11b85a"}, {"url": "https://owasp.org/www-community/attacks/Regular_expression_Denial_of_Service_-_ReDoS"}, {"url": "https://lists.debian.org/debian-lts-announce/2023/05/msg00017.html"}, {"url": "https://github.com/pypa/advisory-database/tree/main/vulns/sqlparse/PYSEC-2023-87.yaml"}, {"url": "https://github.com/advisories/GHSA-rrm6-wvj7-cwh2"}]';

  return (
    <div>
      <button
        onClick={handleShow}
        className="card cursor-pointer p-4 rounded w-[100%]"
      >
        <AlertBadge
          severity={data?.severity}
          dependencyName={data?.dependency_name}
        />
        <p className="text-primary mb-4">{data?.summary}</p>
        <Badge
          bg={
            data?.severity === "high"
              ? "danger"
              : data?.severity === "medium"
              ? "warning"
              : "info"
          }
          className="text-white"
        >
          {data?.severity}
        </Badge>
      </button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="red-test">Vulnerability Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-warning">
            <i className="mdi mdi-alert"></i> {data?.dependency_name}
          </h4>
          <p className="text-primary mb-4">{data?.summary}</p>
          <div className="row justify-between mb-4">
            <Badge
              bg={
                data?.severity === "high"
                  ? "danger"
                  : data?.severity === "medium"
                  ? "warning"
                  : "info"
              }
              className="text-white"
            >
              {data?.severity}
            </Badge>
            <Badge bg="danger" className="text-white">
              Active
            </Badge>
            <Badge
              bg={
                data?.severity === "high"
                  ? "danger"
                  : data?.severity === "medium"
                  ? "warning"
                  : "info"
              }
              className="text-white"
            >
              {data?.vulnerable_version_range}
            </Badge>
          </div>

          <h4 className="text-secondary">Summary</h4>
          <p className="text-primary mb-4">{data?.summary}</p>

          <h4 className="text-secondary">Description</h4>
          <p className="text-primary mb-4">{data?.description}</p>

          <h4 className="text-secondary">Remediation</h4>
          <p className="text-primary mb-4">-</p>

          <h4 className="text-secondary">References</h4>
          <p className="text-primary mb-4">
            {" "}
            {JSON.parse(data?.references).map((item, index) => (
              <li key={index}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.url}
                </a>
              </li>
            ))}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DependencyAlertModal;
