import React, { useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { PatchCollection, CreateEnvironment, CreateVulScan } from "../../../API";
import { useCollectionContext } from "../../../providers/Collection";

import { toast } from "react-toastify";
import ToolTip from "../../../shared/Tooltip.js";

function CollectionsModal() {
  const [show, setShow] = useState(false);
  const [fileName, setFileName] = useState("Upload Valid API Collection");
  const [scan, setScan] = useState("");
  const [btnText, setBtnText] = useState("Submit");
  const [file, setFile] = useState(null);
  const [JSONtype, setJSONType] = useState("openapi");
  const [postman_version, setPostmanVersion] = useState("v2");
  const [view, setView] = useState("file");
  const [prod, setProd] = useState("");
  const [test, setTest] = useState("");
  const [other, setOther] = useState("");
  const [errMessage, setErrMsg] = useState("");
  const { selectedCollectionId } = useCollectionContext();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showFile = (file) => {
    if (file) {
      setFileName(file.name);
      setFile(file);
    }
  };

  const setEnvironment = async (e) => {
    e.preventDefault()
    const { success, error } = await CreateEnvironment(prod, test, other);
    if (!success) {
      toast(JSON.stringify(error));
    }
    else {
      await CreateVulScan(true, true, true, true, true, true)
        toast(JSON.stringify(success));
        window.location.reload();

    }

  };

  const handleUpload = async () => {
    setBtnText("validating");
    if (!file) {
      toast("Please upload a valid Openapi JSON file");
      setBtnText("Submit");
    }
    const formData = new FormData();
    formData.append("content", file);
    formData.append("JSONType", JSONtype);
    formData.append("version", postman_version);
    const { success } = await PatchCollection(formData, selectedCollectionId);
    if (success) {
          setView("environment");
    } else {
      setBtnText("Submit");
      toast("Uploaded file is not a valid");
    }
  };
  function validateInput(input) {
    const domainRegex = /^(?:(http[s]?:\/\/))?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}(\/.*)?$/;
    const ipRegex = /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/;
  
    const match = domainRegex.exec(input);
    if (match) {
      const hasProtocol = !!match[1];
      if (hasProtocol) {
        // Input includes http:// or https://
        return true;
      } else {
        // Input does not include http:// or https://
        return false;
      }
    } else if (ipRegex.test(input)) {
      // Input is an IP address
      return true;
    } else {
      // Invalid input
      return 'Invalid';
    }
  }
  return (
    <>
      <button className="btn btn-primary mt-4" onClick={handleShow}>
        Upload An API Collection
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">Upload JSON File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {view === "environment" ? (
          <form className="forms-sample" onSubmit={setEnvironment}>
          <Form.Group className="mt-4">
            <label htmlFor="production">Production Server</label>
            <Form.Control
              type="text"
              id="production"
              placeholder={prod ? prod : "Domain or IP Addresss"}
              size="lg"
              onChange={(e) => {
                const value = e.target.value;
                if (!validateInput(value)) {
                  setErrMsg(
                    "Input must be a valid domain with http/https protocol or IP"
                  );
                  return;
                }
                if (value.endsWith("/") || value.endsWith(".") || value.endsWith(",")) {
                  setErrMsg(
                    "Input cannot end with an special character"
                  );
                  return;
                }
                // setErrMsg("");
                setProd(value);}}
            />
             {errMessage ? (
        <label className="mt-1 text-danger">{errMessage}</label>
      ) : null}
          </Form.Group>
          <Form.Group className="mt-4">
            <label htmlFor="production">Test Server</label>
            <Form.Control
              type="text"
              id="production"
              placeholder={test ? test : "Domain or IP Addresss"}
              size="lg"
              onChange={(e) => {const value = e.target.value;
                if (!validateInput(value)) {
                  setErrMsg(
                    "Input must be a valid domain with http/https protocol or IP"
                  );
                  return;
                }
                if (value.endsWith("/") || value.endsWith(".") || value.endsWith(",")) {
                  setErrMsg(
                    "Input cannot end with an special character"
                  );
                  return;
                }
                // setErrMsg("");
                setTest(value);}}
            />
             {errMessage ? (
        <label className="mt-1 text-danger">{errMessage}</label>
      ) : null}
          </Form.Group>
          <Form.Group className="mt-4">
            <label htmlFor="production">Other Server</label>
            <Form.Control
              type="text"
              id="production"
              placeholder={other ? other : "Domain or IP Addresss"}
              size="lg"
              onChange={(e) => {const value = e.target.value;
                if (!validateInput(value)) {
                  setErrMsg(
                    "Input must be a valid domain with http/https protocol or IP"
                  );
                  return;
                }
                if (value.endsWith("/") || value.endsWith(".") || value.endsWith(",")) {
                  setErrMsg(
                    "Input cannot end with an special character"
                  );
                  return;
                }
                // setErrMsg("");
                setOther(value);}}
            />
             {errMessage ? (
        <label className="mt-1 text-danger">{errMessage}</label>
      ) : null}
          </Form.Group>

          <button type="submit" className="btn btn-primary float-right mt-4">
Update          </button>
        </form>
          ) : (
            <div>
              <Form.Group>
                <label>File upload</label>
                <div className="custom-file">
                  <Form.Control
                    type="file"
                    className="form-control visibility-hidden"
                    id="customFileLang"
                    lang="es"
                    onChange={(e) => showFile(e.target.files[0])}
                  />
                  <label className="custom-file-label" htmlFor="customFileLang">
                    {fileName}
                  </label>
                </div>
              </Form.Group>
              <Form.Group className="mt-4">
                <label htmlFor="collectiontype">
                  Collection Type <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="collectiontype"
                  required
                  onChange={(e) => setJSONType(e.target.value)}
                >
                  <option value="openapi">OpenAPI Collection</option>
                  <option value="postman">Postman</option>
                </select>
              </Form.Group>
              {JSONtype === "postman" ?
              <Form.Group className="mt-4">
                <label htmlFor="collectionversion">
                  Postman Version{" "}
                  <span className="text-danger">
                    <ToolTip description="Ensure to select the correct Postman Version if your collection is Postmam" />
                  </span>
                </label>
                <select
                  className="form-control"
                  id="collectionversion"
                  required
                  onChange={(e) => setPostmanVersion(e.target.value)}
                >
                  <option value="v2">V2</option>
                  <option value="v2.1">V2.1</option>
                </select>
              </Form.Group>
              :null}
               <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              {scan ? null : (
                <button
                  type="button"
                  className="btn btn-primary btn-icon-text float-right mt-4"
                  onClick={handleUpload}
                  disabled={btnText === "validating" ? true : false}
                >
                  <i className="mdi mdi-file-check btn-icon-prepend"></i>
                  {btnText}
                </button>
              )}
              {btnText === "Validation Failed" ? (
                <h4>
                  The uploaded file is not a valid OpenAPI Document, please
                  check your file and upload again
                </h4>
              ) : null}
            </div>
          </div>
            </div>
            
          )}
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CollectionsModal;
