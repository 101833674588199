import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import ToolTip from "../../shared/Tooltip.js"; 
import {
  useGetCollectionByUserQuery,
  useUpdateCollectionMutation,
} from "../../../redux/services/collectionApi.js";
import { toast } from "react-toastify";

export const UpdateProject = () => {
  const collectionId = localStorage.getItem("collection_id");
  const [updateCollection, isSuccess] = useUpdateCollectionMutation();
  const { data: collectionDetails } = useGetCollectionByUserQuery({
    collectionId,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [gitUrl, setGitUrl] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [host, setHost] = useState("");
  const [defaultEnv, setDefaultEnv] = useState("");
  const [file, setFile] = useState();
  
  const getDetails = () => {
    setProjectName(data?.name);
    setProjectDescription(data?.description);
    setGitUrl(data?.git_url);
    setDocumentUrl(data?.document_url);
    setHost(data?.host);
    setDefaultEnv(data?.default_env);
    setFile(data?.content);
  };

  useEffect(() => {
    setData(collectionDetails);
    getDetails();
  }, [data, collectionDetails]);
  
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("content", file);
    formData.append("collection_token", collectionId);
    formData.append("name", projectName);
    formData.append("description", projectDescription);
    formData.append("git_url", gitUrl);
    formData.append("document_url", documentUrl);
    formData.append("host", host);
    formData.append("default_env", defaultEnv);
    
    updateCollection({
      collectionId: collectionId,
      body: formData,
    });
    console.log(formData)
    if (isSuccess) {
      setLoading(false);
      toast("Success");
    }
  };

  return (
    <div className="card-body">
      <h2 className="card-title text-secondary">Update Project! </h2>
      <p className="card-description">
        Empower Your Collection Management Journey with Your Project as the
        First Essential Step
      </p>
      <form className="forms-sample">
        <div className="row mb-4">
          <Form.Group className="col-6">
            <label htmlFor="project_name" className="text-primary">
              Project Name
              <span className="text-danger">*</span>
            </label>
            <Form.Control
              type="text"
              className="form-control"
              name="name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              // placeholder="Enter a name for your new project"
              required
            />
          </Form.Group>
          <Form.Group className="col-6">
            <label htmlFor="description" className="text-primary">
              Project Description<span className="text-danger">*</span>
            </label>
            <Form.Control
              type="text"
              className="form-control"
              id="description"
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
              // placeholder="Enter a short description for your project"
              required
            />
          </Form.Group>
        </div>
        <div className="row mb-4">
          <Form.Group className="col-6">
            <label htmlFor="git_link" className="text-primary">
              Git URL
              <span className="text-danger">
                <ToolTip description="Your team easy access and reference" />
              </span>
            </label>
            <Form.Control
              type="text"
              className="form-control"
              id="git_link"
              value={gitUrl}
              onChange={(e) => setGitUrl(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="col-6">
            <label htmlFor="document_url" className="text-primary">
              Document URL
              <span className="text-danger">
                <ToolTip description="Your team easy access and reference" />
              </span>
            </label>
            <Form.Control
              type="text"
              className="form-control"
              id="document_url"
              value={documentUrl}
              onChange={(e) => setDocumentUrl(e.target.value)}
              // placeholder="Enter the url of your project API documentation"
              required
            />
          </Form.Group>
        </div>
        <div className="row mb-4">
          <Form.Group className="col-6">
            <label htmlFor="host" className="text-primary">
              Host
              <span className="text-danger">
                <ToolTip description="Your team easy access and reference" />
              </span>
            </label>
            <Form.Control
              type="text"
              className="form-control"
              id="host"
              value={host}
              onChange={(e) => setHost(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="col-6">
            <label htmlFor="env" className="text-primary">
              Default Env
              <span className="text-danger">
                <ToolTip description="Your team easy access and reference" />
              </span>
            </label>
            <Form.Control
              type="text"
              className="form-control"
              id="env"
              value={defaultEnv}
              onChange={(e) => setDefaultEnv(e.target.value)}
              // placeholder="Enter the url of your project API documentation"
              required
            />
          </Form.Group>
        </div>
        <div className="row mb-4">
          <Form.Group className="col-6">
            <label htmlFor="content" className="text-primary">
              Content
              <span className="text-danger">
                <ToolTip description="Your team easy access and reference" />
              </span>
            </label>
            <Form.Control
              type="file"
              className="form-control"
              id="content"
              // value={content}
              onChange={(e) => setFile(e.target.files[0])}
              // onClick={(e)=>console.log(e.target.files[0])}
              required
            />
          </Form.Group>
        </div>
        {/* <div className="row justify-between mb-4">
          <Form.Group className="mb-4 col-6">
            <label htmlFor="collectiontype" className="text-primary">
              Collection Type <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              id="collectiontype"
              required
              value={collection?.JSONType}
              // onChange={(e) => props?.setJSONType(e.target.value)}
            >
              <option value="openapi">OpenAPI Collection</option>
              <option value="postman">PostMan</option>
            </select>
          </Form.Group>
          <Form.Group className="col-6">
            <label className="text-primary">Upload Collection</label>
            <div className="custom-file">
              <Form.Control
                type="file"
                className="form-control visibility-hidden"
                id="customFileLang"
                lang="es"
                onChange={(e) => props.showFile(e.target.files[0])}
              />
              <label className="custom-file-label" htmlFor="customFileLang">
                {props.fileName}
              </label>
            </div>
          </Form.Group>
        </div> */}
        {/* <div className="row justify-between mb-4">
          <Form.Group className="col-6">
            <label htmlFor="collectiontype" className="text-primary">
              Postman Version <span className="text-danger">
                <ToolTip description="Ensure to select the correct Postman Version if your collection is Postmam" />
              </span>
            </label>
            <select
              className="form-control"
              id="collectiontype"
              required
              onChange={(e) => props?.setPostmanVersion(e.target.value)}
            >
              <option value="v2">V2</option>
              <option value="v2.1">V2.1</option>
            </select>
          </Form.Group>
          <Form.Group className="col-6">
            <label htmlFor="document_url" className="text-primary">
              Github Token
              <span className="text-danger">
                <ToolTip description="Required for automated source code analysis" />
              </span>
              <span className="btn text-secondary ml-2 pointer-cursor" onClick={() => window.open("https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens", "_blank")}>
                <i className="mdi mdi-circle"></i>manage tokens
              </span>
            </label>
            <Form.Control
              type="text"
              className="form-control"
              id="document_url"
              placeholder="Enter the url of your project API documentation"
              required
              onChange={(e) => props?.setGithubToken(e.target.value)}
            />
          </Form.Group>
        </div> */}

        {/* <Form.Group className="mb-4">
          <label htmlFor="exampleSelectGender" className="text-primary">
            API Type <span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            id="exampleSelectGender"
            required
            onChange={(e) => props?.setAPIType(e.target.value)}
          >
            <option value="External API">External API</option>
            <option value="Public API">Public API</option>
            <option value="Internal API">Internal API</option>
            <option value="Partner API">Partner API</option>
            <option value="Third Party API">Third Party API</option>
            <option value="Not Sure">Not Sure</option>
          </select>
        </Form.Group> */}
        {/* <Form.Group className="mb-4">
          <label className="text-primary">
            Protocol<span className="text-danger">*</span>
          </label>
          <div>
            <select
              className="form-control"
              required
              onChange={(e) => props?.setProtocol(e.target.value)}
            >
              <option value="REST">REST</option>
              <option value="other">OTHER</option>
            </select>
          </div>
        </Form.Group> */}
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <button
              type="submit"
              className="btn btn-primary mr-2 float-right"
              onClick={handleSubmit}
            >
              {loading ? (
                <Spinner animation="border" role="status" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
