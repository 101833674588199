import React, { useEffect, useState } from 'react';
import { PaginationWithButton } from '../../../components/pagination/PaginationWithButton';
import useButtonPagination from '../../../../hooks/useButtonPagination';
import DarkLoader from '../../../components/loader/DarkLoader';

const btnBcg =
  'linear-gradient(0deg, #023673, #023673),linear-gradient(0deg, rgba(2, 54, 115, 0.4), rgba(2, 54, 115, 0.4))';

const TableItems = ({
  data,
  loading,
  error,
  setPage,
  setShowModal,
  setModalData,
}) => {
  const [items, setItems] = useState([]);
  const { handlePageClick, pageCount, setPageCount } =
    useButtonPagination(setPage);

  const handleOpenModal = (item) => {
    setModalData({
      requestHeaderContent: item?.request_header?.message,
      requestBodyContent: item?.request_payload?.message,
      responseHeaderContent: item?.response_header?.message,
      responseBodyContent: item?.response_payload?.message,
    });
    setShowModal(true);
  };

  useEffect(() => {
    if (data) {
      setItems(data.test_results);
      setPageCount(data.total_pages);
    }
  }, [data, setPageCount]);

  if (loading) return <DarkLoader />;

  if (error) {
    return (
      <div className="w-full  h-full py-28 rounded-[14px] flex justify-center items-center text-base md:text-xl font-serif   text-[#000]">
        An error occurred
      </div>
    );
  }

  return (
    <>
      {items.length > 0
        ? items.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-items-center py-3 border-bottom"
            >
              <div className="w-[20%] ml-2">{'File'}</div>
              <div className="w-[20%] flex justify-center  text-sm">
                {'234'}
              </div>
              <div className="w-[20%] flex justify-center text-sm">
                {'High'}
              </div>
              <div className="w-[20%] flex justify-center text-sm">
                {'Repository'}
              </div>
              <div className="w-[20%] flex justify-center text-sm">
                {'Last 30 days'}
              </div>
            </div>
          ))
        : null}

      {items.length > 0 ? (
        <div className="mt-3">
          <PaginationWithButton
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />
        </div>
      ) : null}
    </>
  );
};

export default TableItems;
