import React, { useEffect, useState, useRef } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  BarChart,
  LineChart,
} from 'recharts';
import moment from 'moment';

export default function Chart({
  isLoading,
  error,
  data,
  isTestReport = false,
  isFetching,
}) {
  const [testReportData, setTestReportData] = useState([]);
  const [testCaseData, setTestCaseData] = useState([]);
  const chartContainer = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  const formatTestCaseLabel = (label) => {
    if (!label) return '';
    if (label && label.length > 20) return label.substring(0, 19) + '...';
    return label;
  };

  const formatDate = (date) => {
    const inputDate = moment(date, 'YYYY-MM-DD');
    const formattedDate = inputDate.format('DD/MM/YY');
    return formattedDate;
  };

  const isNoDataFound = (data, error) =>
    !error && !isLoading && !isFetching && data && data?.length === 0;

  useEffect(() => {
    const handleDataTransform = (data) => {
      if (isTestReport) {
        return data?.test_results?.map((item) => ({
          value: formatDate(item?.dates?.start_date),
          'Critical': item?.results?.test_passed,
          'Medium': item?.results?.test_failed,
          'Low': item.results?.test_skipped,
        }));
      }

      return data?.map((item) => ({
        name: formatTestCaseLabel(item?.name),
        'Critical': item?.test_passed,
        'Medium': item?.test_failed,
        'Low': item?.test_skipped,
      }));
    };

    if (isTestReport && data) setTestReportData(handleDataTransform(data));
    if (!isTestReport && data) {
      setTestCaseData(handleDataTransform(data));
    }
  }, [data, isTestReport]);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainer.current) {
        const width = chartContainer.current.offsetWidth;
        const height = chartContainer.current.offsetHeight;
        setChartWidth(width);
        setChartHeight(height);
      }
    };

    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [chartContainer]);

  const noDataFound = () => (
    <div className="w-full h-full flex justify-center items-center text-base md:text-xl  font-serif   text-[#000]">
      No Record Found
    </div>
  );

  const errorOccured = () => (
    <div className="w-full h-full flex justify-center items-center text-base md:text-xl font-serif   text-[#000]">
      An error occurred
    </div>
  );

  return (
    <>
      <div
        className="w-[85vw]   md:w-[90vw] lg:w-[67vw] xl:w-[75vw]  h-[250px] lg:h-[420px]  mt-4"
        ref={chartContainer}
      >
        {isLoading || isFetching ? (
          <div className="w-full h-full flex justify-center items-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}

        {isTestReport && testReportData?.length > 0 ? (
          <LineChart
            width={chartWidth}
            height={chartHeight}
            data={testReportData}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 40,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="value" angle={-45} textAnchor="end" />
            <YAxis />
            <Tooltip />
            {/* <Legend layout="horizontal" verticalAlign="bottom" align="center" /> */}
            <Line
              type="monotone"
              dataKey="Critical"
              stroke="#ff0000"
              strokeDasharray="20 20"
            />
            <Line
              type="monotone"
              dataKey="Medium"
              stroke="#8884d8"
              strokeDasharray="20 20"
            />
            <Line
              type="monotone"
              dataKey="Low"
              stroke="#82ca9d"
              strokeDasharray="20 20"
            />
          </LineChart>
        ) : null}

        {testCaseData && testCaseData?.length > 0 ? (
          <BarChart
            width={chartWidth}
            height={chartHeight}
            data={testCaseData}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 110,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="name" angle={-45} textAnchor="end" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}

            <Bar dataKey="Critical" stackId="a" fill="#ff0000" />
            <Bar dataKey="Medium" stackId="a" fill="#8884d8" />
            <Bar dataKey="Low" stackId="a" fill="#82ca9d" />
          </BarChart>
        ) : null}

        {isTestReport && isNoDataFound(testReportData, error)
          ? noDataFound()
          : null}

        {!isTestReport && isNoDataFound(testCaseData, error)
          ? noDataFound()
          : null}

        {isTestReport && error ? errorOccured() : null}

        {!isTestReport && error ? errorOccured() : null}
      </div>
    </>
  );
}
