import React, { useEffect, useState } from "react";
import ChecklistScore from "./ChecklistScore";
import ThreatLevel from "./ThreatLevel";
import Predictions from "./Predictions";
import Tasks from "./Tasks";
import {
  useGetSecurityCheckAnalyticsQuery,
  useGetSecurityChecklistQuery,
} from "../../../redux/services/collectionApi";
// import DownloadPdf from "./DownloadPdf";

const SecurityChecklist = () => {
  const collection_id = localStorage.getItem("collection_id");
  const [environment, setEnvironment] = useState("prod");
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { data: analytics } = useGetSecurityCheckAnalyticsQuery({
    collection_id: collection_id,
    environment: environment,
  });
  const { data: checklist } = useGetSecurityChecklistQuery({
    collection_id: collection_id,
    environment: environment,
  });

  useEffect(() => {
    setChartData(analytics);
    setTableData(checklist);
  }, [analytics, checklist]);

  return (
    <>
      <div className="card rounded-lg px-[10px] py-[14px]  border-teal-800">
        <h2 className=" text-primary font-normal text-[18px] mb-2">
          Filter by environment
        </h2>
        <div className="flex justify-between items-center mb-4">
          <select
            className="w-[100px] bg-[#023673] px-2 py-1 text-white rounded"
            onChange={(e) => setEnvironment(e.target.value)}
          >
            <option value="prod">prod</option>
            <option value="test">test</option>
            <option value="other">other</option>
          </select>
          {/* <DownloadPdf analytics={chartData} /> */}
        </div>

        <div className="grid lg:grid-cols-3 gap-4 mt-4 bg-[#09294D0D] px-4 pt-4 rounded">
          <ChecklistScore analytics={chartData} />
          <ThreatLevel analytics={chartData} />
          <Predictions analytics={chartData} />
        </div>
      </div>
      <Tasks analytics={tableData} />
    </>
  );
};

export default SecurityChecklist;
