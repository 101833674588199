import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useUpdatePasswordDuringFirstLoginMutation } from '../../../../redux/services/mfaAuthApi';
import {
  passwordInputErrorMsg,
  validatePassword,
} from '../../../../utils/authInputsValidators';
import { addUserDetailsToLocalStorageWhenLoggedIn } from '../../../../utils/localStorage';

export const FirstLoginPasswordReset = () => {
  const history = useHistory();
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('');
  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false,
  });

  const [updatePassword, { data, isLoading, error }] =
    useUpdatePasswordDuringFirstLoginMutation();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { password, confirmPassword, oldPassword } = formData;

    if (password !== confirmPassword) {
      toast("Passwords don't match");
      return;
    }

    const isValidPassword = validatePassword(password);
    const isValidConfirmPassword = validatePassword(confirmPassword);

    if (!isValidPassword) return setPasswordErrorMsg(passwordInputErrorMsg);
    if (!isValidConfirmPassword)
      return setConfirmPasswordErrorMsg(passwordInputErrorMsg);

    try {
      await updatePassword({
        old_password: oldPassword,
        new_password: password,
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  useEffect(() => {
    if (data) {
      addUserDetailsToLocalStorageWhenLoggedIn('setup', {
        first_login: false,
        completed_password_reset: true,
        completed_set_up_mfa: false,
        completed_onboarding: false,
      });
      toast('Password changed successfully');
      history.push('/proceed-2fa');
    }

    if (error) {
      console.log(error);
      const errMessage = error?.data?.Message || 'An error occurred';
      toast(errMessage);
    }

    if (formData.password) setPasswordErrorMsg('');
    if (formData.confirmPassword) setConfirmPasswordErrorMsg('');
  }, [data, error, history, formData.password, formData.confirmPassword]);

  return (
    <main className="bg-white py-14">
      <div className="grid max-w-[619px] px-6 content-center my-auto mx-auto">
        <div className="mb-[36px]">
          <div className="text-sky-950 text-[24px] font-semibold font-['Nunito Sans']">
            Reset Password
          </div>
          <h1 className="text-sky-950 text-base font-light font-['Noto Sans JP']">
            Enter new password
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-[36px]">
            <label
              htmlFor="oldPassword"
              className="text-sky-950 text-base md:text-lg font-normal font-['Noto Sans JP']"
            >
              Old Password
            </label>
            <input
              id="oldPassword"
              type="text"
              name="oldPassword"
              value={formData.oldPassword}
              required
              onChange={handleChange}
              className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
            />
          </div>
          <div className="flex flex-col mb-[36px]">
            <label
              htmlFor="password"
              className="text-sky-950 text-base md:text-lg font-normal font-['Noto Sans JP']"
            >
              New Password
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              required
              onChange={handleChange}
              className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
            />
            {passwordErrorMsg ? (
              <p className="text-danger mt-1">{passwordErrorMsg}</p>
            ) : null}
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="confirmPassword"
              className="text-sky-950 text-base md:text-lg font-normal font-['Noto Sans JP']"
            >
              Confirm Password
            </label>
            <input
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              required
              onChange={handleChange}
              className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
            />
            {confirmPasswordErrorMsg ? (
              <p className="text-danger mt-1">{confirmPasswordErrorMsg}</p>
            ) : null}
          </div>
          <div className="mt-[46px]">
            <button
              type="submit"
              className="w-full h-[50px] bg-sky-950 rounded shadow flex justify-center items-center border-none focus:outline-none"
            >
              <div className="text-white text-sm font-bold font-['Noto Sans JP']">
                {isLoading ? 'Loading...' : 'Submit'}
              </div>
            </button>

            <div className="flex items-center justify-start mt-2">
              <div className="mr-2">
                <input
                  type="checkbox"
                  name="agreeToTerms"
                  required
                  checked={formData.agreeToTerms}
                  onChange={handleChange}
                />
              </div>

              <div className="text-sky-800 text-sm md:text-base font-medium font-['Noto Sans JP']">
                I have read and I agree with the terms and conditions{' '}
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};
