import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  setTestCaseNumberOfDays,
  setTestReportNumberOfDays,
} from '../../../../redux/features/performanceTestDaysSlice';

export const IntervalSelection = ({ isTestCase, handleSubmit }) => {
  const dispatch = useDispatch();

  const testCaseNumberOfDays = useSelector(
    (store) => store?.performanceTestDays.testCaseNumberOfDays
  );

  const testReportNumberOfDays = useSelector(
    (store) => store?.performanceTestDays.testReportNumberOfDays
  );

  const handleChange = (e) => {
    const value = parseInt(e.target.value);
    if (isTestCase) {
      if (value > 200) return toast.warn('Numbers less than 200');
      dispatch(setTestCaseNumberOfDays(parseInt(e.target.value)));
    } else {
      if (value > 200) return toast.warn('Numbers less than 200');
      dispatch(setTestReportNumberOfDays(parseInt(e.target.value)));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center rounded-[14.77px]  bg-[#f8f8ff] h-[26.25px] w-[100.34px]">
      <form onSubmit={handleSubmit} className="flex">
        <input
          type="number"
          id="number-input"
          aria-describedby="helper-text-explanation"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder=""
          required
          value={isTestCase ? testCaseNumberOfDays : testReportNumberOfDays}
          onChange={handleChange}
        />
      </form>
    </div>
  );
};
