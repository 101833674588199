import React, { useEffect, useState } from 'react';
import ChartHeader from './ChartHeader';
import ChartLegend from './ChartLegend';
import Chart from './Chart';

const TestReports = ({
  testReportIsLoading: isLoading,
  testReportError: error,
  testReportData: data,
  testReportDuration,
  setTestReportDuration,
  testReportIsFetching: isFetching,
}) => {
  const [isTestReport, setIsTestReport] = useState(true);
  const [testReport, setTestReport] = useState([]);

  useEffect(() => {
    if (data) setTestReport(data?.test_results);
  }, [data]);

  return (
    <section className="card  w-full h-full  rounded-[20px] px-3  py-2 ">
      <ChartHeader
        headerName="Test Reports"
        setTestReportDuration={setTestReportDuration}
        days={testReportDuration}
      />
      <div className=" w-full h-full flex justify-center pr-10">
        <Chart
          isLoading={isLoading}
          error={error}
          data={data}
          isTestReport={isTestReport}
          isFetching={isFetching}
        />
      </div>
      {testReport.length > 0 ? (
        <div className="flex my-2 w-full justify-center">
          <ChartLegend />{' '}
        </div>
      ) : null}
    </section>
  );
};

export default TestReports;
