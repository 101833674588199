import React, { useState } from 'react'

const TableResponsTimePerLocation = ({data}) => {
    // const tempData = [
    //     {
    //         "countryName":"Nigeria",
    //         "avg_response_time":"100ms",
    //         "rank":12
    //     },
    //     {
    //         "countryName":"USA",
    //         "avg_response_time":"40ms",
    //         "rank":100
    //     },
    //     {
    //         "countryName":"Nigeria",
    //         "avg_response_time":"9ms",
    //         "rank":15
    //     },
    //     {
    //         "countryName":"Nigeria",
    //         "avg_response_time":"22ms",
    //         "rank":15
    //     },
    //     {
    //         "countryName":"Nigeria",
    //         "avg_response_time":"32ms",
    //         "rank":15
    //     },
    // ]
    // const [data, setdata] = useState(tempData)
    
  return (
    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500 ">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                    <th scope="col" class="px-6 py-3">
                       Location
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Response Time
                    </th>
                
                </tr>
            </thead>
            <tbody>
                {
                    data?.length >  0   && data?.map((item)=>(

                        <tr class="bg-white border-b ">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                {item.countryName}
                            </th>
                            <td class="px-6 py-4">
                            {parseFloat(item.avg_response_time).toFixed(3)}
                            </td>
                        
                        </tr>
                    ))

                }
            
            
            </tbody>
        </table>
    </div>
  )
}

export default TableResponsTimePerLocation