import React, { useEffect, useState } from 'react';
import { PaginationWithButton } from '../../../components/pagination/PaginationWithButton';
import useButtonPagination from '../../../../hooks/useButtonPagination';
import DarkLoader from '../../../components/loader/DarkLoader';
import { Badge } from 'react-bootstrap';
const btnBcg =
  'linear-gradient(0deg, #023673, #023673),linear-gradient(0deg, rgba(2, 54, 115, 0.4), rgba(2, 54, 115, 0.4))';

const TableItems = ({ data, loading, error, setPage }) => {
  const [items, setItems] = useState([]);
  const { handlePageClick, pageCount, setPageCount } =
    useButtonPagination(setPage);

  useEffect(() => {
    if (data) {
      setItems(data.test_results);
      setPageCount(data.total_pages);
    }
  }, [data, setPageCount]);

  if (loading) return <DarkLoader />;

  if (error) {
    return (
      <div className="w-full  h-full py-28 rounded-[14px] flex justify-center items-center text-base md:text-xl font-serif   text-[#000]">
        An error occurred
      </div>
    );
  }

  const issues = [
    {
      id: 234,
      description: 'API entry point not recognized',
      endpoint: '/api/v1/unknown',
      severity: 'High',
      status: 'open'
    },
    {
      id: 235,
      description: 'SQL Injection vulnerability',
      endpoint: '/api/v1/users',
      severity: 'Critical',
      status: 'open'
    },
    {
      id: 236,
      description: 'Cross-Site Scripting (XSS) detected',
      endpoint: '/api/v1/comments',
      severity: 'High',
      status: 'open'
    },
    {
      id: 237,
      description: 'Unauthorized access to protected endpoint',
      endpoint: '/api/v1/admin',
      severity: 'Critical',
      status: 'open'
    },
    {
      id: 238,
      description: 'Sensitive data exposure',
      endpoint: '/api/v1/account',
      severity: 'High',
      status: 'open'
    }
  ];

  return (
    <>
       <div>
      {issues.map(issue => (
        <div key={issue.id} className="flex items-center justify-items-stretch py-2 bg-[#D9D9D933] mt-2 text-center">
          <div className="w-[14.28%] ">{issue.id}</div>
          <div className="w-[14.28%] flex justify-center text-sm">
            {issue.description}
          </div>
          <div className="w-[14.28%] text-[#0041E9] flex justify-center text-sm">
            {issue.endpoint}
          </div>
          <div className="w-[14.28%] flex justify-center">
            <Badge
              className="text-white p-2" pill bg="danger"
            >
                {issue.severity}
              
            </Badge>
          </div>
          <div className="w-[14.28%] text-[#0041E9] flex justify-center text-sm">
            {issue.status}
          </div>
          <div className="w-[14.28%] flex justify-center">
            <button
              style={{ background: btnBcg }}
              className="flex w-max items-center py-1 md:py-[7px] px-3 md:px-[20px] border rounded-[8px]"
            >
              <div className="text-[#FFFFFF] leading-[20.27px] text-[10px] lg:text-sm">
                Assign
              </div>
            </button>
          </div>
          <div className="w-[14.28%] flex justify-center">
            <button
              style={{ background: btnBcg }}
              className="flex w-max items-center py-1 md:py-[7px] px-3 md:px-[20px] border rounded-[8px]"
            >
              <div className="text-[#FFFFFF] leading-[20.27px] text-[10px] lg:text-sm">
                View
              </div>
            </button>
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default TableItems;
