import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import logo from "../../assets/images/logo.png";
import { useAuth } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCollectionContext } from "../providers/Collection";

const Navbar = () => {
  const { logout } = useAuth();
  const history = useHistory();

  const {
    setCollectionId,
    setCollectionName,
    setCollectionToken,
    collections,
    selectedCollectionName,
  } = useCollectionContext();

  const user = JSON.parse(localStorage.getItem("wauser"));

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };
  const selectCollection = (name, id, token) => {
    setCollectionId(id);
    setCollectionName(name);
    setCollectionToken(token);
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row ">
      <ToastContainer />

      <div
        className="text-center navbar-brand-wrapper d-flex"
        style={{
          background:
            "linear-gradient(145.87deg, #09294D 99.55%, #003572 90.8%)",
        }}
      >
        <Link className="mt-2 navbar-brand brand-logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div
        className="navbar-menu-wrapper d-flex align-items-stretch text-primary"
        style={{ background: "#ffffff" }}
      >
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <span className="mdi mdi-menu"></span>
        </button>
        <div className="search-field d-none d-md-block">
        <form
            className="d-flex align-items-center h-100 text-primary"
            // action="#"
            // style={{ color: "#8e94a9" }}
          >
             <button
            className="btn"
            onClick={() => {
              localStorage.setItem("module", "api security");
              history.push('/dashboard/api-security')
            }}
          >
            API Security
          </button>
          {/* <button
            className="btn"
            onClick={() => {
              localStorage.setItem("module", "training");
              history.push('/all_courses')
            }}
          >
            Training
          </button> */}
            </form>
         
        </div>
        <ul className="navbar-nav navbar-nav-right text-primary">
          {/* <li className="nav-item nav-profile d-none d-xl-flex">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator text-primary">
                <Trans>Switch Projects</Trans>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                {collections?.map((i) => (
                  <Dropdown.Item
                    className="dropdown-item d-flex align-items-center"
                    onClick={() => {
                      selectCollection(i?.collections?.name, i?.collections?.id, i?.collections?.collection_token)
                      window.location.reload()
                    }
                    }
                    key={i.id}
                  >
                    <i className="mdi mdi-file-pdf mr-2"></i>
                    <Trans>{i.collections.name}</Trans>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
           
          </li> */}

          <li className="nav-item nav-profile nav-language">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <div className="nav-profile-text text-primary">
                  <p className="mb-1">
                    <Trans>
                      {user?.first_name} {user?.last_name}
                    </Trans>
                  </p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <div className="p-2">
                  {/* <Dropdown.Item className="dropdown-item d-flex align-items-center justify-content-between" href="dashboard/profile" onClick={() =>history.pushState('/profile')}>
                      <span><Trans>Profile</Trans></span>
                      <span className="p-0">
                        <i className="mdi mdi-account-outline ml-1"></i>
                      </span>
                    </Dropdown.Item> */}
                  {/* <Dropdown.Item className="dropdown-item d-flex align-items-center justify-content-between" href="/billing" onClick={() =>history.pushState('/billing')}>
                      <span><Trans>Billing</Trans></span>
                      <span className="p-0">
                        <i className="mdi mdi-square-inc-cash ml-1"></i>
                      </span>
                    </Dropdown.Item> */}
                  {/* <Dropdown.Item className="dropdown-item ">
                    <Link className="nav-link " to="/billing-page">
                      <div className="d-flex align-items-center justify-content-between">
                        <span>
                          <Trans>Billing</Trans>
                        </span>
                        <span className="p-0">
                          <i className="mdi mdi-square-inc-cash ml-1"></i>
                        </span>
                      </div>
                    </Link>
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item className="dropdown-item d-flex align-items-center justify-content-between" href="!#" onClick={evt =>evt.preventDefault()}>
                      <span><Trans>Settings</Trans></span>
                      <i className="mdi mdi-settings"></i>
                    </Dropdown.Item> */}
                  <Dropdown.Item
                    className="dropdown-item d-flex align-items-center justify-content-between"
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <span>
                      <Button onClick={logout}>Log Out</Button>
                    </span>
                    <i className="mdi mdi-logout ml-1"></i>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
