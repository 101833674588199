import React, { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useSelector } from 'react-redux';

export const QrCode = () => {
  // const result = localStorage.getItem('qrCodeUrl');
  // const qrCodeUrl = result ? result : null;
  const qrCodeUrl = useSelector((store) => store?.mfa?.qrCodeUrl);
  const [qrCodeSize, setQrCodeSize] = useState(350);

  useEffect(() => {
    const handleResize = () => {
      const newSize = Math.min(window.innerWidth, window.innerHeight) * 0.6;
      setQrCodeSize(newSize);
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!qrCodeUrl)
    return (
      <div className="flex justify-center w-full h-full text-lg text-slate-300 items-center">
        An error occurred
      </div>
    );

  return (
    <div className="flex justify-center items-center w-full h-full z-10">
      <QRCodeCanvas value={qrCodeUrl} includeMargin={true} size={qrCodeSize} />
    </div>
  );
};
