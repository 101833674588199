import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  qrCodeUrl: null,
};

const mfaAuthSlice = createSlice({
  initialState,
  name: 'mfaAuthSlice',
  reducers: {
    setQrCodeUrl: (state, { payload }) => {
      state.qrCodeUrl = payload;
    },
  },
});

export const { setQrCodeUrl } = mfaAuthSlice.actions;
export default mfaAuthSlice.reducer;
