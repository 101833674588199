import React from "react";
import { useGetEndpointDetectionQuery } from "../../../redux/services/endPointScansApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Badge } from "react-bootstrap";

const ApiAbuse = () => {
  const collection_id = localStorage.getItem("collection_id");

  const { data } = useGetEndpointDetectionQuery({
    collection_id: collection_id,
  });

  const history = useHistory();

  return (
    <div>
      <h4 className="text-primary">API Abuse (30 days)</h4>
      <div className="relative overflow-x-auto">
        <table className="w-full">
          <tbody className="text-[14px]">
            {data?.map((item) => (
              <tr className="text-[#09294d] ">
                <td className="px-6 py-[10px] text-nowrap">
                  {item.attack_type}
                </td>
                <td className="px-6 py-[10px] text-nowrap">
                  <Badge
                    className={
                      item.severity === "high"
                        ? "bg-danger text-white text-[12px] w-[100px] text-center py-[2px] rounded"
                        : item.severity === "critical"
                        ? "bg-danger text-white text-[12px] w-[100px] text-center py-[2px] rounded"
                        : item.severity === "medium"
                        ? "bg-warning text-white text-[12px] w-[100px] text-center py-[2px] rounded"
                        : "bg-info text-white text-[12px] w-[100px] text-center py-[2px] rounded"
                    }
                  >
                    {item.severity}
                  </Badge>
                </td>
                <td className="px-6 py-[10px] text-nowrap">
                  {item.number_of_occurrence}
                </td>
                <td className="px-6 py-[10px] text-nowrap text-center">
                  <button
                    className="btn btn-primary px-8 py-2"
                    onClick={() => {
                      localStorage.setItem("attack_type", item.attack_type);
                      localStorage.setItem("severity", item.severity);
                      history.push(`/dashboard/api-abuse-details?${item.attack_type}_${item.severity}`);
                    }}
                  >
                    View All
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApiAbuse;
