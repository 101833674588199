


const MyButton = ({styles,onClick,value})=>(
    <button className= {`  md:text-[15px] text-[13px]  font-bold py-2 px-4  rounded-full ${styles} border-none`} 
    onClick={onClick}>
       {value}
    </button>

)

export default MyButton;