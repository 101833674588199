import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment';
import { btnBcg } from './ChartHeader';
import { StatCard } from './StatCard';

export const RecentRunStat = ({
  recentRunsData: data,
  recentRunsIsLoading: isLoading,
  recentRunsError: error,
  recentRunsIsFetching: isFetching,
  title,
  details,
}) => {
  const [renderCards, setRenderCards] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();

  const formatDate = (date) => {
    const customDate = moment(date);
    return customDate.format('Do MMM, YYYY');
  };

  const runsPercentage = (percentage) => {
    if (typeof percentage !== 'number') return '';
    if (percentage < 0) return `${percentage}% decrease`;
    else return `+${percentage}% increase`;
  };

  useEffect(() => {
    if (isLoading) setRenderCards(true);

    if (!isLoading && error) {
      setErrorMsg('An error occurred');
      setRenderCards(false);
    }

    if (!isLoading && data && data?.length === 0) setRenderCards(false);
  }, [error, isLoading, isFetching, data]);

  return (
    <section className="card rounded-[20px] px-3 py-2 w-full  h-full flex flex-col mb-4">
      <div className="text-[#9291A5] mt-1 text-[14px] md:text-[16px] font-[Inter] leading-5 ">
        {title}
      </div>
      {details && (
        <div className="flex items-center justify-between mb-2">
          <div>
            <h2 className="text-[#1E1B39] mt-1 font-[Inter] text-[17px] md:text-[19px] font-bold leading-7">
              {isLoading ? <Skeleton /> : null}
              {data ? formatDate(data?.run_date) : null}
            </h2>
          </div>

          <div className="flex items-center">
            <button
              onClick={() => history.push('/dashboard/automated-scan')}
              style={{ background: btnBcg }}
              className={`flex w-max mr-4 items-center px-2 py-1  md:py-[7px] md:px-[12px]  border rounded-[8px]`}
            >
              <div className="text-[#FFFFFF] text-[10px] md:text-[14px] leading-[20.27px] mr-2">
                View Logs
              </div>
            </button>

            {/* <button
              onClick={() =>
                history.push('/dashboard/configure-performance-tests')
              }
              style={{ background: btnBcg }}
              className={`flex w-max items-center  px-2 py-1  md:py-[7px] md:px-[12px] border rounded-[8px]`}
            >
              <div className="text-[#FFFFFF] text-[10px] md:text-[14px] leading-[20.27px] mr-2">
                Settings
              </div>
            </button> */}
          </div>
        </div>
      )}
      <div className="w-full h-[max-content] grid gap-6 grid-rows-[repeat(auto-fit,minmax(130px,auto))] md:grid-rows-[repeat(auto-fit,minmax(149px,auto))]   grid-cols-[repeat(auto-fit,minmax(200px,_1fr))] justify-center">
        {renderCards ? (
          <>
            {' '}
            <StatCard
              bgColor={'#FFE2E6'}
              value={data?.total_runs?.last_run}
              isLoading={isLoading}
              error={error}
              title={'Total Runs'}
              iconColor={'#FC5980'}
              isFetching={isFetching}
              icon={false}
              percentage={runsPercentage(data?.total_runs?.difference)}
            />
            <StatCard
              bgColor={'#FFF4DE'}
              value={data?.passed_tests?.last_run}
              isLoading={isLoading}
              error={error}
              title={'Tests Passed'}
              iconColor={'#82ca9d'}
              isFetching={isFetching}
              icon={false}
              percentage={runsPercentage(data?.passed_tests?.difference)}
            />
            <StatCard
              bgColor={'#DCFCE7'}
              value={data?.failed_tests?.last_run}
              isLoading={isLoading}
              error={error}
              title={'Test Failed'}
              iconColor={'#FF0000'}
              isFetching={isFetching}
              icon={false}
              percentage={runsPercentage(data?.failed_tests?.difference)}
            />
            <StatCard
              bgColor={'#F4E8FE'}
              value={data?.skipped_tests?.last_run}
              isLoading={isLoading}
              error={error}
              title={'Test Skipped'}
              iconColor={'#8884d8'}
              isFetching={isFetching}
              icon={false}
              percentage={runsPercentage(data?.skipped_tests?.difference)}
            />
          </>
        ) : (
          <div className="w-full h-full flex justify-center items-center text-base md:text-xl  font-serif   text-[#000]">
            {errorMsg ? errorMsg : 'No Record Found'}
          </div>
        )}
      </div>
    </section>
  );
};
