import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment';
import { btnBcg } from './ChartHeader';
import { StatCard } from './StatCard';

export const RecentRunStat = (
  {
    // recentRunsData: data,
    // recentRunsIsLoading: isLoading,
    // recentRunsError: error,
  }
) => {
  const history = useHistory();

  const formatDate = (date) => {
    const customDate = moment(date);
    return customDate.format('Do MMM, YYYY');
  };

  const runsPercentage = (percentage) => {
    if (typeof percentage !== 'number') return '';
    if (percentage < 0) return `${percentage}% decrease`;
    else return `+${percentage}% increase`;
  };

  return (
    <section className="flex flex-col mb-2">
      <div className="flex items-center justify-between mb-2">
        <div>
          <div className="text-[#9291A5] mt-1 text-[14px] md:text-[16px] font-[bold] leading-5">
            Fuzzing Test
          </div>
          {/* <h2 className="text-[#1E1B39] mt-1 font-[Inter] text-[17px] md:text-[19px] font-bold leading-7">
            {isLoading ? <Skeleton /> : null}
            {data ? formatDate(data?.run_date) : null}
          </h2> */}
        </div>

        <div className="flex items-center">
          <button
            // onClick={() => history.push('/dashboard/automated-scan')}
            style={{ background: btnBcg }}
            className={`flex w-max mr-4 items-center px-2 py-1  md:py-[7px] md:px-[12px]  border rounded-[8px]`}
          >
            <div className="text-[#FFFFFF] text-[10px] md:text-[14px] leading-[20.27px] mr-2">
              Manual
            </div>
          </button>

          <button
            // onClick={() =>
            //   history.push('/dashboard/configure-performance-tests')
            // }
            style={{ background: btnBcg }}
            className={`flex w-max items-center  px-2 py-1  md:py-[7px] md:px-[12px] border rounded-[8px]`}
          >
            <div className="text-[#FFFFFF] text-[10px] md:text-[14px] leading-[20.27px] mr-2">
              Download Report
            </div>
          </button>
        </div>
      </div>

      <div className="w-full h-[max-content] grid gap-6 grid-rows-[repeat(auto-fit,minmax(130px,auto))] md:grid-rows-[repeat(auto-fit,minmax(149px,auto))]   grid-cols-[repeat(auto-fit,minmax(200px,_1fr))] justify-center">
        <StatCard
          bgColor={'#AFFDD3'}
          value={1089}
          // isLoading={isLoading}
          // error={error}
          title={'Total Runs'}
          iconColor={'#07A550'}
          // percentage={runsPercentage(data?.total_runs?.difference)}
        />
        <StatCard
          bgColor={'#FFF2DE'}
          value={459}
          // isLoading={isLoading}
          // error={error}
          title={'Tests Passed'}
          iconColor={'#FD8901'}
          // percentage={runsPercentage(data?.passed_tests?.difference)}
        />

        <StatCard
          bgColor={'#FFD8D8'}
          value={205}
          // isLoading={isLoading}
          // error={error}
          title={'Test Failed'}
          iconColor={'#FA0B0B'}
          // percentage={runsPercentage(data?.failed_tests?.difference)}
        />

        <StatCard
          bgColor={'#D4EBFF'}
          value={425}
          // isLoading={isLoading}
          // error={error}
          title={'Test Skipped'}
          iconColor={'#023673'}
          // percentage={runsPercentage(data?.skipped_tests?.difference)}
        />
      </div>
    </section>
  );
};
