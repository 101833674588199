import RunDynamicScanModal from '../../modals/dynamic_scan/runDynamicScan';
import './index.css';

export const PageHeader = () => {
  return (
    <div className="w-full flex items-center py-3 md:py-4 mb-4 justify-between border-bottom">
      <h1 className="text-base md:text-[20px] flex flex-wrap text-black">
        Found Vulnerabilities
      </h1>
      <div className='float-right'>
      <RunDynamicScanModal />

      </div>

     
    </div>
  );
};
