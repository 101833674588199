import React from "react";
import MyResponsiveSunburst from "../../components/MyResponsiveSunburst";
import share from "../../../assets/images/share.png";
import ApiAbuse from "./api-abuse";
import ApiInventory from "./api-inventory";
import {
  useGetSeverityAnalyticsQuery,
  useGetSeverityCountQuery,
} from "../../../redux/services/collectionApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./index.css";

const ApiSecurity = () => {
  const collection_id = localStorage.getItem("collection_id");
  const history = useHistory();
  const { data: analytics } = useGetSeverityAnalyticsQuery({
    collection_id: collection_id,
  });

  const { data: count } = useGetSeverityCountQuery({
    collection_id: collection_id,
  });

  const createChildren = (scanData, colors) => {
    if (!scanData || typeof scanData !== "object") {
      return []; // Return an empty array if scanData is null, undefined, or not an object
    }

    return Object.entries(scanData).map(([name, loc, id], index) => ({
      name,
      color: colors[index] || "defaultColor", // Providing a default color if colors array doesn't have enough elements
      loc,
      id: Math.random(),
    }));
  };

  const handleNavigateToLogDataPage = (param) => {
    localStorage.setItem("filterThreatDataBy", param);
    history.push("/dashboard/threats");
  };

  const handleNavigateToEndpointsPage = (param) => {
    localStorage.setItem("filterEndpointDataBy", param);
    history.push("/dashboard/all-endpoints");
  };

  // Colors for different severity levels (Please change to suit our colors)
  const staticColors = [
    "hsl(263, 70%, 50%)",
    "hsl(278, 70%, 50%)",
    "hsl(5, 70%, 50%)",
  ];
  const dynamicColors = [
    "hsl(96, 70%, 50%)",
    "hsl(165, 70%, 50%)",
    "hsl(49, 70%, 50%)",
    "hsl(293, 70%, 50%)",
    "hsl(220, 70%, 50%)",
  ];
  const runtimeColors = [
    "hsl(45, 70%, 50%)",
    "hsl(150, 70%, 50%)",
    "hsl(290, 70%, 50%)",
  ];
  //Reconstruct the data into desired structure
  const transformedData = {
    name: "count",
    color: "hsl(314, 70%, 50%)",
    children: [
      {
        name: "static",
        color: "hsl(315, 70%, 50%)",
        children: createChildren(count?.static_scan, staticColors),
      },
      {
        name: "dynamic",
        color: "hsl(165, 70%, 50%)",
        children: createChildren(count?.dynamic_scan, dynamicColors),
      },
      {
        name: "runtime",
        color: "hsl(15, 70%, 50%)",
        children: createChildren(count?.runtime_attacks, runtimeColors),
      },
    ],
  };

  return (
    <div className="p-4 bg-white rounded-[10px]">
      <h4 className="text-primary">API Security Summary</h4>
      <div className="md:flex justify-around mb-4">
        <div className="h-[250px] md:w-[40%] w-full">
          <MyResponsiveSunburst data={transformedData} />
        </div>
        <table className="md:w-[45%] w-full h-[250px]">
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary">
              Total Number of Endpoints
            </td>
            <td className="table-col-15 text-custom">
              {analytics?.total_endpoints}
            </td>
            <td className="table-col-10">
              <button
                className="btn-custom"
                onClick={() => handleNavigateToEndpointsPage("all")}
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary">Average Response Time</td>
            <td className="table-col-15 text-custom">
              {analytics?.average_response_time.toFixed(2)} s
            </td>
            <td className="table-col-10">
              <button className="btn-custom">View</button>
            </td>
          </tr>
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary">Total API Requests</td>
            <td className="table-col-15 text-custom">
              {analytics?.total_requests}
            </td>
            <td className="table-col-10">
              <button
                className="btn-custom"
                onClick={() => handleNavigateToLogDataPage("all")}
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary">
              {" "}
              Total Successful Requests
            </td>
            <td className="table-col-15 text-custom">
              {analytics?.total_successful_requests}
            </td>
            <td className="table-col-10">
              <button
                className="btn-custom"
                onClick={() => handleNavigateToLogDataPage("responseCode")}
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary">
              {" "}
              Total Mitigated Threats
            </td>
            <td className="table-col-15 text-custom">
              {analytics?.total_mitigated_threats}
            </td>
            <td className="table-col-10">
              <button
                className="btn-custom"
                onClick={() =>
                  handleNavigateToLogDataPage("requestStatusBlocked")
                }
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary"> Requests from Bots</td>
            <td className="table-col-15 text-custom">
              {analytics?.requests_from_bots}
            </td>
            <td className="table-col-10">
              <button
                className="btn-custom"
                onClick={() => handleNavigateToLogDataPage("botStatus")}
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary">
              Total Suspicious Request
            </td>
            <td className="table-col-15 text-custom">
              {analytics?.total_suspicious_requests}
            </td>
            <td className="table-col-10">
              <button
                className="btn-custom"
                onClick={() => handleNavigateToLogDataPage("suspicious")}
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary">
              Total APIS not used in 30 days
            </td>
            <td className="table-col-15 text-custom">
              {analytics?.endpoints_not_accessed_in_30_days}
            </td>
            <td className="table-col-10">
              <button
                className="btn-custom"
                onClick={() => handleNavigateToEndpointsPage("moreThan30Days")}
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td className="table-col-5">
              <i className="mdi mdi-lock text-custom"></i>
            </td>
            <td className="table-col-70 text-primary">
              Total API Frequently used in 30 days
            </td>
            <td className="table-col-15 text-custom">
              {analytics?.endpoints_accessed_in_30_days}
            </td>
            <td className="table-col-10">
              <button
                className="btn-custom"
                onClick={() => handleNavigateToEndpointsPage("lessThan30Days")}
              >
                View
              </button>
            </td>
          </tr>
        </table>
      </div>

      <div className="custom-space">
        <ApiInventory />
      </div>
      <div className="custom-space">
      <ApiAbuse />

      </div>
    </div>
  );
};

export default ApiSecurity;
