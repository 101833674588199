import React from 'react';

const DarkLoader = () => (
  <div className="w-full  h-full py-28  rounded-[14px] flex justify-center items-center ">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
);

export default DarkLoader;
