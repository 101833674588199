import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PaginationWithButton } from '../../../components/pagination/PaginationWithButton';
import useButtonPagination from '../../../../hooks/useButtonPagination';
import DarkLoader from '../../../components/loader/DarkLoader';

const VulnerabilityItems = ({
  isLoading,
  error,
  data,
  titleBcg,
  btnBcg,
  isUnderatedVulnerability = false,
  borderColor,
  setPage,
}) => {
  const history = useHistory();
  const { handlePageClick, pageCount, setPageCount } =
    useButtonPagination(setPage);

  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleNavigate = (threatName) => {
    localStorage.setItem('threat_name', threatName);
    history.push('/dashboard/vulnerability-details');
  };

  useEffect(() => {
    if (data) setPageCount(data.total_pages);
  }, [data, setPageCount]);

  if (isLoading) return <DarkLoader />;

  if (error) {
    return (
      <div className="w-full  h-full py-28 rounded-[14px] flex justify-center items-center text-base md:text-xl font-serif   text-[#000]">
        An error occurred
      </div>
    );
  }

  if (!isLoading && data && data?.results?.length === 0) {
    return (
      <div className="w-full rounded-[14px] py-20 h-full flex justify-center items-center text-base md:text-xl  font-serif   text-[#000]">
        No Record Found
      </div>
    );
  }

  return (
    <>
      <div className="custom-grid">
        {data.results.map((item, index) => (
          <div
            key={index}
            style={{
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: borderColor,
              borderRadius: '14px',
            }}
            className="w-full h-full"
          >
            <div className="card w-full h-full rounded-[14px]">
              <div
                style={{
                  background: titleBcg,
                  borderBottomWidth: '2px',
                  borderColor: borderColor,
                }}
                className=" px-2 border-b-2  border-r-0 border-l-0 border-t-0 border-solid text-black w-full font-[Nunito] rounded-ss-[10px] rounded-se-[10px] font-bold text-[13px] py-2"
              >
                {item.vulnerability_name}
              </div>
              <div className="pt-1 pb-2  w-full h-full">
                <article className="px-2 ">
                  <VulnerabilityInfo
                    icon={'Icon'}
                    desc={`${item.occurrence} Occurrence`}
                  />
                  {/* <VulnerabilityInfo
                    icon={'Icon'}
                    desc={'Vulnerability Category'}
                  /> */}
                  <VulnerabilityInfo
                    icon={'Icon'}
                    desc={`${capitalizeFirstLetter(item.risk)}`}
                  />
                  <VulnerabilityInfo
                    icon={'Icon'}
                    desc={`${item.endpoints_count} Endpoints`}
                  />
                </article>
                <div className="w-full flex justify-end mt-2 px-2">
                  <button
                    onClick={() => handleNavigate(item.vulnerability_name)}
                    style={{
                      background: btnBcg,
                    }}
                    className={`border-transparent py-1 px-2 rounded-md ${
                      isUnderatedVulnerability ? 'text-black' : 'text-[#FFFFFF]'
                    } text-[#FFFFFF] font-[Inter] font-bold text-[10px]`}
                  >
                    View all
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-3 w-full">
        <PaginationWithButton
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        />
      </div>
    </>
  );
};

const VulnerabilityInfo = ({ icon, desc }) => (
  <div className="flex mt-2 items-center font-[Nunito] text-[14px] text-black">
    <div className="mdi mdi-security mr-2"></div>

    <div>{desc}</div>
  </div>
);

export default VulnerabilityItems;
