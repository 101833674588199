import React, { useEffect, useState } from 'react';
import ChartHeader from './ChartHeader';
import Modal from './Modal';
import Chart from './Chart';
import ChartLegend from './ChartLegend';

const TestCases = ({
  testCaseIsLoading: isLoading,
  testCaseDataIsFetching: isFetching,
  testCaseError: error,
  testCaseData: data,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [shouldSkipAllTestCasesQuery, setShouldSkipAllTestCasesQuery] =
    useState(true);
  const [shouldReturnAllTestCaseData, setShouldReturnAllTestCaseData] =
    useState(true);
  const [testCase, setTestCase] = useState([]);

  useEffect(() => {
    if (data) setTestCase(data);
  }, [data]);

  return (
    <section className="card w-full h-full rounded-[20px] mt-4 px-3 py-2">
      <ChartHeader
        headerName="Test Cases"
        isTestCase={true}
        setOpenModal={setOpenModal}
        setShouldSkipAllTestCasesQuery={setShouldSkipAllTestCasesQuery}
        shouldReturnAllTestCaseData={shouldReturnAllTestCaseData}
      />
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldSkipAllTestCasesQuery={shouldSkipAllTestCasesQuery}
        setShouldSkipAllTestCasesQuery={setShouldSkipAllTestCasesQuery}
        shouldReturnAllTestCaseData={shouldReturnAllTestCaseData}
        setShouldReturnAllTestCaseData={setShouldReturnAllTestCaseData}
      />
      <div className=" w-full h-full flex justify-center pr-10">
        {' '}
        <Chart
          isLoading={isLoading}
          data={data}
          error={error}
          isFetching={isFetching}
        />
      </div>
      {testCase.length > 0 ? (
        <div className="flex my-2 w-full justify-center">
          <ChartLegend />
        </div>
      ) : null}
    </section>
  );
};

export default TestCases;
