import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFunc } from '../baseQueryFunc';

export const codeAnalysisApi = createApi({
  reducerPath: 'codeAnalysisApi',

  baseQuery: (args, api, extraOptions) =>
    baseQueryFunc('code_analysis', args, api, extraOptions),

  tagTypes: ['GetSourceCodeReview', 'RunCodeReview'],

  endpoints: (build) => ({
    getSourceCodeReview: build.query({
      query: (collectionId) => `run_analysis/?collection=${collectionId}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.result?.map(({ id }) => ({
                type: 'GetSourceCodeReview',
                id,
              })),
              'GetSourceCodeReview',
            ]
          : ['GetSourceCodeReview'],
    }),

    paginateSourceCodeReview: build.query({
      query: ({ collectionId, pageNumber }) =>
        `run_analysis/?collection=${collectionId}&page=${pageNumber}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.result?.map(({ id }) => ({
                type: 'SourceCodeReview',
                id,
              })),
              'SourceCodeReview',
            ]
          : ['SourceCodeReview'],
    }),

    runCodeReview: build.mutation({
      query: (body) => ({
        url: `run_analysis/`,
        method: 'POST',
        body: body,
      }),
    }),

    setUpCodeReview: build.mutation({
      query: (body) => ({
        url: `set_up_token/`,
        method: 'POST',
        body: body,
      }),
    }),

    updateIssueStatus: build.mutation({
      query: (arg) => ({
        url: `update-issue/${arg.id}/`,
        method: 'PATCH',
        body: arg.body,
      }),

      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled;

          console.log(updatedData);

          const patchResult = dispatch(
            codeAnalysisApi.util.updateQueryData(
              'getSourceCodeReview',
              id,
              (draft) => {
                Object.assign(draft, updatedData);
              }
            )
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useGetSourceCodeReviewQuery,
  useRunCodeReviewMutation,
  useSetUpCodeReviewMutation,
  useUpdateIssueStatusMutation,
  usePaginateSourceCodeReviewQuery,
} = codeAnalysisApi;
