import React from 'react';

const ExpiresAt = ({ handleCheckboxChange, formData }) => (
  <div className="flex items-center mb-1 mt-3">
    <div>
      <input
        type="checkbox"
        value={formData.auth_expiry}
        onChange={() =>
          handleCheckboxChange('auth_expiry', !formData.auth_expiry)
        }
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
    </div>
    <label className="ms-2 pt-1 text-sm font-medium text-gray-900 dark:text-gray-300">
      Expires
    </label>
  </div>
);

export default ExpiresAt;
