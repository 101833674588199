import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { GetSingleCollections } from "../../API";
import { FrontendSetup } from "../threatv2/frontend_setup";

export const ThreatSetup = () => {
  const apiUrl = process.env.REACT_APP_API_PROXY_URL;

  // General
  const [progress, setProgress] = useState(10);
  const [token, setToken] = useState("");
  const [step, setStep] = useState(0);

  const history = useHistory();
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    // Make API call and update doughnutPieData state once data is available
    const RetrieveCollections = async () => {
      const { message, data, success } = await GetSingleCollections();
      if (success) {
        setToken(data.collection_token);
      } else {
        toast(message);
      }
    };
    RetrieveCollections();
  }, []);
  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }

  const switch_step = () => {
    setStep(step + 1);
  };

  return (
    <div className="background">
      <ToastContainer />
      <div className="col-8 grid-margin stretch-card m-auto">
        <div className="card">
          <ProgressBar now={progress} />
          {step === 0 ? (
            <FrontendSetup token={token} switch_step={switch_step} threat_url={apiUrl} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
