import React from "react";
import { Pricing } from "react-pricing";
import './index.css';

export const Billing = () => {
  return (
    <div>
      <div className="row justify-content-between">
        <Pricing
          data={[
            { text: "API Management", value: true },
            { text: "Conformance Scan", value: true },
            { text: "Vulnerability Scan", value: true },
            { text: "Functional Scan", value: true },
            { text: "Penetration Test", value: true },
            { text: "Customer Support", value: true },
          ]}
          price={0}
          duration="m"
          background="linear-gradient(145.87deg, #09294D 41.55%, #003572 79.8%)"
          shadow="#09294d"
          currency="$"
          buttonContent="Current Package"
          subTitle="For Individuals"
          priceText="Manage and Audit Your Collections."
          headerText="free"
        />
        <Pricing style={{color: "#fff"}}
          data={[
            { text: "3 new project / month", value: true },
            { text: "Basic interaction", value: true },
            { text: "Assets library", value: false },
          ]}
          price={7}
          duration="m"
          background="linear-gradient(145.87deg, #09294D 41.55%, #003572 79.8%)"
          shadow="rgba(0, 53, 114, 1)"
          currency="$"
          buttonContent="Get Started"
          subTitle="For Planned Projects"
          priceText="Bring your designs to the next level and export them."
          headerText="free"
          headerColor = "#fff"
        />
        <Pricing
          data={[
            { text: "3 new project / month", value: true },
            { text: "Basic interaction", value: true },
            { text: "Assets library", value: false },
          ]}
          price={7}
          duration="m"
          background="linear-gradient(145.87deg, #09294D 41.55%, #003572 79.8%)"
          shadow="rgba(0, 53, 114, 0.7)"
          currency="$"
          buttonContent="Get Started"
          subTitle="For Planned Projects"
          priceText="Bring your designs to the next level and export them."
          headerText="free"
        />
      </div>
      <div className="row"></div>
    </div>
  );
};
