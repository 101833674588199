import React, { useEffect, useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import { useGetUserInCollectionQuery } from '../../../../redux/services/endPointScansApi';
import { toast } from 'react-toastify';
import { useUpdateIssueStatusMutation } from '../../../../redux/services/codeAnalysisApi';

function SourceCodeModal({ data }) {
  const [show, setShow] = useState(false);
  const [activity, setActivity] = useState(null);
  const collectionId = localStorage.getItem('collection_id');
  const [skipGetUserInCollection, setSkipGetUserInCollection] = useState(true);
  const [assignedTo, setAssignedTo] = useState('');
  const [reasonForResolution, setReasonForResolution] = useState('');
  const [additionalComment, setAdditionalComment] = useState('');

  const {
    data: userDataInCollection,
    isLoading: userDataInCollectionIsLoading,
    error: userDataInCollectionError,
  } = useGetUserInCollectionQuery(
    { collectionId },
    {
      skip: skipGetUserInCollection,
    }
  );

  const [
    updateIssueStatus,
    {
      data: updateStatusData,
      isLoading: updateStatausIsloading,
      error: updateStatusError,
    },
  ] = useUpdateIssueStatusMutation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const formatUrl = (value) => {
    const parts = value.split('/');
    const usernameRepo = parts[3] + '/' + parts[4];
    const filePath = parts.slice(5, parts.length - 1).join('/');
    const filename = parts[parts.length - 1].split('?')[0];

    // Construct the new formatted URL
    const formattedUrl = `https://github.com/${usernameRepo}/blob/${filePath}/${filename}`;
    return formattedUrl;
  };

  const handleGetUsers = (arg) => {
    setActivity(arg);
    setSkipGetUserInCollection(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (activity === 'assign' && !assignedTo)
      return toast.error('Select a user');

    if (activity === 'resolve' && !reasonForResolution && !additionalComment)
      return toast.error('All fields are required');

    const statusId = data?.id;

    if (activity && activity === 'assign') {
      console.log(assignedTo);
      try {
        await updateIssueStatus({
          id: statusId,
          body: {
            assigned_to: assignedTo,
            status: 'in_progress',
          },
        });
      } catch (error) {
        console.log('Error updating status', error);
      }
    }

    if (activity && activity === 'resolve') {
      try {
        await updateIssueStatus({
          id: statusId,
          body: {
            status: 'resolved',
            reason: reasonForResolution,
            comment: additionalComment,
          },
        });
      } catch (error) {
        console.log('Error updating status', error);
      }
    }
  };

  useEffect(() => {
    if (updateStatusError) {
      const errMessage = updateIssueStatus?.error || 'An error occurred';
      toast.error(errMessage);
    }
    if (updateStatusData) {
      toast.success('Status updated sucessfully');
    }
  }, [updateStatusError, updateIssueStatus?.error, updateStatusData]);

  return (
    <div>
      {/* <button
        type="button"
        className="btn btn-outline-primary btn-icon"
        onClick={handleShow}
      > */}
      <i className="mdi mdi-eye cursor-pointer" onClick={handleShow}></i>
      {/* </button> */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vulnerability Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button className="float-right btn btn-primary" disabled>
            {data?.status}
          </button>
          <div style={{ color: 'red' }}>
            {data?.issue}{' '}
            <Badge
              className="text-white"
              bg={
                data?.severity === 'High'
                  ? 'danger'
                  : data?.severity === 'Medium'
                  ? 'warning'
                  : 'info'
              }
            >
              {' '}
              {data?.severity}
            </Badge>
          </div>

          <div className="text-secondary">
            <a
              href={formatUrl(data?.url_path)}
              target="_blank"
              rel="noreferrer"
            >
              {formatUrl(data?.url_path)}
            </a>
          </div>
          <div className="mt-4">
            <h4 className="text-secondary">Line of Code</h4>
            <p className="text-primary">{data?.line_number}</p>
          </div>
          <div className="mt-4">
            <div>
              <h4 className="text-secondary">Description</h4>
              <p className="text-primary">{data?.description}</p>
            </div>
            <div className="mt-4">
              <h4 className="text-secondary">Risk</h4>
              <p className="text-primary">{data?.risk_assessment}</p>
            </div>
            <div className="mt-4">
              <h4 className="text-secondary">Remediation</h4>
              <p className="text-primary">{data?.remediation}</p>
            </div>

            <div className="mt-4">
              <h4 className="text-secondary">Issue Code Snippet</h4>
              <AceEditor
                placeholder=""
                mode="json"
                theme="monokai"
                name="blah2"
                style={{ width: '100%', height: '100px' }}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                readOnly={true}
                editorProps={{ $blockScrolling: true, firstLineNumber: 5 }}
                value={JSON.stringify(data?.code_snippet, null, 2)}
              />
            </div>

            <div className="mt-4">
              <h4 className="text-secondary">Suggested Solution Snippet</h4>
              <AceEditor
                placeholder=""
                mode="json"
                theme="monokai"
                name="blah2"
                style={{ width: '100%', height: '100px' }}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                readOnly={true}
                editorProps={{ $blockScrolling: true, firstLineNumber: 5 }}
                value={JSON.stringify(data?.solution_code, null, 2)}
              />
            </div>

            <div className="flex mb-4 w-[100]">
              <div className="w-[50%] flex float-right ">
                <button
                  className="mt-4 btn btn-success"
                  onClick={() => setActivity('resolve')}
                >
                  Mark As Resolved
                </button>
                <button
                  className="mt-4 btn btn-secondary"
                  onClick={() => handleGetUsers('assign')}
                  disabled={userDataInCollectionIsLoading}
                >
                  Assign to User
                </button>
              </div>
            </div>
            {activity === 'resolve' ? (
              <div className="mt-4 block">
                <form className="flex flex-col " onSubmit={handleSubmit}>
                  <label className="text-primary mb-11">
                    Reason for resolution<span className="text-danger">*</span>
                    <select
                      name="reasonForResolution"
                      className="form-control w-64"
                      required
                      value={reasonForResolution}
                      onChange={(e) => setReasonForResolution(e.target.value)}
                    >
                      <option value="" disabled>
                        Select a reason
                      </option>
                      <option value="Fixed Issue">Fixed Issue</option>
                      <option value="  Issue was identified as False Positive">
                        Issue was identified as False Positive
                      </option>
                    </select>
                  </label>

                  <label className="text-primary">
                    Additional Comment<span className="text-danger">*</span>
                    <input
                      name="additionalComment"
                      className="form-control w-64"
                      required
                      placeholder="Enter a comment for your team reference"
                      value={additionalComment}
                      onChange={(e) => setAdditionalComment(e.target.value)}
                    />
                  </label>

                  <button
                    type="submit"
                    className="btn btn-primary mt-4 w-32"
                    disabled={updateStatausIsloading}
                  >
                    {updateStatausIsloading ? 'Loading' : 'Assign Issue'}
                  </button>
                </form>
              </div>
            ) : activity === 'assign' ? (
              <div className="mt-4 block">
                <div className="mb-4 col-6 mt-4">
                  <form onSubmit={handleSubmit}>
                    <label className="text-primary">
                      Select User<span className="text-danger">*</span>
                      <select
                        name="assignedTo"
                        className="form-control w-64"
                        required
                        value={assignedTo}
                        onChange={(e) => setAssignedTo(e.target.value)}
                      >
                        {userDataInCollection && (
                          <>
                            <option value="" disabled>
                              Select a user
                            </option>
                            {userDataInCollection?.map(
                              ({ username }, index) => (
                                <option value={username} key={index}>
                                  {username}
                                </option>
                              )
                            )}
                          </>
                        )}
                        {userDataInCollection?.length === 0 && (
                          <option value="">No User Found</option>
                        )}
                        {userDataInCollectionError && (
                          <option value="">An error occurred</option>
                        )}
                        {userDataInCollectionIsLoading && (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </label>
                    <button
                      type="submit"
                      className="btn btn-primary mt-4 w-32"
                      disabled={updateStatausIsloading}
                    >
                      {updateStatausIsloading ? 'Loading' : 'Assign Issue'}
                    </button>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SourceCodeModal;
