import React, { useState, useEffect } from "react";
import { Badge, Spinner } from "react-bootstrap";
import ErrorDisplay from "../../components/Error";
import contriesArray from "../../../data/countriesFlag.json";
import MetaDataModal from "./MetaDataModal";

const AbuseDetails = ({ data, isLoading }) => {
  const [countryObject, setCountryObject] = useState(null);
  const initCountryObject = {};
  contriesArray.forEach(
    (country) => (initCountryObject[country.country] = country)
  );
  useEffect(() => {
    contriesArray.forEach(
      (country) => (initCountryObject[country?.country] = country)
    );

    setCountryObject(initCountryObject);
  }, []);
  const [modalData, setModalData] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const btnBcg =
    "linear-gradient(0deg, #023673, #023673),linear-gradient(0deg, rgba(2, 54, 115, 0.4), rgba(2, 54, 115, 0.4))";
  const handleOpenModal = (item) => {
    setModalData(item)
    setShowModal(true);
  };
  return (
    <div className="relative overflow-x-auto">
      {isLoading && (
        <div className="w-50 flex justify-center m-auto text-center h-[300px] pt-[50px]">
          <Spinner
            animation="border"
            variant="secondary"
            className="text-[50px]"
          />
        </div>
      )}
      {!isLoading && data?.test_results?.length === 0 && (
        <ErrorDisplay errorMessage="No Data Found" errorCode={404} />
      )}
      {!isLoading && data?.test_results?.length > 0 && (
        <table className="w-full bg-white">
          <thead>
            <tr className="text-[#09294d] bg-[#00000010]">
              <th scope="col" className="px-6 py-3 text-nowrap">
                Endpoint
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                IP Address
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Origin
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Timestamp
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Category
              </th>
            </tr>
          </thead>
          <tbody className="bg-white text-[14px]">
            {data?.test_results?.map((item, key) => (
              <tr className="text-[#09294d]">
                <td className="px-6 py-[10px] text-nowrap">{item.endpoint}</td>
                <td className="px-6 py-[10px] text-nowrap">
                  {item.activities?.ipAddress}
                </td>
                <td className="px-6 py-[10px] text-nowrap font-bold">
                  <img
                    src={countryObject?.[item?.activities?.countryName]?.flag}
                    alt=""
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "0px",
                    }}
                    className={`w-full h-full object-cover ${
                      key === "null" ? "cursor-none" : "cursor-pointer"
                    }`}
                  />{" "}
                  {item?.activities?.countryName}
                </td>
                <td className="px-6 py-[10px] text-nowrap">
                  {item.created_at.substring(0, 10)}
                </td>
                <td className="px-4 py-[10px] text-nowrap">
                  <Badge
                    className="h-7 flex items-center w-16 rounded-full"
                    bg={item.status === "blocked" ? "danger" : "success"}
                  >
                    <i className="mdi mdi-security mr-[5px] text-white"></i>{" "}
                    <i className="text-white not-italic">{item?.status}</i>
                  </Badge>
                </td>
                <td className="px-6 py-[10px] text-nowrap">
                  <button
                    onClick={() => handleOpenModal(item?.suspicious_requests)}
                    style={{ background: btnBcg }}
                    className="flex w-max items-center  py-1  md:py-[7px] px-3 md:px-[20px]  border rounded-[8px]"
                  >
                    <div className="text-[#FFFFFF]  leading-[20.27px] text-[10px]  lg:text-sm ">
                      View
                    </div>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {showModal ? (
        <MetaDataModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalData={modalData}
        />
      ) : null}
    </div>
  );
};

export default AbuseDetails;
