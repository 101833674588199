import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import useAuthProvider from '../app/providers/auth.provider';

const Loading = () => {
  return (
    <main className="w-full h-full ">
      <Spinner animation="grow" />
    </main>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuthProvider();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) return <Loading />;

        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default PrivateRoute;
