import React, { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';

const AlertBadge = ({ severity, dependencyName }) => {
  const [showBadge, setShowBadge] = useState(true);

  const toggleBadge = () => {
    setShowBadge((prevShowBadge) => !prevShowBadge);
    setTimeout(toggleBadge, 1000); // Set the duration for the badge to stay visible (in milliseconds)
  };

  useEffect(() => {
    toggleBadge();
  }, []);

  return (
    <h4 className={severity === 'high' ? 'text-danger' : severity === 'medium' ? 'text-warning' : 'text-info'}>
      <i className="mdi mdi-alert"></i> {dependencyName}
      {severity === 'critical' && showBadge ? (
        <Badge bg="danger" className="ml-4 text-white alert-badge">
          Alert
        </Badge>
      ) : null}
    </h4>
  );
};

export default AlertBadge;
