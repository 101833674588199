import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import { useGetCollectionEnvironmentQuery, useSetupEnvironmentMutation } from "../../../redux/services/collectionApi";

const ManageEnvironments = (props) => {
  const collectionId = localStorage.getItem("collection_id");

  // const [productionUpdate, setProductionUpdate] = useState({
  //   productionServer: "",
  //   testServer: "",
  //  otherServer:""
  // });

  const {data: collectionEnvironment} = useGetCollectionEnvironmentQuery({collectionId})
  const [setupEnvironment, isSuccess] = useSetupEnvironmentMutation();

  const [data, setData] = useState({});
  const [prodServer, setProdServer] = useState("");
  const [testServer, setTestServer] = useState("");
  const [otherServer, setOtherServer] = useState("");
  const [loading, setLoading] = useState(false);
  const getDetails = () => {
    setProdServer(data?.prod);
    setTestServer(data?.test);
    setOtherServer(data?.other);
  };

  useEffect(() => {
    setData(collectionEnvironment?.[0])
    getDetails();
  }, [data, collectionEnvironment]);

  const handleSubmit =  (e) => {
    e.preventDefault();
    setupEnvironment({
      collection_id: collectionId,
      prod: prodServer,
      test: testServer,
      other: otherServer,
    });
    if (isSuccess) {
      setLoading(false);
      toast("Success");
    }
  };


  const history = useHistory();
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated()) {
    history.push("/login");
  }

  function validateInput(input) {
    const domainRegex = /^(?:(http[s]?:\/\/))?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}(\/.*)?$/;
    const ipRegex = /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/;
  
    const match = domainRegex.exec(input);
    if (match) {
      const hasProtocol = !!match[1];
      if (hasProtocol) {
        // Input includes http:// or https://
        return true;
      } else {
        // Input does not include http:// or https://
        return false;
      }
    } else if (ipRegex.test(input)) {
      // Input is an IP address
      return true;
    } else {
      // Invalid input
      return 'Invalid';
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="card">
        <div className="row">
          <div className="col-md-6">
            <div className="card-body">
              <h4 className="card-title">Set Up Environments</h4>
              <p className="card-description">
                {" "}
                These are servers where your APIs are deployed{" "}
              </p>
              <p className="card-description">
                {" "}
                <i className="mdi mdi-alert-circle-outline"></i> These servers
                will override the servers url in your collection
              </p>
              <form className="forms-sample">
                <Form.Group>
                  <label htmlFor="production_server">Production Server</label>
                  <Form.Control
                    type="text"
                    id="production_server"
                    className="form-control"
                    placeholder={props.prod ? props.prod : "Domain or IP Addresss"}
                    size="lg"
                    value={prodServer}
                    onChange={(e) => {
                      const value = setProdServer(e.target.value);
                      if (!validateInput(value)) {
                        props?.setErrMsg(
                          "Input must be a valid domain with http/https protocol or IP"
                        );
                        return;
                      }
                    }}
                  />
                   {props?.errMessage ? (
              <label className="mt-1 text-danger">{props?.errMessage}</label>
            ) : null}
                </Form.Group>
                <Form.Group>
                  <label htmlFor="test_server" className="mt-3">Test Server</label>
                  <Form.Control
                    type="text"
                    id="test_server"
                    className="form-control"
                    placeholder={props?.test ? props?.test : "Domain or IP Addresss"}
                    size="lg"
                    value={testServer}
                    onChange={(e) => {const value = setTestServer(e.target.value);
                      if (!validateInput(value)) {
                        props?.setErrMsg(
                          "Input must be a valid domain with http/https protocol or IP"
                        );
                        return;
                      }
                    }}
                  />
                   {props?.errMessage ? (
              <label className="mt-1 text-danger">{props?.errMessage}</label>
            ) : null}
                </Form.Group>
                <Form.Group>
                  <label htmlFor="other_server" className="mt-3">Other Server</label>
                  <Form.Control
                    type="text"
                    id="other_server"
                    className="form-control"
                    placeholder={props.other ? props.other : "Domain or IP Addresss"}
                    size="lg"
                    value={otherServer}
                    onChange={(e) => {const value = setOtherServer(e.target.value);
                      if (!validateInput(value)) {
                        props?.setErrMsg(
                          "Input must be a valid domain with http/https protocol or IP"
                        );
                        return;
                      }
                    }}
                  />
                   {props?.errMessage ? (
              <label className="mt-1 text-danger">{props?.errMessage}</label>
            ) : null}
                </Form.Group>

                <button
              type="submit"
              className="btn btn-primary mt-4"
              onClick={handleSubmit}
            >
              {loading ? (
                <Spinner animation="border" role="status" />
              ) : (
                "Submit"
              )}
            </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEnvironments;
