import React, { useEffect, useState } from "react";
import {
  useConfigureWafMutation,
  useCreateCustomRegexMutation,
} from "../../../redux/services/collectionApi";
import { toast } from "react-toastify";

const ThreatMonitoring = () => {
  const collection_id = localStorage.getItem("collection_id");
  const [mode, setMode] = useState("learning");
  const [botStatus, setBotStatus] = useState(false);
  const [name, setName] = useState("");
  const [pattern, setPattern] = useState("");
  const [configureWaf] = useConfigureWafMutation();
  const [createCustomRegex] = useCreateCustomRegexMutation();

  useEffect(() => {
    setMode(mode);
    setBotStatus(botStatus);
    setName(name);
    setPattern(pattern);
  }, [mode, botStatus, name, pattern]);

  const changeMode = async () => {
    try {
      await configureWaf({
        body: {
          collection: collection_id,
          waf_mode: mode,
          block_bot: botStatus,
        },
      }).unwrap();
      toast.success("Successful");
    } catch (error) {
      console.error("Failed:", error);
      toast.error("Failed to change mode");
    }
  };

  const blockBot = async () => {
    try {
      await configureWaf({
        body: {
          collection: collection_id,
          block_bot: botStatus,
        },
      }).unwrap();
      toast.success("Successful");
    } catch (error) {
      console.error("Failed:", error);
      toast.error("Failed to change mode");
    }
  };

  const createRegex = async () => {
    try {
      await createCustomRegex({
        body: {
          collection_id: collection_id,
          name: name,
          pattern: pattern,
        },
      }).unwrap();
      toast.success("Successful");
    } catch (error) {
      console.error("Failed:", error);
      toast.error("Failed to create regex");
    }
  };

  return (
    <div>
      <div className=" grid-margin stretch-card m-auto">
        <div className="card p-4">
          <h4 className="card-title">Configure WAF Rules</h4>
          <p className="card-description">Change WAF Mode</p>
          <div className="md:flex justify-between ">
            <div className="md:w-[70%] w-full md:flex justify-between ">
              <p
                className={
                  mode === "learning"
                    ? "lg:w-[300px] md:w-[200px] w-full h-[50px] leading-[50px] rounded-[10px] border border-black bg-[#07A55096] text-black text-left font-bold px-3 mb-3"
                    : "lg:w-[300px] md:w-[200px] w-full h-[50px] leading-[50px] rounded-[10px] border border-black hover:bg-[#07A55096] text-black text-left font-bold px-3 mb-3"
                }
                onClick={() => setMode("learning")}
              >
                Learning Mode
              </p>
              <p
                className={
                  mode === "active"
                    ? "lg:w-[300px] md:w-[200px] w-full h-[50px] leading-[50px] rounded-[10px] border border-black bg-[#07A55096] text-black text-left font-bold px-3 mb-3"
                    : "lg:w-[300px] md:w-[200px] w-full h-[50px] leading-[50px] rounded-[10px] border border-black hover:bg-[#07A55096] text-black text-left font-bold px-3 mb-3"
                }
                onClick={() => setMode("active")}
              >
                Active Mode
              </p>
            </div>
            <button
              className=" md:w-[150px] w-full h-[50px] rounded-[10px] border border-black outline-0 bg-[#023673] font-bold text-white px-3 mb-3"
              onClick={changeMode}
            >
              Change Mode
            </button>
          </div>

          <p className="card-description">Create Custom Regex</p>
          <div className="md:flex justify-between ">
            <div className="md:w-[70%] w-full md:flex md:justify-between ">
              <input
                placeholder="Name"
                className="md:w-[300px] w-full h-[50px] rounded-[10px] border border-black outline-0 px-2 mb-3"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                placeholder="Pattern"
                className="md:w-[500px] w-full h-[50px] rounded-[10px] border border-black outline-0 px-2 mb-3"
                onChange={(e) => setPattern(e.target.value)}
              />
            </div>
            <button
              className="md:w-[150px] w-full h-[50px] rounded-[10px] border border-black outline-0 bg-[#023673] text-white font-bold mb-3"
              onClick={createRegex}
            >
              Add
            </button>
          </div>

          <p className="class-description">Configure Traffic</p>

          <div className="md:flex justify-between items-center">
            <div className="md:w-[70%] w-full">
              <input
                type="checkbox"
                onChange={(e) => setBotStatus(e.target.checked)}
              />
              <label className="text-primary text-[18px] ml-2 mb-4">
                Block Requests from Bots
              </label>
            </div>
            <button
              className="md:w-[150px] w-full h-[50px] rounded-[10px] border border-black outline-0 bg-[#023673] text-white font-bold mb-3"
              onClick={blockBot}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreatMonitoring;
