import React, { useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import VulReport from "./vul_report";
import { Button, Modal } from "react-bootstrap";
import { CircleLoader } from "react-spinners";

function RenderVulPDF(props) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleLoading = () => {
    setIsLoading(true);
  };

  const handleLoadingComplete = () => {
    setIsLoading(false);
  };

  const renderWithDelay = () => {
    const dataLength = props?.data?.length;

    // Calculate the delay time based on data length
    let delayTime = 0;
    if (dataLength < 5) {
      delayTime = 3000; // 3 seconds
    } else if (dataLength < 50) {
      delayTime = 20000; // 20 seconds
    } else if (dataLength < 100) {
      delayTime = 40000; // 40 seconds
    } else {
      delayTime = 60000; // 60 seconds
    }

    // Show loading spinner for the calculated delay time
    handleLoading();
    setTimeout(() => {
      handleLoadingComplete();
    }, delayTime);
  };

  return (
    <div>
      <button type="button" className="btn btn-secondary " onClick={handleShow}>
        Download PDF
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="red-test">
            Endpoint Vulnerability Assessment Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFViewer
            style={{ width: "100%", border: "none", minHeight: "800px" }}
          >
            {isLoading ? (
              <div className="loader-container">
                <CircleLoader size={500} color={"black"} loading={isLoading} />
              </div>
            ) : (
              <VulReport
                data={props?.data}
                endpoint_id={props?.endpoint_id}
                onLoading={renderWithDelay}
                onLoadingComplete={handleLoadingComplete}
              />
            )}
          </PDFViewer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RenderVulPDF;
