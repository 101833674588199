import React, { useEffect, useState } from 'react';
import SelectAuthenticationType from './component/SelectAuthenticationType';
import BearerForm from './component/BearerForm';
import ApiKeyForm from './component/ApiKeyForm';
import OauthForm from './component/OauthForm';
import Basic from './component/Basic';
import JwtForm from './component/JwtForm';
import TokenForm from './component/TokenForm';
import { toast } from 'react-toastify';
import { useConfigurePerformanceTestsMutation } from '../../../redux/services/endPointScansApi';

const authTypes = ['Bearer', 'api_key', 'oauth', 'Basic', 'jwt', 'token'];

const ConfigurePerformanceTests = () => {
  const [selectedAuthType, setSelectedAuthType] = useState('Bearer');
  const collectionId = localStorage.getItem('collection_id');

  const [formData, setFormData] = useState({
    collection: '',
    auth_type: '',
    auth_token: '',
    auth_username: '',
    auth_password: '',
    auth_expiry: false,
    auth_endpoint: '',
    auth_email: '',
    auth_api_key: '',
    auth_bearer_token: '',
    auth_jwt: '',
    schedule: 0,
  });

  const [configurePerformanceTests, { data, isLoading, error }] =
    useConfigurePerformanceTestsMutation();

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (name, checked) => {
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleUpdateHighlightTestCase = async () => {
    const body = {
      ...formData,
      collection: collectionId,
      auth_type: selectedAuthType,
    };
    try {
      await configurePerformanceTests(body);
    } catch (error) {}
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleUpdateHighlightTestCase();
  };

  const handleChange = (event) => setSelectedAuthType(event.target.value);

  const authTypeFormRenderer = (type) => {
    let authTypeForm;
    switch (type) {
      case authTypes[0]:
        authTypeForm = (
          <BearerForm
            formData={formData}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            isLoading={isLoading}
          />
        );
        break;
      case authTypes[1]:
        authTypeForm = (
          <ApiKeyForm
            formData={formData}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            isLoading={isLoading}
          />
        );
        break;
      case authTypes[2]:
        authTypeForm = (
          <OauthForm
            formData={formData}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            isLoading={isLoading}
          />
        );
        break;
      case authTypes[3]:
        authTypeForm = (
          <Basic
            formData={formData}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            isLoading={isLoading}
          />
        );
        break;
      case authTypes[4]:
        authTypeForm = (
          <JwtForm
            formData={formData}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            isLoading={isLoading}
          />
        );
        break;
      case authTypes[5]:
        authTypeForm = (
          <TokenForm
            formData={formData}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            isLoading={isLoading}
          />
        );
        break;
      default:
        return null;
    }

    return authTypeForm;
  };

  useEffect(() => {
    if (data) toast.success('Configuration updated successfully');

    if (error) {
      console.log(error);
      toast.error('An error occurred');
    }
  }, [data, error]);

  return (
    <div className="w-[80%] md:w-[57%] flex flex-col mx-auto  justify-center">
      <h1 className="text-black text-xl mb-4">Configure Performance Tests</h1>
      <div className="">
        <SelectAuthenticationType
          selectedAuthType={selectedAuthType}
          handleChange={handleChange}
          authTypes={authTypes}
        />

        {authTypeFormRenderer(selectedAuthType)}
      </div>
    </div>
  );
};

export default ConfigurePerformanceTests;
