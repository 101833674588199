import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
export const btnBcg =
  'linear-gradient(0deg, #023673, #023673),linear-gradient(0deg, rgba(2, 54, 115, 0.4), rgba(2, 54, 115, 0.4))';

const Heading = () => {
  const history = useHistory();
  return (
    <div className="flex items-center justify-between mb-3">
      <div>
        <h2 className="text-[#1E1B39] mt-1 font-[Inter] text-[17px] md:text-[19px] font-bold leading-7">
          Software Component Analysis
        </h2>
      </div>

      <div className="flex items-center">
        <button
          onClick={() => history.push('/dashboard/dependencies/')}
          style={{ background: btnBcg }}
          className={`flex w-max mr-4 items-center px-2 py-1  md:py-[7px] md:px-[12px]  border rounded-[8px]`}
        >
          <div className="text-[#FFFFFF] text-[10px] md:text-[14px] leading-[20.27px] mr-2">
            View Dependencies
          </div>
        </button>
      </div>
    </div>
  );
};

export default Heading;
