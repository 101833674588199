import React, { useEffect, useState } from 'react';
import { Badge, Spinner } from 'react-bootstrap';
// import { useFetch } from '../../../context';
import { useGetEndPointScansQuery } from '../../../../redux/services/endPointScansApi';
import errorImg from '../../../../assets/images/searching-error.png';

const DetectedThreats = ({ id }) => {
  // const [activity_data, setData] = useState([]);
  const [detectedThreats, setDetectedThreats] = useState([]);

  // const {
  //   getEndpointActivity,
  //   getFilterEndpointActivity,
  //   getPaginatedEndpointActivity,
  // } = useFetch();

  const {
    data: endPointScansData,
    isLoading: endPointScansDataIsLoading,
    isError: endPointScansIsError,
  } = useGetEndPointScansQuery(id);

  const [data, setDatas] = useState([]);
  const [nextLink, setNextLink] = useState(null);
  const [prevLink, setPrevLink] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [neededData, setNeededData] = useState([]);
  const [filteredData, setFilteredData] = useState({
    ipaddress: '',
    location: '',
    status: '',
    res_code: '',
    res_time: '',
  });
  const [modalStates, setModalStates] = useState({});

  const toggleModal = (index) => {
    setModalStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getTodayDate = () => {
    const today = new Date();
    return today;
  };

  const getYesterdayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  };

  useEffect(() => {
    if (endPointScansData) {
      setDetectedThreats(endPointScansData.detectedThreatsData);
    }
  }, [endPointScansData, endPointScansDataIsLoading, id]);

  if (endPointScansDataIsLoading) {
    return (
      <div className="w-[100%]   flex justify-center mx-auto  h-[300px] pt-[50px]">
        <Spinner
          animation="border"
          variant="secondary"
          className="text-[50px] block"
        />
      </div>
    );
  }

  return (
    <div>
      {endPointScansDataIsLoading && (
        <div className="w-[100%]   flex justify-center mx-auto  h-[300px] pt-[50px]">
          <Spinner
            animation="border"
            variant="secondary"
            className="text-[50px] block"
          />
        </div>
      )}
      {endPointScansIsError && (
        <div
          style={{ width: '50%', margin: 'auto', textAlign: 'center' }}
          className="mt-5  w-[100%]  py-6 flex flex-col justify-center mx-auto font-medium text-slate-400 text-xl"
        >
          <img src={errorImg} alt="" />
          <p>An error occured</p>
        </div>
      )}

      {!endPointScansDataIsLoading &&
        endPointScansData &&
        !detectedThreats?.length && (
          <div className=" w-[70%] justify-center mx-auto text-center">
          <h4 className='text-primary'> No Threat Found  <i className="mdi mdi-alert-circle-outline"></i></h4> 
          <a className='btn-primary btn' href='/dashboard/configuration/threat_monitoring'>Configure Threat Monitoring</a>
        </div>
        )}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {detectedThreats?.length > 0 && (
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 p-4 ">
              <tr>
                <th className="px-6 py-4 font-medium text-primary whitespace-nowrap">
                  Attack Type
                </th>
                <th className="px-6 py-4 font-medium text-primary whitespace-nowrap">
                  Attack Category
                </th>
                <th className="px-6 py-4 font-medium text-primary whitespace-nowrap">
                  Attack IP
                </th>
                <th className="px-6 py-4 font-medium text-primary whitespace-nowrap">
                  Message
                </th>
                <th className="px-6 py-4 font-medium text-primary whitespace-nowrap">
                  Risk
                </th>
                <th className="px-6 py-4 font-medium text-primary whitespace-nowrap">
                  Severity
                </th>
                <th className="px-6 py-4 font-medium text-primary whitespace-nowrap">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {detectedThreats?.length > 0 &&
                detectedThreats?.map((threat) => (
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      {threat?.detection?.attack_type}
                    </th>
                    <td className="px-6 py-4 text-primary">
                      {threat?.detection?.category}
                    </td>
                    <td className="px-6 py-4 text-primary">
                      {threat?.detection?.ipaddress}
                    </td>
                    <td className="px-6 py-4 text-primary">
                      {threat?.detection?.message}
                    </td>
                    <td className="px-6 py-4 text-primary">
                      {threat?.detection?.risk}
                    </td>
                    <td className="px-6 py-4 text-primary">
                      <Badge
                        pill
                        className="text-white p-2"
                        bg={
                          threat?.detection?.severity === 'critical'
                            ? 'danger'
                            : threat?.detection?.severity === 'medium'
                            ? 'warning'
                            : threat?.detection?.severity === 'low'
                            ? 'primary'
                            : 'info'
                        }
                      >
                        <i className="mdi mdi-security"> </i>
                        {threat?.detection?.severity}
                      </Badge>
                    </td>
                    <td className="px-6 py-4 text-primary">
                      {threat?.request_status}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DetectedThreats;
