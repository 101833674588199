import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiUrl = process.env.REACT_APP_API_URL;

const user = () => localStorage.getItem("watoken");

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/notifications/`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Token ${user()}`);
      return headers;
    },
  }),
  tagTypes: ["GetSlackNotifications"],

  endpoints: (build) => ({
    configureSlack: build.mutation({
      query: ({ channel, token, collection_id }) => ({
        url: `configure_slack/`,
        method: "POST",
        body: {
          channel: channel,
          token: token,
          collection_id: collection_id,
        },
      }),
    }),
  }),
});
export const { useConfigureSlackMutation } = notificationsApi;
