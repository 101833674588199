export const addUserDetailsToLocalStorageWhenLoggedIn = (response) => {
  // if (response?.key) {
  // const token = response.key;

  const user = {
    company_name: response.user?.company_name || '',
    email: response.user?.email || '',
    username: response.user?.username || '',
    first_name: response.user?.first_name || '',
    last_name: response.user?.last_name || '',
    // expiry: response?.expiry || '',
    // setup: response?.user?.setup || '',
  };

  // localStorage.setItem('watoken', token);
  localStorage.setItem('wauser', JSON.stringify(user));
  // }
};

export const addSetUpDetailsToLocalStorage = (name, data) => {
  localStorage.setItem(`${name}`, JSON.stringify(data));
};

export const removeUserTokenFromLocalStorage = () => {
  localStorage.removeItem('watoken');
};

export const getUserTokenFromLocalStorage = () => {
  const result = localStorage.getItem('watoken');
  const watoken = result ? result : null;
  return watoken;
};

export const removeSavedDataOnLogout = () => {
  localStorage.removeItem('watoken');
  localStorage.removeItem('wauser');
  localStorage.removeItem('collection_id');
  localStorage.removeItem('collection_name');
  localStorage.removeItem('collection_token');
  localStorage.removeItem('tempToken');
  localStorage.removeItem('tempId');
  localStorage.removeItem('token');

  localStorage.removeItem('step');
  localStorage.removeItem('selected_endpoint');
  localStorage.removeItem('user_details');
  localStorage.removeItem('session_id');
  localStorage.removeItem('endpoint_name');
  window.location.reload();
  // setUser(null);
  // history.push('/login');
};
