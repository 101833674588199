import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFunc } from '../baseQueryFunc';
import { method } from 'lodash';

export const collectionApi = createApi({
  reducerPath: 'collectionApi',
  baseQuery: (args, api, extraOptions) =>
    baseQueryFunc('scan', args, api, extraOptions),

  tagTypes: [
    "Projects",
    "EndpointDetails, Collections",
    "SingleCollection",
    "EndPointScans",
    "ThreatMonitoringEndpointDetails",
    "UserInfo",
    "WafMode",
    "CustomRegex",
    "BlockedIps",

  ],
  endpoints: (build) => ({
    getActivityLogs: build.query({
      query: ({ collectionId }) => `activities/`,
      method: 'GET',
      body: {
        // collectionId: collectionId,
        // prod: prod,
        // test: test,
        // other: other,
      },
    }),

    getBlockedIps: build.query({
      query: ({ collectionId }) =>
        `/blocked_ip/?collection=${collectionId}&status=blocked`,
    }),

    createProject: build.mutation({
      query: ({ name, description }) => ({
        url: `create_project/`,
        method: "POST",
        body: { name: name, description: description },
        
      }),
      invalidatesTags: ["Projects"],
    }),

    createProjectAuto: build.mutation({
      query: ({ key, collection }) => ({
        url: `create_project/`,
        method: "POST",
        body: { key: key, collection: collection },
        
      }),
      invalidatesTags: ["Projects"],
    }),

    getCollections: build.query({
      query: () => "collections/",
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.map(({ id }) => ({
                type: "Collections",
                id,
              })),
              "Collections",
            ]
          : ["Collections"],
    }),

    uploadCollection: build.mutation({
      query: ({ formData }) => ({
        url: `collections/`,
        method: "POST",
        body:formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Collections"],
    }),

    getCollectionByUser: build.query({
      query: ({ collectionId }) => `collections/${collectionId}/`,
    }),

    getCollectionEnvironment: build.query({
      query: ({ collectionId }) => `environments/${collectionId}/`,
    }),

    getCollectionUsers: build.query({
      query: (collectionId) =>
        `get-user-in-collection/?collection=${collectionId}`,
    }),

    getSecurityCheckAnalytics: build.query({
      query: ({collection_id,environment}) =>
        `security_check_analytics/?collection_id=${collection_id}&environment=${environment}`,
    }),

    getSecurityChecklist: build.query({
      query: ({collection_id,environment}) =>
        `security_checklist/?collection_id=${collection_id}&environment=${environment}`,
    }),

    getSeverityCount: build.query({
      query: ({collection_id}) =>
        `severity-count/?collection_id=${collection_id}`,
    }),

    getSeverityAnalytics: build.query({
      query: ({collection_id}) =>
        `severity_analytics/?collection_id=${collection_id}`,
    }),

    getIssueType: build.query({
      query: ({collection_id}) =>
        `issue-groups/?collection_id=${collection_id}`,
    }),

    getSeverityCounts: build.query({
      query: ({collection_id}) =>
        `severity-counts/?collection_id=${collection_id}`,
    }),

    getEndpointCounts: build.query({
      query: ({collection_id}) =>
        `unique-endpoints-count/?collection_id=${collection_id}`,
    }),

    getLastScanDate: build.query({
      query: ({collection_id}) =>
        `last-scan-date/?collection_id=${collection_id}`,
    }),

    getFilterIssues: build.query({
      query: ({collection_id, threatLevel,path, issue}) =>
        `issue-groups-list/?collection_id=${collection_id}&threat_level=${threatLevel}&path=${path}&issue=${issue}`,
    }),

    updateCollection: build.mutation({
      query: ({ collectionId, formData }) => ({  
        url: `collections/${collectionId}/`,
        method: "PATCH",
        body: formData,
      }),
      async onQueryStarted(collectionId, { dispatch, queryFulfilled }) {
        
        try {
          const { data: updatedCollection } = await queryFulfilled;
          dispatch(
            collectionApi.util.updateQueryData(
              "getCollections",
              collectionId,
              (draft) => {
                Object.assign(draft, updatedCollection);
              }
            )
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),

    setupEnvironment: build.mutation({
      query: ({collection_id, prod, test, other  }) => ({
        url: `environments/`,
        method: "POST",
        body: {
          collection_id: collection_id,
          prod: prod,
          test: test,
          other: other,
        },
      }),
      async onQueryStarted(collection_id, { dispatch, queryFulfilled }) {
        try {
          const { data: setupEnvironment } = await queryFulfilled;
          dispatch(
            collectionApi.util.updateQueryData(
              "getCollectionEnvironment",
              collection_id,
              (draft) => {
                Object.assign(draft, setupEnvironment);
                Object.assign(draft, setupEnvironment);
              }
            )
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),

    runDynamicScan: build.mutation({
      query: ({collection_id, environment, type}) => ({
        url: `dynamic_scan/`,
        method: "POST",
        body: {
          collection_id: collection_id,
          environment: environment,
          type: type,
        },
      }),
      // async onQueryStarted(collection_id, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data: runDynamicScan } = await queryFulfilled;
      //     dispatch(
      //       collectionApi.util.updateQueryData(
      //         "getCollectionEnvironment",
      //         collection_id,
      //         (draft) => {
      //           Object.assign(draft, runDynamicScan);
      //         }
      //       )
      //     );
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    }),

    createCollection: build.mutation({
      query: (formData) => ({
        url: `collections/`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: createdCollection } = await queryFulfilled;
          dispatch(
            collectionApi.util.upsertQueryData(
              "getCollections",
              undefined,
              createdCollection
            )
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),

    createEnvironment: build.mutation({
      query: ({ document_id, prod, test, other }) => ({
        url: `environments/`,
        method: "POST",
        body: {
          collection_id: document_id,
          prod: prod,
          test: test,
          other: other,
        },
      }),
    }),

    createVulScan: build.mutation({
      query: ({
        document_id,
        allow_prod_scan,
        allow_test_scan,
        allow_other_scan,
        allow_active_scan_on_prod,
        allow_active_scan_on_test,
        allow_active_scan_on_other,
      }) => ({
        url: `setup_vulscan/`,
        method: "POST",
        body: {
          document_id,
          allow_prod_scan,
          allow_test_scan,
          allow_other_scan,
          allow_active_scan_on_prod,
          allow_active_scan_on_test,
          allow_active_scan_on_other,
        },
      }),
    }),

    getSingleCollection: build.query({
      url: ({ id }) => `collections/${id}`,
    }),

    deleteCollection: build.mutation({
      query: (documentId) => ({
        url: `collections/${documentId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ['Collections'],
      // async onQueryStarted(args, { queryFulfilled, dispatch }) {
      //   try {
      //     await queryFulfilled;

      //     dispatch(
      //       collectionApi.util.updateQueryData(
      //         "getCollections",
      //         undefined,
      //         (draft) => {
      //           return draft?.filter(
      //             (collection) => collection?.document_id !== args
      //           );
      //         }
      //       )
      //     );
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    }),

    getEndpointDetails: build.query({
      query: (collectionId, otherCollectionUrl) => {
        let url = "";
        if (otherCollectionUrl) {
          url = `${otherCollectionUrl}`;
        } else {
          url = `path_monitoring/?collection=${collectionId}`;
        }
        return `${url}`;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.results?.map(({ id }) => ({
                type: "EndpointDetails",
                id,
              })),
              "EndpointDetails",
            ]
          : ["EndpointDetails"],
    }),

    getPaginatedEndpointDetails: build.query({
      query: ({ collectionId, page }) =>
      // query: ({ collectionId, page }) =>
        `path_monitoring/?collection=${collectionId}&page=${page}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.results?.map(({ id }) => ({
                type: 'EndpointDetails',
                id,
              })),
              '',
            ]
          : ['EndpointDetails'],
    }),

    deleteMultipleEndpoints: build.mutation({
      query: ({ collectionId, body }) => ({
        url: `delete-multiple/${collectionId}/`,
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: ['EndpointDetails'],
    }),

    configureWaf: build.mutation({
      query: ({ body }) => ({
        url: `waf-configurations/`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['WafMode'],
    }),

    createCustomRegex: build.mutation({
      query: ({ body }) => ({
        url: `custom_regex/`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['CustomRegex'],
    }),

    updateCustomRegex: build.mutation({
      query: ({ uuid, body }) => ({
        url: `soft_delete/${uuid}/`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['CustomRegex'],
    }),

    getBlockedIpAddresses: build.query({
      query: ({collectionId}) => `blocked_ip/?collection=${collectionId}&status=blocked`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.results?.map(({ id }) => ({
                type: "BlockedIps",
                id,
              })),
              "BlockedIps",
            ]
          : ["BlockedIps"],
    }),

    deleteBlockedIpAddresses: build.mutation({
      query: ({ ip_id, ipAddress }) => ({
        url: `blocked_ip?ip_id=${ip_id}&ip_address=${ipAddress}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BlockedIps'],
    }),

    getCustomRegex: build.query({
      query: ({collectionId}) => `custom_regex/${collectionId}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.map(({ id }) => ({
                type: "CustomRegex",
                id,
              })),
              "CustomRegex",
            ]
          : ["CustomRegex"],
    }),

    reportThreat: build.mutation({
      query: ({ id, body }) => ({
        url: `/activities/${id}/update_reported/`,
        method: 'PATCH',
        body: body,
      }),
    }),


    getThreatMonitoringEndpointDetails: build.query({
      query: ({ collectionId, pathnameLastVal, page }) => {
        let url = "";

        if (pathnameLastVal === 'threats' || pathnameLastVal !== 'endpoints') {
          url = `threat_monitoring/?collection_id=${collectionId}&page=${page}`;
        } else {
          url = `threat_monitoring/?collection_id=${collectionId}&ip_address=${pathnameLastVal}&page=${page}`;
        }

        return url;
      },

      // transformResponse: (response, meta, arg) => {
      //   let data;

      //   const ipAddress = arg?.ipAddress;
      //   console.log(response);

      //   if (arg.filterThreatDataBy === 'requestStatusBlocked') {
      //     data = {
      //       ...response,
      //       results: response.results.filter(
      //         (response) => response?.request_status === 'blocked'
      //       ),
      //     };
      //   } else if (arg.filterThreatDataBy === 'ipAddress') {
      //     data = {
      //       ...response,
      //       ipAddress: response.results.filter(
      //         (response) => response?.ipAddress === ipAddress
      //       ),
      //     };
      //   } else {
      //     data = response;
      //   }

      //   return data;
      // },

      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.results?.map(({ id }) => ({
                type: 'ThreatMonitoringEndpointDetails',
                id,
              })),
              '',
            ]
          : ['ThreatMonitoringEndpointDetails'],
    }),
  }),
});

export const {
  useGetCollectionsQuery,
  useGetSingleCollectionQuery,
  useDeleteCollectionMutation,
  useCreateProjectMutation,
  useCreateProjectAutoMutation,
  useUploadCollectionMutation,
  useRunDynamicScanMutation,
  useGetEndpointDetailsQuery,
  useGetPaginatedEndpointDetailsQuery,
  useGetThreatMonitoringEndpointDetailsQuery,
  useGetCollectionUsersQuery,
  useGetBlockedIpsQuery,
  useGetActivityLogsQuery,
  useGetSecurityCheckAnalyticsQuery,
  useGetSecurityChecklistQuery,
  useGetIssueTypeQuery,
  useGetSeverityCountsQuery,
  useGetEndpointCountsQuery,
  useGetLastScanDateQuery,
  useGetFilterIssuesQuery,
  useGetCollectionByUserQuery,
  useUpdateCollectionMutation,
  useGetCollectionEnvironmentQuery,
  useGetSeverityAnalyticsQuery,
  useGetSeverityCountQuery,
  useSetupEnvironmentMutation,
  useDeleteMultipleEndpointsMutation,
  useConfigureWafMutation,
  useCreateCustomRegexMutation,
  useUpdateCustomRegexMutation,
  useGetBlockedIpAddressesQuery,
  useDeleteBlockedIpAddressesMutation,
  useGetCustomRegexQuery,
  useReportThreatMutation,
} = collectionApi;
