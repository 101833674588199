import React from "react";
import "./billingPage.css";
const BillingPage = () => {
  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className="nav-link  active"
            data-bs-toggle="tab"
            aria-current="page"
            href="#individual"
          >
            Individual
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-bs-toggle="tab" href="#enterprise">
            Enterprise
          </a>
        </li>
      </ul>
      <div className="tab-content my-2">
        <div className="tab-pane fade show active" id="individual">
          <div className="md:flex justify-around text-[#0e5cb5]">
            <div className="border shadow p-4 rounded-[20px] md:w-[30%] bg-white flex flex-col mb-4">
              <div className="text-center">
                <p className="text-[18px]">Starter</p>
                <p className="text-[35px]">$50/mo</p>
                <p className="text-[16px]">
                  <s>$100/mo</s>
                </p>
              </div>
              <button className="btn py-3 my-2 rounded-[10px] bg-[#003572] hover:bg-[#0e5cb5] text-[16px] text-white">
                Get Started
              </button>
              <div className="mt-2">
                <p>Features</p>
                <ul className="space-y-1 mt-2">
                  <li>API Management</li>
                  <li>Automated Testing</li>
                  <li>Unlimited Projects</li>
                  <li>Full API Vulnerability Scan</li>
                  <li>Endpoint & Threat Monitoring</li>
                </ul>
              </div>
            </div>
            <div className="border shadow p-4 rounded-[20px] md:w-[30%] bg-white flex flex-col mb-4">
              <div className="text-center">
                <p className="text-[18px]">Premium</p>
                <p className="text-[35px]">$150/mo</p>
                <p className="text-[16px]">
                  <s>$200/mo</s>
                </p>
              </div>
              <button className="btn py-3 my-2 rounded-[10px] bg-[#003572] hover:bg-[#0e5cb5] text-[16px] text-white">
                Get Started
              </button>

              <div className="mt-2">
                <p>Features</p>
                <ul className="space-y-1 mt-2">
                  <li>API Management</li>
                  <li>Automated Testing</li>
                  <li>Unlimited Projects</li>
                  <li>Full API Vulnerability Scan</li>
                  <li>Endpoint & Threat Monitoring</li>
                  <li>Vendor Monitoring</li>
                  <li>Slack, Email & CI/CD Integrations</li>
                  <li>Scheduled Scan & Testing</li>
                </ul>
              </div>
            </div>
            <div className="border shadow p-4 rounded-[20px] md:w-[30%] bg-white flex flex-col mb-4">
              <div className="text-center">
                <p className="text-[18px]">Executive</p>
                <p className="text-[35px]">$200/mo</p>
                <p className="text-[16px]">
                  <s>$250/mo</s>
                </p>
              </div>
              <button className="btn py-3 my-2 rounded-[10px] bg-[#003572] hover:bg-[#0e5cb5] text-[16px] text-white">
                Get Started
              </button>

              <div className="mt-2">
                <p>Features</p>
                <ul className="space-y-1 mt-2">
                  <li>API Management</li>
                  <li>Automated Testing</li>
                  <li>Unlimited Projects</li>
                  <li>Full API Vulnerability Scan</li>
                  <li>Endpoint & Threat Monitoring</li>
                  <li>Vendor Monitoring</li>
                  <li>Slack, Email & CI/CD Integrations</li>
                  <li>Scheduled Scan & Testing</li>
                  <li>Download Collection</li>
                  <li>Source Code Review</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="enterprise">
          <div className="md:flex justify-around text-[#0e5cb5]">
            <div className="border shadow p-4 rounded-[20px] md:w-[40%] bg-white flex flex-col mb-4">
              <div className="text-center">
                <p className="text-[18px]">Starter</p>
                <p className="text-[35px]">$350/mo</p>
                <p className="text-[16px]">
                  <s>$400/mo</s>
                </p>
              </div>
              <button className="btn py-3 my-2 rounded-[10px] bg-[#003572] hover:bg-[#0e5cb5] text-[16px] text-white">
                Get Started
              </button>
              <div className="mt-2">
                <p>Features</p>
                <ul className="space-y-1 mt-2">
                  <li>API Management</li>
                  <li>Automated Scan</li>
                  <li>Unlimited API Collections</li>
                  <li>Full API Vulnerability Scan</li>
                  <li>Automatic Test Case Generation</li>
                  <li>Manual & Scheduled Trigger</li>
                  <li>Slack, Email & CI/CD Integrations</li>
                  <li>IP, Threat & Error Monitoring</li>
                  <li>Security Scan</li>
                  <li>Monthly Reporting</li>
                </ul>
              </div>
            </div>
            <div className="border shadow p-4 rounded-[20px] md:w-[40%] bg-white flex flex-col mb-4">
              <div className="text-center">
                <p className="text-[18px]">Premium</p>
                <p className="text-[35px]">$500/mo</p>
                <p className="text-[16px]">
                  <s>$600/mo</s>
                </p>
              </div>
              <button className="btn py-3 my-2 rounded-[10px] bg-[#003572] hover:bg-[#0e5cb5] text-[16px] text-white">
                Get Started
              </button>

              <div className="mt-2">
                <p>Features</p>
                <ul className="space-y-1 mt-2">
                  <li>API Management</li>
                  <li>Automated Scan</li>
                  <li>Unlimited API Collections</li>
                  <li>Full API Vulnerability Scan</li>
                  <li>Automatic Test Case Generation</li>
                  <li>Manual & Scheduled Trigger</li>
                  <li>Slack, Email & CI/CD Integrations</li>
                  <li>IP, Threat & Error Monitoring</li>
                  <li>Security Scan</li>
                  <li>Ciso Monthly Reporting</li>
                  <li>Source Code Monitoring</li>
                  <li>Issue Tracking</li>
                  <li>Incidence Response</li>
                  <li>User Collaboration Commenting</li>
                  <li>Monthly Reporting</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPage;
