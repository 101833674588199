import React, { useEffect, useState } from 'react';
import DarkLoader from '../../../components/loader/DarkLoader';

const TopAttackTypes = ({
  topAttackTypesData,
  topAttackTypesIsLoading: isLoading,
  topAttackTypesError: error,
}) => {
  const [data, setData] = useState(null);

  const isNoDataFound = (str) => {
    if (!isLoading && !error && !str) return true;

    return false;
  };

  useEffect(() => {
    if (topAttackTypesData && topAttackTypesData?.length > 0) {
      //split string  into pairs of key-value pairs
      const keyValuePairs = topAttackTypesData.split(/\s*:\s*/);
      const responseObject = {};

      for (let i = 0; i < keyValuePairs.length; i += 2) {
        let key = keyValuePairs[i].trim();
        //numbers removed from the key
        key = key.replace(/\d+(\.\d+)?/g, '').trim();

        const value = parseFloat(keyValuePairs[i + 1]);
        responseObject[key] = value;
      }
      setData(responseObject);
    }
  }, [error, topAttackTypesData]);

  return (
    <div
      className={`table lg:w-[33%] border mb-4 ${
        isLoading || error ? 'h-[350px]' : ''
      } ${
        topAttackTypesData && topAttackTypesData.length < 1 ? 'hidden' : 'block'
      }`}
    >
      <h4 className="text-lg text-black text-center mb-0 bg-[#d9d9d980] py-2">
        Top Attack Types
      </h4>
      <table className="table table-hover relative">
        {isLoading ? (
          <div className="absolute  text-center mb-0  inset-0  h-[230px]  w-full  flex items-center justify-center">
            <DarkLoader />
          </div>
        ) : null}

        {error ? (
          <div className="absolute text-base  text-black text-center mb-0   inset-0  h-[230px]  w-full  flex items-center justify-center">
            An error occured
          </div>
        ) : null}
        {data
          ? Object.entries(data)?.map(([key, value]) => (
              <tr key={key} className="bg-white border-b h-[53px]">
                <td className="px-6 py-2">{key}</td>
                <td>{value}%</td>
              </tr>
            ))
          : null}

        {isNoDataFound(topAttackTypesData) ? (
          <div className="absolute text-base bg-white text-black text-center mb-0   inset-0  h-[230px]  w-full  flex items-center justify-center">
            No threat
          </div>
        ) : null}
      </table>
    </div>
  );
};

export default TopAttackTypes;
