import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Tab, Tabs } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import ThreatMonitoring from './threatMonitoring';
import DetectedThreats from './detectedThreats';
import SecurityScan from './securityScan';

const ViewEndpoint = () => {
  const [key, setKey] = useState('home');
  const history = useHistory();
  const urlPath = history.location.pathname;

  const pathParts = urlPath.split('/');

  const id = pathParts[pathParts.length - 1];

  return (
    <>
      <a
        href="/dashboard/api-security"
        className="mb-4"
      >
        API Security {'|'}{' '}
      </a>
      <span className="cursor-pointer">
        {localStorage.getItem('endpoint_name')}
      </span>

      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        id="justify-tab-example"
        className="mb-3 mt-4"
      >
        <Tab eventKey="home" title="Threat Monitoring">
          {(key === 'home' ||
            key !== 'environment' ||
            key !== 'environment') && <ThreatMonitoring id={id} />}
        </Tab>
        <Tab eventKey="environment" title="Security Scan">
          {key === 'environment' && <SecurityScan id={id} />}
        </Tab>
        <Tab eventKey="threats" title="Detected Threats">
          {key === 'threats' && <DetectedThreats id={id} />}
        </Tab>
        {/* <Tab eventKey="environment" title="Performance Scan" disabled>
        <EndpointSummary />
      </Tab> */}
      </Tabs>
    </>
  );
};

export default ViewEndpoint;
