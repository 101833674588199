import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MapChart, EmptyMapChart } from './component/MapChart';
import {
  useGetSuspiciousIpsQuery,
  useGetThreatMapDataQuery,
  useGetTopAttackTypesQuery,
  useGetTopCountriesAttachersQuery,
} from '../../../redux/services/endPointScansApi';
import TopAttachers from './component/TopAttachers';
import TopAttackTypes from './component/TopAttackTypes';
import SuspiciousIps from './component/SuspiciousIps';
import { useGetThreatMonitoringEndpointDetailsQuery } from '../../../redux/services/collectionApi';
import ThreatTable from './component/threatTable';
import ErrorDisplay from '../../components/Error';

const ThreatMap = () => {
  const history = useHistory();

  const [threatMapPage, setThreatMapPage] = useState(1);
  const [threatMapData, setThreatMapData] = useState([]);
  const [logData, setLogData] = useState([]);

  const collectionId = localStorage.getItem('collection_id');
  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  const {
    data: threatMapResult,
    isLoading: threatMapIsLoading,
    error: threatMapError,
  } = useGetThreatMapDataQuery(
    {
      collectionId,
      threatMapPage,
    },
    {
      pollingInterval: 30000, //30 seconds polling interval
      skipPollingIfUnfocused: true,
    }
  );

  const {
    data: topCountriesAttachersData,
    isLoading: topCountriesAttachersIsLoading,
    error: topCountriesAttachersError,
  } = useGetTopCountriesAttachersQuery({
    collectionId,
  });

  const {
    data: topAttackTypesData,
    isLoading: topAttackTypesIsLoading,
    error: topAttackTypesError,
  } = useGetTopAttackTypesQuery({
    collectionId,
  });

  const {
    data: suspiciousIpsData,
    isLoading: suspiciousIpsIsLoading,
    error: suspiciousIpsError,
  } = useGetSuspiciousIpsQuery({
    collectionId,
  });

  const {
    data: logResult,
    isLoading: logResultIsLoading,
    error: logResultError,
  } = useGetThreatMonitoringEndpointDetailsQuery({
    collectionId,
    page: currentPage,
  });

  const handleNavigateToLogDataPage = (param) => {
    localStorage.setItem('filterThreatDataBy', param);
    history.push('/dashboard/threats');
  };

  useEffect(() => {
    if (collectionId && threatMapError)
      toast.error('Error loading map threat data');

    if (threatMapResult) {
      const swapCoordinates = (item) => {
        const [lat, long] = item.coord;
        return { coord: [long, lat], city: item.city };
      };
      const swappedData = threatMapResult?.results?.map((item) => ({
        from_info: swapCoordinates(item.from_info),
        to_info: swapCoordinates(item.to_info),
      }));

      setThreatMapData(swappedData);
    }
  }, [threatMapResult, threatMapError, collectionId]);

  useEffect(() => {
    if (logResult) {
      if (logResult?.results?.length > 6)
        setLogData(logResult.results.slice(0, 5));
      else setLogData(logResult.results);
    }
  }, [logResult]);

  useEffect(() => {
    if (!collectionId) {
      const redirectTimeout = setTimeout(() => {
        history.push('/dashboard/collections');
      }, 4000);
      return () => clearInterval(redirectTimeout);
    }
  }, [collectionId, history]);

  if (!collectionId)
    return (
      <div className="flex flex-col items-center mb-1">
        <ErrorDisplay errorMessage="No Project Selected" errorCode={404} />
        <h4 className="text-primary mt-4 mb-4">Redirecting Shortly</h4>
      </div>
    );

  return (
    <div className="w-full mb-4  md:mb-5">
      <div className="mb-4">
        <button
          onClick={() => handleNavigateToLogDataPage('requestStatusBlocked')}
          className="bg-primary py-2 px-3 text-white border-0 outline-0 rounded mb-4"
        >
          View Dectected Threat
        </button>
      </div>
      <div className="lg:flex w-full mb-4 h-full justify-between overflow-hidden">
        {threatMapData.length > 0 ? (
          <MapChart threatMapData={threatMapData} />
        ) : (
          <EmptyMapChart />
        )}
        <ThreatTable
          data={logData}
          isLoading={logResultIsLoading}
          error={logResultError}
        />
      </div>

      <div className="table lg:flex mb-4">
        <TopAttachers
          topCountriesAttachersData={topCountriesAttachersData}
          topCountriesAttachersIsLoading={topCountriesAttachersIsLoading}
          topCountriesAttachersError={topCountriesAttachersError}
        />

        <TopAttackTypes
          topAttackTypesData={topAttackTypesData}
          topAttackTypesIsLoading={topAttackTypesIsLoading}
          topAttackTypesError={topAttackTypesError}
        />

        <SuspiciousIps
          suspiciousIpsData={suspiciousIpsData}
          suspiciousIpsIsLoading={suspiciousIpsIsLoading}
          suspiciousIpsError={suspiciousIpsError}
        />
      </div>
    </div>
  );
};

export default ThreatMap;
