import React, { useEffect, useState } from 'react';
import signInBg from '../../../../assets/images/auth/sign-in-bg.png';
import googleLogoNew from '../../../../assets/images/auth/google-logo-new.png';
import googleLogoAuth from '../../../../assets/images/auth/google-logo-auth.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLoginUserMutation } from '../../../../redux/services/authApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  setSignInDetails,
  setUser,
} from '../../../../redux/features/authSlice';
import {
  addSetUpDetailsToLocalStorage,
  addUserDetailsToLocalStorageWhenLoggedIn,
} from '../../../../utils/localStorage';
import { useDispatch } from 'react-redux';

export const SignIn = () => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const [loginUser, { data, isLoading, isSuccess, error }] =
    useLoginUserMutation();

  useEffect(() => {
    const isSessionExpired = localStorage.getItem('isSessionExpired');

    if (isSessionExpired && isSessionExpired === 'true')
      toast.error('Session expired, please login again');
  }, []);

  useEffect(() => {
    if (error) {
      localStorage.setItem('isSessionExpired', 'false');
      const errMessage = error?.data?.error || 'An error occurred';
      if (errMessage === 'Please provide an OTP.') history.push('/confirm-2fa');
      toast(errMessage);
    }

    if (data) {
      localStorage.setItem('isSessionExpired', 'false');
      const userStatus = data?.status;

      localStorage.setItem('accountExpiry', data?.expiry);

      const userToken = data?.key;
      localStorage.setItem('watoken', userToken);

      if (userStatus?.first_login) {
        if (!userStatus.completed_password_reset)
          history.push('/first-login-reset-password');
        else if (!userStatus.completed_set_up_mfa) history.push('/proceed-2fa');
        else if (!userStatus.completed_onboarding)
          history.push('/new_onboarding');
      } else {
        const {
          completed_password_reset,
          completed_set_up_mfa,
          completed_onboarding,
        } = data?.user?.setup;

        if (!completed_password_reset)
          history.push('/first-login-reset-password');
        else if (!completed_set_up_mfa) history.push('/proceed-2fa');
        else if (!completed_onboarding) history.push('/new_onboarding');
        else {
          const setup = data?.user?.setup;

          if (!setup) return history.push('/login');

          const {
            first_login,
            completed_password_reset,
            completed_set_up_mfa,
            completed_onboarding,
          } = setup;

          const isUserCompletedAccountSetup =
            first_login === false &&
            completed_password_reset &&
            completed_set_up_mfa &&
            completed_onboarding;

          addUserDetailsToLocalStorageWhenLoggedIn(data);

          addSetUpDetailsToLocalStorage('setup', {
            first_login,
            completed_password_reset,
            completed_set_up_mfa,
            completed_onboarding,
          });

          localStorage.setItem('accountExpiry', data?.expiry);

          dispatch(setUser(data?.user));

          if (isUserCompletedAccountSetup) {
            toast('Login Successful');
            history.push('/dashboard/collections');
          } else {
            toast('Complete authentication');
            history.push('/proceed-2fa');
          }
        }
      }
    }
  }, [error, data, isLoading, isSuccess, history, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    dispatch(setSignInDetails({ username, password }));

    try {
      await loginUser({
        username,
        password,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const toggleForgotPassword = () => history.push('/reset-password');

  const handleNavigateToOtp = () =>
    toast('Sign in with  username and password');

  return (
    <main className="w-full h-full  bg-white">
      <div className="grid grid-cols-1 w-full h-full md:grid-cols-2 grid-rows-[100%] gap-[19px] ">
        <div className="w-full h-[100vh]  hidden md:block  ">
          <img
            className="h-full w-full sm:block object-cover  rounded-tr-[270px] rounded-br-[270px]"
            src={signInBg}
            alt="Img"
          />
        </div>
        <div className=" px-4 md:pr-[28px] w-full md:mx-auto  h-full flex flex-col justify-center">
          <div className="mb-[47px]">
            <h1 className="pt-4 md:pt-0 text-sky-950 text-[20px]  md:text-[28px] font-semibold font-['Nunito Sans']">
              Hello! Let’s get started
            </h1>
            <div className="flex items-center ">
              <div className="text-sky-950  text-sm md:text-base font-light font-['Noto Sans JP']">
                Sign in to continue
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mb-[35px]">
              <label className="text-sky-950 text-sm md:text-base font-normal font-['Noto Sans JP']">
                Username
              </label>
              <input
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
              />
            </div>

            <div className="flex flex-col mb-[35px]">
              <label className="text-sky-950 text-sm md:text-base font-normal font-['Noto Sans JP']">
                Password
              </label>
              <input
                required
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
              />

              <button
                type="button"
                disabled={isLoading}
                onClick={toggleForgotPassword}
                className="text-sky-800 text-sm md:text-base font-medium font-['Noto Sans JP'] ml-auto mt-2 border-none bg-transparent focus:outline-none"
              >
                Forgotten password?{' '}
              </button>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full h-[50px] bg-sky-950 rounded shadow flex justify-center items-center"
            >
              <div className="text-white text-sm font-bold font-['Noto Sans JP']">
                {isLoading ? 'LOADING...' : 'SIGN IN'}
              </div>
            </button>

            <div className="flex items-center justify-center  mt-[60px]">
              <button
                onClick={handleNavigateToOtp}
                type="button"
                className="w-[46px] h-[41px]  mr-2 border-none bg-transparent focus:outline-none"
              >
                <img
                  className="w-full h-full"
                  src={googleLogoNew}
                  alt="Google logo"
                />
              </button>

              <button
                onClick={handleNavigateToOtp}
                type="button"
                className="w-[55px] h-[41px] border-none bg-transparent focus:outline-none"
              >
                <img
                  className="w-full h-full"
                  src={googleLogoAuth}
                  alt="Google auth logo"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};
