import React, { createContext, useState, useContext } from 'react';

// Create the context
const AutomatedTest = createContext();

// Create a custom hook to access the context value
export const useAutomatedTest = () => useContext(AutomatedTest);

// Create a provider component to wrap the components that need access to the context
export const AutomatedTestProvider = ({ children }) => {
  // State or any data you want to share across components
  const [tests, setTests] = useState([]);

  return (
    <AutomatedTest.Provider value={{ tests, setTests }}>
      {children}
    </AutomatedTest.Provider>
  );
};
