import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import googleLogoAuth from '../../../../../assets/images/auth/google-logo-auth.png';
import tokenIcon from '../../../../../assets/images/auth/token-icon.svg';
import { BigLeftIcon } from './component/BigLeftIcon';
import { useSelector } from 'react-redux';
import { useConfirmMfaMutation } from '../../../../../redux/services/authApi';
import {
  addSetUpDetailsToLocalStorage,
  addUserDetailsToLocalStorageWhenLoggedIn,
} from '../../../../../utils/localStorage';
import { useConfirmMfaOnboardingMutation } from '../../../../../redux/services/mfaAuthApi';

// const correctOTP = '123456';

export const ConfirmMultifactorAuthentication = ({ numberOfDigits = 6 }) => {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''));
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);
  const history = useHistory();

  const signInDetails = useSelector((store) => store?.auth?.signInDetails);

  const userInfo = localStorage.getItem('wauser');

  const setup = userInfo ? JSON.parse(userInfo)?.setup : null;

  const [
    confirmMfa,
    {
      data: confirmMfaData,
      isLoading: confirmMfaIsLoading,
      error: confirmMfaError,
    },
  ] = useConfirmMfaMutation();

  const [
    confirmMfaOnboarding,
    {
      data: confirmMfaOnboardingData,
      isLoading: confirmMfaOnboardingIsLoading,
      error: confirmMfaOnboardingError,
    },
  ] = useConfirmMfaOnboardingMutation();

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === 'Enter' && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resultOptType = localStorage.getItem('otpType');
    const otpType = resultOptType ? resultOptType : '';

    if (confirmMfaIsLoading || confirmMfaOnboardingIsLoading) return;

    if (otp.length < 6) {
      toast('Enter all fields');
      return;
    }

    const otpToString = otp.join('');

    if (otpToString.length < 6) {
      toast('Enter all fields');
      return;
    }

    if (otpType !== '') {
      try {
        await confirmMfaOnboarding({
          otp: otpToString,
          otp_type: otpType,
        });
      } catch (error) {
        console.log('Error', error);
      }
    } else {
      try {
        await confirmMfa({
          otp: otpToString,
          username: signInDetails?.username,
          password: signInDetails?.password,
        });
      } catch (error) {
        console.log('Error', error);
      }
    }
  };

  useEffect(() => {
    if (confirmMfaOnboardingData) {
      // const userToken = confirmMfaOnboardingData?.token;
      // localStorage.setItem('watoken', userToken);
      // localStorage.setItem('accountExpiry', confirmMfaOnboardingData?.expiry);
      // localStorage.setItem('otpType', '');

      addSetUpDetailsToLocalStorage('setup', {
        first_login: false,
        completed_password_reset: true,
        completed_set_up_mfa: true,
        completed_onboarding: false,
      });

      toast('OTP confirmed');
      history.push('/new_onboarding');
    }

    if (confirmMfaOnboardingError) {
      const errMessage =
        confirmMfaOnboardingError?.data?.error || 'An error occurred';
      toast(errMessage);
    }
  }, [confirmMfaOnboardingData, confirmMfaOnboardingError, history]);

  useEffect(() => {
    if (confirmMfaData) {
      const userToken = confirmMfaData.key;
      localStorage.setItem('watoken', userToken);
      localStorage.setItem('accountExpiry', confirmMfaData.expiry);

      const {
        first_login,
        completed_password_reset,
        completed_set_up_mfa,
        completed_onboarding,
      } = confirmMfaData?.user?.setup;

      addUserDetailsToLocalStorageWhenLoggedIn(confirmMfaData);

      addSetUpDetailsToLocalStorage('setup', {
        first_login,
        completed_password_reset,
        completed_set_up_mfa,
        completed_onboarding,
      });

      const isUserCompletedAccountSetup =
        first_login === false &&
        completed_password_reset &&
        completed_set_up_mfa &&
        completed_onboarding;

      if (isUserCompletedAccountSetup) {
        toast('Login Successful');
        history.replace('/dashboard/collections');
      } else {
        toast('OTP confirmed');
        history.replace('/new_onboarding');
      }
    }

    if (confirmMfaError) {
      const errMessage = confirmMfaError?.data?.error || 'An error occurred';
      toast(errMessage);
    }
  }, [confirmMfaError, confirmMfaData, history]);

  return (
    <div className="flex justify-between w-full h-full  bg-white">
      <div className="  hidden  md:flex justify-center items-center w-0 md:w-[35%]  bg-blue-300 rounded-lg shadow border-2 border-sky-950 border-opacity-10">
        <BigLeftIcon />
      </div>

      <div className="w-full md:w-[60%] h-full grid  justify-center md:mr-3">
        <div className="w-[50px] h-[44px] mx-auto mt-5">
          <img
            className="w-full h-full"
            src={googleLogoAuth}
            alt="Google Logo"
          />
        </div>
        <article className="w-full grid justify-center ">
          <div className="flex item-center">
            <h1 className="text-sky-950 text-lg md:text-xl mr-1 font-normal font-['Noto Sans JP']">
              Enter Token
            </h1>
            <div>
              <img
                className="w-[20px] h-[20px]"
                src={tokenIcon}
                alt="Google Logo"
              />
            </div>
          </div>

          <div className="flex items-center gap-2 md:gap-4">
            {otp.map((digit, index) => (
              <div key={index} className="flex flex-col">
                <input
                  value={digit}
                  maxLength={1}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                  ref={(reference) =>
                    (otpBoxReference.current[index] = reference)
                  }
                  placeholder="*"
                  className={`text-black text-center border-none text-[17px] md:text-[20px] font-thin font-['Noto Sans JP'] w-[45px] md:w-16 p-3 rounded-md block focus:border-2 focus:outline-none appearance-none`}
                />
                <div className="h-0.5 bg-black bg-opacity-20" />
              </div>
            ))}
          </div>

          <p
            className={`text-lg text-white mt-4 ${
              otpError ? 'error-show' : ''
            }`}
          >
            {otpError}
          </p>
        </article>
        <button
          onClick={handleSubmit}
          className="sm:min-w-[300px] mt-3 md:max-w-[619px] h-[50px]  bg-sky-950 rounded shadow"
        >
          <div className="text-white text-[16px]  md:text-[20px] font-bold font-['Noto Sans JP']">
            {confirmMfaOnboardingIsLoading || confirmMfaIsLoading
              ? 'Loading...'
              : 'Activate'}
          </div>
        </button>
      </div>
    </div>
  );
};
