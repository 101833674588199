import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import DarkLoader from '../../../components/loader/DarkLoader';

const DependencyLevelProgressChart = ({
  isLoading,
  error,
  data,
  bcg,
  dataLabelsColors,
  name,
}) => {
  const chartContainer = useRef(null);

  const vulnerabilityPercentage = (data, name) => {
    const total = data.high + data.medium + data.low;

    const updatedData = {
      high: (data.high / total) * 100,
      medium: (data.medium / total) * 100,
      low: (data.low / total) * 100,
    };

    if (name === 'High') return 20;
    if (name === 'Medium') return 60;
    if (name === 'Low') return 20;

    // if (name === 'High')
    //   return Math.round(updatedData.high ? updatedData.high : 0);
    // if (name === 'Medium')
    //   return Math.round(updatedData.medium ? updatedData.medium : 0);
    // if (name === 'Low')
    //   return Math.round(updatedData.low ? updatedData.low : 0);
  };

  useEffect(() => {
    if (data) {
      const options = {
        chart: {
          height: 200,
          type: 'radialBar',
        },
        series: [vulnerabilityPercentage(data, name)],
        colors: [dataLabelsColors],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '75%',
              background: bcg,
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: dataLabelsColors,
                fontSize: '13px',
              },
              value: {
                color: dataLabelsColors,
                fontSize: '20px',
                show: true,
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: [name],
      };

      const chart = new ApexCharts(chartContainer.current, options);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [bcg, chartContainer, dataLabelsColors, name, data]);

  // if (isLoading)
  //   return (
  //
  //       <DarkLoader />
  //
  //   );

  if (error) {
    return (
      <div
        ref={chartContainer}
        className="w-full  line-chart flex justify-center items-center text-base md:text-xl font-serif   text-[#000]"
      >
        An error occurred
      </div>
    );
  }

  // if (!data) {
  //   return (
  //     <div
  //       ref={chartContainer}
  //       className="w-full line-chart h-full flex justify-center items-center text-base md:text-xl  font-serif   text-[#000]"
  //     >
  //       No Record Found
  //     </div>
  //   );
  // }

  return <div id="chart" ref={chartContainer} />;
};

export default DependencyLevelProgressChart;
