import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";
import CollectionsModal from "../modals/upload_collection";
import "./index.css";
import {
  useGetEndpointDetailsQuery,
  useGetPaginatedEndpointDetailsQuery,
  useGetThreatMonitoringEndpointDetailsQuery,
  useDeleteMultipleEndpointsMutation,
} from "../../../../redux/services/collectionApi";
import errorImg from "../../../../assets/images/searching-error.png";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  retrieveCollectionDetails,
  setEndpointDetails,
} from "../../../../redux/features/collectionSlice";
import { toast } from "react-toastify";
import DeleteItemModal from "../../modals/deleteItem";

export const EndpointTable = (props) => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const [rowData, setRowData] = useState([]);
  const [nextLink, setNextLink] = useState(false);
  const [prevLink, setPrevLink] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [ready] = useState(false);
  const [LogResultss, setLogResults] = useState([]);
  const [counts, setCounts] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [allSelected, setAllSelected] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const mainCheckboxes = document.querySelectorAll("#checkbox");
  const selectCheckbox = document.querySelectorAll("#select-checkbox");
  const [deleteMultipleEndpoints] = useDeleteMultipleEndpointsMutation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const collectionId = localStorage.getItem("collection_id");
  const { data: paginatedData } = useGetPaginatedEndpointDetailsQuery({
    collectionId: collectionId,
    page: pageNumber,
  });
  const results = paginatedData?.results;
  const getNextPage = () => {
    setPageNumber(pageNumber + 1);
    setNextLink(true);
  };

  const getPrevPage = () => {
    setPageNumber(pageNumber - 1);
    setPrevLink(true);
  };

  const { isAuthenticated } = useAuth();
  const slack = process.env.REACT_SLACK_WEBHOOK_URL;

  const pathnameList = pathname?.split("/");
  const pathnameLastVal = pathnameList?.[pathnameList?.length - 1];

  if (!isAuthenticated()) {
    history.push("/login");
  }

  const {
    data: endpointDetailsData,
    isLoading: endpointDetailsDataIsLoading,
    isError: endpointDetailsDataIsError,
    error: endpointDetailsDataError,
  } = useGetEndpointDetailsQuery(collectionId);

  const {
    data: logData,
    isLoading: logDataIsLoading,
    isError: logDataIsError,
    error: logDataError,
  } = useGetThreatMonitoringEndpointDetailsQuery({
    collectionId,
    pathnameLastVal,
  });

  useEffect(() => {
    //retrieveCollectionDetails called when the component mounts
    dispatch(retrieveCollectionDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!endpointDetailsDataIsLoading && rowData === undefined) {
      const redirectTimeout = setTimeout(() => {
        history.push("/dashboard/collections");
      }, 2000);
      return () => clearInterval(redirectTimeout);
    }
  }, [rowData, history, endpointDetailsData, endpointDetailsDataIsLoading]);

  useEffect(() => {
    // if (endpointDetailsDataIsError || endpointDetailsDataError) {
    //   notifyToSlackChannel(slack, endpointDetailsDataError);
    // }

    if (!endpointDetailsDataIsLoading || !endpointDetailsDataIsError) {
      setRowData(endpointDetailsData?.results);
    }

    if (nextLink || prevLink) {
      setRowData(results);
    }
  }, [
    slack,
    logData,
    logDataIsError,
    logDataError,
    logDataIsLoading,
    endpointDetailsData,
    endpointDetailsData?.next,
    endpointDetailsData?.previous,
    endpointDetailsData?.results,
    endpointDetailsDataIsError,
    endpointDetailsDataIsLoading,
    rowData,
    endpointDetailsDataError,
    nextLink,
    prevLink,
    results,
  ]);

  useEffect(() => {
    const checkButton = () => {
      mainCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked === true) {
          setDisabled(false);
        } else {
          selectCheckbox.forEach(function (select) {
            select.checked = false;
          });
        }
      });
    };
    checkButton();

    const checked = [];
    for (let i = 0; i < mainCheckboxes.length; i++) {
      if (mainCheckboxes[i].checked === true) {
        checked.push(mainCheckboxes[i]);
      }

      if (checked.length === mainCheckboxes.length) {
        setAllSelected(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCheckboxes]);

  const selectAll = (mycheckbox) => {
    if (mycheckbox === true) {
      mainCheckboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      const allIds = rowData.map((item) => item.id);
      setCheckedValues(allIds);
    } else {
      mainCheckboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      setCheckedValues([]);
    }
  };

  const select = () => {
    let value;
    mainCheckboxes.forEach(function (checkbox) {
      if (checkbox.checked === true) {
        value = checkbox.value;
      }
    });
    checkedValues.push(value);
  };

  const unselect = (id) => {
    const index = checkedValues.findIndex((item) => item === id);
    if (index !== -1) {
      const newCheckedValues = [...checkedValues];
      newCheckedValues.splice(index, 1);
      setCheckedValues(newCheckedValues);
    }
  };

  const onDelete = async () => {
    try {
      await deleteMultipleEndpoints({
        collectionId: `${collectionId}`,
        body: {
          endpoint_ids: checkedValues,
        },
      });
      toast.success("Successful");
      setCheckedValues([]);
      setDisabled(true);
    } catch (error) {
      console.error("Delete failed:", error);
      toast.error("Failed to delete");
    }
  };

  const retrieveEndpoint = useCallback(
    (endpoint_id) => {
      let result = LogResultss?.results?.filter((i) => i.path === endpoint_id);
      return result === undefined ? 0 : result?.length;
    },
    [LogResultss]
  );

  useEffect(() => {
    // const getLog = async () => {
    //   setReady(true);
    //   await setLogResults(LogResults);
    //   const newCounts = {};
    //   for (let index = 0; index < rowData?.length; index++) {
    //     const path = rowData[index].path;
    //     const result = retrieveEndpoint(path);
    //     newCounts[path] = result;
    //   }
    //   setCounts(newCounts);
    // };
    // getLog();
    const getLog = async () => {
      // setReady(true);
      if (logData) {
        setLogResults(logData);
        const newCounts = {};
        for (let index = 0; index < rowData?.length; index++) {
          const path = rowData[index].path;
          const result = retrieveEndpoint(path);
          newCounts[path] = result;
        }
        setCounts(newCounts);
      }
    };

    if (logData) {
      setLogResults(logData);
    }

    getLog();
  }, [
    rowData,
    logData,
    logDataError,
    logDataIsError,
    retrieveEndpoint,
    setLogResults,
    ready,
  ]);

  const loginRenderer = (value) => {
    return (
      <span>
        <Badge
          pill
          className="text-white p-2"
          bg={
            value > 0
              ? "danger"
              : "info"
          }
        >
          <i
            className={
              value > 0 
                ? "mdi mdi-alert-circle-outline"
                : "mdi mdi-check-circle-outline"
            }
          ></i>
          {value > 0 ? "Alert" : "New"}
        </Badge>
      </span>
    );
  };

  return (
    <div style={containerStyle}>
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-secondary">API Inventory</h4>

        <DeleteItemModal
          onDelete={onDelete}
          disabled={disabled}
          checkedValues={checkedValues}
        />
      </div>
      <div
        style={{
          maxHeight: "800px",
          width: "100%",
          boxSizing: "border-box",
          overflowY: "scroll",
        }}
        className={`${collectionId ? "card" : ""}`}
      >
        {!collectionId && (
          <div className="flex flex-col items-center mb-1">
            <p className="text-xl flex py-8 font-serif  justify-center text-[#000]">
              Please create a new project or select an existing one, if
              available
            </p>
            <p className="text-xl flex py-8 font-serif  justify-center text-[#000]">
              Redirecting...
            </p>
          </div>
        )}
        {collectionId &&
          endpointDetailsDataIsError &&
          endpointDetailsDataError && (
            <div
              style={{ width: "50%", margin: "auto", textAlign: "center" }}
              className="mt-5"
            >
              <img src={errorImg} alt="" />
              <p>{endpointDetailsDataError?.message}</p>
            </div>
          )}
        {collectionId && endpointDetailsDataIsLoading ? (
          <div className="w-50 m-auto text-center h-[300px] pt-[50px]">
            <Spinner
              animation="border"
              variant="secondary"
              className="text-[50px]"
            />
          </div>
        ) : (
          rowData?.length > 0 && (
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    <input
                      type="checkbox"
                      id="select-checkbox"
                      value="all"
                      checked={allSelected}
                      onChange={(e) => {
                        setAllSelected(!allSelected);
                        selectAll(e.target.checked);
                        setDisabled(!disabled);
                      }}
                      className="me-1 checkbox"
                    />
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Investigate
                  </th>
                  <th scope="col" className="px-6 py-3 max-width-column">
                    Path
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Activity Count
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Vulnerabilities
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Last Accessed
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Compromised
                  </th>
                </tr>
              </thead>
              <tbody>
                {rowData &&
                  rowData?.length > 0 &&
                  rowData?.map((item) => (
                    <tr className="border-b cursor-pointer" key={item.id}>
                      <th className="px-6 py-4 text-primary">
                        <input
                          type="checkbox"
                          className="me-2 checkbox"
                          id="checkbox"
                          value={item?.id}
                          onChange={(e) => {
                            setDisabled(!disabled);
                            if (allSelected) {
                              setAllSelected(false);
                            }
                            if (e.target.checked === true) {
                              select();
                            } else {
                              unselect(item.id);
                            }
                          }}
                        />
                      </th>
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-primary "
                      >
                        <button
                          className="btn btn-outline-secondary text-secondary"
                          onClick={() => {
                            localStorage.setItem("endpoint_name", item.path);
                            localStorage.setItem(
                              "selected_endpoint",
                              JSON.stringify(item)
                            );
                            history.push(`/dashboard/api-security/${item.id}`);
                          }}
                        >
                          <i className="mdi mdi-magnify font-[12]"></i>
                        </button>
                      </td>
                      <td
                        style={{ textWrap: "wrap" }}
                        scope="row"
                        className="px-6 py-4 font-medium text-primary w-[50px] max-width-column "
                      >
                        {item?.path}
                      </td>
                      <td className="px-6 py-4 text-primary">
                        {item?.activity_count}
                      </td>
                      <td className="px-6 py-4 text-primary">
                        {item?.vulnerability_count} Found
                      </td>
                      <td className="px-6 py-4 text-primary">
                        {item?.last_accessed?.substring(0, 10)}
                      </td>
                      <td className="px-6 py-4 text-secondary">
                        {loginRenderer(item?.compromise_status)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )
        )}

        {rowData?.length === 0 && !endpointDetailsDataIsLoading && (
          <div className="my-[10%] w-[50%] text-center mx-auto">
            <h4 className="text-primary">No Endpoints Found In This Project</h4>
            <CollectionsModal />
          </div>
        )}
      </div>

      {collectionId &&
        rowData === undefined &&
        !endpointDetailsDataIsLoading && (
          <div
            style={{ width: "50%", margin: "auto", textAlign: "center" }}
            className="mt-5"
          >
            <img src={errorImg} alt="" />
            <p>An error occured</p>
            <p className="text-xl  text-gray-700">Redirecting...</p>
          </div>
        )}

      {/* {endpointDetailsDataIsLoading ? null : rowData?.length > 0 ? (
        <div className="flex justify-between mt-3 mb-8">
          <button
            className="btn text-white bg-primary py-2 px-4"
            disabled={prevLink === null || pageNumber === 1}
            onClick={() => getOtherPage('prev')}
          >
            Prev
          </button>
          <button
            className="btn text-white bg-primary py-2 px-4"
            disabled={nextLink === null}
            onClick={() => getOtherPage('next')}
          >
            Next
          </button>
        </div>
      ) : null} */}

      {rowData?.length > 0 && (
        <div className="flex justify-between mt-3 mb-8">
          <button
            className="btn text-white bg-primary py-2 px-4"
            disabled={paginatedData?.previous === null}
            onClick={getPrevPage}
          >
            Prev
          </button>
          <button
            className="btn text-white bg-primary py-2 px-4"
            disabled={paginatedData?.next === null}
            onClick={getNextPage}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};
