import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { AddUserAccess, RequestPentest } from "../../../API";
import { toast } from "react-toastify";

function AddUserModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [user, setUser] = useState("");
  const [level, setLevel] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { success } = await AddUserAccess(user, level);
    if (success) {
      toast("Collection Shared With User");
      handleClose();
    } else {
      toast("Action Failed");
    }
  };
  return (
    <div>
      <button className="btn btn-outline-primary" onClick={handleShow}>
        Share Collection
      </button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="red-test">
            Share Collection With User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div>
              <Form.Group>
                <label htmlFor={`input-user`}>User Email Address</label>
                <Form.Control
                  type="text"
                  id={`input-user`}
                  size="lg"
                  value={user}
                  onChange={(event) => setUser(event.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>Position</label>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setLevel(e.target.value)}
                  >
                    <option>Please Select</option>
                    <option value="edit">View and Manage Project</option>
                    {/* <option value="edit">Can Edit</option>
                    <option value="admin">Admin</option> */}
                  </select>
                </div>
              </Form.Group>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Share
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddUserModal;
