import React, { useState } from "react";
import { Button, Modal, Badge } from "react-bootstrap";

function ViewScanModel({ data }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <i className="mdi mdi-eye" onClick={handleShow}></i>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="red-test">Vulnerability Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-warning">
            <i className="mdi mdi-alert"></i> {data?.threat_name}
          </h4>
          {/* <p className="text-primary mb-4">{data?.summary}</p> */}
          <div className="row justify-between mb-4">
            <Badge
              bg={
                data?.severity === "high"
                  ? "danger"
                  : data?.severity === "medium"
                  ? "warning"
                  : "info"
              }
              className="text-white"
            >
              {data?.severity}
            </Badge>
            <Badge bg="primary" className="text-white">
              {data?.category}
            </Badge>
            <Badge bg="primary" className="text-white">
              {data?.attack_group}
            </Badge>
          </div>

          <h4 className="text-secondary">Description</h4>
          <p className="text-primary mb-4">{data?.description}</p>

          <h4 className="text-secondary">Risk</h4>
          <p className="text-primary mb-4">{data?.risk}</p>

          <h4 className="text-secondary">Remediation</h4>
          <p className="text-primary mb-4">{data?.remediation}</p>

          <h4 className="text-secondary">References</h4>
          <p className="text-primary mb-4">{data?.references}</p>
          <h4 className="text-secondary">Execution Time</h4>
          <p className="text-primary mb-4">{data?.execution_time}</p>

          {data.category === "Detected Attack" ? (
            <div className="row justify-between mt-4">
              <p className="text-secondary">Response Code</p>
              <Badge
                bg={
                  data?.response_code === "200" || data?.response_code === "201"
                    ? "success"
                    : "primary"
                }
                className={data?.response_code === "200" || data?.response_code === "201" ? "p-2 text-white" : "p-2"}
              >
                {data?.response_code}
              </Badge>
            </div>
          ) : null}
          {data.category === "Dynamic Scan" ? (
            <div>
              <h4 className="text-secondary">Execution Details</h4>
              <div className="row justify-between mt-4">
                <p className="text-secondary"> Endpoint</p>
                <p>{data?.endpoint}</p>
              </div>

              <div className="row justify-between mt-4">
                <p className="text-secondary"> Request Header</p>
                <p>{data?.request_header}</p>
              </div>
              <div className="row justify-between mt-4">
                <p className="text-secondary">Request Body</p>
                <p>{data?.request_body}</p>
              </div>

              <div className="row justify-between mt-4">
                <p className="text-secondary">Request Type</p>
                <Badge bg="primary" className="p-2">
                  {data?.threat_name}
                </Badge>
              </div>
              <div className="row justify-between mt-4">
                <p className="text-secondary">Response Code</p>
                <Badge bg="primary" className="p-2">
                  {data?.response_code}
                </Badge>
              </div>

              <div className="row justify-between mt-4">
                <p className="text-secondary"> Response Header</p>
                <p>
                  {typeof data?.response_header === "object"
                    ? ""
                    : data?.response_header}
                </p>
              </div>
              <div className="row justify-between mt-4">
                <p className="text-secondary">Response Body</p>
                <p>{data?.response_body}</p>
              </div>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewScanModel;
