import React from 'react';
import FormInput from './FormInput';
import UpdateButton from './UpdateButton';
import Schedule from './Schedule';
import ExpiresAt from './ExpiresAt';

const TokenForm = ({
  handleSubmit,
  handleInputChange,
  formData,
  handleCheckboxChange,
  isLoading,
}) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormInput
          label={'Enter Bearer Token'}
          value={formData.auth_bearer_token}
          required={true}
          handleInputChange={(event) =>
            handleInputChange('auth_bearer_token', event.target.value)
          }
          placeholder={'some-bearer-token'}
        />
        <Schedule handleInputChange={handleInputChange} formData={formData} />
        <ExpiresAt
          handleCheckboxChange={handleCheckboxChange}
          formData={formData}
        />

        <div className="w-full my-7 flex justify-end">
          <UpdateButton isLoading={isLoading} />
        </div>
      </form>
    </div>
  );
};

export default TokenForm;
