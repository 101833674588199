import React from "react";
import PropTypes from "prop-types";
import warning from '../../assets/images/warning.png'

const ErrorDisplay = ({ errorMessage, errorCode }) => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {/* Render the image */}
      <img
        src={warning}
        alt="Error illustration"
        style={{ width: "150px", height: "150px" }} // Adjust size as needed
      />

      <h2 style={{ color: "red", marginTop: "10px" }}>{errorMessage}</h2>

      <p style={{ color: "gray" }}>Error code: {errorCode}</p>
    </div>
  );
};

// PropTypes to ensure the right types are passed into the component
ErrorDisplay.propTypes = {
  imageUrl: PropTypes.string.isRequired, // Image URL to display
  errorMessage: PropTypes.string.isRequired, // Error message to show
  errorCode: PropTypes.string, // Error code (optional)
};

// Default props to handle cases where errorCode might not be provided
ErrorDisplay.defaultProps = {
  errorCode: "Unknown error", // Default error code if none is provided
};

export default ErrorDisplay;
