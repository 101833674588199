const industries =
  [
    "Accounting",
    "Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel and Fashion",
    "Architecture and Planning",
    "Arts and Crafts",
    "Automotive",
    "Aviation and Aerospace",
    "Banking",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies and Equipment",
    "Capital Markets",
    "Chemicals",
    "Civic and Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer and Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Dairy",
    "Defense and Space",
    "Design",
    "Education Management",
    "E-learning",
    "Electrical and Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fishery",
    "Food and Beverages",
    "Food Production",
    "Fundraising",
    "Furniture",
    "Gambling and Casinos",
    "Glass, Ceramics and Concrete",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness and Fitness",
    "Higher Education",
    "Hospital and Health Care",
    "Hospitality",
    "Human Resources",
    "Import and Export",
    "Individual and Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology and Services",
    "Insurance",
    "International Affairs",
    "International Trade and Development",
    "Internet",
    "Investment Banking/Venture",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure and Travel",
    "Libraries",
    "Logistics and Supply Chain",
    "Luxury Goods and Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Marketing and Advertising",
    "Market Research",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Device",
    "Medical Practice",
    "Mental Health Care",
    "Military",
    "Mining and Metals",
    "Motion Pictures and Film",
    "Museums and Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers",
    "Nonprofit Organization Management",
    "Oil and Energy",
    "Online Publishing",
    "Outsourcing/Offshoring",
    "Package/Freight Delivery",
    "Packaging and Containers",
    "Paper and Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary",
    "Printing",
    "Professional Training",
    "Program Development",
    "Public Policy",
    "Public Relations",
    "Public Safety",
    "Publishing",
    "Railroad Manufacture",
    "Ranching",
    "Real Estate",
    "Recreational",
    "Facilities and Services",
    "Religious Institutions",
    "Renewables and Environment",
    "Research",
    "Restaurants",
    "Retail",
    "Security and Investigations",
    "Semiconductors",
    "Shipbuilding",
    "Sporting Goods",
    "Sports",
    "Staffing and Recruiting",
    "Supermarkets",
    "Telecommunications",
    "Textiles",
    "Think Tanks",
    "Tobacco",
    "Translation and Localization",
    "Transportation/Trucking/Railroad",
    "Utilities",
    "Venture Capital",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine and Spirits",
    "Wireless",
    "Writing and Editing"
  ]
  export default industries