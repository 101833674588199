import React from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setUpProxy } from "../../API";

export const FrontendSetup = (props) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }

  const setup = async () => {
    const { data } = await setUpProxy();
    toast(data?.message);
  };

  return (
    <div>
      <ToastContainer />
      <div className="grid-margin stretch-card m-auto">
        <div className="card">
          <div className="card-body w-[100%]">
            <div className=" text-center m-auto">
              <h4 className="text-primary  my-[10%]">
                Threat Monitoring Is Not Configured For This Project
              </h4>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setup();
                  history.push("/configuration?default=threat_monitoring");
                }}
              >
                SetUp Threat Monitoring Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
