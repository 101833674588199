import React, { useState, useEffect } from "react";
import { useAuth } from "../../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CreateProject, UploadCollections } from "../../../API";
import "./index.css";
import { Form, Spinner } from "react-bootstrap";
import { useSetupEnvironmentMutation, useRunDynamicScanMutation } from "../../../../redux/services/collectionApi";

export const Setup_Environment = ({ setDisabled }) => {
  const [loading, setLoading] = useState(false);
  const [prodServer, setProdServer] = useState("");
  const [testServer, setTestServer] = useState("");
  const [otherServer, setOtherServer] = useState("");
  const [dynamicScan, setDynamicScan] = useState(false);

  const collectionId = localStorage.getItem("collection_id");
  const [setupEnvironment, isSuccess] = useSetupEnvironmentMutation();
  const [runDynamicScan] = useRunDynamicScanMutation()
  const [errMsg, setErrMsg] = useState('')
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  console.log(dynamicScan)
  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }

  function validateInput(input) {
    const domainRegex = /^(?:(http[s]?:\/\/))?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}(\/.*)?$/;
    const ipRegex = /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/;
  
    const match = domainRegex.exec(input);
    if (match) {
      const hasProtocol = !!match[1];
      if (hasProtocol) {
        // Input includes http:// or https://
        return true;
      } else {
        // Input does not include http:// or https://
        return false;
      }
    } else if (ipRegex.test(input)) {
      // Input is an IP address
      return true;
    } else {
      // Invalid input
      return 'Invalid';
    }
  }

  // create collection
  const handleUpload = (e) => {
    e.preventDefault();
    setLoading(true);
    setupEnvironment({
      collection_id: collectionId,
      prod: prodServer,
      test: testServer,
      other: otherServer,
    });
    if (dynamicScan === false){
      runDynamicScan({
        collection_id: collectionId,
          environment: 'prod',
          type: 'deep',
      })
    }
    if (isSuccess) {
      setLoading(false);
      toast("Successful");
      setDisabled(false);
    }
  };

  return (
    <div className="bg-white rounded-[10px] my-8">
      <ToastContainer />
      <div className="card-body">
        <h2 className="card-title text-secondary">SET UP ENVIRONMENT</h2>

        <form className="forms-sample" onSubmit={(e) => handleUpload(e)}>
          <div className="mb-4">
            <Form.Group className="col-12">
              <label htmlFor="project_name" className="text-primary">
                Production Server Host URL<span className="text-danger">*</span>
              </label>
              <Form.Control
                type="text"
                className="form-control"
                id="prod_server"
                onChange={(e) => {
                  setProdServer(e.target.value);
                  if (!validateInput(e.target.value)) {
                    setErrMsg(
                      "Input must be a valid domain with http/https protocol or IP"
                    );
                    return;
                  }
                }}
                required
              />
              {errMsg ? (<label htmlFor="project_name" className="text-primary">
                {errMsg}
              </label>) : null}
              
            </Form.Group>
            <Form.Group className="col-12 mt-4">
              <label htmlFor="description" className="text-primary">
                Test Server Host URL<span className="text-danger">*</span>
              </label>
              <Form.Control
                type="text"
                className="form-control"
                id="test_server"
                onChange={(e) => {
                  setTestServer(e.target.value);
                  if (!validateInput(e.target.value)) {
                    setErrMsg(
                      "Input must be a valid domain with http/https protocol or IP"
                    );
                    return;
                  }
                }}
              />
              {errMsg ? (<label htmlFor="project_name" className="text-primary">
                {errMsg}
              </label>) : null}
            </Form.Group>
            <Form.Group className="col-12 mt-4">
              <label htmlFor="description" className="text-primary">
                Dev or Other Server Host URL
                <span className="text-danger">*</span>
              </label>
              <Form.Control
                type="text"
                className="form-control"
                id="other_server"
                onChange={(e) => {
                  setOtherServer(e.target.value);
                  if (!validateInput(e.target.value)) {
                    setErrMsg(
                      "Input must be a valid domain with http/https protocol or IP"
                    );
                    return;
                  }
                }}
              />
              {errMsg ? (<label htmlFor="project_name" className="text-primary">
                {errMsg}
              </label>) : null}
            </Form.Group>
            {/* <Form.Group className="col-12 mt-4 d-flex items-center space-x-2">
              <label htmlFor="description" className="text-primary">
                Disable Dynamic Scan
              </label>
              <label className="switch ">
                <input type="checkbox"  onChange={(e)=> {setDynamicScan(e.target.checked)}}/>
                <span className="slider round"></span>
              </label>
            </Form.Group> */}
          </div>
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <button
                
                className="btn btn-primary mr-2 float-right w-[75px]"
              >
                {loading ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  "Set up"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
