import { fetchBaseQuery } from '@reduxjs/toolkit/query';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const user = () => localStorage.getItem('watoken');

const isTokenExpired = (tokenExpiration) =>
  Date.now() > Date.parse(tokenExpiration);

export const baseQueryFunc = async (apiUrlInit, args, api, extraOptions) => {
  const tokenExpiration = localStorage.getItem('accountExpiry');

  if (!tokenExpiration || isTokenExpired(tokenExpiration)) {
    localStorage.setItem('isSessionExpired', 'true');
    window.location.href = '/login';
  }

  let result = await fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/${apiUrlInit}/`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Token ${user()}`);
      return headers;
    },
  })(args, api, extraOptions);

  if (result?.meta?.response?.status === 401) {
    localStorage.setItem('isSessionExpired', 'true');
    window.location.href = '/login';
  }

  return result;
};
