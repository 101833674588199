import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const StatCard = ({
  bgColor,
  iconColor,
  title,
  value,
  percentage,
  isLoading,
  isFetching,
  error,
  icon,
  report
}) => (
  <div
    style={{ backgroundColor: bgColor }}
    className="py-2 pl-3  rounded-[10px] w-full  h-full"
  >
    <div className="w-max">
      <div
        style={{ backgroundColor: iconColor }}
        className=" mb-2 w-[28px] h-[28px] md:w-[35px] md:h-[35px]  rounded-full flex justify-center"
      >
        {icon && <img src={report} alt="report" className='p-1' />}
      </div>
      <div
        className={`text-black flex justify-center mb-2 tracking-[0.5px]   ${
          error
            ? 'text-[12px] leading-5'
            : 'text-[16px] md:text-[20px]  leading-7 '
        }  font-[800] font-[Nunito]`}
      >
        {isLoading ? <Skeleton width={50} /> : null}
        {error && !isFetching ? 'An error occurred' : null}
        {value || value === 0 ? value : null}
      </div>
    </div>
    <div className="text-black tracking-[0.5px] mb-2  leading-7 text-[14px] md:text-[16px] font-semibold font-[Nunito]">
      {title}
    </div>
    <div className="text-[#078AFD] tracking-[0.5px] leading-7 text-[12px] font-medium font-[Nunito] mr-3">
      {isLoading ? <Skeleton width={130} /> : null}
      {error ? 'An error occured' : null}
      {percentage ? percentage : 'Scanned'}
    </div>
  </div>
);
