import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import contriesArray from '../../../../data/countriesFlag.json';
import DarkLoader from '../../../components/loader/DarkLoader';

const SuspiciousIps = ({
  suspiciousIpsData: data,
  suspiciousIpsIsLoading: isLoading,
  suspiciousIpsError: error,
}) => {
  const history = useHistory();
  const [countryObject, setCountryObject] = useState(null);
  const initCountryObject = {};

  contriesArray.forEach(
    (country) => (initCountryObject[country.country] = country)
  );
  const formatCountryName = (name) => {
    let countryName;

    if (!name || name === 'null') return '';

    if (name.split(' ')?.length > 1) {
      const splitName = name.split(' ');
      const firstTwoWords = splitName.slice(0, 2);
      countryName = firstTwoWords.join(' ');
    } else countryName = name;

    return countryName;
  };
  const handleNavigateToLogDataPage = (ipAddress) => {
    if (!ipAddress || ipAddress === 'null') return;

    localStorage.setItem('filterThreatDataIpAddress', ipAddress);
    localStorage.setItem('filterThreatDataBy', 'ipAddress');
    history.push('/dashboard/threats');
  };
  useEffect(() => {
    contriesArray.forEach(
      (country) => (initCountryObject[country?.country] = country)
    );

    setCountryObject(initCountryObject);
  }, []);

  return (
    <div
      className={`lg:w-[33%] border mb-4 ${
        isLoading || error ? 'h-[350px]' : ''
      } ${data && data.length < 1 ? 'hidden' : 'block'}`}
    >
      <h4 className="text-lg text-black text-center mb-0 bg-[#d9d9d980] py-2">
        Suspicious IPs
      </h4>
      <table className=" w-full  table table-hover">
        <tbody className=" w-full  relative bg-white">
          {isLoading ? (
            <div className="absolute   text-center mb-0  inset-0  h-[230px]  w-full  flex items-center justify-center">
              <DarkLoader />
            </div>
          ) : null}

          {error ? (
            <div className="absolute text-base  text-black text-center mb-0   inset-0  h-[230px]  w-full  flex items-center justify-center">
              An error occured
            </div>
          ) : null}

          {countryObject && data
            ? data?.map((key, value) => (
                <tr
                  key={key}
                  className="bg-white border-b h-[53px] cursor-pointer"
                >
                  <td
                    onClick={() => handleNavigateToLogDataPage(key?.ip_address)}
                    className={`w-min h-full ${
                      key === 'null' ? 'cursor-none' : 'cursor-pointer'
                    }`}
                  >
                    <img
                      src={countryObject[key?.country_name]?.flag}
                      alt=""
                      style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '0px',
                      }}
                      className={`w-min  h-full object-cover ${
                        key === 'null' ? 'cursor-none' : 'cursor-pointer'
                      }`}
                    />
                  </td>

                  <td
                    className={`h-3 w-min   ${
                      key === 'null' ? 'cursor-none' : 'cursor-pointer'
                    }`}
                  >
                    <div
                      onClick={() =>
                        handleNavigateToLogDataPage(key?.ip_address)
                      }
                      pill
                      className={`h-full w-min items-center flex justify-start  px-2 py-2 ${
                        key === 'null' ? 'cursor-none' : 'cursor-pointer'
                      }`}
                    >
                      {key?.ip_address}
                    </div>
                  </td>

                  <td className="w-min truncate">
                    {key?.count?.toFixed(3)} Requests
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default SuspiciousIps;
