import React, { useState, useEffect, useContext, useCallback } from "react";
import { useFetch } from '../context';

const LogContext = React.createContext();

export const LogProvider = ({ children }) => {
    const { getThreateMonitoringEndpointDetails}  = useFetch()
  const [LogResults, setLogResults] = useState([])

  // useEffect(()=>{
    const getThreats = async()=>{
      try{
        const data = await getThreateMonitoringEndpointDetails()
        setLogResults(data)
        // parseStatic(data?.static_scan)
      }catch(err){
        console.log("err")
      }
    }

    // getData()
  // },[getThreateMonitoringEndpointDetails, setLogResults])

  

  const contextValue = {
    LogResults,
    getThreats
  };

  return (
    <LogContext.Provider value={contextValue}>
      {children}
    </LogContext.Provider>
  );
};

export const useLogContext = () => {
  const context = useContext(LogContext);
  if (context === undefined) {
    throw new Error(
      "useLogContext must be used within a LogProvider"
    );
  }
  return context;
};
