import React, { useState, useEffect } from "react";
import { useAuth } from "../../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { Form, Spinner } from "react-bootstrap";
import { useConfigureWafMutation } from "../../../../redux/services/collectionApi";

export const Threats = ({ setDisabled }) => {
  const collection_id = localStorage.getItem("collection_id");

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("learning");
  const [configureWaf] = useConfigureWafMutation();

  const [selectedOption, setSelectedOption] = useState("application");

  const changeMode = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await configureWaf({
        body: {
          collection: collection_id,
          waf_mode: mode,
        },
      }).unwrap();
      setLoading(false);
      toast.success("Successful");
      setDisabled(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed:", error);
      toast.error("Failed to change mode");
    }
  };

  useEffect(() => {
    setMode(mode);
  }, [mode]);

  const history = useHistory();
  const { isAuthenticated } = useAuth();

  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }

  return (
    <div className="my-8">
      <ToastContainer />
      <div className="card-body  bg-white rounded-[10px] ">
      <form className="forms-sample" onSubmit={(e) => changeMode(e)}>
        <h2 className="card-title text-secondary">Setup Proxy Server</h2>

        <div className="md:flex md:space-x-4">
          <p
            className={
              mode === "learning"
                ? "lg:w-[300px] md:w-[200px] w-full h-[50px] leading-[50px] rounded-[10px] border border-black bg-[#07A55096] text-black text-left font-bold px-3 mb-3"
                : "lg:w-[300px] md:w-[200px] w-full h-[50px] leading-[50px] rounded-[10px] border border-black hover:bg-[#07A55096] text-black text-left font-bold px-3 mb-3"
            }
            onClick={() => setMode("learning")}
          >
            Learning Mode
          </p>
          <p
            className={
              mode === "active"
                ? "lg:w-[300px] md:w-[200px] w-full h-[50px] leading-[50px] rounded-[10px] border border-black bg-[#07A55096] text-black text-left font-bold px-3 mb-3"
                : "lg:w-[300px] md:w-[200px] w-full h-[50px] leading-[50px] rounded-[10px] border border-black hover:bg-[#07A55096] text-black text-left font-bold px-3 mb-3"
            }
            onClick={() => setMode("active")}
          >
            Active Mode
          </p>
        </div>

        <div className="">
          {mode === "learning" ? (
            <div className="mb-4">
              <h4 className="text-secondary">Select Installation Process</h4>
              <Form.Select
                aria-label="Select Installation Process"
                className="col-6 block p-2 rounded-[8px] form-control text-primary mb-4"
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="application">
                  Install on your application server (recommended)
                </option>
                <option value="another">Install on another server</option>
              </Form.Select>

              {mode === "learning" && selectedOption === "application" && (
                <div className="text-black">
                  <p>Step 1: Deploy the WAF Application</p>
                  <ul className="ml-4">
                    <li>
                      Copy the provided code snippet and paste it onto the
                      server where your APIs are hosted.
                    </li>
                    <li>
                      Ensure you have the necessary permissions to execute the
                      code on your server.
                    </li>
                    <li>
                      The application will be configured to listen on port 8001
                      by default.
                    </li>
                  </ul>
                  <p>Step 2: Configure Your Reverse Proxy</p>
                  <ul className="ml-4">
                    <li>
                      Configure your reverse proxy (NGINX, HAProxy, Apache,
                      etc.) to send mirrored requests to port 8001
                    </li>
                    <li>
                      Restart your reverse proxy to apply the new configuration
                    </li>
                  </ul>
                  <p>Step 3: Verify the Configuration</p>
                  <ul className="ml-4">
                    <li>
                      Send a request to your application as you normally would.
                    </li>
                    <li>Check the threat monitoring page here.</li>
                    <li>
                      Verify that the mirrored request appears on the monitoring
                      page, indicating that the configuration is working
                      correctly.
                    </li>
                  </ul>
                </div>
              )}

              {mode === "learning" && selectedOption === "another" && (
                <div className="text-black">
                  <p>Step 1: Deploy the WAF Application</p>
                  <ul className="ml-4">
                    <li>
                      Copy the provided code snippet and paste it onto a linux
                      server.
                    </li>
                    <li>
                      Ensure you have the necessary permissions to execute the
                      code on your server.
                    </li>
                    <li>
                      The application will be configured to listen on port 8001
                      by default.
                    </li>
                  </ul>
                  <p>Step 2: Configure Your Reverse Proxy</p>
                  <ul className="ml-4">
                    <li>
                      Configure your reverse proxy or load balancers to send
                      mirrored requests to the new ip address
                    </li>
                  </ul>
                  <p>Step 3: Verify the Configuration</p>
                  <ul className="ml-4">
                    <li>
                      Send a request to your application as you normally would.
                    </li>
                    <li>Check the threat monitoring page here.</li>
                    <li>
                      Verify that the mirrored request appears on the monitoring
                      page, indicating that the configuration is working
                      correctly.
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="mb-4">
              <h4 className="text-secondary">Select Installation Process</h4>
              <Form.Select
                aria-label="Select Installation Process"
                className="col-6 block p-2 rounded-[8px] form-control text-primary mb-4"
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="application">
                  Install on your application server (recommended)
                </option>
                <option value="another">Install on another linux server</option>
              </Form.Select>

              {mode === "active" && selectedOption === "application" && (
                <div className="text-black">
                  <p>Step 1: Deploy the WAF Application</p>
                  <ul className="ml-4">
                    <li>
                      Copy the provided code snippet and paste it onto the
                      server where your APIs are hosted.
                    </li>
                    <li>
                      Ensure you have the necessary permissions to execute the
                      code on your server.
                    </li>
                    <li>
                      The application will be configured to listen on port 8001
                      by default.
                    </li>
                  </ul>
                  <p>Step 2: Configure Your Reverse Proxy</p>
                  <ul className="ml-4">
                    <li>
                      Configure your reverse proxy (NGINX, HAProxy, Apache,
                      etc.) to send incoming requests to port 8001
                    </li>
                    <li>
                      Restart your reverse proxy to apply the new configuration
                    </li>
                  </ul>
                  <p>Step 3: Verify the Configuration</p>
                  <ul className="ml-4">
                    <li>
                      Send a request to your application as you normally would.
                    </li>
                    <li>Check the threat monitoring page here.</li>
                    <li>
                      Verify that the mirrored request appears on the monitoring
                      page, indicating that the configuration is working
                      correctly.
                    </li>
                    <li>
                      Ensure that your application also received the request (if
                      request status is not blocked)
                    </li>
                  </ul>
                </div>
              )}

              {mode === "active" && selectedOption === "another" && (
                <div className="text-black">
                  <p>Step 1: Deploy the WAF Application</p>
                  <ul className="ml-4">
                    <li>
                      Copy the provided code snippet and paste it onto a linux
                      server.
                    </li>
                    <li>
                      Ensure you have the necessary permissions to execute the
                      code on your server.
                    </li>
                    <li>
                      The application will be configured to listen on port 8001
                      by default.
                    </li>
                  </ul>
                  <p>Step 2: Configure Your Reverse Proxy</p>
                  <ul className="ml-4">
                    <li>
                      Configure your reverse proxy or load balancers to send
                      incoming requests to the new ip address
                    </li>
                  </ul>
                  <p>Step 3: Verify the Configuration</p>
                  <ul className="ml-4">
                    <li>
                      Send a request to your application as you normally would.
                    </li>
                    <li>Check the threat monitoring page here.</li>
                    <li>
                      Verify that the mirrored request appears on the monitoring
                      page, indicating that the configuration is working
                      correctly.
                    </li>
                    <li>
                      Ensure that your application also received the request (if
                      request status is not blocked)
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <button
              className="btn btn-primary mr-2 float-right w-[75px]"
            >
              {loading ? (
                <Spinner animation="border" role="status" />
              ) : (
                "Set up"
              )}
            </button>
          </div>
        </div>
        </form>
      </div>
    </div>
  );
};
