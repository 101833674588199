import React from "react";
import { StatCard } from "../performance-test/component/StatCard";
import report from "../../../assets/images/reportCard.png";
import { useGetEndpointDetectionFilterQuery } from "../../../redux/services/endPointScansApi";
import AbuseDetails from "./AbuseDetails";
import { MyResponsiveLine } from "../../components/MyResponsiveLine";
import { lineDb } from "../../../utils/applicationlogsdb";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ApiAbuseDetails = () => {
  const runsPercentage = (percentage) => {
    if (typeof percentage !== "number") return "";
    if (percentage < 0) return `${percentage}% decrease`;
    else return `+${percentage}% increase`;
  };
  const collection_id = localStorage.getItem("collection_id");
  const attack_type = localStorage.getItem("attack_type");
  const severity = localStorage.getItem("severity");
  const { data, isLoading } = useGetEndpointDetectionFilterQuery({
    collection_id: collection_id,
    attack_type: attack_type,
    severity: severity,
  });
  const location = useLocation();
  const path = new URLSearchParams(location.search);
  const history = useHistory();

  return (
    <div >
      <div className="flex justify-between mb-4">
      <h4 className="text-primary">{decodeURIComponent(path)}</h4>
      <button className="btn btn-primary" onClick={() => {history.push('/dashboard/api-security')}}>Back</button>
      </div>


      {/* <section className="card rounded-[20px] px-3 py-4 w-full  h-full flex flex-col mb-4">
        <h4 className="text-[#000000B2] font-bold mb-3">30 Days Recent Logs</h4>

        <div className="w-full h-[max-content] grid gap-6 grid-rows-[repeat(auto-fit,minmax(130px,auto))] md:grid-rows-[repeat(auto-fit,minmax(149px,auto))]   grid-cols-[repeat(auto-fit,minmax(200px,_1fr))] justify-center">
          <StatCard
            bgColor={"#FFF4DE"}
            value="1K"
            percentage={runsPercentage(8)}
            title={"Total Occurence"}
            iconColor={"#FF9478"}
            icon={true}
            report={report}
          />
          <StatCard
            bgColor={"#FFF4DE"}
            value="1K"
            percentage={runsPercentage(8)}
            title={"False Positives"}
            iconColor={"#FF9478"}
            icon={true}
            report={report}
          />
          <StatCard
            bgColor={"#FFF4DE"}
            value="1K"
            percentage={runsPercentage(8)}
            title={"Blocked"}
            iconColor={"#FF9478"}
            icon={true}
            report={report}
          />
          <StatCard
            bgColor={"#FFF4DE"}
            value="1K"
            percentage={runsPercentage(8)}
            title={"Critical"}
            iconColor={"#FF9478"}
            icon={true}
            report={report}
          />
        </div>
      </section> */}

      {/* <section className="card rounded-[20px] px-3 w-full py-3 h-[300px] mb-4">
        <h4 className="text-[#000000B2] font-bold">Number of Requests</h4>
        <MyResponsiveLine data={lineDb} />
      </section> */}

      <section className="card rounded-[20px] px-3 py-4 w-full  h-full flex flex-col mb-4">
        <AbuseDetails data={data} isLoading={isLoading} />
      </section>
    </div>
  );
};

export default ApiAbuseDetails;
