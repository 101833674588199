import React, { useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import AddUserModal from "../modals/configuration/adduser";
import { useCollectionContext } from "../../providers/Collection";
import DeleteUserModal from "../modals/configuration/deleteuser";

export const ProjectAccess = (props) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  const { selectedCollection } = useCollectionContext();
  const { selectedUser, setDeleteUser } = useState(null);
  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }

  return (
    <div>
      <div className="card p-4">
        <div className="row justify-content-between mb-4">
          <h4 className="card-title mb-0">Users</h4>
          <AddUserModal />
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>User</th>
                <th>Email</th>
                <th>Access</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props?.collection?.users?.map((user) => (
                <tr key={user.id}>
                  <td>
                    {user.first_name} {user.last_name}
                  </td>
                  <td>{user.email}</td>
                  <td>
                    {user.access_level.charAt(0).toUpperCase() +
                      user.access_level.slice(1)}
                  </td>
                  <td>
                    <label
                      className={
                        user.onboarded
                          ? "badge badge-success"
                          : "badge badge-danger"
                      }
                    >
                      {user.onboarded ? "Active" : "Pending"}
                    </label>
                  </td>
                  <td>
                    <DeleteUserModal user_id = {user.id} user={user} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
