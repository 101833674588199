import React, { useState, useEffect } from "react";
import { Form, Dropdown } from "react-bootstrap";
import countries from "./countries.js";
import industries from "./industries.js";
import { useAuth } from "../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { getUser, onboard } from "../../API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Profile = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [gender, setGender] = useState("Male");
  const [phone_number, setPhone] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [company_size, setCompanySize] = useState("");
  const [industry, setIndustry] = useState("");
  const [source, setSource] = useState("APISentry");
  const [error, setError] = useState(null)
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    history.push("/login");
  }

  useEffect(() => {
    async function fetchResult() {
      try {
        const response = await getUser();
        const jsonData = await response.json();
        // setResult(jsonData.data[0]);
      } catch (error) {
        setError(`Error: ${error.message}`);
      }
    }
    fetchResult();
  }, []);

  const filteredCountries = countries.filter(
    (country) => country.name === selectedCountry
  );

  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setSource("APISentry");
    const { success, error } = await onboard(
      first_name,
      last_name,
      gender,
      phone_number,
      company_name,
      company_size,
      industry,
      position,
      source,
      selectedCountry
    );
    if (success) {
      toast("Onboarding Successful");
      history.push("collections");
    } else {
      toast(error);
    }
  };
  return (
    <div>
      <ToastContainer />
      <div className="col-8 grid-margin stretch-card m-auto">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Your Data</h4>
            <p className="card-description">Review or edit your data</p>
            <form className="forms-sample" onSubmit={(e) => onSubmit(e)}>
              <div className="row">
                <Form.Group className="col-6">
                  <label htmlFor="first_name">
                    First Name<span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="first_name"
                    placeholder="Enter First Name"
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="col-6">
                  <label htmlFor="last_name">
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="last_name"
                    placeholder="Enter Last Name"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <Form.Group>
                <label htmlFor="exampleSelectGender">
                  Gender<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleSelectGender"
                  required
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                  <option value="Not Answer">Not Answer</option>
                </select>
              </Form.Group>
              <Form.Group>
                <label>
                  Country<span className="text-danger">*</span>
                </label>
                <div>
                  <select
                    className="form-control"
                    required
                    onChange={(e) => setSelectedCountry(e.target.value)}
                  >
                    {countries.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Form.Group>
              <Form.Group>
                <label>
                  Phone Number<span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="btn btn-outline-primary pt-3 pb-3"
                        id="dropdownMenuOutlineButton1"
                      >
                        {filteredCountries[0]?.dial_code}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          {filteredCountries[0]?.dial_code}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    className="form-control"
                    aria-label="Text input with dropdown button"
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputCity1">
                  Company Name<span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputCity1"
                  placeholder="Company Name"
                  required
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>Position</label>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setPosition(e.target.value)}
                  >
                    <option>Please Select</option>
                    <option value="CEO">CEO</option>
                    <option value="CTO/CISO">CTO/CISO</option>
                    <option value="Team Lead">Team Lead</option>
                    <option value="Engineer">Engineer</option>
                    <option value="Tester">Tester</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </Form.Group>
              <Form.Group>
                <label>
                  Company Size<span className="text-danger">*</span>
                </label>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setCompanySize(e.target.value)}
                    required
                  >
                    <option>Please Select</option>
                    <option value="1-50">1-50</option>
                    <option value="50-100">50-100</option>
                    <option value="100-200">100-200</option>
                    <option value="Above 200">Above 200</option>
                  </select>
                </div>
              </Form.Group>
              <Form.Group>
                <label>Industry</label>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setIndustry(e.target.value)}
                  >
                    <option>Please Select</option>
                    {industries.map((i) => (
                      <option value={i}>{i}</option>
                    ))}
                  </select>
                </div>
              </Form.Group>

              <button type="submit" className="btn btn-primary mr-2">
                Submit
              </button>
              <button className="btn btn-light">Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
