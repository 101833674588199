import React, { useState } from "react";
import { useAuth } from "../../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, Spinner } from "react-bootstrap";
import { useConfigureSlackMutation } from "../../../../redux/services/notificationsApi";

export const ConnectApps = ({
  description,
  image,
  btnName,
  input1,
  input2,
  setDisabled,
  btn,
  form,
  githubStatus
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [errMessage, setErrMsg] = useState("");
  const collectionId = localStorage.getItem("collection_id");
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }

  const [configureSlack, isSuccess] = useConfigureSlackMutation();
  let handleSlack = (event) => {
    event.preventDefault();
    setLoading(true);
    configureSlack({
      channel: name,
      token: token,
      collection_id: collectionId,
    });
    if (isSuccess) {
      setLoading(false);
      setDisabled(false);
      toast("Successful");
    }
  };



  return (
    <div className="bg-white rounded-[10px] my-8 ">
      <ToastContainer />
      <div className="card-body min-h-[300px]">
        <h2 className="card-title text-secondary">Connect Apps </h2>
        <p className="card-description">{description}</p>
        <div className="text-center mb-4">
          <img src={image} alt="github logo" className="text-center" />
        </div>
        {btn && (
          <div className="text-center md:w-[50%] mx-auto col-12">
            <a href="https://github.com/apps/apisentry-ai/installations/new" onClick={()=>setDisabled(false)}>
              <Button className={githubStatus ? "w-full btn-success" : "w-full"} disabled={githubStatus}>{btnName}</Button>
            </a>
          </div>
        )}
        {btn && form && (
          <h2 className="card-title text-secondary text-center my-4">OR </h2>
        )}
        {form && (
          <form className="forms-sample " onSubmit={(e) => handleSlack(e)}>
            <div className="mb-4 md:w-[50%] mx-auto">
              <Form.Group className="col-12">
                <label htmlFor="name" className="text-primary">
                  {input1}
                </label>
                <Form.Control
                  type="text"
                  className="form-control mb-4"
                  id="name"
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 50) {
                      setErrMsg("Invalid");
                      return;
                    }
                    setErrMsg("");
                    setName(value);
                  }}
                />
                {errMessage ? (
                  <label className="mt-1 text-danger">{errMessage}</label>
                ) : null}
              </Form.Group>
              <Form.Group className="col-12">
                <label htmlFor="token" className="text-primary">
                  {input2}
                </label>
                <Form.Control
                  type="text"
                  className="form-control mb-4"
                  id="token"
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 50) {
                      setErrMsg("Invalid");
                      return;
                    }
                    setErrMsg("");
                    setToken(value);
                  }}
                />
                {errMessage ? (
                  <label className="mt-1 text-danger">{errMessage}</label>
                ) : null}
              </Form.Group>
              <div className="text-right col-12">
                <button type="submit" className="btn btn-primary mx-0 w-[75px]">
                  {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Connect"
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
