import React, { useState } from 'react';
import FormInput from './FormInput';
import UpdateButton from './UpdateButton';
import Checkbox from './Checkbox';
import Schedule from './Schedule';

const JwtForm = ({ handleSubmit, handleInputChange, formData, isLoading }) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState('');

  const handleAuthTypeChange = (label) => setSelectedCheckbox(label);

  return (
    <div>
      <div className="my-4">
        <div className="text-base  font-medium text-black">
          Select Authentication Type
        </div>
        <Checkbox
          labelText={'Email'}
          selected={selectedCheckbox === 'Email'}
          onChange={() => handleAuthTypeChange('Email')}
        />
        <Checkbox
          labelText={'Username'}
          selected={selectedCheckbox === 'Username'}
          onChange={() => handleAuthTypeChange('Username')}
        />
      </div>
      <form onSubmit={handleSubmit}>
        {selectedCheckbox === 'Email' ? (
          <>
            <FormInput
              label={'Enter email'}
              value={formData.auth_email}
              required={true}
              handleInputChange={(event) =>
                handleInputChange('auth_email', event.target.value)
              }
              placeholder={'email'}
            />
          </>
        ) : null}

        {selectedCheckbox === 'Username' ? (
          <>
            <FormInput
              label={'Enter Username'}
              value={formData.auth_username}
              required={true}
              handleInputChange={(event) =>
                handleInputChange('auth_username', event.target.value)
              }
              placeholder={'username'}
            />
          </>
        ) : null}

        {selectedCheckbox === 'Username' || selectedCheckbox === 'Email' ? (
          <>
            <FormInput
              label={'Enter Password'}
              value={formData.auth_password}
              required={true}
              placeholder={'password'}
              type={'password'}
              handleInputChange={(event) =>
                handleInputChange('auth_password', event.target.value)
              }
            />
            <FormInput
              label={'Full Authentication Route'}
              value={formData.auth_endpoint}
              handleInputChange={(event) =>
                handleInputChange('auth_endpoint', event.target.value)
              }
              placeholder={'e.g https://api.example.com/auth'}
            />
            <Schedule
              handleInputChange={handleInputChange}
              formData={formData}
            />
          </>
        ) : null}

        <div className="w-full my-7 flex justify-end">
          <UpdateButton isLoading={isLoading} />
        </div>
      </form>
    </div>
  );
};

export default JwtForm;
