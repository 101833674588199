import React, { useState, useEffect } from "react";
import { useFetch } from "../../context";
import { Badge } from "react-bootstrap";

const LogbyIP = () => {
  const [data, setData] = useState([]);
  const { getThreateMonitoringEndpointDetails } = useFetch();
  useEffect(() => {
    const getData = async () => {
      const threatData = await getThreateMonitoringEndpointDetails();
      threatData && setData(threatData);
    };
    getData();
  }, []);
  const datadb = [
    {
      timestamp: "12-03-2001-001",
      ipaddress: "192.168.1.1",
      location: "Nigeria",
      threatLevel: "High",
      endpoint: "/",
      method: "GET",
      responseTime: "100s",
      responseCode: "500",
    },
    {
      timestamp: "12-03-2001-001",
      ipaddress: "192.168.1.1",
      location: "London",
      threatLevel: "High",
      endpoint: "/",
      method: "GET",
      responseTime: "100s",
      responseCode: "200",
    },
    {
      timestamp: "12-03-2001-001",
      ipaddress: "192.168.1.1",
      location: "Dubai",
      threatLevel: "Medium",
      endpoint: "/",
      method: "GET",
      responseTime: "100s",
      responseCode: "100s",
    },
  ];
  return (
    <div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-4">
        <table class="w-full text-sm text-left text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" class="px-6 py-3">
                Time Stamp
              </th>
              <th scope="col" class="px-6 py-3">
                Ip Address
              </th>
              <th scope="col" class="px-6 py-3">
                Location
              </th>
              <th scope="col" class="px-6 py-3">
                Threat Level
              </th>
              <th scope="col" class="px-6 py-3">
                Endpoint
              </th>
              <th scope="col" class="px-6 py-3">
                Method
              </th>
              <th scope="col" class="px-6 py-3">
                Response Time
              </th>
              <th scope="col" class="px-6 py-3">
                Response Code
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item) => (
                <tr class="bg-white border-b ">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    {item.timestamp}
                  </th>
                  <td class="px-6 py-4">{item.ipAddress}</td>
                  <td class="px-6 py-4">{item.location}</td>
                  <td class="px-6 py-4">
                    <Badge
                      pill
                      className="text-white p-2"
                      bg={
                        item.threat_level === "critical"
                          ? "danger"
                          : item.threat_level === "medium"
                          ? "warning"
                          : item.threat_level === "low"
                          ? "primary"
                          : "info"
                      }
                    >
                      <i className="mdi mdi-security"> </i>
                      {item.threat_level}
                    </Badge>
                  </td>
                  <td class="px-6 py-4 text-primary">{item.path}</td>
                  <td
                    class={
                      item.method === "GET"
                        ? "px-6 py-4 text-success"
                        : item.method === "POST"
                        ? "px-6 py-4 text-primary"
                        : item.method === "PATCH"
                        ? "px-6 py-4 text-secondary"
                        : item.method === "PUT"
                        ? "px-6 py-4 text-warning"
                        : item.method === "DELETE"
                        ? "px-6 py-4 text-danger"
                        : "px-6 py-4 text-info"
                    }
                  >
                    {item.method}
                  </td>
                  <td class="px-6 py-4">{item.response_time}</td>
                  <td class="px-6 py-4">
                    <button
                      className={
                        item?.response_code?.toString()?.startsWith("5")
                          ? "btn btn-sm btn-outline-danger"
                          : item?.response_code?.toString()?.startsWith("4")
                          ? "btn btn-sm btn-outline-danger"
                          : item?.response_code?.toString()?.startsWith("3")
                          ? "btn btn-sm btn-outline-info"
                          : item?.response_code?.toString()?.startsWith("2")
                          ? "btn btn-sm btn-outline-success"
                          : "text-primary"
                      }
                      disabled
                    >
                      {item.response_code}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LogbyIP;
