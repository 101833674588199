import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SelectCustomDate = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  labelStyle,
  selectStyle,
}) => {
  const handleStartDateChange = (date) => {
    if (date) {
      const isoDate = date.toISOString();
      setStartDate(isoDate);
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const isoDate = date.toISOString();
      setEndDate(isoDate);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-5 items-center">
      <div>
        <label htmlFor="start-date" className={labelStyle}>
          Start Date
        </label>
        <DatePicker
          selected={startDate ? new Date(startDate) : null}
          onChange={handleStartDateChange}
          className={selectStyle}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>

      <div>
        <label htmlFor="end-date" className={labelStyle}>
          End Date
        </label>
        <DatePicker
          selected={endDate ? new Date(endDate) : null}
          onChange={handleEndDateChange}
          className={selectStyle}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>
    </div>
  );
};

export default SelectCustomDate;
