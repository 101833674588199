import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function ToolTip(props) {
  const renderTooltip = (description) => (
    <Tooltip id="button-tooltip-2" {...props}>
      {description}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(props.description)}
    >
      {/* <Button variant="success"> */}
        <i className='mdi mdi-alert-circle-outline'></i>
      {/* </Button> */}
    </OverlayTrigger>
  );
}

export default ToolTip;