import React from "react";
import up from "../../../assets/images/green-arrow.png";
import down from "../../../assets/images/red-arrow.png";
const Predictions = ({ analytics }) => {
  const prediction_score = analytics?.filter(
    (item) => item.label === "prediction_score"
  );
  const last_prediction_score = analytics?.filter(
    (item) => item.label === "last_prediction_score"
  );
  const predictionScoreDifference = Math.floor(
    ((prediction_score?.[0]?.value - last_prediction_score?.[0]?.value) /
      (last_prediction_score?.[0]?.value === 0
        ? 1
        : last_prediction_score?.[0]?.value)) 
  );

  const security_score = analytics?.filter(
    (item) => item.label === "security_score"
  );
  const last_security_score = analytics?.filter(
    (item) => item.label === "last_security_score"
  );
  const securityScoreDifference = Math.floor(
    ((security_score?.[0]?.value - last_security_score?.[0]?.value) /
      (last_security_score?.[0]?.value === 0
        ? 1
        : last_security_score?.[0]?.value)) 
  );

  return (
    <div className="mb-4 md:mb-0 md:w-full w-[220px]">
      <p className="font-bold text-black">Predictions</p>
      <div className="grid grid-cols-2 gap-1 md:gap-2 ">
        <div>
          <p className="text-black font-bold md:text-[20px] text-[16px] mb-1">
            {prediction_score?.[0]?.value} %
          </p>
          <p>Possible Threat <br/> Occurrence</p>
        </div>
        <div className="flex space-x-2">
          {predictionScoreDifference >= 0 ? (
            <img
              src={up}
              alt=""
              className="w-[15px] h-[15px] md:w-[25px] md:h-[25px]"
            />
          ) : (
            <img
              src={down}
              alt=""
              className="w-[15px] h-[15px] md:w-[25px] md:h-[25px]"
            />
          )}

          {predictionScoreDifference >= 0 ? (
            <p>
              +{predictionScoreDifference}% 
              last week
            </p>
          ) : (
            <p>
              -{predictionScoreDifference}% 
              last week
            </p>
          )}
        </div>
        <div>
          <p className="text-black font-bold md:text-[20px] text-[16px] mb-1">
            {security_score?.[0]?.value} / 3000
          </p>
          <p>Security Score</p>
        </div>
        <div className="flex space-x-2">
          {securityScoreDifference >= 0 ? (
            <img
              src={up}
              alt=""
              className="w-[15px] h-[15px] md:w-[25px] md:h-[25px]"
            />
          ) : (
            <img
              src={down}
              alt=""
              className="w-[15px] h-[15px] md:w-[25px] md:h-[25px]"
            />
          )}

          {securityScoreDifference >= 0 ? (
            <p>
              +{securityScoreDifference}%
              last week
            </p>
          ) : (
            <p>
              -{securityScoreDifference}% 
              last week
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Predictions;
