import React from 'react'

const tableTopLocation = ({data}) => {
//   const data = [
//     {
//         "location":"oshodi",
//         "countryName":"Nigeria",
//         "total_visits":12
//     },
//     {
//         "location":"london",
//         "country":"Uk",
//         "total_visits":12
//     },
//     {
//         "location":"oshodi",
//         "country":"Nigeria",
//         "total_visits":12
//     },
//     {
//         "location":"oshodi",
//         "country":"Nigeria",
//         "total_visits":12
//     },
//     {
//         "location":"jekam",
//         "country":"uk",
//         "total_visits":12
//     },
 
  
// ]
return (
<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left text-gray-500 ">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
                {/* <th scope="col" class="px-6 py-3">
                   Location
                </th> */}
                <th scope="col" class="px-6 py-3">
                    Country
                </th>
            
                <th scope="col" class="px-6 py-3">
                    Counts
                </th>
            
            </tr>
        </thead>
        <tbody>
            {
                data.length > 0 && data.map((item)=>(

                    <tr class="bg-white border-b ">
                        {/* <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                            {item.location}
                        </th> */}
                        <td class="px-6 py-4">
                        {item.countryName}
                        </td>
                        <td class="px-6 py-4">
                        {item.total_visits}
                        </td>
                    
                    </tr>
                ))

            }
        
        
        </tbody>
    </table>
</div>
)
}

export default tableTopLocation