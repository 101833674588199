import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useConfigureSlackMutation } from "../../../../redux/services/notificationsApi";

function ConfigureSlackModal(props) {
  const collectionId = localStorage.getItem("collection_id");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [token, setToken] = useState("");
  const [channel, setChannel] = useState("");
  const [configureSlack, isSuccess] = useConfigureSlackMutation();

  const handleSubmit = (event) => {
    event.preventDefault();
    configureSlack({ 
        channel: channel,
        token: token,
        collection_id: collectionId,
    });
    if (isSuccess) {
        toast("Success");
        handleClose()
      } 
  };

  return (
    <div>
      <button
        className='sm:px-3 md:px-10  md:py-2 py-1 md:text-sm text-xs md:font-bold text-white bg-[#023673] rounded border-0 outline-0'
        onClick={handleShow}
      >
        Set up
      </button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="red-test">Slack Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div>
              <Form.Group>
                <label htmlFor={`token`}>App Token</label>
                <Form.Control
                  type="text"
                  id={`token`}
                  size="lg"
                  value={token}
                  placeholder="Enter your token"
                  onChange={(event) => setToken(event.target.value)}
                  className="mb-4"
                />
              </Form.Group>
              <Form.Group>
                <label>Preferred Channel</label>
                <Form.Control
                  type="text"
                  id={`channel`}
                  size="lg"
                  value={channel}
                  placeholder="Enter channel to send notifications"
                  onChange={(event) => setChannel(event.target.value)}
                  className="mb-4"
                />
              </Form.Group>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
              onClick={handleSubmit}
          >
            Configure
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfigureSlackModal;
