import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

import googleLogoAuth from '../../../../../assets/images/auth/google-logo-auth.png';
import emailLogo from '../../../../../assets/images/auth/email-icon-logo.png';
import { useActivateMfaMutation } from '../../../../../redux/services/mfaAuthApi';
import { setQrCodeUrl } from '../../../../../redux/features/mfaAuthSlice';

export const ProceedWithMultifactorAuthentication = () => {
  const history = useHistory();
  const [activateMfa, { data, isLoading, error }] = useActivateMfaMutation();
  const [isEmailOptLoading, setIsEmailOptLoading] = useState(false);
  const [isGoogleAuthOtpLoading, setIsGoogleAuthOtpLoading] = useState(false);
  const [otpType, setOtpType] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const message = data?.message;
      localStorage.setItem('otpType', otpType);

      if (otpType === 'email') history.push('confirm-2fa');
      else {
        const qrCodeUrl = data?.qr_code_url;
        dispatch(setQrCodeUrl(qrCodeUrl));
        // localStorage.setItem('qrCodeUrl', qrCodeUrl);
        history.push('activate-2fa');
      }

      toast(message);
    }
    if (error) {
      const errMessage = error?.data?.error || 'An error occurred';
      toast(errMessage);
    }
  }, [data, error, dispatch, history, otpType]);

  const handleClick = (otpType) => {
    if (isLoading) return;
    setOtpType(otpType);

    if (otpType === 'email') setIsEmailOptLoading(true);
    else setIsGoogleAuthOtpLoading(true);

    handleSubmit(otpType);
  };

  const handleSubmit = async (otpType) => {
    try {
      await activateMfa({
        otp_type: otpType,
      });
    } catch (error) {
      console.log('Error', error);
    } finally {
      setIsEmailOptLoading(false);
      setIsGoogleAuthOtpLoading(false);
    }
  };

  return (
    <div className="bg-white w-full h-full ">
      <div className="grid grid-rows-3  justify-items-center  justify-center h-[45%] ">
        <div className="pt-[25px]">
          <h1 className="text-sky-950 text-[18px] text-center md:text-[28px] font-semibold font-['Nunito Sans']">
            Multi Factor Authentication
          </h1>
          <p className="text-sky-950 text-[12px] md:text-sm text-center font-light font-['Noto Sans JP']">
            Two-factor Authentication Security Setting
          </p>
        </div>

        <div className="max-w-14 h-[48px] mt-3">
          <img className="w-full h-full" src={emailLogo} alt="Google Logo" />
        </div>

        <div className="max-w-[440px]  md:max-w-[630px] px-7">
          <div className="text-sky-950  text-base md:text-lg font-medium font-['Noto Sans JP']">
            Email Account
          </div>
          <div className="h-[40px] md:h-[50px]">
            <button
              disabled={isLoading}
              onClick={() => handleClick('email')}
              className="w-full h-full   bg-sky-950 rounded shadow flex justify-center items-center border-none  focus:outline-none"
            >
              <div className="text-white sm:text-xs md:text-sm font-bold font-['Noto Sans JP'] ">
                {isEmailOptLoading ? 'Loading...' : 'Activate'}
              </div>
            </button>
          </div>

          <p className=" text-sky-950  text-sm mt-2 font-light font-['Noto Sans JP']">
            Activation token will be sent to your email, please follow the steps
            to complete activation{' '}
          </p>
        </div>
      </div>

      <div className="flex items-center mx-4 h-[10%] my-3">
        <div className="w-[48vw] h-px  bg-zinc-300" />
        <div className="  text-sky-950 text-xl font-normal font-['Noto Sans JP'] mx-4">
          or
        </div>
        <div className="w-[48vw] h-px  bg-zinc-300" />
      </div>

      <div className="grid grid-rows-2 h-[30%] w-full">
        <div className="w-14 h-[50px] justify-self-center">
          <img
            className="w-full h-full"
            src={googleLogoAuth}
            alt="Google Logo"
          />
        </div>
        <div className="w-full">
          <div
            className="h-[40px] md:h-[50px] max-w-[440px] mx-auto md:max-w-[630px] px-7 
        "
          >
            <div className="text-sky-950 w-full mt-2  text-base md:text-lg font-medium font-['Noto Sans JP']">
              Email Authentication
            </div>
            <button
              disabled={isLoading}
              onClick={() => handleClick('auth_app')}
              className="w-full h-full    bg-sky-950 rounded shadow  border-none  focus:outline-none"
            >
              <div className="text-white sm:text-xs md:text-sm font-bold font-['Noto Sans JP'] ">
                {isGoogleAuthOtpLoading ? 'Loading' : ' Activate'}
              </div>
            </button>
            <p className=" text-sky-950  text-sm mt-2 font-light font-['Noto Sans JP']">
              Follow the steps to set up 2FA
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
