import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import {  Badge } from "react-bootstrap";

function MetaDataModal({ modalData, showModal, setShowModal }) {
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Modal show={showModal} onHide={setShowModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-secondary">
            Request MetaData{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 className="text-danger">
            <i className="mdi mdi-alert"></i> Attack Group:
          </h4>
          {/* <p className="text-primary mb-4">{data?.summary}</p> */}
          <div className="row justify-between mb-4">
            <Badge bg="primary" className="text-white">
              {modalData?.attack_group}
            </Badge>
          </div>

          <div className="row justify-between mb-4">
            <h4 className="text-secondary">Severity: </h4>
            <Badge
              bg={
                modalData?.severity === "high"
                  ? "danger"
                  : modalData?.severity === "medium"
                  ? "warning"
                  : "info"
              }
              className="text-white"
            >
              {modalData?.severity}
            </Badge>
          
          </div>
          <div className="row justify-between mb-4">
            <h4 className="text-secondary">Threat Name: </h4>
            <Badge
              bg={
                modalData?.severity === "high"
                  ? "danger"
                  : modalData?.severity === "medium"
                  ? "warning"
                  : "info"
              }
              className="text-white"
            >
              {modalData?.threat_name}
            </Badge>
          
          </div>

          <h4 className="text-secondary">Description</h4>
          <p className="text-primary mb-4">{modalData?.description}</p>

          <h4 className="text-secondary">Remediation</h4>
          <p className="text-primary mb-4">{modalData?.remediation}</p>

          <h4 className="text-secondary">References</h4>
          <p className="text-primary mb-4">{modalData?.references}</p>
          <AceEditorComp
            name={'Request Header'}
            content={modalData.requestHeaderContent}
          />
          <AceEditorComp
            name={'Request Body'}
            content={modalData.requestBodyContent}
          />
          <AceEditorComp
            name={'Response Header'}
            content={modalData.responseHeaderContent}
          />
          <AceEditorComp
            name={'Response Body'}
            content={modalData.responseBodyContent}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const AceEditorComp = ({ content, name }) => (
  <div className="mt-4">
    <h5 className="text-secondary">{name}</h5>
    <div className="p-2" style={{ border: '1px solid' }}>
      <AceEditor
        mode="json"
        theme="monokai"
        value={JSON.stringify(content, null, 2)}
        style={{ width: '100%', height: '100px' }}
        name="ace-editor_req_header"
      />
    </div>
  </div>
);

export default MetaDataModal;
