

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { parseHeaderString } from '../../../utils';
// import { parseHeaderString } from '../../utils';



const codeStyles = {
  ...darcula,
  'code[class*="language-"]': {
    ...solarizedlight['code[class*="language-"]'],
  //   backgroundColor: '#202020', // Dark background color
    color: '#00ff00', // Green text color
  },
};

export default function SecurityScanModel({text}) {

  
  
  return (
    // <div className="w-[100px] h-[100px] bg-greeb-900">
    //       <div className="w-screen  h-full  bottom-full bg-gray-800 text-white text-sm rounded py-2 px-4 mt-2">
    //             <SyntaxHighlighter language="javascript" style={darcula}>
    //                 {text}
    //             </SyntaxHighlighter>
            
           
    //     </div>
      
    // </div>
    <div className="absolute right-0 -top-4  text-white p-2 rounded">
     <SyntaxHighlighter language="javascript" style={codeStyles}>
     {`${parseHeaderString(text)[0]} \n { 
                                 ${parseHeaderString(text)[1]} \n }`}
    </SyntaxHighlighter>
  </div>
  );
};

  
   