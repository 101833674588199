import { useState } from 'react';

const useButtonPagination = (setPage) => {
  const [pageCount, setPageCount] = useState(null);

  const handlePageClick = (event) => {
    const page = event.selected + 1;
    setPage(page);
  };

  return { pageCount, handlePageClick, setPageCount };
};

export default useButtonPagination;
