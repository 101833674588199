import React, { useEffect, useState } from 'react';
import forgotPassword from '../../../../assets/images/auth/forgot-password-bg.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { useNewPasswordMutation } from '../../../../redux/services/authApi';
import {
  passwordInputErrorMsg,
  validatePassword,
} from '../../../../utils/authInputsValidators';

export const NewPassword = () => {
  const history = useHistory();
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [comfirmPassword, setComfirmPassword] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('');

  const [newPassword, { data, isLoading, isSuccess, error }] =
    useNewPasswordMutation();

  useEffect(() => {
    if (data || isSuccess) {
      toast('Password changed successfully');
      history.push('/login');
    }

    if (error) {
      const errMessage = error?.data?.Message || 'An error occurred';
      toast(errMessage);
    }
  }, [error, data, isLoading, isSuccess, history]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    const result = localStorage.getItem('resetPasswordEmail');
    const email = result ? result : '';

    if (password !== comfirmPassword) {
      toast('Both New and Confirmed password should be the same');
      return;
    }

    const isValidPassword = validatePassword(password);
    const isValidConfirmPassword = validatePassword(comfirmPassword);

    if (!isValidPassword) return setPasswordErrorMsg(passwordInputErrorMsg);
    if (!isValidConfirmPassword)
      return setConfirmPasswordErrorMsg(passwordInputErrorMsg);

    await newPassword({
      token,
      email,
      new_password1: password,
      new_password2: comfirmPassword,
    });
  };

  return (
    <main className="grid grid-cols-1 md:grid-cols-2 gap-[38px] w-full h-full items-center px-4 md:px-[38px]  bg-white">
      <div className="w-full h-[420px] hidden md:block  rounded-[58px]">
        <img
          className="w-full h-full "
          src={forgotPassword}
          alt="Security Logo"
        />
      </div>

      <form
        onSubmit={handleSubmit}
        className="md:grid-rows-[min-content]   grid-rows-4 mb-auto [&>div]:mb-[27px] md:[&>div]:mb-[35px]  md:my-auto"
      >
        <div className="mt-14 md:mt-0">
          <h1 className="text-sky-950 text-[20px] md:text-[28px] font-semibold font-['Nunito Sans']">
            New Password
          </h1>
          <p className="text-sky-950 text-sm md:text-base font-light font-['Noto Sans JP']">
            Enter the received token sent to you, new password and confirm
            password
          </p>
        </div>

        <div className="flex flex-col  md:mt-0">
          <label
            htmlFor="token"
            className="text-sky-950 text-sm md:text-lg font-normal font-['Noto Sans JP']"
          >
            Enter Token
          </label>
          <input
            id="token"
            required
            type="number"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
          />
        </div>

        <div className="flex flex-col  md:mt-0">
          <label
            htmlFor="new-password"
            type="text"
            className="text-sky-950 text-sm md:text-lg font-normal font-['Noto Sans JP']"
          >
            New Password
          </label>
          <input
            id="new-password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
          />
          {passwordErrorMsg ? (
            <p className="text-danger mt-1">{passwordErrorMsg}</p>
          ) : null}
        </div>

        <div className="flex flex-col  md:mt-0">
          <label
            htmlFor="comfirm-password"
            required
            className="text-sky-950 text-sm md:text-lg font-normal font-['Noto Sans JP']"
          >
            Confirm Password
          </label>
          <input
            id="comfirm-password"
            type="text"
            required
            value={comfirmPassword}
            onChange={(e) => setComfirmPassword(e.target.value)}
            className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
          />
          {confirmPasswordErrorMsg ? (
            <p className="text-danger mt-1">{confirmPasswordErrorMsg}</p>
          ) : null}
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="w-full h-[50px]  bg-sky-950 rounded shadow flex justify-center items-center border-none focus:outline-none"
        >
          <div className="text-white text-sm font-bold font-['Noto Sans JP']">
            {isLoading ? 'Loading...' : 'Submit'}
          </div>
        </button>
      </form>
    </main>
  );
};
