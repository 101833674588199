import React, { createContext, useContext } from 'react';
import { useAuth } from '../providers/AuthProvider';
import axios from 'axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { setCollectionDetails } from '../../redux/features/collectionSlice';

// const  PATH_TOKEN = "c290eb3c-943d-40ac-a11d-fe1ca5fc77b4"
const PATH_TOKEN = localStorage.getItem('collection_token');

// const COLLECTION_ID = localStorage.getItem("collection_id");

const apiUrl = process.env.REACT_APP_API_URL;

const FetchContext = createContext();

const FetchContextProvider = ({ children }) => {
  const { id: collectionId } = useSelector(setCollectionDetails);
  const { pathname } = useLocation();

  const pathnameList = pathname && pathname?.split('/');
  const pathnameLastVal = pathnameList[pathnameList?.length - 1];

  const { userToken } = useAuth();
  // const userToken = "xxx"

  const getEndpointDetails = async () => {
    console.log(collectionId);
    const COLLECTION_ID = localStorage.getItem('collection_id');
    const url = `${apiUrl}/scan/path_monitoring/?collection=${collectionId}`;
    // const url = `${apiUrl}/scan/path_monitoring/6ee1eb8a-4c25-45b4-a18a-7fe4e77ba151 `;
    const data = { collection_token: collectionId };
    const accessToken = userToken;
    if (collectionId !== null || collectionId) {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } catch (error) {
        console.error('Error:');
      }
    }
  };

  const getEndpointScan = async (endpoint_id) => {
    const url = `${apiUrl}/scan/path_monitoring/${endpoint_id}/`;
    const accessToken = userToken;
    // if (COLLECTION_ID !== null || COLLECTION_ID) {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const getPaginatedEndpointDetails = async (pageNumber) => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    const url = `${apiUrl}/scan/path_monitoring/?collection=${COLLECTION_ID}&page=${pageNumber}`;
    // const data = { collection_token: COLLECTION_ID };
    const accessToken = userToken;
    if (COLLECTION_ID !== null || COLLECTION_ID) {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } catch (error) {
        console.error('Error:');
      }
    }
  };
  // getEndpointDetails()

  const getThreateMonitoringEndpointDetails = async () => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    let url = '';
    if (pathnameLastVal === 'threats' || pathnameLastVal === 'endpoints') {
      url = `${apiUrl}/scan/threat_monitoring/?collection_id=${COLLECTION_ID}`;
    } else {
      url = `${apiUrl}/scan/threat_monitoring/?collection_id=${COLLECTION_ID}&ip_address=${pathnameLastVal}`;
    }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      // console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const getFilterThreateMonitoringEndpointDetails = async (start, end) => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    let url = '';
    if (pathnameLastVal === 'threats' || pathnameLastVal === 'endpoints') {
      url = `${apiUrl}/scan/threat_monitoring/?collection_id=${COLLECTION_ID}${
        start !== undefined ? `&start_time=${start}&end_time=${end}` : ''
      }`;
    } else {
      url = `${apiUrl}/scan/threat_monitoring/?collection_id=${COLLECTION_ID}&ip_address=${pathnameLastVal}`;
    }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      // console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getPaginatedThreateMonitoringEndpointDetails = async (pageNumber) => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    let url = '';
    if (pathnameLastVal === 'threats' || pathnameLastVal === 'endpoints') {
      url = `${apiUrl}/scan/threat_monitoring/?collection_id=${COLLECTION_ID}&page=${pageNumber}`;
    } else {
      url = `${apiUrl}/scan/threat_monitoring/?collection_id=${COLLECTION_ID}&ip_address=${pathnameLastVal}`;
    }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      // console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getThreatActivity = async (activity_id) => {
    let url = `${apiUrl}/scan/threat_monitoring/${activity_id}/`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      // console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getEndpointActivity = async (endpoint_id) => {
    const url = `${apiUrl}/scan/path_activity/?path_token=${endpoint_id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      // console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const getPaginatedEndpointActivity = async (endpoint_id, pageNumber) => {
    const url = `${apiUrl}/scan/path_activity/?path_token=${endpoint_id}?page=${pageNumber}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      // console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const getFilterEndpointActivity = async (endpoint_id, start, end) => {
    const url = `${apiUrl}/scan/path_activity/?path_token=${endpoint_id}&start_time=${start}&end_time=${end}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      // console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const getThreatMonitoringLogs = async () => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    const url = `${apiUrl}/scan/threat_monitoring/?collection_id=${COLLECTION_ID}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const getThreatMonitoringAnalytics = async (scope = 'Yearly') => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    const url = `${apiUrl}/scan/threat_analytics/?scope=${scope}&collection_id=${COLLECTION_ID}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  // getThreatMonitoringAnalytics()

  const getEndPointsAnalyticsDetails = async (endpoint_id) => {
    const url = `${apiUrl}/scan/path_analytics/?path_token=${endpoint_id}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };
  const getAutomatedScanDetails = async () => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    const url = `${apiUrl}/scan/automated_test/?collection_id=${COLLECTION_ID}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const runAllScans = async (env) => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    const url = `${apiUrl}/scan/r_automated_test/`;

    try {
      const response = await axios.post(
        url,
        {
          collection_id: COLLECTION_ID,
          environment: env,
        },
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const runOneScan = async (env, endpoint_id) => {
    const url = `${apiUrl}/scan/r_automated_test/`;

    try {
      const response = await axios.post(
        url,
        {
          endpoint_id: endpoint_id,
          environment: env,
        },
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const updateScan = async (test_case_id, body) => {
    const url = `${apiUrl}/scan/update_test_case/${test_case_id}/`;

    try {
      const response = await axios.patch(url, body, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });

      return response;
    } catch (error) {
      return error;
    }
  };

  const getScanResult = async (env, endpoint_id) => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    const url = `${apiUrl}/scan/security_scan/?collection_id=${COLLECTION_ID}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const runDynamicResult = async (env, type) => {
    const COLLECTION_ID = localStorage.getItem('collection_id');
    const url = `${apiUrl}/scan/dynamic_scan/`;

    try {
      const response = await axios.post(
        url,
        {
          collection_id: `${COLLECTION_ID}`,
          environment: env,
          type: type,
        },
        {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error:');
    }
  };

  const value = {
    userToken,
    getEndpointActivity,
    getFilterEndpointActivity,
    getPaginatedEndpointActivity,
    getEndpointDetails,
    getPaginatedEndpointDetails,
    getThreateMonitoringEndpointDetails,
    getFilterThreateMonitoringEndpointDetails,
    getPaginatedThreateMonitoringEndpointDetails,
    getAutomatedScanDetails,
    getThreatMonitoringLogs,
    runAllScans,
    runOneScan,
    updateScan,
    getScanResult,
    runDynamicResult,
    getEndPointsAnalyticsDetails,
    getThreatMonitoringAnalytics,
    getThreatActivity,
    getEndpointScan,
  };

  return (
    <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
  );
};

const useFetch = () => {
  const context = useContext(FetchContext);
  if (!context) {
    throw new Error('unable to get Data');
  }
  return context;
};

export { FetchContextProvider, useFetch };
