import React from 'react'

const tableEndpointUsage = ({data}) => {
//   const data = [
//     {
//         "path":"/",
//         "method":"GET",
//         "request_rate":12,
//         "frequent_response_code":200,
//         "avg_res_time":"12ms",
//     },
//     {
//         "path":"/api",
//         "method":"POST",
//         "request_rate":12,
//         "frequent_response_code":500,
//         "avg_res_time":"30ms",
//     },
//     {
//         "path":"/",
//         "method":"GET",
//         "request_rate":12,
//         "frequent_response_code":200,
//         "avg_res_time":"12ms",
//     },
//     {
//         "path":"/",
//         "method":"GET",
//         "request_rate":12,
//         "frequent_response_code":200,
//         "avg_res_time":"12ms",
//     },
//     {
//         "path":"/",
//         "method":"GET",
//         "request_rate":12,
//         "frequent_response_code":200,
//         "avg_res_time":"12ms",
//     },
  
  
// ]
return (
<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left text-gray-500 ">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr className='  font-bold font-epilogue'>
                <th scope="col" class="px-6 py-3">
                   Path
                </th>
                <th scope="col" class="px-6 py-3">
                    Method
                </th>
            
                <th scope="col" class="px-6 py-3">
                    Request rate
                </th>
                <th scope="col" class="px-6 py-3">
                    Frequent Request code
                </th>
                <th scope="col" class="px-6 py-3">
                    Av Response Time
                </th>
            
            </tr>
        </thead>
        <tbody>
            {
                data.length > 0 && data.map((item)=>(

                    <tr class="bg-white border-b  hover:text-[#0FE78C] 
                    cursor-pointer font-semibold">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                            {item.path}
                        </th>
                        <td class="px-6 py-4">
                        {item.method}
                        </td>
                        <td class="px-6 py-4">
                        {item.request_rate}
                        </td>
                        <td class="px-6 py-4">
                        {item.frequent_response_code}
                        </td>
                        <td class="px-6 py-4">
                        {item.avg_res_time}
                        </td>
                    
                    </tr>
                ))

            }
        
        
        </tbody>
    </table>
</div>
)
}

export default tableEndpointUsage