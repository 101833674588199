import React, { useEffect, useState } from "react";
import {
  useGetCustomRegexQuery,
  useUpdateCustomRegexMutation,
} from "../../../redux/services/collectionApi";
import EditRegexModal from "../modals/editRegexModal";
import { toast } from "react-toastify";

const CustomRegex = () => {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [uuid, setUUID] = useState("");

  const [pattern, setPattern] = useState("");
  const [status, setStatus] = useState("");
  const collectionId = localStorage.getItem("collection_id");
  const { data: regex } = useGetCustomRegexQuery({ collectionId });
  const [updateCustomRegex] = useUpdateCustomRegexMutation();
  useEffect(() => {
    setData(regex);
  }, [regex]);
  const updateRegex = async () => {
    try {
      await updateCustomRegex({
        uuid: uuid,
        body: {
          name: name,
          pattern: pattern,
          status: status,
        },
      }).unwrap();
      setName("")
      setStatus("")
      setPattern("")
      setUUID("")
      toast.success("Successful");
    } catch (error) {
      console.error("Failed:", error);
      toast.error("Failed to update");
    }
  };
  console.log(data);

  return (
    <div className="card rounded-lg px-[10px] py-[14px] my-4 border-teal-800">
      <h3 className="text-dark py-2">Custom Regex Patterns</h3>

      <div className="relative overflow-x-auto">
        <table className="w-full bg-white">
          <thead>
            <tr className="text-[#09294d] bg-[#00000010]">
              <th scope="col" className="px-6 py-3 text-nowrap">
                Name
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Pattern
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Created at
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white text-[14px]">
            {data?.map((item, key) => (
              <tr className="text-[#09294d]" key={item.id}>
                <td className="px-6 py-[10px] text-nowrap">{item.name}</td>
                <td className="px-6 py-[10px] text-nowrap">{item.pattern}</td>
                <td className="px-6 py-[10px] text-nowrap">{item.status}</td>
                <td className="px-6 py-[10px] text-nowrap">
                  {item.created_at.substring(0, 10)}
                </td>
                <td className="px-6 py-[10px] text-nowrap">
                  <EditRegexModal
                    item={item}
                    setUUID = {setUUID}
                    onSubmit={updateRegex}
                    setName={setName}
                    setPattern={setPattern}
                    setStatus={setStatus}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomRegex;
