import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFunc } from '../baseQueryFunc';

export const endPointScanApi = createApi({
  reducerPath: 'endPointScanApi',

  baseQuery: (args, api, extraOptions) =>
    baseQueryFunc('scan', args, api, extraOptions),

  tagTypes: [
    'EndPointScans',
    'EndpointActivity',
    'FilterEndpointActivity',
    'RealTimeScan',
    'DynamicScan',
    'ConformanceScan',
    'ThreatActivity',
    'FilteredLogData',
    'AutomatedTests',
    'PerformanceTests',
    'OneScanAutomatedTests',
    'UserInCollection',
    'ThreatMap',
    'TopCountriesAttachers',
    'TopAttackTypes',
    'SuspiciousIps',
    'RecentRuns',
    'TestReport',
    'UpdateHighlightTestCase',
    'AllTestCases',
    'TestCase',
    'QueryTestCaseByPath',
    'ConfigurePerformanceTests',
    'SuspiciousDynamicPerYear',
    'VulnerabilityCounts',
    'CriticalVulnerability',
    'HighVulnerability',
    'MediumVulnerability',
    'LowVulnerability',
    'EndpointDetails',
    'EndpointDetected',

    'SingleVulnerabilityDetails',
    // 'EndpointDetails'
  ],

  endpoints: (build) => ({
    getEndPointScans: build.query({
      query: (endpointId) => `path_monitoring/${endpointId}/`,
      transformResponse: (response) => {
        const parseConformance = (data) => {
          let parsedData = [];
          for (let index = 0; index < data?.length; index++) {
            let parse_log = {
              threat_name: data[index]?.issue,
              attack_group: 'vulnerability',
              category: 'Conformance Scan',
              severity: data[index]?.severity?.toLowerCase(),
              description: data[index]?.description,
              remediation: data[index]?.remediation,
              risk: data[index]?.risk,
              execution_time: data[index]?.created_at,
              request_header: '',
              request_body: '',
              issue_type: '',
              response_code: '',
              response_header: '',
              response_body: '',
            };
            parsedData.push(parse_log);
          }
          return parsedData;
        };
        const parseDynamic = (data) => {
          let parsedData = [];
          for (let index = 0; index < data?.length; index++) {
            let parse_log = {
              threat_name: data[index]?.threat_name,
              attack_group: data[index]?.attack_group,
              category: 'Dynamic Scan',
              severity: data[index]?.severity,
              description: data[index]?.description,
              remediation: data[index]?.remediation,
              risk: data[index]?.risk,
              execution_time: '',
              request_header: data[index]?.request_header,
              request_body: data[index]?.request_payload,
              issue_type: '',
              response_code: data[index]?.response_code,
              response_header: data[index]?.response_header,
              response_body: data[index]?.response_payload,
              count: data[index]?.count,
              cve_id: data[index]?.cve_id,
              cve_score: data[index]?.cve_score,
              environment: '',
              references: data[index]?.references,
            };
            parsedData.push(parse_log);
          }
          return parsedData;
        };

        let formatedDetectedThreatData = [];

        function ldata(response) {
          let main_data = response?.['Real-time Scan'];

          for (let index = 0; index < main_data?.length; index++) {
            for (let l = 0; l < main_data[index]?.detection?.length; index++) {
              // const threat = main_data[index]?.detection?.[l]
              const body = {
                id: main_data[index]?.id,
                detection: main_data[index]?.detection[l],
                request_status: main_data[index]?.request_status,
                suspicious_requests: main_data[index]?.suspicious_requests,
              };
              formatedDetectedThreatData.push(body);
            }
          }
        }
        ldata(response);

        const dynamic_result = parseDynamic(
          response?.results?.['Dynamic Scan']
        );
        const static_result = parseConformance(
          response?.results?.['Conformance Scan']
        );
        const vul_array = [...dynamic_result, ...static_result];

        return {
          securityScanData: vul_array,
          detectedThreatsData: formatedDetectedThreatData,
        };
      },
      providesTags: ['EndPointScans'],
    }),

    blockIpAdress: build.mutation({
      query: ({ ipAddress, collectionId }) => ({
        url: 'configure-waf/',
        method: 'POST',
        body: {
          block_ip: ipAddress,
          collection: collectionId,
        },
      }),
    }),

    getAllEndpoints: build.query({
      query: ({ collection_id, status, page }) =>
        `endpoints/?collection_id=${collection_id}&status=${status}&page=${page}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.results?.map(({ id }) => ({
                type: 'EndpointDetails',
                id,
              })),
              'EndpointDetails',
            ]
          : ['EndpointDetails'],
      // providesTags: ['EndpointDetails'],
    }),

    deleteMultipleEndpoints: build.mutation({
      query: ({ collectionId, body }) => ({
        url: `delete-multiple/${collectionId}/`,
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: ['EndpointDetails'],
    }),

    getEndpointDetection: build.query({
      query: ({collection_id}) =>
        `endpoint-detection/group_by_attack_type/?collection_id=${collection_id}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.map(({ id }) => ({
                type: 'EndpointDetected',
                id,
              })),
              'EndpointDetected',
            ]
          : ['EndpointDetected'],
    }),

    getEndpointDetectionFilter: build.query({
      query: ({collection_id, attack_type, severity}) =>
        `endpoint-detection/filter_top_20/?collection_id=${collection_id}&attack_type=${attack_type}&severity=${severity}`,  
    }),

    /// error from the server
    getEndpointActivity: build.query({
      query: (endpointId) => `path_activity/?path_token=${endpointId}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.results?.map(({ id }) => ({
                type: 'EndpointActivity',
                id,
              })),
              'EndpointActivity',
            ]
          : ['EndpointActivity'],
    }),

    getEndpointStatusCount: build.query({
      query: ({ collection_id }) =>
        `endpoints_status_count/?collection_id=${collection_id}`,
    }),

    getFilteredLogData: build.query({
      query: ({
        startTime,
        endTime,
        ipAddress,
        vpn,
        threatLevel,
        ipLocation,
        suspicious,
        responseCode,
        unreported,
        reported,
        requestStatus,
        botStatus,
        collectionId,
        page,
      }) =>
        `threat_monitoring/?ip_address=${ipAddress}&vpn=${vpn}&threat_level=${threatLevel}&suspicious=${suspicious}&response_code=${responseCode}&unreported=${unreported}&reported=${reported}&request_status=${requestStatus}&bot_status=${botStatus}&collection_id=${collectionId}&ipLocation=${ipLocation}&start_time=${startTime}&end_time=${endTime}&page=${page}`,
      providesTags: ['FilteredLogData'],
    }),

    getFilterEndpointActivity: build.query({
      query: (endpointId, start, end) =>
        `path_activity/?path_token=${endpointId}&start_time=${start}&end_time=${end}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.map(({ id }) => ({
                type: 'FilterEndpointActivity',
                id,
              })),
              'FilterEndpointActivity',
            ]
          : ['FilterEndpointActivity'],
    }),

    getPaginatedEndpointActivity: build.query({
      query: (endpointId, pageNumber) =>
        `path_activity/?path_token=${endpointId}?page=${pageNumber}`,
    }),

    getThreatActivity: build.query({
      query: (endpointId) => `threat_monitoring/${endpointId}/`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.map(({ id }) => ({
                type: 'ThreatActivity',
                id,
              })),
              'ThreatActivity',
            ]
          : ['ThreatActivity'],
    }),

    getAutomatedScanDetails: build.query({
      query: ({ collectionId }) =>
        `automated_test/?collection_id=${collectionId}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.test_results?.map(({ id }) => ({
                type: 'PerformanceTests',
                id,
              })),
              'PerformanceTests',
            ]
          : ['PerformanceTests'],
    }),

    getPaginatedAutomatedScanDetails: build.query({
      query: ({ collectionId, page }) =>
        `automated_test/?collection_id=${collectionId}&page=${page}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.test_results?.map(({ id }) => ({
                type: 'PerformanceTests',
                id,
              })),
              'PerformanceTests',
            ]
          : ['PerformanceTests'],
    }),

    runScans: build.mutation({
      query: (body) => ({
        url: `r_automated_test/`,
        method: 'POST',
        body: body,
      }),

      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.test_results?.map(({ id }) => ({
                type: 'AutomatedTests',
                id,
              })),
              'AutomatedTests',
            ]
          : ['AutomatedTests'],
    }),

    runOneScan: build.mutation({
      query: (body) => ({
        url: `r_automated_test/`,
        method: 'POST',
        body: body,
      }),

      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.Result?.map(({ id }) => ({
                type: 'OneScanAutomatedTests',
                id,
              })),
              'OneScanAutomatedTests',
            ]
          : ['OneScanAutomatedTests'],
    }),

    updateScan: build.mutation({
      query: ({ testCaseId, body }) => ({
        url: `update_test_case/${testCaseId}/`,
        method: 'PATCH',
        body: body,
      }),
    }),

    getUserInCollection: build.query({
      query: ({ collectionId }) =>
        `get-user-in-collection/?collection=${collectionId}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.map(({ id }) => ({
                type: 'UserInCollection',
                id,
              })),
              'UserInCollection',
            ]
          : ['UserInCollection'],
    }),

    getThreatMapData: build.query({
      query: ({ collectionId, threatMapPage }) =>
        `threat-map/?collection=${collectionId}`,
      providesTags: ['ThreatMap'],
    }),

    getTopCountriesAttachers: build.query({
      query: ({ collectionId }) => `top_attacks/${collectionId}/`,
      providesTags: ['TopCountriesAttachers'],
    }),

    getTopAttackTypes: build.query({
      query: ({ collectionId }) => `top_attack_types/${collectionId}/`,
      providesTags: ['TopAttackTypes'],
    }),

    getSuspiciousIps: build.query({
      query: ({ collectionId }) => `suspicious_ips/${collectionId}/`,
      providesTags: ['SuspiciousIps'],
    }),

    getRecentRuns: build.query({
      query: (collectionId) => `get_recent_runs/?collection_id=${collectionId}`,
      providesTags: ['RecentRuns'],
    }),

    getTestReport: build.query({
      query: ({ collectionId, duration }) =>
        `get_test_charts/?collection_id=${collectionId}&env=prod&number_of_days=${duration}`,
      providesTags: ['TestReport'],
    }),

    getAllTestCases: build.query({
      query: ({ collectionId, pageNumber }) =>
        `all_test_cases/?collection_id=${collectionId}&page=${pageNumber}`,
      providesTags: ['AllTestCases'],
    }),

    getTestCase: build.query({
      query: ({ collectionId, duration }) =>
        `get_chart_highlighted_cases/?collection_id=${collectionId}&number_of_days=${duration}&env=prod`,
      providesTags: ['TestCase'],
    }),

    queryTestCaseByPath: build.mutation({
      query: ({ collectionId, body }) => ({
        url: `query_testcase/?collection_id=${collectionId}&page=${1}`,
        method: 'POST',
        body,
      }),

      providesTags: ['QueryTestCaseByPath'],
    }),

    updateHighlightTestCase: build.mutation({
      query: ({ collectionId, body }) => ({
        url: `highlight_testcase/?collection_id=${collectionId}`,
        method: 'POST',
        body,
      }),

      providesTags: ['UpdateHighlightTestCase'],
    }),

    configurePerformanceTests: build.mutation({
      query: (body) => ({
        url: 'automated_test_configs/',
        method: 'POST',
        body,
      }),

      providesTags: ['ConfigurePerformanceTests'],
    }),

    getVulnerabilityCounts: build.query({
      query: (collectionId) => `vulnerability_counts/${collectionId}/`,
      providesTags: ['VulnerabilityCounts'],
    }),

    getSuspiciousDynamicPerYear: build.query({
      query: (collectionId) => `suspicious_dynamic_per_year/${collectionId}/`,
      providesTags: ['SuspiciousDynamicPerYear'],
    }),

    getCriticalVulnerability: build.query({
      query: ({ collectionId, criticalPageNum }) =>
        `dynamic_critical/${collectionId}/?page=${criticalPageNum}`,
      providesTags: ['CriticalVulnerability'],
    }),

    getHighVulnerability: build.query({
      query: ({ collectionId, highPageNum }) =>
        `dynamic_high/${collectionId}/?page=${highPageNum}`,
      providesTags: ['HighVulnerability'],
    }),

    getMediumVulnerability: build.query({
      query: ({ collectionId, mediumPageNum }) =>
        `dynamic_medium/${collectionId}/?page=${mediumPageNum}`,
      providesTags: ['MediumVulnerability'],
    }),

    getLowVulnerability: build.query({
      query: ({ collectionId, lowPageNum }) =>
        `dynamic_low/${collectionId}/?page=${lowPageNum}`,
      providesTags: ['LowVulnerability'],
    }),

    getSingleVulnerabilityDetails: build.query({
      query: ({ collectionId, threatName, page }) =>
        `attack-group-summary/?collection_id=${collectionId}&threat_name=${threatName}&page=${page}`,
      providesTags: ['SingleVulnerabilityDetails'],
    }),
  }),
});

export const {
  useGetEndpointActivityQuery,
  useGetEndPointScansQuery,
  useGetFilterEndpointActivityQuery,
  useGetThreatActivityQuery,
  useBlockIpAdressMutation,
  useGetFilteredLogDataQuery,
  useGetAutomatedScanDetailsQuery,
  useGetPaginatedAutomatedScanDetailsQuery,
  useRunScansMutation,
  useRunOneScanMutation,
  useUpdateScanMutation,
  useGetUserInCollectionQuery,
  useGetThreatMapDataQuery,
  useGetTopCountriesAttachersQuery,
  useGetTopAttackTypesQuery,
  useGetSuspiciousIpsQuery,
  useGetRecentRunsQuery,
  useGetTestReportQuery,
  useUpdateHighlightTestCaseMutation,
  useGetAllTestCasesQuery,
  useGetTestCaseQuery,
  useQueryTestCaseByPathMutation,
  useConfigurePerformanceTestsMutation,
  useGetSuspiciousDynamicPerYearQuery,
  useGetVulnerabilityCountsQuery,
  useGetCriticalVulnerabilityQuery,
  useGetHighVulnerabilityQuery,
  useGetMediumVulnerabilityQuery,
  useGetLowVulnerabilityQuery,
  useGetEndpointStatusCountQuery,
  useGetAllEndpointsQuery,
  useDeleteMultipleEndpointsMutation,
  useGetEndpointDetectionQuery,
  useGetEndpointDetectionFilterQuery,
  useGetSingleVulnerabilityDetailsQuery,
} = endPointScanApi;
