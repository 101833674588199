import React, { useEffect, useState } from 'react';
import forgotPassword from '../../../../assets/images/auth/forgot-password-bg.png';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useResetPasswordMutation } from '../../../../redux/services/mfaAuthApi';

export const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const [resetPassword, { data, isLoading, isSuccess, error }] =
    useResetPasswordMutation();

  useEffect(() => {
    if (data || isSuccess) {
      localStorage.setItem('resetPasswordEmail', email);
      toast('Successful');
      history.push('/new-password');
    }

    if (error) {
      const errMessage = error?.data?.Message || 'An error occurred';
      toast(errMessage);
    }
  }, [error, data, isLoading, isSuccess, history, email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (!email) {
      toast('Email is required');
      return;
    }

    try {
      await resetPassword({
        email,
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-[38px] w-full h-full items-center px-4 md:px-[38px]  bg-white">
      <div className="w-full h-[420px] hidden md:block  rounded-[58px]">
        <img className="w-full h-full " src={forgotPassword} alt="Img" />
      </div>
      <div className="grid-rows-3 mb-auto  md:my-auto">
        <div className="mb-[47px] mt-14 md:mt-0">
          <h1 className="text-sky-950 text-[20px] md:text-[28px] font-semibold font-['Nunito Sans']">
            Forgot Password
          </h1>
          <p className="text-sky-950 text-sm md:text-base font-light font-['Noto Sans JP']">
            Enter registered email, we will send you a link to enter new
            password
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-[35px] mt-20 md:mt-0">
            <label
              htmlFor="email"
              className="text-sky-950 text-sm md:text-lg font-normal font-['Noto Sans JP']"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-[50px] bg-sky-100 rounded-lg shadow border-2 border-sky-950 border-opacity-10"
            />
          </div>

          <button
            type="submit"
            disable={isLoading}
            className="w-full h-[50px] mt-20 bg-sky-950 rounded shadow flex justify-center items-center border-none focus:outline-none"
          >
            <div className="text-white text-sm font-bold font-['Noto Sans JP']">
              {isLoading ? 'Loading...' : 'Submit'}
            </div>
          </button>
        </form>
      </div>
    </div>
  );
};
