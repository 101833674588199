import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  ProgressBar,
  Popover,
  PopoverBody,
  OverlayTrigger,
} from "react-bootstrap";
import { CreateProject } from "./create_project";
import { useHistory } from "react-router-dom";
import { Setup_Environment } from "./setup_environment";
import { ConnectApps } from "./connect_apps";
import { ThreatMonitoring } from "./threat_monitoring";
import github from "../../../../assets/images/github.png";
// import slack from "../../../../assets/images/slack-img.png";
import UploadCollection from "./upload_collection";
import { useLocation } from 'react-router-dom';
import { useSetUpCodeReviewMutation } from "../../../../redux/services/codeAnalysisApi";
import { Threats } from "./threats";

const NewProject = () => {
  const [step, setStep] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [manualUpload, setManualUpload] = useState(null)
  const [githubStatus, setGithubStatus] = useState(false)
  const [setupCodeReview] = useSetUpCodeReviewMutation();
  const [warning] = useState("");
  const history = useHistory();
  const location = useLocation();

  const handleNext = () => {
    if (manualUpload === false) {
      setStep(step + 2)
      setManualUpload(null)
    }
    else{
    setStep(step + 1);
    }
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value
      if (key === "step") {
        setStep(parseInt(params[key]))
      }
      // http://localhost:3000/dashboard/new-project?installation_id=48483807&setup_action=update&step=4"
      if (key === "installation_id") {
        setupCodeReview({"installation_id": params[key], "collection_id": localStorage.getItem("collection_id")})
        setDisabled(false)
        setGithubStatus(true)
      }
    }
}, [location.search, setupCodeReview]);
  const popover = (
    <Popover>
      <PopoverBody className="p-3">
        <p>
          We won’t be able to review your source code and third party
          application dependencies. Do you want to continue?
        </p>
        <div className="flex items-center space-x-1">
          <Button
            variant="secondary"
            onClick={() => {
              handleNext();
              setDisabled(true);
            }}
            className="w-[130px]"
          >
            I will do this later
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              setDisabled(true);
            }}
            className="w-[80px]"
          >
            Connect
          </Button>
        </div>
      </PopoverBody>
    </Popover>
  );

  return (
    <Form>
      <p className="text-secondary text-right"> Step ({step}/5)</p>
      <ProgressBar now={(step / 5) * 100} />
      {step === 1 && <CreateProject setDisabled={setDisabled} setManualUpload={setManualUpload} />}
      {step === 2 && <UploadCollection setDisabled={setDisabled} />}
      {step === 3 && <Setup_Environment setDisabled={setDisabled} />}
      {step === 4 && (
        <ConnectApps
          description={null}
          image={github}
          btnName={githubStatus ? "Organization Github Account Connected Successfully" : "Connect Your Organization Github Account"}
          input1={"Enter Git Url"}
          input2={"Enter Git Token"}
          setDisabled={setDisabled}
          btn={true}
          form={false}
          githubStatus = {githubStatus}
        />
      )}
      {/* {step === 5 && (
        <ConnectApps
          description={
            "Do you want to receive slack notification whenever there is an activity on your project?"
          }
          image={slack}
          input1={"Enter Preferred Slack Channel"}
          input2={"Enter Slack Token"}
          btn={false}
          form={true}
          setDisabled={setDisabled}
        />
      )} */}
      {step === 5 && <Threats setDisabled={setDisabled} />}
      <div
        className={
          step === 1 ? "text-right mb-4" : "d-flex justify-content-between mb-4"
        }
      >
        {step > 1 && (
          <Button
            variant="secondary"
            onClick={handlePrevious}
            className="w-[100px]"
          >
            Previous
          </Button>
        )}

        {step < 5 ? (
           step === 4 || step === 2 ? (
            <div>
              <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    // handleNext();
                    // setDisabled(true);
                  }}
                  className="w-[100px]"
                  // disabled={disabled}
                >
                  Skip
                </Button>
              </OverlayTrigger>

              <Button
                variant="secondary"
                onClick={() => {
                  handleNext();
                  // setDisabled(true);
                }}
                className="w-[100px]"
                disabled={disabled}
              >
                Next
              </Button>
            </div>
          ) : (
            <Button
              variant="secondary"
              onClick={() => {
                handleNext();
                setDisabled(true);
              }}
              className="w-[100px]"
              disabled={disabled}
            >
              Next
            </Button>
          )
        ) : (
          <Button
            variant="secondary"
            onClick={() => {
              history.push("/dashboard/collections");
              setDisabled(true);
            }}
            className="w-[100px]"
            disabled={disabled}
          >
            Finish
          </Button>
        )}
      </div>
    </Form>
  );
};

export default NewProject;
