import React, { useEffect, useState } from "react";
import { useRunDynamicScanMutation } from "../../../redux/services/collectionApi";
import { useRunCodeReviewMutation } from "../../../redux/services/codeAnalysisApi";
import { Badge } from "react-bootstrap";

const Tasks = ({ analytics }) => {
  const allTasks = analytics;
  const openTasks = analytics?.filter((item) => item.value === false);
  const [data, setData] = useState([]);
  const [link, setLink] = useState();

  useEffect(() => {
    setData(allTasks);
  }, [analytics]);

  const [runDynamicScan] = useRunDynamicScanMutation();
  const [runCodeReview] = useRunCodeReviewMutation();
  const collectionId = localStorage.getItem("collection_id");

  const resolveTasks = (item) => {
    if (item.label === "mfa_setup" && item.value === false) {
      setLink("/dashboard/configuration");
    }
    if (item.label === "sensitive_endpoints_marked" && item.value === false) {
      setLink("/dashboard/mark_sensitive_endpoints");
    }
    if (item.label === "restricted_endpoints_marked" && item.value === false) {
      setLink("/dashboard/mark_sensitive_endpoints");
    }
    if (item.label === "dynamic_scans_ran" && item.value === false) {
      runDynamicScan({
        collection_id: collectionId,
        environment: "prod",
        type: "deep",
      });
    }
    if (
      item.label === "no_severe_vulnerabilities_found" &&
      item.value === false
    ) {
      setLink("/dashboard/vulnerability");
    }
    if (
      item.label === "no_medium_vulnerabilities_found" &&
      item.value === false
    ) {
      setLink("/dashboard/vulnerability");
    }
    if (item.label === "no_low_vulnerabilities_found" && item.value === false) {
      setLink("/dashboard/vulnerability");
    }
    if (item.label === "no_vulnerabilities_found" && item.value === false) {
      setLink("/dashboard/vulnerability");
    }

    if (item.label === "source_code_review_ran" && item.value === false) {
      runCodeReview({ collection: collectionId });
    }

    if (item.label === "no_severe_code_issues" && item.value === false) {
      setLink("/dashboard/code-reviews");
    }
    if (item.label === "no_medium_code_issues" && item.value === false) {
      setLink("/dashboard/code-reviews");
    }
    if (item.label === "no_low_code_issues" && item.value === false) {
      setLink("/dashboard/code-reviews");
    }
    if (item.label === "no_code_issues" && item.value === false) {
      setLink("/dashboard/code-reviews");
    }
    if (item.label === "pentest_done" && item.value === false) {
      setLink("dashboard/penetration-test");
    }
    if (item.label === "dependency_scan_ran" && item.value === false) {
      setLink("/dashboard/dependencies");
    }
    if (item.label === "no_severe_dependencies" && item.value === false) {
      setLink("/dashboard/dependencies");
    }
    if (item.label === "no_medium_dependencies" && item.value === false) {
      setLink("/dashboard/dependencies");
    }
    if (item.label === "no_low_dependencies" && item.value === false) {
      setLink("/dashboard/dependencies");
    }
    if (item.label === "no_dependencies" && item.value === false) {
      setLink("/dashboard/dependencies");
    }
    if (item.label === "environment_setup" && item.value === false) {
      setLink("/dashboard/configuration");
    }
    if (item.label === "prod_env_configured" && item.value === false) {
      setLink("/dashboard/configuration");
    }
    if (item.label === "configured_code_review" && item.value === false) {
      setLink("/dashboard/configuration");
    }
    if (item.label === "enabled_dependency_checks" && item.value === false) {
      setLink("/dashboard/configuration");
    } else {
      return;
    }
  };

  const subData = data?.filter((item)=>item.label != 'sensitive_endpoints_marked' &&  item.label != 'restricted_endpoints_marked')

  return (
    <div className="card rounded-lg px-[10px] py-[14px] my-4 border-teal-800">
      <div className="mb-3">
        <button
          className=" bg-[#023673] focus:bg-[#09294D] px-2 py-1 text-white outline-none border border-[#023673] rounded mr-3"
          onClick={() => setData(allTasks)}
        >
          All Tasks
        </button>
        <button
          className=" bg-[#023673] focus:bg-[#09294D] px-2 py-1 text-white outline-none border border-[#023673] rounded"
          onClick={() => setData(openTasks)}
        >
          Open Tasks
        </button>
      </div>

      <div className="relative overflow-x-auto">
        <table className="w-full bg-white">
          <thead>
            <tr className="text-[#09294d] bg-[#00000010]">
              <th scope="col" className="px-6 py-3 text-nowrap">
                Task
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Risk Level
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Resolve
              </th>
            </tr>
          </thead>
          <tbody className="bg-white text-[14px]">
            {subData?.map((item, key) => (
              <tr className="text-[#09294d]" key={item.id}>
                <td className="px-6 py-[10px] text-nowrap">{item.name}</td>
                <td className="px-6 py-[10px] text-nowrap">
                  {item.value === true ? (
                    <span className="mdi mdi-check-circle text-success "></span>
                  ) : (
                    <span className="mdi mdi-close-circle text-danger"></span>
                  )}
                </td>
                <td className="px-6 py-[10px] text-nowrap font-bold">
                  <Badge
                    className={
                      item.risk_level === "High "
                        ? "bg-danger text-white w-[100px]"
                        : item.risk_level === "Critical"
                        ? "bg-danger text-white w-[100px]"
                        : item.risk_level === "Medium"
                        ? "bg-warning text-white w-[100px]"
                        : "bg-info text-white w-[100px]"
                    }
                  >
                    {item.risk_level}
                  </Badge>
                </td>
                <td className="px-6 py-[10px] text-nowrap">
                  <a href={link}>
                    <button
                      className="btn btn-primary md:py-2 py-1 text-[14px]"
                      onClick={() => resolveTasks(item)}
                      disabled={item.value}
                    >
                      Resolve
                    </button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Tasks;
