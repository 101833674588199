import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';

const Checkbox = ({
  data,
  setData,
  setUpdateHighlightTestCaseBody,
  updateHighlightTestCaseBody,
}) => {
  const [checkboxData, setCheckboxData] = useState([]);

  useEffect(() => {
    setCheckboxData(data);
  }, [data]);

  const formatPath = (path) => {
    if (path && path?.length > 32) return `${path?.substring(0, 31)}...`;
    return path;
  };

  const isSelectedTestCase = (item) => {
    const existingItem = updateHighlightTestCaseBody.find(
      (queryItem) => queryItem.test_case_id === item.id
    );

    if (!existingItem) return false;

    const originalItem = data.find((i) => i.id === item.id);

    if (originalItem.is_highlighted !== existingItem.is_highlighted)
      return true;

    return false;
  };

  const handleChange = (item) => {
    const modifiedData = checkboxData.map((dataItem) => {
      if (dataItem?.id === item?.id) {
        return {
          ...dataItem,
          is_highlighted: !dataItem?.is_highlighted,
        };
      }
      return dataItem;
    });

    setCheckboxData(modifiedData);
    setData(modifiedData);

    const existingItem = updateHighlightTestCaseBody.find(
      (queryItem) => queryItem.test_case_id === item.id
    );

    const originalItem = data.find((i) => i.id === item.id);

    if (!existingItem || originalItem.is_highlighted !== item.is_highlighted) {
      setUpdateHighlightTestCaseBody((prev) => {
        const updatedBody = [
          ...prev.filter((queryItem) => queryItem.test_case_id !== item.id),
        ];

        if (!existingItem) {
          updatedBody.push({
            test_case_id: item.id,
            is_highlighted: !item.is_highlighted,
          });
        }

        return updatedBody;
      });
    }
  };

  return (
    <>
      {checkboxData?.map((item) => (
        <div key={item?.id} className="flex items-center mb-3 justify-between">
          <div className="flex items-center ">
            <div className="cursor-pointer">
              {' '}
              <input
                // checked={isSelectedTestCase(item)}
                id={`checkbox-${item?.id}`}
                type="checkbox"
                value={item?.is_highlighted}
                onChange={() => handleChange(item)}
                className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <label
              htmlFor={`checkbox-${item.id}`}
              className="ms-2  cursor-pointer text-sm mr-3 font-medium text-primary"
            >
              {`(${item?.methods}) ${formatPath(item?.path?.path)}`}
            </label>
          </div>

          <div className="flex items-center pr-3">
            <div className="flex items-center">
              {item?.is_highlighted ? (
                 <Badge
                 className={
                     "bg-primary text-white p-2"
                 }
               >
                 highlighted
               </Badge>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Checkbox;
