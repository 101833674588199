// import { Label } from "@headlessui/react/dist/components/label/label";


export const makeCurvePlotReady = (arr)=> {
    try{

        if (arr.length === 0) {
          return undefined
        }
        if (arr.length === 1) {
    
          return[ {
            id:"series1",
            data: [
                { x: 0, y: 0 }, 
                { x: arr[0][0], 
                y: arr[0][1] }
        ]
        }
      ];
    
        } else {
          return [{
            id : "series1",
            data:arr.map(([x, y]) => ({ x, y }))
          }
        ]
        }
      
    }catch(e){
        console.log("err")
    }
  }

export const makeCurvePlotReadyThreatAnalytics = (arr)=> {
    try{

        if (arr.length === 0) {
          return undefined
        }
        if (arr.length === 1) {
    
          return[ {
            id:"series1",
            data: [
                { x: 0, y: 0 }, 
                { x: arr[0].hour, 
                y: arr[0].request_rate }
        ]
        }
      ];
    
        } else {
          return [{
            id : "series1",
            data:arr.map((x) => ({ x : x.hour, y: x.request_rate }))
          }
        ]
        }
      
    }catch(e){
        console.log("err")
    }
  }



  export const parseHeaderString = (str)=>{
    if(str === ""){

      return ['','']
    }

    const fstring = str.split('\r')[0];
    const newText = str.replace(fstring, '');
    return  [fstring, `${newText}`]



  }


export const parseEndpintDetails = (data)=>{

  const parsedData = data.map((item)=>(

        {
            path:item.path,
          endpoint:item.path,
          method:item?.method,
          confidence_level:"low",
          risk_level:item?.vulnerability_detail?.[0]?.risk_level,
          vulnerability_rate:item?.vulnerability_detail?.[0]?.vulnerabilities_found,
          is_login:item?.is_login,
          description:item.description,
          allowed_response_codes:item.allowed_response_codes,
          critical_issues:data?.itemconformance_details,
          operational_issues:0,
          security_issues:0,
          risk_level_:0,
          vulnerabilities_found: item?.vulnerabilities_found,
          response_code:500,
          response_time:0,
          status:item?.status,
          action:0
      
      
      }

    

  ))

  return parsedData
}




export const parseAllString=(text)=>(
  `${parseHeaderString(text)[0]} \n { 
    ${parseHeaderString(text)[1]} \n }`
)



export const pieChartParser=(val)=>{
  if (val?.length > 0){
    return(
        val.map((item)=>(
        
          {
            "id": item[0],
            "label": item[0],
            "value": item[1],
            "color": "hsl(201, 70%, 50%)"
          }
      
        ))

    )
  }
    
  else return []
  
  }



