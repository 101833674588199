import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import warning from '../../../assets/images/warning.png';

function DeleteItemModal({ disabled, onDelete, checkedValues }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <button
        type="button"
        className="btn btn-outline-danger btn-icon-text float-right"
        onClick={() => { handleShow() }}
        disabled={disabled || checkedValues.length === 0}
      >
        <i className="mdi mdi-delete btn-icon-prepend"></i>
        Delete
      </button>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title className="red-test text-primary">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='md:w-[10%] w-[30%] mx-auto'>
            <img src={warning} alt="warning alert" width="100%" />
          </div>
          <h2 className="text-center text-primary">
            Are you sure?
          </h2>
          <h4 className="text-center text-primary">
            This action cannot be undone
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={()=>{onDelete(); handleClose()}}
          >
            Proceed
          </Button>
          <Button variant="primary" onClick={handleClose} >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteItemModal;
