import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { authApi } from './services/authApi';
import { mfaAuthApi } from './services/mfaAuthApi';
import mfaAuthSlice from './features/mfaAuthSlice';
import { collectionApi } from './services/collectionApi';
import { codeAnalysisApi } from './services/codeAnalysisApi';
import { collectionSlice } from './features/collectionSlice';
import { endPointScanApi } from './services/endPointScansApi';
import { notificationsApi } from './services/notificationsApi';
import authSlice from './features/authSlice';
import performanceTestDaysSlice from './features/performanceTestDaysSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    mfa: mfaAuthSlice,
    performanceTestDays: performanceTestDaysSlice,
    collection: collectionSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [mfaAuthApi.reducerPath]: mfaAuthApi.reducer,
    [collectionApi.reducerPath]: collectionApi.reducer,
    [codeAnalysisApi.reducerPath]: codeAnalysisApi.reducer,
    [endPointScanApi.reducerPath]: endPointScanApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      mfaAuthApi.middleware,
      collectionApi.middleware,
      codeAnalysisApi.middleware,
      endPointScanApi.middleware,
      notificationsApi.middleware
    ),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);
