import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-dark.png';
import { useAuth } from '../../providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Register = () => {
  const history = useHistory();
  const { register, isAuthenticated } = useAuth();
  const [username, updateUsername] = useState('');
  const [password, updatePassword] = useState('');
  const [email, updateEmail] = useState('');
  const [cpassword, updateCPassword] = useState('');
  const [pError, setPError] = useState(null);
  const [cError, setCError] = useState(null);

  if (isAuthenticated()) {
    history.push('/analytics-page');
  }

  function validateEmail(email) {
    // Regular expression to check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email is valid
    if (emailRegex.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  function validatePassword(password) {
    // Regular expressions to check for different password criteria
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    // Check if password meets all criteria
    if (
      password.length >= 8 &&
      uppercaseRegex.test(password) &&
      lowercaseRegex.test(password) &&
      numberRegex.test(password) &&
      specialCharRegex.test(password)
    ) {
      return true;
    }

    return false;
  }

  const validate = async (e, username, email, password, cpassword) => {
    e.preventDefault();
    if (
      validateEmail(email) &&
      validatePassword(password) &&
      password === cpassword
    ) {
      const { success, error } = await register(
        username,
        email,
        password,
        cpassword
      );
      if (success) {
        toast('Registration Successful');
      } else {
        toast(error);
      }
    } else {
      if (!validatePassword(password)) {
        setPError(
          'Password must be at least 8 character, contains atleast: one uppercase, one lowercase, one number, and one special character'
        );
      }
      if (!validateEmail) {
        setCError('Passwords does not match');
      }
    }
  };

  return (
    <div>
      <div
        className="brand-logo text-center"
        style={{ width: '10%', margin: '5px 0' }}
      >
        <img src={logo} alt="logo" width="30%" />
      </div>
      <div
        className="d-flex align-items-center auth px-0"
        // style={{ background: "linear-gradient(to right, #4465be, #09294d)" }}
      >
        <ToastContainer />
        <div className="row w-100 mx-0">
          <div className="col-lg-8 mx-auto row">
            <div className="col-lg-6 register-background">
              <div className="row flex-direction-column"></div>
            </div>
            <div className="col-lg-6">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h4 className="text-primary">New here?</h4>
                <h6 className="font-weight-light text-primary">
                  Signing up is easy. It only takes a few steps
                </h6>
                <form
                  className="pt-3"
                  onSubmit={(e) =>
                    validate(e, username, email, password, cpassword)
                  }
                >
                  <label className="text-primary">Username</label>
                  <Form.Group className="d-flex search-field mb-4">
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      size="lg"
                      className="h-auto"
                      onChange={(e) => updateUsername(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <label className="text-primary">Email Address</label>

                  <Form.Group className="d-flex search-field mb-4">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      size="lg"
                      className="h-auto"
                      onChange={(e) => updateEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <label className="text-primary">Password</label>

                  <div className="form-group mb-4">
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      onChange={(e) => {
                        updatePassword(e.target.value);
                        setPError(null);
                      }}
                      required
                    />
                    {pError ? <p className="text-danger">{pError}</p> : null}{' '}
                  </div>
                  <label className="text-primary">Confirm Password</label>

                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="exampleInputPassword2"
                      placeholder="Confirm Password"
                      onChange={(e) => {
                        updateCPassword(e.target.value);
                        setCError(null);
                      }}
                      required
                    />
                    {pError ? <p className="text-danger">{cError}</p> : null}{' '}
                  </div>
                  <div className="mb-4">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          required
                        />
                        <i className="input-helper"></i>I agree to all{' '}
                        <Link to="/terms_conditions" className="text-primary">
                          {' '}
                          Terms and Conditions{' '}
                        </Link>
                      </label>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Button
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      type="submit"
                    >
                      SIGN UP
                    </Button>
                  </div>
                  {/* <div className="text-center mt-4 font-weight-light">
                    Already have an account?{' '}
                    <Link to="/login" className="text-primary">
                      Login
                    </Link>
                  </div> */}
                  <div className="text-center mt-4 font-weight-light">
                    Already have an account?{' '}
                    <Link to="/login" className="text-primary">
                      Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
