import React, { useEffect, useState } from "react";
import {
  useConfigureWafMutation,
  useDeleteBlockedIpAddressesMutation,
  useGetBlockedIpAddressesQuery,
} from "../../../redux/services/collectionApi";
import { MdDelete } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";
import ActionModal from "../modals/ActionModal";
import { toast } from "react-toastify";

const BlockedIps = () => {
  const [data, setData] = useState([]);
  const [ipAddress, setIpAddress] = useState("");
  const [ip_id, setId] = useState("");

  const collectionId = localStorage.getItem("collection_id");
  const { data: blockedIp } = useGetBlockedIpAddressesQuery({ collectionId });
  const [configureWaf] = useConfigureWafMutation();
  const [deleteBlockedIp] = useDeleteBlockedIpAddressesMutation();
  useEffect(() => {
    setData(blockedIp);
    setIpAddress(ipAddress);
  }, [blockedIp, ipAddress]);

  const results = data?.results;
  console.log(results);
  console.log(ipAddress);

  // const unblockIP = async () => {
  //   try {
  //     await configureWaf({
  //       body: {
  //         allow_ip: ipAddress,
  //         collection: collectionId,
  //       },
  //     }).unwrap();
  //     toast.success("Successful");
  //   } catch (error) {
  //     console.error("Failed:", error);
  //     toast.error("Failed to unblock");
  //   }
  // };

  const deleteIP = async () => {
    try {
      await deleteBlockedIp({
        ip_id,
        ipAddress,
      }).unwrap();
      toast.success("Successful");
    } catch (error) {
      console.error("Failed:", error);
      toast.error("Failed to delete");
    }
  };

  return (
    <div className="card rounded-lg px-[10px] py-[14px] border-teal-800">
      <h3 className="text-dark py-2">Blocked Ip Addresses</h3>

      <div className="relative overflow-x-auto">
        <table className="w-full bg-white">
          <thead>
            <tr className="text-[#09294d] bg-[#00000010]">
              <th scope="col" className="px-6 py-3 text-nowrap">
                IP Address
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Blocked by
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Timestamp
              </th>
              <th scope="col" className="px-6 py-3 text-nowrap">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white text-[14px]">
            {results?.map((item, key) => (
              <tr className="text-[#09294d]" key={item.id}>
                <td className="px-6 py-[10px] text-nowrap">{item.ipaddress}</td>
                <td className="px-6 py-[10px] text-nowrap">
                  {item.username}
                </td>
                <td className="px-6 py-[10px] text-nowrap">
                  {item.blocked_at.substring(0, 10)}
                </td>
                <td
                  className="px-6 py-[10px] text-nowrap flex items-center space-x-4"
                  onClick={() => setIpAddress(item.ipaddress)}
                >
                  <ActionModal
                    icon={<MdDelete />}
                    title="Delete IP"
                    onSubmit={deleteIP}
                    iconColor='red-500'
                    item = {item}
                    setId = {setId}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BlockedIps;
