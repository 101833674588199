import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { otherRoutes, privateRoutes } from '../AppRoutes';

export const useAuthProvider = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const token = localStorage.getItem('watoken');
  const userInfo = localStorage.getItem('wauser');
  const userSetup = localStorage.getItem('setup');
  // const expiry = userInfo ? JSON.parse(userInfo)?.expiry : null;
  const accountExpiryDate = localStorage.getItem('accountExpiry');

  const isTokenExpired = () => {
    if (!accountExpiryDate) return true;

    const setup = userSetup ? JSON.parse(userSetup) : null;
    if (!setup) return true;

    const {
      first_login,
      completed_password_reset,
      completed_set_up_mfa,
      completed_onboarding,
    } = setup;

    const expirationMillisecondsSinceEpoch = Date.parse(accountExpiryDate);
    const currentMillisecondsSinceEpoch = Date.now();

    const isUserCompletedAccountSetup =
      first_login === false &&
      completed_password_reset &&
      completed_set_up_mfa &&
      completed_onboarding;

    if (isUserCompletedAccountSetup === false) return true;

    const isExpired =
      currentMillisecondsSinceEpoch > expirationMillisecondsSinceEpoch;

    return isExpired;
  };

  useEffect(() => {
    let currentPath =
      location.pathname === '/' ? '/dashboard/collections' : location.pathname;

    const isOtherRoute = otherRoutes.includes(location.pathname);
    const isPrivateRoute = privateRoutes.includes(location.pathname);

    const checkAuthentication = () => {
      if (token && accountExpiryDate && !isTokenExpired()) {
        setIsLoading(false);
        setIsAuthenticated(true);
        if (location.pathname === '/') {
          history.push({
            pathname: '/dashboard/collections',
            search: location.search,
          });
        } else {
          history.push({
            pathname: location.pathname,
            search: location.search,
          });
        }
      } else {
        setIsLoading(false);
        setIsAuthenticated(false);
        if (isOtherRoute)
          history.push({
            pathname: currentPath,
            search: location.search,
          });
        else history.push('/login');
      }
    };

    checkAuthentication();
  }, []);

  return { isAuthenticated, isLoading, setIsAuthenticated };
};

export default useAuthProvider;
