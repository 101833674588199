import React from "react";
import slack from "../../../assets/images/slack-img.png";
import github from "../../../assets/images/github.png";
import ConfigureSlackModal from "../modals/configuration/configureSlack";

const Integrations = () => {
  return (
    <div>
      <h4 className="text-[#00000080]">Notifications</h4>
      <div className="flex justify-between md:px-4 sm:px-2 py-8 bg-white items-center mb-8">
        <img src={slack} alt="slack" className="md:w-[100px] w-[70px]" />
        <ConfigureSlackModal />
      </div>

      <h4 className="text-[#00000080] ">CI/CD</h4>
      <div className="flex justify-between md:px-4 sm:px-2 py-8 bg-white items-center">
        <img src={github} alt="github" className="md:w-[100px] w-[70px]" />
        <a href="https://github.com/apps/apisentry-ai/installations/select_target">
          <button className="sm:px-3 md:px-10  md:py-2 py-1 md:text-sm text-xs md:font-bold text-white bg-[#023673] rounded border-0 outline-0">
            Update
          </button>
        </a>
      </div>
    </div>
  );
};

export default Integrations;
