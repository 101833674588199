import React, { useEffect, useState } from 'react';
import './component/index.css';
import TableItems from './component/TableItems';
import TableColumnName from './component/TableColumnName';
import { useGetSingleVulnerabilityDetailsQuery } from '../../../redux/services/endPointScansApi';
import MetaDataModal from './component/MetaDataModal';

const VulnerabilityDetails = () => {
  const [page, setPage] = useState(1);
  const [threatName, setThreatName] = useState('');
  const [skipThreatQuery, setSkipThreatQuery] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const retrievedThreatName = localStorage.getItem('threat_name');
  const collectionId = localStorage.getItem('collection_id');

  const { data, error } = useGetSingleVulnerabilityDetailsQuery(
    { collectionId, threatName, page },
    { skip: skipThreatQuery }
  );

  useEffect(() => {
    if (retrievedThreatName) {
      setThreatName(retrievedThreatName);
      setSkipThreatQuery(false);
    }
  }, [retrievedThreatName]);

  useEffect(() => {
    if (data) setLoading(false);
    if (error) setLoading(false);
  }, [data, error]);

  return (
    <main className="mb-5">
      <h1 className="text-base md:text-[20px] py-4 flex flex-wrap text-black">
        {localStorage.getItem('threat_name')}
      </h1>
      <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
        <tr>
                <th scope="col" className="px-6 py-3">
                Endpoint Path                </th>
                <th scope="col" className="px-6 py-3">
                CVE Score
                </th>
                <th scope="col" className="px-6 py-3">
                CVE ID               </th>
                <th scope="col" className="px-6 py-3">
                Meta Data
                </th>
                </tr>
        </thead>
        <tbody>
        {showModal ? (
          <MetaDataModal
            showModal={showModal}
            setShowModal={setShowModal}
            modalData={modalData}
          />
        ) : null}
        {/* <div className="w-full"> */}
          <TableItems
            loading={loading}
            error={error}
            data={data}
            setPage={setPage}
            setModalData={setModalData}
            setShowModal={setShowModal}
          />
        {/* </div> */}
        </tbody>
       
      </table>
    </main>
  );
};

export default VulnerabilityDetails;
