import React, { useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useCollectionContext } from "../../providers/Collection";
import { useGetActivityLogsQuery } from "../../../redux/services/collectionApi";

export const Activities = (props) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const [end, setEnd] = useState(10);
  const { collections, selectedCollectionId } = useCollectionContext();
  const { data: activities } = useGetActivityLogsQuery({});
  let collection = collections.filter((i) => i?.collections?.id === selectedCollectionId)
  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }
  const FormatTime = (datetimeString) => {
    const date = new Date(datetimeString);

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    const formattedDate = date.toLocaleDateString("en-GB", options);

    return formattedDate; // Output: 23 April 2023, 16:00:28
  };

  return (
    <div>
       <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>User</th>
              <th>Action</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {collection[0]?.collections?.logs
              .map((log) => (
                <tr>
                  <td>{log.user}</td>
                  <td>{log.activity}</td>
                  <td>{FormatTime(log.created_at)}</td>
                </tr>
              ))
              .slice(0, end)}
          </tbody>
        </table>
        <button
          className="mt-4 btn btn-secondary"
          onClick={() => {
            setEnd(end + 5);
          }}
          disabled={end === 30 || collection[0]?.collections?.logs?.length <= end}
        >
          Show More
        </button>
      </div>
    </div>
  );
};
