import React, { useEffect, useState } from 'react'
import {CountBox,ResponsiveLinest, MylineChart, TableResponsTimePerLocation ,TableEndpointUsage,TableLocation} from "../../components"
import {fakeLinestDb, makeCurvePlotReadyThreatAnalytics} from "../../../utils"
import {useFetch} from "../../context"


// const activities = [
//   {
//     ip_address:"89:99:90:80",
//     location:"Paris"
//   },
//   {
//     ip_address:"89:10:90:80",
//     location:"Paris"
//   },
//   {
//     ip_address:"89:22:90:80",
//     location:"USA"
//   },
// ]


// const DisplaySuspicion = ({ip_address, location,icon})=>{
//   return(
        
//     <div className='flex flex-col  py-2 px-4 font-epilogue font-semibold text-gray-700'>
//       <h5>Icon. {icon}</h5>
//       <h5>ip_address:{ip_address} Location:{location}</h5>
//   </div>
    
//   )
// }



const HeaderText  = ({value})=>{

  return(
    <div className='w-full flex justify-left p-4  items-center'>
          <h4 className='text-secondary'>
            {value}
            
          </h4>
      </div>

  )

}

const Analytics = () => {
  const {getThreatMonitoringAnalytics} = useFetch()
  const  cardClassName = " bg-gray-100 border border-gray-200 rounded-lg shadow "
  const [responseTimeLocationData, setResponseTimeLocationData] = useState([])
  const [endpointUsageData, setEndpointUsageData] = useState([])
  const [topLocationData, setTopLoationData]  = useState([])
  const [LeastLocationData, setLeastLoationData]  = useState([])
  const [summary, setSummary] = useState([])
  const [summary_, setSummary_] = useState([])

  const [requestRateByTimeData, setRequestRateByTimeData ] = useState([])

  const data_ = [
    { id: "response_2xx", value: parseInt(summary_.response_2xx) },
    { id: "response_3xx", value: parseInt(summary_.response_3xx) },
    { id: "response_4xx", value: parseInt(summary_.response_4xx) },
    { id: "response_5xx", value: parseInt(summary_.response_5xx) },
  ];
  
  const parseSummary = (summ)=>{
    const values =  [
      {
        title:"Total Visits",
        value:summ?.total_visit,
        icon: "mdi mdi-account",
        theme: 'secondary'
        
      },
      {
        title:"Response Time",
        value:parseFloat(summ?.avg_response_time).toFixed(2),
        icon: "mdi mdi-clock-alert",
        theme: 'info'
      },
      {
        title:"Sucessful Requests",
        value:summ?.response_2xx,
        icon: "mdi mdi-checkbox-multiple-marked-circle-outline",
        theme: 'success'
      },
      {
        title:"Failed Requests",
        value:summ?.response_4xx,
        icon: "mdi mdi-earth-off",
        theme: 'warning'
      },
      {
        title:"Server Errors",
        value:summ?.response_5xx,
        icon: "mdi mdi-flag-variant",
        theme: 'danger'
      },
      {
        title:"Total Redirections",
        value:summ?.response_3xx,
        icon: "mdi mdi-flip-to-back",
        theme: 'primary'
      },
    ]
    return values
  }

  const [data, setData] = useState([])
    useEffect(()=>{
      const getData =  async()=>{
          const analyticsData = await getThreatMonitoringAnalytics()
          //  analyticsData && setData(analyticsDataData)
            if(analyticsData){ 
              setData(analyticsData)
              setResponseTimeLocationData(analyticsData["response_time_per_location"])
              setEndpointUsageData(analyticsData["endpoint_usage"])
              setTopLoationData(analyticsData["top_location"])
              setLeastLoationData(analyticsData["least_visited_locations"])
              setSummary(parseSummary(analyticsData["summary"][0]))
              setSummary_(analyticsData["summary"][0])
              setRequestRateByTimeData(makeCurvePlotReadyThreatAnalytics(analyticsData["request_rate_by_time"]))
              
            }

      }
      getData()
  },[])


 
  return (
    <div >
      <div className="border p-2 rounded border-danger bg-danger text-white w-100 mt-4">
          {data?.length === 0 ? "Stats are empty because no traffic has been detected on this API yet": null}
        </div>
       <div className={`hidden lg:flex flex-wrap py-3`}>

        {summary?.map((cval, index)=>(
          <div className='lg:w-[15%] ml-3' key={index}>

              <CountBox value={+cval.value} title={cval.title} icon={cval.icon} theme={cval.theme}/>
          </div>
        ))}

       </div>


       <div className='flex flex-col md:flex-row justify-between my-4  w-full gap-3'>
                <div className={`flex flex-col w-full md:w-3/5 ${cardClassName}`}>
                      <div>
                        <HeaderText value="Response Codes Summary"/>
                            
                      </div> 
                  <div>
                <ResponsiveLinest data={data_}/>
              </div>


        </div>
        <div className={`flex flex-col  w-full md:w-2/5  ${cardClassName}`}>
              <HeaderText value="Top Locations with Shorter Response Time"/>                       
              <TableResponsTimePerLocation data={responseTimeLocationData}/>    
        </div>

         

       </div>


       <div className='flex flex-col md:flex-row justify-between my-4  w-full gap-3 '>
                <div className={`flex flex-col w-full md:w-3/5 ${cardClassName}`}>
                      <div>
                        <HeaderText value="EndPoint Usage"/>
                            
                      </div> 
                  <div>
                <TableEndpointUsage data={endpointUsageData}/>
              </div>


        </div>
        <div className={`flex flex-col   w-full md:w-2/5  ${cardClassName}`}>
              <HeaderText value="Top 10 Visited Locations"/>                       
              <TableLocation  data={topLocationData}/>    
        </div>

         

       </div>


       <div className='flex flex-col md:flex-row justify-between my-4  w-full gap-3 '>
                <div className={`flex flex-col w-full md:w-1/2 ${cardClassName}`}>
                      <div>
                        <HeaderText value="Time Based Request Rates"/>
                            
                      </div> 
                  <div>
                { requestRateByTimeData?.length > 0 && <MylineChart data={requestRateByTimeData} xLabel="time (hr)"
                yLabel="frequency"/>}
              </div>


        </div>
        <div className={`flex flex-col   w-full md:w-1/2  ${cardClassName}`}>
              <HeaderText value="Least 10 Visited Locations"/>                       
              <TableLocation data={LeastLocationData}/>    
        </div>

         

       </div>

       <div className='flex flex-col md:flex-row justify-between my-4  w-full gap-3 '>
                {/* <div className={`flex flex-col w-full md:w-1/2 ${cardClassName}`}>
                      <div>
                        <HeaderText value="Suspicion activities"/>
                            
                      </div> 
                <div>

                {
                  activities.map((activitiy)=>(
                    <DisplaySuspicion icon="activity" ip_address={activitiy.ip_address} location={activitiy.location}/>
                  ))
                }  

               
                
              </div>


        </div> */}


        {/* <div className={`flex flex-col   w-full md:w-1/2  ${cardClassName}`}>
              <HeaderText value="Suspicion Report"/>                       
              {
                  activities.map((activitiy)=>(
                    <DisplaySuspicion icon="Report" ip_address={activitiy.ip_address} location={activitiy.location}/>
                  ))
              }  
    
        </div> */}

         

       </div>

      

       
       

    </div>
  )
}

export default Analytics