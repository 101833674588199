import React, { useState, useEffect } from 'react';
import LogTable from './LogTable';
import Analytics from './Analytics';
import { ConfirmProxy } from '../../API';
import { ToastContainer, toast } from 'react-toastify';
import { FrontendSetup } from './frontend_setup';

import { useHistory } from 'react-router-dom';

const Threatv2 = () => {
  const [currentView, setCurrentView] = useState('logs');
  const history = useHistory();

  useEffect(() => {
    const setup = async () => {
      const { data } = await ConfirmProxy();
      toast(data?.message);
      if (data?.message === 'Proxy is active for this collection') {
        setCurrentView('logs');
      } else if (
        data?.message ===
        'Proxy is inactive for this collection, please activate'
      ) {
        setCurrentView('test');
      } else {
        setCurrentView('integration');
      }
    };

    // setup()
  }, []);

  const setup = async (e) => {
    e.preventDefault();
    const { data } = await ConfirmProxy();
    if (data?.message === 'Collection not forwarded through proxy') {
      toast('Verification failed, no request received from your application');
    } else {
      toast('Verification successful');
      window.location.reload();
    }
  };
  return (
    <div>
      <ToastContainer />
      {/* {currentView === "integration" || currentView === "test" ? null : (
        <div className="flex flex-row justify-end">
          <MyButton
            styles={
              currentView === "logs"
                ? "btn-secondary text-white"
                : " btn btn-outline-secondary "
            }
            value="Logs"
            onClick={() => setCurrentView("logs")}
          />
          <MyButton
            styles={
              currentView === "analytics"
                ? "bg-secondary text-white "
                : " btn btn-outline-secondary  "
            }
            value="analytics"
            onClick={() => setCurrentView("analytics")}
          />
        </div>
      )} */}
      {currentView === 'integration' ? (
        <FrontendSetup />
      ) : currentView === 'test' ? (
        <div className="card  p-4 m-auto">
          <h4 className="text-danger my-4 text-center">
            No Logs Received From Your Application Yet
          </h4>
          <div className="flex border rounded w-[50%] ml-auto mr-auto justify-between mb-4">
            <p className="text-primary m-auto">I have configured logging</p>
            <button className="btn btn-secondary" onClick={setup}>
              Test Connection
            </button>{' '}
          </div>
          <div className="flex border rounded w-[50%] ml-auto mr-auto justify-between mb-4">
            <p className="text-primary m-auto">I have not configured logging</p>
            <button
              className="btn btn-primary"
              onClick={() =>
                history.push('/configuration?default=threat_monitoring')
              }
            >
              Setup Threat Monitoring Now
            </button>
          </div>
        </div>
      ) : currentView === 'logs' ? (
        <LogTable />
      ) : (
        <Analytics />
      )}
    </div>
  );
};

export default Threatv2;
