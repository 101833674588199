import React from "react";
import { StatCard } from "../performance-test/component/StatCard";
import report from "../../../assets/images/reportCard.png";
const Summary = ({ count, lastDate }) => {
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    return date.toLocaleDateString("en-US", options);
  };
  return (
    <div className="w-full">
      <p className="font-bold text-black">Summary</p>
      {/* lg:w-[300px] w-[250px] h-[150px] */}
      {/* <div className=" ">
        <StatCard
          bgColor={"#FFF4DE"}
          value={count?.unique_endpoints_count}
          title={"Total Endpoints"}
          iconColor={"#FF9478"}
          icon={true}
          report={report}
        />
      </div> */}

      <div className="py-2 pl-3  rounded-[10px] lg:w-[300px] md:w-full w-[220px]   bg-[#FFF4DE]">
        <div className="bg-[#FF9478] mb-2 w-[28px] h-[28px] md:w-[35px] md:h-[35px]  rounded-full flex justify-center">
          <img src={report} alt="report" className="p-1" />
        </div>
        <div className="text-black tracking-[0.5px] font-[800] font-[Nunito]">
          {count?.unique_endpoints_count}
        </div>

        <div className="text-black tracking-[0.5px] mb-2  leading-7 text-[14px] md:text-[16px] font-semibold font-[Nunito]">
          Total Endpoints
        </div>
        <div className="text-[#078AFD] tracking-[0.5px] leading-7 text-[12px] font-medium font-[Nunito] mr-3">
          Scanned
        </div>
      </div>

      <p className="mt-2 my-1">Last Run</p>
      <p className="text-black lg:text-[14px] text-[16px] mt-0 leading-0 font-bold">
        {lastDate ? formatDate(lastDate?.last_scan_date) : "Loading ..."}
      </p>
    </div>
  );
};

export default Summary;
