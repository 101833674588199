import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  testCaseNumberOfDays: 30,
  testReportNumberOfDays: 30,
};

const performanceTestDaysSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTestCaseNumberOfDays: (state, { payload }) => {
      state.testCaseNumberOfDays = payload;
    },

    setTestReportNumberOfDays: (state, { payload }) => {
      state.testReportNumberOfDays = payload;
    },
  },
});

export const { setTestCaseNumberOfDays, setTestReportNumberOfDays } =
  performanceTestDaysSlice.actions;

export default performanceTestDaysSlice.reducer;
