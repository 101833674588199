import React, { useEffect, useState } from "react";
import {
  useDeleteMultipleEndpointsMutation,
  useGetAllEndpointsQuery,
} from "../../../redux/services/endPointScansApi";
import DeleteItemModal from "../modals/deleteItem";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactPaginate from "react-paginate";
import "../../../assets/styles/pagination.css";
import ErrorDisplay from "../../components/Error";
import { Spinner } from "react-bootstrap";

const AllEndpoints = () => {
  const collection_id = localStorage.getItem("collection_id");
  const [allSelected, setAllSelected] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const filterEndpointDataBy = localStorage.getItem("filterEndpointDataBy");
  const [filteredData, setFilteredData] = useState([]);
  const [deleteMultipleEndpoints] = useDeleteMultipleEndpointsMutation();
  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  const [status, setStatus] = useState("");

  const { data, isLoading } = useGetAllEndpointsQuery({
    collection_id: collection_id,
    page: currentPage,
    status: status,
  });
  const totalPages = data?.total_pages;

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // Adjust for page index starting from 1
  };

  useEffect(() => {
    if (data) {
      let filtered = data.results;
      const currentDate = new Date();

      if (filterEndpointDataBy === "lessThan30Days") {
        const days30 = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        filtered = data.results.filter((item) => {
          const lastAccessedDate = new Date(item.last_accessed);
          return currentDate - lastAccessedDate < days30;
        });
      } else if (filterEndpointDataBy === "moreThan30Days") {
        const days30 = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        filtered = data.results.filter((item) => {
          const lastAccessedDate = new Date(item.last_accessed);
          return currentDate - lastAccessedDate >= days30;
        });
      }

      setFilteredData(filtered);
    }
  }, [data, filterEndpointDataBy]);

  useEffect(() => {
    setDisabled(checkedValues.length === 0);
  }, [checkedValues]);

  const selectAll = (checked) => {
    if (checked) {
      const allIds = filteredData.map((item) => item.id);
      setCheckedValues(allIds);
      setAllSelected(true);
    } else {
      setCheckedValues([]);
      setAllSelected(false);
    }
  };

  const handleCheckboxChange = (id, checked) => {
    if (checked) {
      setCheckedValues((prevCheckedValues) => {
        const newCheckedValues = [...prevCheckedValues, id];
        if (newCheckedValues.length === filteredData.length) {
          setAllSelected(true);
        }
        return newCheckedValues;
      });
    } else {
      setCheckedValues((prevCheckedValues) => {
        const newCheckedValues = prevCheckedValues.filter(
          (value) => value !== id
        );
        setAllSelected(false);
        return newCheckedValues;
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteMultipleEndpoints({
        collectionId: `${collection_id}`,
        body: {
          endpoint_ids: checkedValues,
        },
      });
      toast.success("Successful");
      setCheckedValues([]);
      setAllSelected(false);
    } catch (error) {
      console.error("Delete failed:", error);
      toast.error("Failed to delete");
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-primary">All Endpoints</h4>
        <div className="flex">
          <select
            className="border border-grey-500 rounded p-2 text-[12px] mr-2"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Filter by Threat Level</option>
            <option value="new">New</option>
            <option value="scanned">Scanned</option>
            <option value="discovered">Discovered</option>
          </select>
          <DeleteItemModal
            onDelete={onDelete}
            disabled={disabled}
            checkedValues={checkedValues}
          />
        </div>
      </div>
      {isLoading && (
        <div className="w-50 flex justify-center m-auto text-center h-[300px] pt-[50px]">
          <Spinner
            animation="border"
            variant="secondary"
            className="text-[50px]"
          />
        </div>
      )}
      {!isLoading && filteredData?.length === 0 && (
        <ErrorDisplay errorMessage="No Data Found" errorCode={404} />
      )}
      {!isLoading && filteredData?.length > 0 && (
        <table className="w-full text-sm text-left text-gray-500 mb-4">
          <thead className="text-xs text-gray-700 uppercase bg-[#0000001A]">
            <tr>
              <th scope="col" className="px-6 py-3">
                <input
                  type="checkbox"
                  id="select-checkbox"
                  checked={allSelected}
                  onChange={(e) => selectAll(e.target.checked)}
                  className="me-1 checkbox"
                />
              </th>
              {/* <th scope="col" className="px-6 py-3">
                Investigate
              </th> */}
              <th scope="col" className="px-6 py-3 max-width-column">
                Endpoint Path
              </th>
              <th scope="col" className="px-6 py-3 max-width-column">
                Method
              </th>
              <th scope="col" className="px-6 py-3">
                Is Sensitive
              </th>
              <th scope="col" className="px-6 py-3">
                Is Admin
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Vulnerability Count
              </th>
              <th scope="col" className="px-6 py-3">
                Requires Attention
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {filteredData.map((item) => (
              <tr className="border-b cursor-pointer" key={item.id}>
                <th className="px-6 py-4 text-primary">
                  <input
                    type="checkbox"
                    className="me-2 checkbox"
                    id="checkbox"
                    checked={checkedValues.includes(item.id)}
                    onChange={(e) =>
                      handleCheckboxChange(item.id, e.target.checked)
                    }
                  />
                </th>
                {/* <td className="px-6 py-4 font-medium text-primary ">
                  <button
                    className="btn btn-outline-secondary text-secondary"
                    onClick={() => {
                      localStorage.setItem("endpoint_name", item.path);
                      localStorage.setItem(
                        "selected_endpoint",
                        JSON.stringify(item)
                      );
                      history.push(`/dashboard/endpoints/${item.id}`);
                    }}
                  >
                    <i className="mdi mdi-magnify font-[12]"></i>
                  </button>
                </td> */}
                <td
                  style={{ textWrap: "wrap" }}
                  scope="row"
                  className="px-6 py-4 font-medium text-primary w-[50px] max-width-column "
                >
                  {item.path}
                </td>
                <td className="px-6 py-4 text-primary">{item.method}</td>
                <td className="px-6 py-4 text-primary">
                  {item.is_sensitive ? "True" : "False"}
                </td>
                <td className="px-6 py-4 text-primary">
                  {item.is_admin ? "True" : "False"}
                </td>
                <td className="px-6 py-4 text-primary">{item.status}</td>
                <td className="px-6 py-4 text-primary">
                  {item.vulnerability_count}
                </td>
                <td className="px-6 py-4 text-primary">
                  {item.requires_attention ? "True" : "False"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {filteredData?.length > 0 && (
        <ReactPaginate
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          activeClassName={"active-page"}
          pageCount={totalPages}
          onPageChange={handlePageChange}
          forcePage={currentPage - 1} // Adjust for 0-based indexing of ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
        />
      )}
    </div>
  );
};

export default AllEndpoints;
