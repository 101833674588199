import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./index.css";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import { ConfirmProxy } from "../../API";
import { toast } from "react-toastify";
import { Badge } from "react-bootstrap";
import DjangoIntegration from "../modals/integrations/django";
import FlaskIntegration from "../modals/integrations/flask";
import NodeIntegration from "../modals/integrations/node";
function AccordionTab(props) {
  const apiUrl = process.env.REACT_APP_API_PROXY_URL;
  const unique_uuid = localStorage.getItem("collection_id")

  const setup = async (e) => {
    e.preventDefault()
    const { data } = await ConfirmProxy();
    if (data?.message === "Collection not forwarded through proxy") {
      toast("Verification failed, no request received from your application")
    }
    else{
      toast("Verification successful")
      window.location.reload()
    }
      };

  const script_code = `!function(){var a=window.XMLHttpRequest,b="",c="",d="",e="",f="",g="",h="",i="${props.collection_token}";window.XMLHttpRequest=function(){var b=new a();return b.open=function(a,b){c=b,d=a,arguments[0].apply(this,arguments)},b.onload=function(){f=b.status,500===b.status?(g=b.responseType,h=b.responseType):(g=b.responseType,h=b.responseType),i(),arguments[0]?.apply(this,arguments)},b};function i(){fetch("https://api.ipify.org?format=json").then(function(a){return a.json()}).then(function(a){e=a.ip,fetch("${props.threat_url}",{method:"POST",body:JSON.stringify({w_endpoint:c,w_method:d,w_error:f,w_public_ip:e,w_response_body:g,w_response_code:h,w_response_type:h,w_timeout:f,w_token:i}),headers:{"Content-Type":"application/json"}})}).catch(function(a){d=JSON.stringify(a),fetch("http://localhost:8000/scan/public_scan/",{method:"POST",body:JSON.stringify({w_endpoint:c,w_method:d,w_error:f,w_public_ip:e,w_response_body:g,w_response_code:h,w_response_type:h,w_timeout:f,w_token:i}),headers:{"Content-Type":"application/json"}})})}}();`;
  return (
    <Accordion defaultActiveKey="0">
      {/* <Accordion.Item eventKey="0">
        <Accordion.Header className="text-secondary">
          Install via NPM
        </Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header className="text-secondary">
          Use HTML script
        </Accordion.Header>
        <Accordion.Body>
          <AceEditor
            placeholder="Placeholder Text"
            mode="javascript"
            theme="monokai"
            name="blah2"
            height="100px"
            width="100%"
            fontSize={14}
            showGutter={true}
            value={script_code}
          />
        </Accordion.Body>
      </Accordion.Item> */}
       <Accordion.Item eventKey="0">
        <Accordion.Header className="text-secondary bold">
         <h4>Send Request Logs From Your Application <Badge bg="warning"> Recommended</Badge></h4> 
        </Accordion.Header>
        <Accordion.Body>
          <span className="text-primary mb-4">
          By sending logs directly from your API server to our platform, you gain real-time insights into potential threats and vulnerabilities, enabling quick response and mitigation.

          </span>
          <h4 className="text-secondary mt-4">Step 1:</h4>
          In your API server's code, implement logging of API requests

          <h4 className="text-secondary my-4">Step 2:</h4>
          Configure the logs to be sent securely to our API endpoints using secure protocols.

<h4 className="text-primary my-4">Final OutCome</h4>
Our platform will analyze these logs and provide you with alerts and insights to keep your project secure
          <h4 className="text-warning my-4">Example: How To Do This 👇</h4>
        <div className="row justify-between wrap">
          <DjangoIntegration />
          <FlaskIntegration />
          <NodeIntegration />
        </div>

          <h4 className="text-secondary my-4">Step 3:</h4>
          You will need to test the connection. If the steps above are done, perform a requests and click on "Confirm" button to confirm if we received your request

          <button className="btn btn-secondary mt-4 float-right" onClick={setup}>Confirm</button>
        </Accordion.Body>
      </Accordion.Item>
      {/* <Accordion.Item eventKey="1">
        <Accordion.Header className="text-secondary">
          Integrate With Your Existing API Gateway
        </Accordion.Header>
        <Accordion.Body>
          <span className="text-primary mb-4">
          To connect your application to our proxy,

          </span>
          <h4 className="text-secondary mt-4">Step 1:</h4>
          You will need a unique UUID assigned to your account. Find your unique UUID below:

          <div className="bg-red-200 p-4 my-4 text-primary">
            Collection UUID: <span className="code">{unique_uuid}</span>
          </div>

          <h4 className="text-secondary my-4">Step 2:</h4>
          You need to update your API endpoint or URL configuration to forward requests to our proxy. Find your proxy url below:
          <div className="my-4">
          <AceEditor
            placeholder="Placeholder Text"
            mode="javascript"
            theme="monokai"
            name="blah2"
            height="100px"
            width="100%"
            fontSize={14}
            showGutter={true}
            value={`${apiUrl}/rs_scan/
            ?environment={'desired_environment to test'}
            &uuid=${unique_uuid}
            &originalUrl={'/any_valid_url_in_your_collection'}`}
          />
          
          </div>

          <h4 className="text-warning">Example</h4>
          <AceEditor
            placeholder="Placeholder Text"
            mode="javascript"
            theme="monokai"
            name="blah2"
            height="100px"
            width="100%"
            fontSize={14}
            showGutter={true}
            value={`${apiUrl}/rs_scan/
            ?environment=test
            &uuid=${unique_uuid}
            &originalUrl=/login`}
          />

          <h4 className="text-secondary my-4">Step 3:</h4>
          You will need to test the connection. If the steps above are done, perform a requests and click on "Confirm" button to confirm if we received your request

          <button className="btn btn-secondary mt-4 float-right" onClick={setup}>Confirm</button>
        </Accordion.Body>
      </Accordion.Item> */}
      <Accordion.Item eventKey="2">
        <Accordion.Header className="text-secondary">
          Connect To Us As A WAF
        </Accordion.Header>
        <Accordion.Body>
          <span className="text-primary mb-4">
          By using us as a WAF, we sit in between your client applications and your server. Every request sent through us is scruntinized, analyzed, and monitored for your own protection.
To connect your application to our proxy,

          </span>
          <h4 className="text-secondary mt-4">Step 1:</h4>
          You will need a unique UUID assigned to your account. Find your unique UUID below:

          <div className="bg-red-200 p-4 my-4 text-primary">
            Collection UUID: <span className="code">{unique_uuid}</span>
          </div>

          <h4 className="text-secondary my-4">Step 2:</h4>
          You need to update your API endpoint or URL configuration to forward requests to our proxy. Find your proxy url below:
          <div className="my-4">
          <AceEditor
            placeholder="Placeholder Text"
            mode="javascript"
            theme="monokai"
            name="blah2"
            height="100px"
            width="100%"
            fontSize={14}
            showGutter={true}
            value={`${apiUrl}
            ?environment={'target environment, can be prod || test || other'}
            &uuid=${unique_uuid}
            &originalUrl={'/target url'}`}
          />
          
          </div>

          <h4 className="text-warning">Example</h4>
          <AceEditor
            placeholder="Placeholder Text"
            mode="javascript"
            theme="monokai"
            name="blah2"
            height="100px"
            width="100%"
            fontSize={14}
            showGutter={true}
            value={`${apiUrl}
            ?environment=prod
            &uuid=${unique_uuid}
            &originalUrl=/login`}
          />

          <h4 className="text-secondary my-4">Step 3:</h4>
          You will need to test the connection. If the steps above are done, perform a requests and click on "Confirm" button to confirm if we received your request

          <button className="btn btn-secondary mt-4 float-right" onClick={setup}>Confirm</button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionTab;
