import React, { useEffect, useState } from 'react';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import countries from './countries.js';
import industries from './industries.js';
import { useAuth } from '../../providers/AuthProvider';
import { useHistory } from 'react-router-dom';
import { onboard } from '../../API';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { useOnboardUserMutation } from '../../../redux/services/mfaAuthApi.js';
import {
  addSetUpDetailsToLocalStorage,
  addUserDetailsToLocalStorageWhenLoggedIn,
} from '../../../utils/localStorage.js';
import { useSelector } from 'react-redux';

export const Onboarding = () => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [gender, setGender] = useState('Male');
  const [phone_number, setPhone] = useState('');
  const [company_name, setCompanyName] = useState('');
  const [position, setPosition] = useState('');
  const [company_size, setCompanySize] = useState('');
  const [industry, setIndustry] = useState('');
  const [source, setSource] = useState('APISentry');
  const [spinner, setSpinner] = useState(false);
  const history = useHistory();

  const signInDetails = useSelector((store) => store?.auth?.signInDetails);

  const [onboardUser, { data, isLoading, error }] = useOnboardUserMutation();

  const filteredCountries = countries.filter(
    (country) => country.name === selectedCountry
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    setSource('APISentry');
    try {
      await onboardUser({
        first_name,
        last_name,
        gender,
        phone_number,
        company_name,
        company_size,
        industry,
        position,
        source,
        country: selectedCountry,
      });
    } catch (error) {
      console.log('Error', error);
    }
    // const { success, error } = await onboard(
    //   first_name,
    //   last_name,
    //   gender,
    //   phone_number,
    //   company_name,
    //   company_size,
    //   industry,
    //   position,
    //   source,
    //   selectedCountry
    // );
    // if (success) {
    //   console.log('Success');
    //   toast('Onboarding Successful');
    //   history.push('/new-project');
    // } else {
    //   console.log('Error');
    //   toast(error);
    // }
    setSpinner(false);
  };

  useEffect(() => {
    if (data) {
      const userDetails = {
        user: {
          company_name: company_name,
          email: '',
          username: signInDetails?.username,
          first_name: first_name,
          last_name: last_name,
        },
      };
      addUserDetailsToLocalStorageWhenLoggedIn(userDetails);

      localStorage.setItem('otpType', '');
      addSetUpDetailsToLocalStorage('setup', {
        first_login: false,
        completed_password_reset: true,
        completed_set_up_mfa: true,
        completed_onboarding: true,
      });
      toast('Onboarding Successful');
      history.push('/dashboard/new-project');
    }

    if (error) {
      const errMessage = error?.data?.error || 'An error occurred';
      toast(errMessage);
    }
  }, [data, error, history]);

  return (
    <div className="background">
      <ToastContainer />
      <div className="col-8 grid-margin stretch-card m-auto">
        <div className="card">
          <div className="card-body">
            <h2 className="card-title text-secondary">Welcome {first_name}</h2>
            <p className="card-description">Let's get you started</p>
            <form className="forms-sample" onSubmit={(e) => onSubmit(e)}>
              <div className="row mb-4">
                <Form.Group className="col-6">
                  <label htmlFor="first_name" className="text-primary">
                    First Name<span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="first_name"
                    placeholder="Enter First Name"
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="col-6">
                  <label htmlFor="last_name" className="text-primary">
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="last_name"
                    placeholder="Enter Last Name"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-4">
                <label htmlFor="exampleSelectGender" className="text-primary">
                  Gender<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleSelectGender"
                  required
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                  <option value="Not Answer">Not Answer</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-4">
                <label className="text-primary">
                  Country<span className="text-danger">*</span>
                </label>
                <div>
                  <select
                    className="form-control"
                    required
                    onChange={(e) => setSelectedCountry(e.target.value)}
                  >
                    {countries.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Form.Group>
              <Form.Group className="mb-4">
                <label className="text-primary">
                  Phone Number<span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="btn btn-outline-primary pt-3 pb-3"
                        id="dropdownMenuOutlineButton1"
                      >
                        {filteredCountries[0]?.dial_code}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          {filteredCountries[0]?.dial_code}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    className="form-control"
                    aria-label="Text input with dropdown button"
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4">
                <label htmlFor="exampleInputCity1" className="text-primary">
                  Company Name<span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputCity1"
                  placeholder="Company Name"
                  required
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <label className="text-primary">Position</label>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setPosition(e.target.value)}
                  >
                    <option>Please Select</option>
                    <option value="CEO">CEO</option>
                    <option value="CTO/CISO">CTO/CISO</option>
                    <option value="Team Lead">Team Lead</option>
                    <option value="Engineer">Engineer</option>
                    <option value="Tester">Tester</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </Form.Group>
              <Form.Group className="mb-4">
                <label className="text-primary">
                  Company Size<span className="text-danger">*</span>
                </label>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setCompanySize(e.target.value)}
                    required
                  >
                    <option>Please Select</option>
                    <option value="1-50">1-50</option>
                    <option value="50-100">50-100</option>
                    <option value="100-200">100-200</option>
                    <option value="Above 200">Above 200</option>
                  </select>
                </div>
              </Form.Group>
              <Form.Group className="mb-4">
                <label className="text-primary">Industry</label>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setIndustry(e.target.value)}
                  >
                    <option>Please Select</option>
                    {industries.map((i) => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </Form.Group>
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary mr-2 float-right"
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status" />
                    ) : (
                      'Get Started'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
