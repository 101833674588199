import React from 'react';

const btnBcg =
  'linear-gradient(0deg, #023673, #023673),linear-gradient(0deg, rgba(2, 54, 115, 0.4), rgba(2, 54, 115, 0.4))';

const UpdateButton = ({ isLoading }) => {
  return (
    <button
      disabled={isLoading}
      type="submit"
      style={{ background: btnBcg }}
      className="flex w-max items-center  py-1  md:py-[15px] px-2 md:px-[17px]  border rounded-[8px]"
    >
      <div className="text-[#FFFFFF]  leading-[20.27px]  text-xs  lg:text-base ">
        {isLoading ? 'Loading...' : 'Update'}
      </div>
    </button>
  );
};

export default UpdateButton;
