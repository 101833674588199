import React from 'react'
import { ResponsiveLine } from '@nivo/line'

const MylineChart = ({data,xLabel, yLabel}) => {
  return (
   
    <div className='h-[250px] md:h-[300px]'>
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
      curve="monotoneX"
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      // curve="linear"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend:xLabel,
       
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legend: yLabel
      }}
      enableGridX={false}
      enableGridY={true}
    //   colors={{ scheme: 'nivo' }}
      colors="#09294d"
      lineWidth={3}
      enablePoints={true}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      enableArea={false}
      areaOpacity={0.1}
      enableCrosshair={true}
      useMesh={true}
    />
  </div>
  )
  
}

export default MylineChart