import React, { Component, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useEffect } from 'react';

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });
  }

  render() {
    const sidebarMenuSecurity = [
      {
        name: "Overview",
        activePath: "/dashboard/security-checklist",
        icon: "mdi mdi-chart-pie menu-icon",
        link: "/dashboard/security-checklist",
      },
      {
        name: 'API Security',
        activePath: '/dashboard/api-security',
        icon: 'mdi mdi-folder-lock menu-icon',
        link: '/dashboard/api-security',
      },
      {
        name: 'Realtime Monitoring',
        activePath: '/dashboard/threat-map',
        icon: 'mdi mdi-flag-checkered menu-icon',
        link: '/dashboard/threat-map',
      },
      {
        name: "Vulnerability Assessment",
        activePath: "/dashboard/vulnerability",
        icon: "mdi mdi-eye menu-icon",
        link: "/dashboard/vulnerability",
      },
      {
        name: 'Performance Tests',
        activePath: '/dashboard/performance-tests',
        icon: 'mdi mdi-checkbox-multiple-marked-circle menu-icon',
        link: '/dashboard/performance-tests',
      },
      {
        name: "WAF Rules",
        activePath: "/dashboard/view-rules",
        icon: "mdi mdi-chart-pie menu-icon",
        link: "/dashboard/view-rules",
      },
      {
        name: "Static Code Analysis",
        activePath: "/dashboard/conformance-scan",
        icon: "mdi mdi-lock menu-icon",
        link: "/dashboard/conformance-scan",
      },

      {
        name: 'Penetration Tests',
        activePath: '/dashboard/penetration-test',
        icon: 'mdi mdi-hexagon menu-icon',
        link: '/dashboard/penetration-test',
      },
      {
        name: 'Source Code Review',
        activePath: '/dashboard/code-reviews',
        icon: 'mdi mdi-folder menu-icon',
        link: '/dashboard/code-reviews',
      },
      // {
      //   name: 'Dependencies Review',
      //   activePath: '/dashboard/dependencies',
      //   icon: 'mdi mdi-volume-medium menu-icon',
      //   link: '/dashboard/dependencies',
      // },
      // {
      //   name: "API Management",
      //   activePath: "/collections",
      //   icon: "mdi mdi-shield menu-icon",
      //   link: "/collections",
      // },
      {
        name: 'Configuration',
        activePath: '/dashboard/configuration',
        icon: 'mdi mdi mdi-airplay menu-icon',
        link: '/dashboard/configuration',
      },
    ];

    const sidebarMenuGeneral = [
      {
        name: 'All Projects',
        activePath: '/dashboard/collections',
        icon: 'mdi mdi-chart-pie menu-icon',
        link: '/dashboard/collections',
      },
      {
        name: 'New Project',
        activePath: '/dashboard/new-project',
        icon: 'mdi mdi-folder-lock menu-icon',
        link: '/dashboard/new-project',
      },
    ];

    const sidebarMenuTraining = [
      {
        name: 'All Courses',
        activePath: '/all_courses',
        icon: 'mdi mdi-chart-pie menu-icon',
        link: '/dashboard/all_courses',
      },
      {
        name: 'My Courses',
        activePath: '/new-project',
        icon: 'mdi mdi-folder-lock menu-icon',
        link: '/dashboard/new-project',
      },
      {
        name: 'My Certificates',
        activePath: '/new-project',
        icon: 'mdi mdi-folder-lock menu-icon',
        link: '/dashboard/new-project',
      },
    ];
    return (
      <>
        {localStorage.getItem('module') === 'api security' ||
        localStorage.getItem('module') === null ? (
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav fixed">
              <li>
                <span className="menu-title text-white ml-4 mt-4">
                  <h5 className="ml-4">
                    {/* {localStorage.getItem('collection_name')} */}
                    {<CollectionName />}
                  </h5>
                </span>
              </li>

              {sidebarMenuSecurity.map((menu) => (
                <li
                  className={
                    this.isPathActive(menu.activePath)
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link className="nav-link" to={menu.link}>
                    <span className="icon-bg">
                      <i className={menu.icon}></i>
                    </span>
                    <span className="menu-title">
                      <Trans>{menu.name}</Trans>
                    </span>
                  </Link>
                </li>
              ))}

              <li className="nav-item documentation-link">
                <Link
                  style={{ background: 'rgba(255, 255, 255, 0.2)' }}
                  className="nav-link"
                  to="request-service"
                >
                  <span className="icon-bg">
                    <i className="mdi mdi-file-document-box menu-icon"></i>
                  </span>
                  <span className="menu-title">
                    <Trans>Request Service</Trans>
                  </span>
                </Link>
              </li>

              <li>
                <span className="menu-title text-white ml-4 mt-4">
                  <h5 className="ml-4">General</h5>
                </span>
              </li>
              {sidebarMenuGeneral.map((menu) => (
                <li
                  className={
                    this.isPathActive(menu.activePath)
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link className="nav-link" to={menu.link}>
                    <span className="icon-bg">
                      <i className={menu.icon}></i>
                    </span>
                    <span className="menu-title">
                      <Trans>{menu.name}</Trans>
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        ) : (
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav fixed">
              <li>
                <span className="menu-title text-white ml-4 mt-4">
                  <h5 className="ml-4">Training</h5>
                </span>
              </li>
              {sidebarMenuTraining.map((menu) => (
                <li
                  className={
                    this.isPathActive(menu.activePath)
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link className="nav-link" to={menu.link}>
                    <span className="icon-bg">
                      <i className={menu.icon}></i>
                    </span>
                    <span className="menu-title">
                      <Trans>{menu.name}</Trans>
                    </span>
                  </Link>
                </li>
              ))}
              <li className="nav-item documentation-link">
                <a
                  style={{ background: 'rgba(255, 255, 255, 0.2)' }}
                  className="nav-link"
                  href="https://www.wiredassurance.com/"
                >
                  <span className="icon-bg">
                    <i className="mdi mdi-file-document-box menu-icon"></i>
                  </span>
                  <span className="menu-title">
                    <Trans>Request Service</Trans>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        )}
      </>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
}

export default withRouter(Sidebar);

function CollectionName() {
  const [collectionName, setCollectionName] = useState('');
  const name = localStorage.getItem('collection_name');

  useEffect(() => {
    if (name) setCollectionName(name);
  }, [name]);

  return collectionName;
}
