import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdEdit  } from "react-icons/md";

function EditRegexModal({ item, onSubmit, setName, setPattern, setStatus, setUUID }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }

  return (
    <div>
      <MdEdit  className="mr-3 cursor-pointer" title="Update" onClick={() => {
                      setUUID(item.uuid)

        handleShow()}}/>
      <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
          <Modal.Title className="red-test text-primary">Edit Regex</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row mb-4">
          <Form.Group className="col-6">
            <label htmlFor="name" className="text-primary">
            Name
            </label>
            <Form.Control
              type="text"
              className="form-control"
              name="name"
              defaultValue={item.name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-6">
            <label htmlFor="name" className="text-primary">
            Pattern
            </label>
            <Form.Control
              type="text"
              className="form-control"
              name="pattern"
              defaultValue={item.pattern}
              onChange={(e) => setPattern(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="col-6 mt-4">
            <label htmlFor="name" className="text-primary">
            Status
            </label>
            <Form.Select
              className="form-control"
              name="status"
              defaultValue={item.status}
              onChange={(e) => setStatus(e.target.value)}
            >
                <option>active</option>
                <option>inactive</option>
            </Form.Select>
          </Form.Group>
          
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onSubmit();
              handleClose();
            }}
          >
            Update
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditRegexModal;
