import React, { useEffect, useState } from 'react';
import { Badge, Dropdown, Spinner } from 'react-bootstrap';
import {
  useBlockIpAdressMutation,
  useGetEndpointActivityQuery,
} from '../../../../redux/services/endPointScansApi';
import Modal from 'react-bootstrap/Modal';
import RealtimeMonitoringModal from '../../modals/api_security/realtime_monitoring';
import DatePicker from 'react-datepicker';
import errorImg from '../../../../assets/images/searching-error.png';
import { toast } from 'react-toastify';

const ThreatMonitoring = ({ id }) => {
  // const [data, setData] = useState([]);
  // const [isError, setIsError] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [neededData, setNeededData] = useState([]);

  const [filteredData, setFilteredData] = useState({
    ipaddress: '',
    location: '',
    status: '',
    res_code: '',
    res_time: '',
  });
  const [modalStates, setModalStates] = useState({});

  const toggleModal = (index) => {
    setModalStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const {
    data: endpointActivityData,
    isLoading: endpointActivityIsLoading,
    isError: endpointActivityIsError,
  } = useGetEndpointActivityQuery(id);

  const [
    blockIpAdress,
    {
      data: blockedIpAddressData,
      isLoading: blockIpIsLoading,
      isError: blockIpIsError,
      isSuccess: blockIpIsSuccess,
    },
  ] = useBlockIpAdressMutation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(false);

  const getTodayDate = () => {
    const today = new Date();
    return today;
  };

  const getYesterdayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  };

  const todayDate = getTodayDate();
  const yesterdayDate = getYesterdayDate();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // const start = startDate?.toISOString()?.split("T")[0];
  // const end = endDate?.toISOString()?.split("T")[0];

  const start =
    startDate.toISOString().split('.')[0] +
    `${startDate.getTimezoneOffset() > 0 ? '-' : '%2B'}${String(
      Math.abs(startDate.getTimezoneOffset() / 60)
    ).padStart(2, '0')}:${String(
      Math.abs(startDate.getTimezoneOffset() % 60)
    ).padStart(2, '0')}`;

  const end =
    endDate.toISOString().split('.')[0] +
    `${endDate.getTimezoneOffset() > 0 ? '-' : '%2B'}${String(
      Math.abs(endDate.getTimezoneOffset() / 60)
    ).padStart(2, '0')}:${String(
      Math.abs(endDate.getTimezoneOffset() % 60)
    ).padStart(2, '0')}`;

  useEffect(() => {
    // if (endpointActivityData) {
    //   setData(endpointActivityData);
    // }

    if (blockedIpAddressData || blockIpIsSuccess) {
      toast.success('IP Address blocked!');
    }
    if (blockIpIsError) {
      toast.error('An error occurred');
    }
  }, [
    endpointActivityData,
    blockedIpAddressData,
    blockIpIsSuccess,
    blockIpIsError,
  ]);

  return (
    <div>
      <div className="flex justify-between">
        {/* <CSVLink endpointActivityData={endpointActivityData || []} className="btn btn-secondary mb-2">
          Download CSV
        </CSVLink> */}

        <Dropdown>
          <Dropdown.Toggle
            variant="btn btn-outline-primary pt-3 pb-3"
            id="dropdownMenuOutlineButton1"
            disabled={endpointActivityData?.length === 0 ? true : false}
          >
            Date Filter
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setStartDate(todayDate);
                // getCustomData();
              }}
            >
              Today
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setStartDate(yesterdayDate);
                // getCustomData();
              }}
            >
              Yesterday
            </Dropdown.Item>
            <Dropdown.Item onClick={handleShow}>Custom Dates</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Custom Date Modal */}
      <div>
        <Modal show={show} onHide={handleClose} size="md">
          <Modal.Header closeButton>
            <Modal.Title className="text-secondary">Custom date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              <p className="text-secondary text-md mb-1">Start Date</p>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  // getCustomData();
                }}
                className="form-control mb-0"
              />
            </div>

            <div>
              <p className="text-secondary text-md mb-1">End Date</p>
              <DatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  // getCustomData();
                }}
                className="form-control mb-0"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={getCustomData}>
              Submit
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>

      {endpointActivityIsLoading && (
        <div className="w-[100%]   flex justify-center mx-auto  h-[300px] pt-[50px]">
          <Spinner
            animation="border"
            variant="secondary"
            className="text-[50px] block"
          />
        </div>
      )}
      {endpointActivityIsError && (
        <div
          style={{ width: '50%', margin: 'auto', textAlign: 'center' }}
          className="mt-5  w-[100%]  py-6 flex flex-col justify-center mx-auto font-medium text-slate-400 text-xl"
        >
          <img src={errorImg} alt="" />
          <p>An error occured</p>
        </div>
      )}

      {!endpointActivityIsLoading &&
        endpointActivityData &&
        !endpointActivityData?.results?.length && (
          <div className=" w-[70%] justify-center mx-auto text-center">
            <h4 className='text-primary'> No Threat Found  <i className="mdi mdi-alert-circle-outline"></i></h4> 
           <a className='btn-primary btn' href='/dashboard/configuration/threat_monitoring'>Configure Threat Monitoring</a>
          </div>
        )}

      {/* <div className="border p-2 rounded border-danger bg-danger text-white w-100 mb-4">
          {endpointActivityData?.length === 0 ? "Stats are empty because no traffic has been detected on this API Endpoint yet; Configure Threat Monitoring To Enable API Monitoring": null}
        </div> */}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-4">
        {endpointActivityData?.results?.length > 0 && (
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Time Stamp
                </th>
                <th scope="col" className="px-6 py-3">
                  Ip Address
                </th>
                <th scope="col" className="px-6 py-3">
                  Location
                </th>
                <th scope="col" className="px-6 py-3">
                  Threat Level
                </th>
                <th scope="col" className="px-6 py-3">
                  URL
                </th>
                <th scope="col" className="px-6 py-3">
                  Method
                </th>
                <th scope="col" className="px-6 py-3">
                  Request Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Response Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Investigate
                </th>
              </tr>
            </thead>

            <tbody>
              {endpointActivityData?.results?.length > 0 &&
                endpointActivityData?.results?.map((item, index) => (
                  <tr className="bg-white border-b " key={index}>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {item?.timezone?.substring(0, 10)}
                    </th>
                    <td className="px-6 py-4">{item.ipAddress}</td>
                    <td className="px-6 py-4">
                      {item.location || item.countryName}
                    </td>
                    <td className="px-6 py-4">
                      <Badge
                        pill
                        className="text-white p-2"
                        bg={
                          item.threat_level === 'critical'
                            ? 'danger'
                            : item.threat_level === 'medium'
                            ? 'warning'
                            : item.threat_level === 'low'
                            ? 'primary'
                            : 'info'
                        }
                      >
                        <i className="mdi mdi-security"> </i>
                        {item.threat_level}
                      </Badge>
                    </td>
                    <td className="px-6 py-4 text-primary max-width-column">
                      {item.path}
                    </td>
                    <td
                      className={
                        item.method === 'GET'
                          ? 'px-6 py-4 text-success'
                          : item.method === 'POST'
                          ? 'px-6 py-4 text-primary'
                          : item.method === 'PATCH'
                          ? 'px-6 py-4 text-secondary'
                          : item.method === 'PUT'
                          ? 'px-6 py-4 text-warning'
                          : item.method === 'DELETE'
                          ? 'px-6 py-4 text-danger'
                          : 'px-6 py-4 text-info'
                      }
                    >
                      {item.method}
                    </td>
                    <td className="px-6 py-4 text-success">
                      {item?.request_status?.toUpperCase()}
                    </td>
                    <td className="px-6 py-4">
                      <button
                        className={
                          item?.response_code?.toString()?.startsWith('5')
                            ? 'btn btn-sm btn-outline-danger'
                            : item?.response_code?.toString()?.startsWith('4')
                            ? 'btn btn-sm btn-outline-danger'
                            : item?.response_code?.toString()?.startsWith('3')
                            ? 'btn btn-sm btn-outline-info'
                            : item?.response_code?.toString()?.startsWith('2')
                            ? 'btn btn-sm btn-outline-success'
                            : 'text-primary'
                        }
                        disabled
                      >
                        {item.response_code}
                      </button>
                    </td>
                    <td
                      className="px-6 py-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        // height: "30vh",
                        justifyContent: 'center',
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={() => toggleModal(index)}
                      >
                        <i className="mdi mdi-eye cursor-pointer"></i>
                      </button>
                      {modalStates[index] && (
                        <div className="flex justify-between">
                          <RealtimeMonitoringModal data={item} />
                          <button
                            disabled={blockIpIsLoading}
                            className="btn btn-outline-danger btn-sm text-[8px] w-[70px]"
                            style={{ fontSize: '8px' }}
                            onClick={() =>
                              blockIpAdress({
                                ipAddress: item?.ipAddress,
                                collectionId: item?.collection_id,
                              })
                            }
                          >
                            Block IP
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-2"></td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      {/* {neededData?.length > 0 && (
        <div className="flex justify-between mb-8">
          <button
            className="btn text-white bg-primary py-2 px-4"
            // disabled={prevLink === null}
            // onClick={() => getPrevPage()}
          >
            Prev
          </button>
          <button
            className="btn text-white bg-primary py-2 px-4"
            // disabled={nextLink === null}
            // onClick={() => getNextPage()}
          >
            Next
          </button>
        </div>
      )} */}
    </div>
  );
};

export default ThreatMonitoring;
