import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  useGetAutomatedScanDetailsQuery,
  useRunOneScanMutation,
  useRunScansMutation,
} from "../../../redux/services/endPointScansApi";
import PerformanceTable from "./component/PerformanceTable";
import errorImg from "../../../assets/images/searching-error.png";
import ErrorDisplay from "../../components/Error";

const PerformanceTest = () => {
  // const { getAutomatedScanDetails, runAllScans } = useFetch();
  // const [main_data, setData] = useState([]);
  const [env, setEnv] = useState("prod");
  const [schedule, setSchedule] = useState(7);
  const [scanDataIsLoading, setScanDataIsLoading] = useState(true);
  const [scanDataError, setScanDataError] = useState(null);
  const [scanData, setScanData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const history = useHistory();

  const collectionId = localStorage.getItem("collection_id");

  const {
    data: performanceTestsData,
    isLoading: performanceTestIsLoading,
    error: performanceTestsError,
  } = useGetAutomatedScanDetailsQuery({
    collectionId,
  });

  const [
    runScans,
    {
      data: automatedTestsData,
      isLoading: AutomatedTestsIsLoading,
      error: AutomatedTestsError,
    },
  ] = useRunScansMutation();

  const [
    runOneScan,
    {
      data: automatedScanData,
      isLoading: automatedScanIsLoading,
      error: automatedScanError,
    },
  ] = useRunOneScanMutation();

  const cardClassName =
    " bg-gray-100 border border-gray-200 rounded-lg shadow ";

  const handleScheduleScan = async () => {
    try {
      await runScans({
        collection_id: collectionId,
        environment: env,
        interval: parseInt(schedule),
      });
    } catch (error) {
      console.log("AutomatedTestsError", AutomatedTestsError);
    }
  };

  useEffect(() => {
    if (!collectionId) {
      const redirectTimeout = setTimeout(() => {
        history.push("/dashboard/collections");
      }, 4000);
      return () => clearInterval(redirectTimeout);
    }
  }, [collectionId, history]);

  useEffect(() => {
    if (performanceTestIsLoading) {
      setScanData([]);
      setScanDataError(null);
      setScanDataIsLoading(true);
    }
    if (performanceTestsError) {
      setScanDataIsLoading(false);
      setScanData([]);
      setScanDataError(performanceTestsError);
    }
    if (performanceTestsData) {
      setScanDataIsLoading(false);
      setScanDataError(null);
      setScanData(performanceTestsData?.test_results);
    }
  }, [performanceTestsData, performanceTestsError, performanceTestIsLoading]);

  useEffect(() => {
    if (AutomatedTestsError) {
      const message = automatedScanError?.data?.error;
      toast(message || "An error occured");
    }

    if (automatedTestsData) {
      const message = automatedTestsData?.Message;
      toast(message);
    }
  }, [
    automatedTestsData,
    AutomatedTestsError,
    automatedScanError?.data?.error,
  ]);

  useEffect(() => {
    if (automatedScanError)
      toast(automatedScanError?.data?.error || "An error occured");

    if (automatedScanData)
      toast(automatedScanData?.Message || "Scanned Successfully");
  }, [automatedScanData, automatedScanError]);

  return (
    <>
          <h4 className="text-primary pr-4 my-4">Last Execution</h4>

      {/* <ToastContainer /> */}
      <div className="flex justify-between">

        <div className="flex justify-between items-center mb-4">
          <select
            className="w-[100px] bg-[#023673] px-2 py-1 text-white rounded"
            onChange={(e) => setEnv(e.target.value)}
            id="selectAuth"
            defaultValue={"prod"}
          >
            <option value="prod">Prod</option>
            <option value="test">Test</option>
            <option value="other">Other</option>
          </select>
         
        </div>
        {collectionId ? (
            <div>
              <button
                type="button"
                className="btn bg-[#023673] text-white"
                onClick={handleScheduleScan}
              >
                {AutomatedTestsIsLoading ? <Spinner /> : "Run All"}
              </button>
            </div>
          ) : null}
      </div>

      <div className="flex flex-col md:flex-row w-full gap-2 mt-3">
        <div
          className={`flex flex-col w-full   ${
            collectionId && !scanDataError ? "" : ""
          }`}
        >
          
          {collectionId && scanDataIsLoading && (
            <div className="w-50 flex justify-center m-auto text-center h-[300px] pt-[50px]">
              <Spinner
                animation="border"
                variant="secondary"
                className="text-[50px]"
              />
            </div>
          )}
          {collectionId && scanDataError && (
            <div
              style={{ width: "50%", margin: "auto", textAlign: "center" }}
              className="mt-2 flex flex-col justify-center "
            >
              <div>
                <img src={errorImg} alt="" />
              </div>
              <p className="text-xl py-3  flex justify-center items-center text-[#000]">
                {scanDataError && scanDataError?.originalStatus === 500
                  ? "Server Error"
                  : "An error occurred"}
              </p>
            </div>
          )}

          {!collectionId && (
            <div className="flex flex-col items-center mb-1">
              <ErrorDisplay
                errorMessage="No Project Selected"
                errorCode={404}
              />
              <h4 className="text-primary mt-4 mb-4">Redirecting Shortly</h4>
            </div>
          )}
          {collectionId &&
            !scanDataIsLoading &&
            !scanDataError &&
            scanData.length === 0 && (
              <ErrorDisplay errorMessage="No Data Found" errorCode={404} />
            )}
          {scanData.length !== 0 && (
            <PerformanceTable
              data={scanData}
              scanDataIsLoading={scanDataIsLoading}
              // scanDataError={scanDataError}

              env={env}
              collectionId={collectionId}
              automatedScanIsLoading={automatedScanIsLoading}
              runOneScan={runOneScan}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PerformanceTest;
