import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import SelectCountries from './selectCountries';
import statusCodes from '../../../../data/statusCodes.json';
import SelectCustomDate from './selectCustomDate';

const labelStyle =
  'block mb-[2px] text-sm font-medium text-primary';

const selectStyle =
  'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5';

const AdvancedFilterModal = ({
  filtershow,
  setFilterShow,
  handleSubmit,
  selectedCountry,
  setSelectedCountry,
  formData,
  setFormData,
  logData,
  setData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleInputChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleClearFilters = () => {
    setSelectedCountry('');
    setStartDate('');
    setEndDate('');
    setFormData({
      startTime: '',
      endTime: '',
      ipAddress: '',
      vpn: '',
      threatLevel: '',
      ipLocation: '',
      suspicious: '',
      responseCode: '',
      unreported: '',
      reported: '',
      requestStatus: '',
    });
    setFilterShow(false);
    setData(logData?.results);
  };
  return (
    <Modal show={filtershow} onHide={() => setFilterShow(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="text-secondary">
          Apply Advanced Filter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-1 grid gap-7">
          <div className="grid grid-cols-2 gap-5 items-center">
            <div>
              <label htmlFor="ip-address" className={labelStyle}>
                Ip Address
              </label>
              <input
                type="text"
                id="ip-address"
                value={formData?.ipAddress}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter ip address"
                onChange={(e) => handleInputChange('ipAddress', e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="country" className={labelStyle}>
                Country
              </label>
              <SelectCountries
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5 items-center">
            <div>
              <label htmlFor="Threat-level" className={labelStyle}>
                Threat level
              </label>
              <select
                id="Threat-level"
                value={formData?.threatLevel}
                onChange={(e) =>
                  handleInputChange('threatLevel', e.target.value)
                }
                className={selectStyle}
              >
                <option defaultValue={''}>Choose</option>
                <option value="critical">Critical</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="low">Low</option>
                <option value="None">None</option>
              </select>
            </div>

            <div>
              <label htmlFor="start-date" className={labelStyle}>
                Response code
              </label>
              <select
                id="response-code"
                value={formData?.responseCode}
                onChange={(e) =>
                  handleInputChange('responseCode', e.target.value)
                }
                className={selectStyle}
              >
                <option defaultValue={''}>Choose</option>
                {Object.keys(statusCodes).map((code) => (
                  <option key={code} value={code}>
                    {`${statusCodes[code].status} (${code})`}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5 items-center">
            <div>
              <label htmlFor="unreported" className={labelStyle}>
                Unreported
              </label>
              <select
                id="unreported"
                value={formData?.unreported}
                onChange={(e) =>
                  handleInputChange('unreported', e.target.value)
                }
                className={selectStyle}
              >
                <option defaultValue={''}>Choose</option>
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            </div>

            <div>
              <label htmlFor="reported" className={labelStyle}>
                Reported
              </label>
              <select
                id="reported"
                value={formData?.reported}
                onChange={(e) => handleInputChange('reported', e.target.value)}
                className={selectStyle}
              >
                <option defaultValue={''}>Choose</option>
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5 items-center">
            <div>
              <label htmlFor="vpn" className={labelStyle}>
                Vpn
              </label>
              <select
                id="vpn"
                value={formData?.vpn}
                onChange={(e) => handleInputChange('vpn', e.target.value)}
                className={selectStyle}
              >
                <option defaultValue={''}>Choose</option>
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            </div>

            <div>
              <label htmlFor="request-status" className={labelStyle}>
                Request status
              </label>
              <select
                id="request-status"
                value={formData?.requestStatus}
                onChange={(e) =>
                  handleInputChange('requestStatus', e.target.value)
                }
                className={selectStyle}
              >
                <option defaultValue={''}>Choose</option>
                <option value="blocked">Blocked</option>
                <option value="allowed">Allowed</option>
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5 items-center">
            <div>
              <label htmlFor="suspicious" className={labelStyle}>
                Suspicious
              </label>
              <select
                id="suspicious"
                value={formData?.suspicious}
                onChange={(e) =>
                  handleInputChange('suspicious', e.target.value)
                }
                className={selectStyle}
              >
                <option defaultValue={''}>Choose</option>
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            </div>

            <div>
              <label htmlFor="category" className={labelStyle}>
                Category
              </label>
              <select disabled id="category" className={selectStyle}>
                <option defaultValue={''}>Choose</option>
                <option value="blocked" disabled>
                  {/* Blocked */}
                </option>
                <option value="allowed" disabled>
                  {/* Allowed */}
                </option>
              </select>
            </div>
          </div>

          <SelectCustomDate
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            labelStyle={labelStyle}
            selectStyle={selectStyle}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center justify-between">
          <div>
            <button
              type="button"
              className="btn btn-light "
              onClick={handleClearFilters}
            >
              Clear Filters
            </button>
          </div>
          <div className="ml-auto">
            <Button variant="danger" onClick={() => setFilterShow(false)}>
              Close
            </Button>
            <Button variant="secondary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvancedFilterModal;
