import React from 'react';
import { IoPencilSharp } from 'react-icons/io5';

import { IntervalSelection } from './IntervalSelection';
import { useSelector } from 'react-redux';
import './index.css';

export const btnBcg =
  'linear-gradient(0deg, #023673, #023673),linear-gradient(0deg, rgba(2, 54, 115, 0.4), rgba(2, 54, 115, 0.4))';

const ChartHeader = ({
  headerName,
  isTestCase,
  setOpenModal,
  shouldReturnAllTestCaseData,
  setShouldSkipAllTestCasesQuery,
}) => {
  const testCaseNumberOfDays = useSelector(
    (store) => store?.performanceTestDays.testCaseNumberOfDays
  );

  const testReportNumberOfDays = useSelector(
    (store) => store?.performanceTestDays.testReportNumberOfDays
  );

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const openModal = () => {
    setOpenModal(true);
    if (!shouldReturnAllTestCaseData) return;
    setShouldSkipAllTestCasesQuery(false);
  };

  return (
    <>
      <div className="flex w-full chartHeader  items-center">
        <Title
          isTestCase={isTestCase}
          headerName={headerName}
          testCaseNumberOfDays={testCaseNumberOfDays}
          testReportNumberOfDays={testReportNumberOfDays}
        />

        {isTestCase ? <EditHighLightTestCase openModal={openModal} /> : null}

        <div className="ml-auto flex items-center flex-col justify-center">
          <div className="w-full text-black text-[10px]  md:text-base">
            Filter by days
          </div>
          <div className="flex items-center mt-1 md:mt-2  justify-center">
            <IntervalSelection
              handleSubmit={handleSubmit}
              isTestCase={isTestCase}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Title = ({
  isTestCase,
  headerName,
  testCaseNumberOfDays,
  testReportNumberOfDays,
}) => (
  <div>
    <div className="text-[#9291A5] text-[12px]  lg:text-[16px] font-[Inter] leading-3  lg:leading-5 ">
      Recent
    </div>
    <h2 className="text-[#1E1B39] text-nowrap mt-0 lg:mt-1 font-[Inter] text-[12px]  lg:text-[19px] font-bold leading-4 lg:leading-7">
      <span className="mr-1">{headerName}</span>(
      {`${
        isTestCase
          ? testCaseNumberOfDays
            ? testCaseNumberOfDays
            : 30
          : testReportNumberOfDays
          ? testReportNumberOfDays
          : 30
      }`}{' '}
      day/s)
    </h2>
  </div>
);

const EditHighLightTestCase = ({ openModal }) => (
  <div className="mx-auto md:ml-10 flex items-center  mt-3 md:mt-0 editHighlightTestCase">
    <button
      onClick={openModal}
      style={{ background: btnBcg }}
      className={`flex w-max items-center mr-10 py-1  md:py-[7px] px-2 md:px-[12px]  border rounded-[8px]`}
    >
      <div className="text-[#FFFFFF]  leading-[20.27px] mr-2 text-xs  lg:text-base ">
        Edit Highlighted Test Cases
      </div>
      <div>
        <IoPencilSharp className="fill-white" />
      </div>
    </button>
  </div>
);

export default ChartHeader;
