import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoMdCloseCircle } from 'react-icons/io';
import SearchBar from './SearchBar';
import Checkbox from './CheckBox';
import {
  useGetAllTestCasesQuery,
  useQueryTestCaseByPathMutation,
  useUpdateHighlightTestCaseMutation,
} from '../../../../redux/services/endPointScansApi';
import { toast } from 'react-toastify';
import InfiniteScrollComp from '../../../components/pagination/InfiniteScrollComp';
import useInfiniteScroolPagination from '../../../../hooks/useInfiniteScroolPagination';

export default function Modal({
  openModal,
  setOpenModal,
  shouldSkipAllTestCasesQuery,
  setShouldSkipAllTestCasesQuery,
  shouldReturnAllTestCaseData,
  setShouldReturnAllTestCaseData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [allTestCaseData, setAllTestCaseData] = useState([]);
  const [testCaseByPathData, setTestCaseByPathData] = useState([]);
  const [updateHighlightTestCaseBody, setUpdateHighlightTestCaseBody] =
    useState([]);
  const [queryTestCaseByPathBody, setQueryTestCaseByPathBody] = useState([]);
  const { pageNumber, hasMore, fetchMoreData, setTotalPages, setCurrentPage } =
    useInfiniteScroolPagination();

  const cancelButtonRef = useRef(null);

  const collectionId = localStorage.getItem('collection_id');

  const [
    queryTestCaseByPath,
    {
      data: queryTestCaseByPathData,
      isLoading: queryTestCaseByPathIsLoading,
      isFetching: queryTestCaseByPathIsFetching,
      error: queryTestCaseByPathError,
    },
  ] = useQueryTestCaseByPathMutation();

  const [
    updateHighlightTest,
    {
      data: updateHighlightTestCaseData,
      isLoading: updateHighlightTestCaseIsLoading,
      error: updateHighlightTestCaseError,
    },
  ] = useUpdateHighlightTestCaseMutation();

  const {
    data: testCaseData,
    isLoading: testCaseIsLoading,
    isFetching: testCaseIsFetching,
    error: testCaseError,
  } = useGetAllTestCasesQuery(
    { collectionId, pageNumber },
    {
      skip: shouldSkipAllTestCasesQuery,
    }
  );

  const handleUpdateHighlightTestCase = async () => {
    if (updateHighlightTestCaseBody.length === 0) return;
    try {
      await updateHighlightTest({
        collectionId,
        body: updateHighlightTestCaseBody,
      });
    } catch (error) {}
  };

  const handleSubmitQueryTestCaseByPath = async () => {
    try {
      await queryTestCaseByPath({
        collectionId,
        body: [queryTestCaseByPathBody],
      });
    } catch (error) {}
  };

  const isNoDataFound = (data, error) =>
    !isLoading && !error && data.length === 0;

  const handleReturnBackAllTestCaseData = () => {
    setShouldReturnAllTestCaseData((prev) => !prev);
    setQueryTestCaseByPathBody('');
    setAllTestCaseData(allTestCaseData);
  };

  useEffect(() => {
    if (testCaseIsLoading) {
      setError('');
      setIsLoading(true);
    }

    if (testCaseError && shouldSkipAllTestCasesQuery === false) {
      setIsLoading(false);
      setError('An error occured');
    }

    if (testCaseData && shouldSkipAllTestCasesQuery === false) {
      setTotalPages(testCaseData.total_pages);
      setCurrentPage(testCaseData.current_page);
      setIsLoading(false);
      setError('');
      setAllTestCaseData((prevData) => [...prevData, ...testCaseData.results]);
    }
  }, [
    setCurrentPage,
    setTotalPages,
    shouldSkipAllTestCasesQuery,
    testCaseData,
    testCaseError,
    testCaseIsLoading,
  ]);

  useEffect(() => {
    if (queryTestCaseByPathIsLoading) {
      setError('');
      setIsLoading(true);
    }

    if (queryTestCaseByPathError && shouldSkipAllTestCasesQuery === true) {
      setIsLoading(false);
      setError('An error occured');
    }

    if (queryTestCaseByPathData && shouldSkipAllTestCasesQuery === true) {
      setIsLoading(false);
      setError('');
      setTestCaseByPathData(queryTestCaseByPathData);
    }
  }, [
    shouldSkipAllTestCasesQuery,
    queryTestCaseByPathData,
    queryTestCaseByPathError,
    queryTestCaseByPathIsLoading,
  ]);

  useEffect(() => {
    if (updateHighlightTestCaseData)
      toast.success(
        updateHighlightTestCaseData?.detail || 'updated successfully'
      );

    if (updateHighlightTestCaseError)
      toast.error(
        updateHighlightTestCaseError?.data?.detail || 'An error occurred'
      );
  }, [updateHighlightTestCaseData, updateHighlightTestCaseError]);

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpenModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" /> */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-4 sm:p-4 sm:pb-4">
                  <div className=" sm:items-start">
                    <div className="relative mt-1 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {' '}
                        <div className="w-full">
                          <div className="w-[95%] ml-3">
                            <SearchBar
                              setQueryTestCaseByPathBody={
                                setQueryTestCaseByPathBody
                              }
                              handleSubmitQueryTestCaseByPath={
                                handleSubmitQueryTestCaseByPath
                              }
                              queryTestCaseByPathBody={queryTestCaseByPathBody}
                              setShouldSkipAllTestCasesQuery={
                                setShouldSkipAllTestCasesQuery
                              }
                              setShouldReturnAllTestCaseData={
                                setShouldReturnAllTestCaseData
                              }
                            />
                          </div>
                          <button
                            onClick={() => setOpenModal(false)}
                            className="w-10 h-10 border-none bg-transparent absolute mt-2 top-[-30px] left-[-35px]"
                          >
                            <IoMdCloseCircle className="w-full h-full" />
                          </button>
                        </div>
                      </Dialog.Title>

                      <div className="mt-[18px] w-full h-[300px]">
                        {isLoading ? (
                          <div className="w-full h-full flex justify-center items-center">
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : null}

                        {error ? (
                          <div className="absolute text-base text-black text-center mb-0 inset-0  h-[300px]  w-full  flex items-center justify-center">
                            An error occured
                          </div>
                        ) : null}

                        {!isLoading &&
                        shouldReturnAllTestCaseData &&
                        allTestCaseData &&
                        allTestCaseData?.length > 0 ? (
                          <div
                            id="scrollableDiv"
                            style={{
                              height: 320,
                              overflow: 'auto',
                            }}
                          >
                            <InfiniteScrollComp
                              hasMore={hasMore}
                              data={allTestCaseData}
                              setData={setAllTestCaseData}
                              setUpdateHighlightTestCaseBody={
                                setUpdateHighlightTestCaseBody
                              }
                              updateHighlightTestCaseBody={
                                updateHighlightTestCaseBody
                              }
                              content={Checkbox}
                              fetchMoreData={fetchMoreData}
                            />
                          </div>
                        ) : null}

                        {!isLoading &&
                        !shouldReturnAllTestCaseData &&
                        queryTestCaseByPath &&
                        testCaseByPathData?.length > 0 ? (
                          <div
                            style={{
                              height: 300,
                              overflow: 'auto',
                            }}
                          >
                            <Checkbox
                              data={testCaseByPathData}
                              setData={setTestCaseByPathData}
                              setUpdateHighlightTestCaseBody={
                                setUpdateHighlightTestCaseBody
                              }
                              updateHighlightTestCaseBody={
                                updateHighlightTestCaseBody
                              }
                            />
                          </div>
                        ) : null}
                        {shouldReturnAllTestCaseData &&
                        isNoDataFound(allTestCaseData, error) ? (
                          <div className="absolute text-base text-black text-center mb-0 inset-0  h-[300px]  w-full  flex items-center justify-center">
                            No data found
                          </div>
                        ) : !shouldReturnAllTestCaseData &&
                          isNoDataFound(testCaseByPathData, error) ? (
                          <div className="absolute text-base text-black text-center mb-0 inset-0  h-[300px]  w-full  flex items-center justify-center">
                            No data found
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 flex items-center justify-between ">
                  {!shouldReturnAllTestCaseData ? (
                    <button
                      onClick={handleReturnBackAllTestCaseData}
                      type="button"
                      className=" inline-flex w-full mr-2 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      ref={cancelButtonRef}
                    >
                      Back
                    </button>
                  ) : (
                    <div></div>
                  )}
                  <div className="flex items-center">
                    <button
                      type="button"
                      className=" inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      ref={cancelButtonRef}
                      onClick={handleUpdateHighlightTestCase}
                      disabled={
                        updateHighlightTestCaseIsLoading ||
                        updateHighlightTestCaseBody.length === 0
                      }
                    >
                      {updateHighlightTestCaseIsLoading ? (
                        <span
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        'Edit'
                      )}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
