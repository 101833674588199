import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/accounts/`,
  }),

  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => {
        return {
          url: 'login/',
          method: 'POST',
          body,
        };
      },
    }),

    confirmMfa: builder.mutation({
      query: (body) => {
        return {
          url: 'login/',
          method: 'POST',
          body,
        };
      },
    }),

    newPassword: builder.mutation({
      query: (body) => {
        return {
          url: 'reset/confirm/',
          method: 'POST',
          body,
        };
      },
    }),

    registerUser: builder.mutation({
      query: ({
        username,
        email,
        password1,
        password2,
        account_type = 'Client',
      }) => {
        return {
          url: 'registration/',
          method: 'POST',
          body: {
            username,
            email,
            password1,
            password2,
            account_type,
          },
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useNewPasswordMutation,
  useConfirmMfaMutation,
} = authApi;
