import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import { Fade, Form, Spinner } from 'react-bootstrap';
import { useFetch } from '../../context';
import { toast } from 'react-toastify';
import { useUpdateScanMutation } from '../../../redux/services/endPointScansApi';
import { useEffect } from 'react';

const validContentTypes = [
  'application/json',
  'application/html',
  'application/pdf',
  'application/xml',
  'text/plain',
  'text/html',
  'image/jpeg',
  'image/gif',
  'image/png',
  'audio/mpeg',
  'audio/wav',
  'video/mp4',
  'video/avi',
  'application/octet-stream',
];

function EditDetails(props) {
  // const { updateScan } = useFetch();

  const [show, setShow] = useState(false);
  const [auth, setAuth] = useState(null);
  const [api_key, setAPI] = useState(null);
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [access_token, setAccess] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [request_body, setRequestBody] = useState(null);
  const [loading, setLoading] = useState(false);
  const [edit_body, setEditBody] = useState(false);
  const [body_error, setBodyError] = useState(null);
  const [request_header, setRequestHeader] = useState(null);
  const [edit_header, setEditHeader] = useState(false);
  const [header_error, setHeaderError] = useState(null);
  const [method, setMethod] = useState(null);
  const [enterQuery, setEnterQuery] = useState(false);
  const [addHeader, setAddHeader] = useState(false);

  const [headerIsLoading, setHeaderIsLoading] = useState(false);
  const [bodyIsLoading, setBodyIsLoading] = useState(false);
  const [contentTypeIsLoading, setContentTypeIsLoading] = useState(false);

  const [updateScan, { isLoading, error, data }] = useUpdateScanMutation();

  function onChange(newValue) {
    setRequestBody(newValue);
    setEditBody(true);
  }

  function onChangeHeader(newValue) {
    setRequestHeader(newValue);
    setEditHeader(true);
  }
  const [headerParameters, setHeaderParameters] = useState([
    { key: '', value: '' },
  ]);
  const [bodyParameters, setBodyParameters] = useState([
    { key: '', value: '' },
  ]);
  const [pathParameters, setPathParameters] = useState([
    { key: '', value: '' },
  ]);
  const [queryParameters, setQueryParameters] = useState([
    { key: '', value: '' },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const buildBody = () => {
    const updateData = {};

    // if (auth !== null) {
    //   updateData.is_authorization_required = auth;
    // }

    // if (api_key !== null) {
    //   updateData.authorization_type = api_key;
    // }

    // if (username !== null) {
    //   updateData.username = username;
    // }

    // if (email !== null) {
    //   updateData.email = email;
    // }

    // if (password !== null) {
    //   updateData.password = password;
    // }

    // if (access_token !== null) {
    //   updateData.access_token = access_token;
    // }

    if (contentType !== null) {
      updateData.content_type = contentType;
    }

    // if (headerParameters != null) {
    //   updateData.headerParameters = headerParameters;
    // }

    if (request_body !== null) {
      updateData.request_schema = request_body;
    }

    // if (pathParameters != null) {
    //   updateData.pathParameters = pathParameters;
    // }

    // if (queryParameters != null) {
    //   updateData.queryParameters = queryParameters;
    // }
    return updateData;
  };

  // const handleSubmit = async (data) => {
  //   setLoading(true);
  //   let response = await updateScan(props?.data?.path?.[0]?.id, data);
  //   if (response?.status === 200) {
  //     toast.success('Test Case Updated Successfully');
  //   }
  //   setLoading(false);
  // };

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      await updateScan({ testCaseId: props?.data?.path?.[0]?.id, body: data });
    } catch (error) {
      console.log('Error', error);
    } finally {
      setLoading(false);
    }
  };
  // Function to handle changes in header parameters
  const handleHeaderParameterChange = (index, property, newValue) => {
    const updatedParameters = [...headerParameters];
    updatedParameters[index][property] = newValue;
    setHeaderParameters(updatedParameters);
  };

  // Function to add a new input for header parameter
  const addHeaderParameter = () => {
    setAddHeader(true);
    setHeaderParameters([...headerParameters, { key: '', value: '' }]);
  };

  // Function to handle changes in query parameters
  const handleQueryParameterChange = (index, property, newValue) => {
    const updatedParameters = [...queryParameters];
    updatedParameters[index][property] = newValue;
    setQueryParameters(updatedParameters);
  };

  // Function to add a new input for query parameter
  const addQueryParameter = () => {
    setEnterQuery(!enterQuery);
    setQueryParameters([...queryParameters, { key: '', value: '' }]);
  };

  // Function to handle changes in path parameters
  const handlePathParameterChange = (index, property, newValue) => {
    const updatedParameters = [...pathParameters];
    updatedParameters[index][property] = newValue;
    setPathParameters(updatedParameters);
  };

  // Function to add a new input for Path parameter
  const addPathParameter = () => {
    setPathParameters([...pathParameters, { key: '', value: '' }]);
  };

  // Function to handle changes in body parameters
  const handleBodyParameterChange = (index, property, newValue) => {
    const updatedParameters = [...bodyParameters];
    updatedParameters[index][property] = newValue;
    setBodyParameters(updatedParameters);
  };

  // Function to add a new input for Body parameter
  const addBodyParameter = () => {
    setBodyParameters([...bodyParameters, { key: '', value: '' }]);
  };
  const record = props?.data;

  // OPENAPI Tested
  function formatRequestSchema(requestSchema) {
    const formattedData = {};

    for (const key in requestSchema) {
      formattedData[key] = requestSchema[key]?.example;
    }

    return formattedData;
  }

  const formattedHeaders = (headersSchema) => {
    let result = {};
    // console.log(headersSchema);
    if (headersSchema?.length > 0) {
      for (let index = 0; index < headersSchema?.length; index++) {
        result[headersSchema[index]?.name] = headersSchema[index].example;
      }
      // console.log(result);
      return result;
    } else {
      return result;
    }
  };

  function isValidJSON(jsonString) {
    try {
      JSON?.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }

  function convertToRequestSchema(formattedJSON) {
    const jsonString = JSON?.stringify(formattedJSON);

    if (!isValidJSON(jsonString)) {
      setBodyError('The Object is not a valid JSON, please check your syntax');
      return;
    }
    const requestSchema = {};
    try {
      for (const key in JSON?.parse(formattedJSON)) {
        requestSchema[key] = {
          type: 'text',
          example: JSON?.parse(formattedJSON)[key],
        };
      }
    } catch (error) {
      setBodyError(`${error}`);
    }
    return requestSchema;
  }

  const UpdateContentType = async () => {
    setContentTypeIsLoading(true);
    await handleSubmit({ request_schema: contentType });
  };

  const UpdateBody = async () => {
    let request_schema = convertToRequestSchema(request_body);
    await handleSubmit({ request_schema: request_schema });
    setBodyError('');
    setEditBody(false);
  };

  const UpdateHeader = async () => {
    let request_schema = convertToRequestSchema(request_header);
    await handleSubmit({ header_parameters: request_schema });
    setHeaderError('');
    setEditHeader(false);
  };

  useEffect(() => {
    if (!isLoading) {
      setBodyIsLoading(false);
      setHeaderIsLoading(false);
      setContentTypeIsLoading(false);
    }
    if (data) {
      toast.success('Test Case Updated Successfully');
    }

    if (error) {
      toast.error('An error occured');
    }
  }, [data, error, isLoading]);

  return (
    <>
      <i
        className="mdi mdi-border-color cursor-pointer"
        title="Edit"
        onClick={handleShow}
      ></i>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-secondary">Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="text-secondary">
              Test Case Title:{' '}
              <span className="text-primary">{props?.data?.description}</span>
            </p>

            <p className="text-secondary">
              Endpoint:{' '}
              <span className="text-primary">
                {props?.data?.path?.[0]?.path}
              </span>
            </p>
            <p className="text-secondary">
              Request URL:{' '}
              <span className="text-primary">
                {props?.data?.path?.[0]?.path}
                {props?.data?.path_parameters
                  ? props?.data?.path_parameters?.map(
                      (i, index) => props?.data?.path_parameters[index]
                    )
                  : ''}
                {props?.data?.query_parameters
                  ? props?.data?.query_parameters?.map((i) => i.name)
                  : ''}
              </span>
            </p>
          </div>
          <div className="">
            <Form.Group className="mb-4">
              <label htmlFor="selectAuth">Content Type</label>
              <select
                className="form-control text-primary"
                id="selectAuth"
                onChange={(e) => setContentType(e.target.value)}
                defaultValue={record?.content_type}
              >
                <option key={0} value={record?.content_type}>
                  {record?.content_type}
                </option>
                {validContentTypes.map((contentType) => (
                  <option key={contentType} value={contentType}>
                    {contentType}
                  </option>
                ))}
              </select>
              {validContentTypes.includes(contentType) ? null : (
                <span className="text-danger">
                  <i>
                    Content-type does not appear to be correct, please validate
                  </i>
                </span>
              )}
              <button
                className="mt-4 btn btn-secondary float-right"
                disabled={contentTypeIsLoading || !contentType}
                onClick={UpdateContentType}
              >
                {contentTypeIsLoading ? <Spinner /> : 'Update Content Type'}
              </button>
            </Form.Group>

            <div className="p-2 mb-4">
              <h5 className="text-secondary mt-4">Request Body</h5>
              <AceEditor
                placeholder=""
                mode="json"
                theme="monokai"
                name="blah2"
                style={{ width: '100%', height: '300px' }}
                // onLoad={this.onLoad}
                onChange={onChange}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={
                  request_body
                    ? request_body
                    : JSON.stringify(
                        formatRequestSchema(record?.request_schema),
                        null,
                        2
                      )
                }
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
              {header_error ? (
                <span className="text-danger">
                  <i>{header_error}</i>
                </span>
              ) : null}
              <button
                className="mt-4 btn btn-secondary float-right"
                disabled={!edit_body}
                onClick={UpdateBody}
              >
                {/* {loading ? <Spinner /> : 'Update Request Body'} */}
                {bodyIsLoading ? <Spinner /> : 'Update Request Body'}
              </button>
            </div>

            <div className="p-2 mb-4 mt-4">
              <h5 className="text-secondary mt-4">Request Header</h5>

              <AceEditor
                placeholder=""
                mode="json"
                theme="monokai"
                name="blah2"
                style={{ width: '100%', height: '300px' }}
                // onLoad={this.onLoad}
                onChange={onChangeHeader}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={
                  request_header
                    ? request_header
                    : JSON.stringify(
                        formattedHeaders(record?.header_parameters),
                        null,
                        2
                      )
                }
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
              {body_error ? (
                <span className="text-danger">
                  <i>{body_error}</i>
                </span>
              ) : null}
              <button
                className="mt-4 btn btn-secondary float-right"
                disabled={!edit_header}
                onClick={UpdateHeader}
              >
                {loading ? <Spinner /> : 'Update Request Header'}
              </button>
            </div>

            {record?.query_parameters.length !== 0
              ? record?.query_parameters?.map((parameter, index) => (
                  <div key={index} className="flex justify-between">
                    {/* <Form.Group className="col-6 mb-4">
                      <label htmlFor={`query_key_${index}`}>
                        Query Parameter {index + 1} Key
                        <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id={`query_key_${index}`}
                        placeholder="Enter query key"
                        value={parameter.name}
                        required
                        onChange={(e) =>
                          handleQueryParameterChange(
                            index,
                            'key',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group> */}
                    {/* 
                    <Form.Group className="col-6 mb-4">
                      <label htmlFor={`query_value_${index}`}>
                        Query Parameter {index + 1} Value
                        <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id={`query_value_${index}`}
                        placeholder="Enter query value"
                        value={parameter.example}
                        required
                        onChange={(e) =>
                          handleQueryParameterChange(
                            index,
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group> */}
                  </div>
                ))
              : null}
            {/* {enterQuery ? (
              queryParameters.map((parameter, index) => (
                <div key={index} className="flex justify-between">
                  <Form.Group className="col-6 mb-4">
                    <label htmlFor={`query_key_${index}`}>
                      Query Parameter {index + 1} Key
                      <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id={`query_key_${index}`}
                      placeholder="Enter query key"
                      value={parameter.key}
                      required
                      onChange={(e) =>
                        handleQueryParameterChange(index, 'key', e.target.value)
                      }
                    />
                  </Form.Group>

                  <Form.Group className="col-6 mb-4">
                    <label htmlFor={`query_value_${index}`}>
                      Query Parameter {index + 1} Value
                      <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id={`query_value_${index}`}
                      placeholder="Enter query value"
                      value={parameter.value}
                      required
                      onChange={(e) =>
                        handleQueryParameterChange(
                          index,
                          'value',
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </div>
              ))
            ) : (
              <div className="text-primary">
                {' '}
                <label>Add New Query Parameter</label>
              </div>
            )} */}
            {/* <button
              className="mb-4 btn-secondary rounded"
              onClick={addQueryParameter}
            >
              Add More
            </button> */}
            {/* 
            {record?.path_parameters.length !== 0
              ? record?.path_parameters?.map((parameter, index) => (
                  <div key={index} className="flex justify-between">
                    <Form.Group className="col-6 mb-4">
                      <label htmlFor={`path_key_${index}`}>
                        Path Parameter {index + 1} Key
                        <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id={`path_key_${index}`}
                        placeholder="Enter path key"
                        value={parameter.key}
                        required
                        onChange={(e) =>
                          handlePathParameterChange(
                            index,
                            'key',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group className="col-6 mb-4">
                      <label htmlFor={`path_value_${index}`}>
                        Path Parameter {index + 1} Value
                        <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id={`path_value_${index}`}
                        placeholder="Enter path value"
                        value={parameter.value}
                        required
                        onChange={(e) =>
                          handlePathParameterChange(
                            index,
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>
                  </div>
                ))
              : null} */}
            {/* {pathParameters.map((parameter, index) => (
              <div key={index} className="flex justify-between">
                <Form.Group className="col-6 mb-4">
                  <label htmlFor={`path_key_${index}`}>
                    Path Parameter {index + 1} Key
                    <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id={`path_key_${index}`}
                    placeholder="Enter path key"
                    value={parameter.key}
                    required
                    onChange={(e) =>
                      handlePathParameterChange(index, 'key', e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group className="col-6 mb-4">
                  <label htmlFor={`path_value_${index}`}>
                    Path Parameter {index + 1} Value
                    <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id={`path_value_${index}`}
                    placeholder="Enter path value"
                    value={parameter.value}
                    required
                    onChange={(e) =>
                      handlePathParameterChange(index, 'value', e.target.value)
                    }
                  />
                </Form.Group>
              </div>
            ))}
            <button
              className="mb-4 btn-secondary rounded"
              onClick={addPathParameter}
            >
              Add More
            </button>
            {record?.request_schema
              ? Object.keys(record?.request_schema).map((parameter, index) => (
                  <div key={index} className="flex justify-between">
                    <Form.Group className="col-6 mb-4">
                      <label htmlFor={`body_key_${index}`}>
                        Request Body {parameter} Key
                        <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id={`body_key_${index}`}
                        placeholder="Enter body key"
                        value={parameter}
                        required
                        onChange={(e) =>
                          handleBodyParameterChange(
                            index,
                            'key',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group className="col-6 mb-4">
                      <label htmlFor={`body_value_${index}`}>
                        Request Body Parameter {index + 1} Value
                        <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id={`body_value_${index}`}
                        placeholder="Enter body value"
                        value={record?.request_schema[parameter]?.example}
                        required
                        onChange={(e) =>
                          handleBodyParameterChange(
                            index,
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>
                  </div>
                ))
              : null}
            {bodyParameters.map((parameter, index) => (
              <div key={index} className="flex justify-between">
                <Form.Group className="col-6 mb-4">
                  <label htmlFor={`body_key_${index}`}>
                    Request Body {index + 1} Key
                    <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id={`body_key_${index}`}
                    placeholder="Enter body key"
                    value={parameter.key}
                    required
                    onChange={(e) =>
                      handleBodyParameterChange(index, 'key', e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group className="col-6 mb-4">
                  <label htmlFor={`body_value_${index}`}>
                    Request Body Parameter {index + 1} Value
                    <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id={`body_value_${index}`}
                    placeholder="Enter body value"
                    value={parameter.value}
                    required
                    onChange={(e) =>
                      handleBodyParameterChange(index, 'value', e.target.value)
                    }
                  />
                </Form.Group>
              </div>
            ))}
            <button
              className="mb-4 btn-secondary rounded"
              onClick={addBodyParameter}
            >
              Add More
            </button> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditDetails;
