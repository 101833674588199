import { useEffect } from 'react';
import { useState } from 'react';

const useInfiniteScroolPagination = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const fetchMoreData = () => setPageNumber((prevPage) => prevPage + 1);

  useEffect(() => {
    if (totalPages === null && currentPage === null) setHasMore(true);
    else if (totalPages > pageNumber) setHasMore(true);
    else setHasMore(false);
  }, [totalPages, currentPage, pageNumber]);

  return { pageNumber, hasMore, fetchMoreData, setTotalPages, setCurrentPage };
};

export default useInfiniteScroolPagination;
