import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

function MetaDataModal({ modalData, showModal, setShowModal }) {
  const handleClose = () => setShowModal(false);
console.log(modalData)
  return (
    <>
      <Modal show={showModal} onHide={setShowModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-secondary">
            Request MetaData{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AceEditorComp
            name={'Request Header'}
            content={modalData.request_header}
          />
          <AceEditorComp
            name={'Request Body'}
            content={modalData.request_payload}
          />
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const AceEditorComp = ({ content, name }) => (
  <div className="mt-4">
    <h5 className="text-secondary">{name}</h5>
    <div className="p-2" style={{ border: '1px solid' }}>
      <AceEditor
        mode="json"
        theme="monokai"
        value={JSON.stringify(content, null, 2)}
        style={{ width: '100%', height: '100px' }}
        name="ace-editor_req_header"
      />
    </div>
  </div>
);

export default MetaDataModal;
