import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ data, isLoading, error }) => {
  const [options, setOptions] = useState({
    chart: {
      id: 'line-chart',
    },
    xaxis: {
      categories: [],
    },
    colors: ['#FF5733'],
    grid: {
      show: false, // Remove grid lines
    },
  });

  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);

  const [chartWidth, setChartWidth] = useState(0);
  const chartContainer = useRef(null);

  useEffect(() => {
    if (data) {
      const counts = data?.map((item) => item?.count) || [];
      const monthNames = data.map((item) => item?.month_name) || [];

      setOptions((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: monthNames,
        },
      }));

      setSeries([
        {
          data: counts,
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartContainer.current) {
        const width = chartContainer.current.offsetWidth;
        setChartWidth(width);
      }
    };

    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [chartContainer]);

  if (isLoading) {
    return (
      <div
        ref={chartContainer}
        className="w-full line-chart flex justify-center items-center"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        ref={chartContainer}
        className="w-full  line-chart flex justify-center items-center text-base md:text-xl font-serif   text-[#000]"
      >
        An error occurred
      </div>
    );
  }

  if (!data || data?.length === 0) {
    return (
      <div
        ref={chartContainer}
        className="w-full line-chart h-full flex justify-center items-center text-base md:text-xl  font-serif   text-[#000]"
      >
        No Record Found
      </div>
    );
  }

  return (
    <div ref={chartContainer} className="w-full line-chart">
      <Chart
        options={options}
        series={series}
        type="line"
        width={`${chartWidth}px`}
        height={'80%'}
      />
    </div>
  );
};

export default LineChart;
