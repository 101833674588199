import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  collectionDetails: {
    selectedCollectionId: '',
    selectedCollectionName: '',
    selectedCollectionToken: '',
    selectedCollection: '',
    endpointName: '',
    selectedEndpoint: '',
  },
};

export const collectionSlice = createSlice({
  name: 'collectionSlice',
  initialState,
  reducers: {
    setCollectionDetails: (state, { payload: collection }) => {
      localStorage.setItem('collection_id', collection?.collections.id);
      localStorage.setItem('collection_name', collection?.collections?.name);
      localStorage.setItem(
        'collection_token',
        collection?.collections?.collection_token
      );

      state.collectionDetails.selectedCollectionId =
        collection?.collections?.id;
      state.collectionDetails.selectedCollectionName =
        collection?.collections?.name;
      state.collectionDetails.selectedCollectionToken =
        collection?.collections?.collection_token;
    },

    retrieveCollectionDetails: (state) => {
      const collectionId =
        !state.collectionDetails.selectedCollectionId &&
        localStorage.getItem('collection_id');
      const collectionName =
        !state.collectionDetails.selectedCollectionName &&
        localStorage.getItem('collection_name');
      const collectionToken =
        !state.collectionDetails.selectedCollectionToken &&
        localStorage.getItem('collection_token');

      if (collectionId)
        state.collectionDetails.selectedCollectionId = collectionId;

      if (collectionName)
        state.collectionDetails.selectedCollectionName = collectionName;

      if (collectionToken)
        state.collectionDetails.selectedCollectionToken = collectionToken;
    },

    setEndpointDetails: (state, { payload: item }) => {
      localStorage.setItem('endpoint_name', item?.path);
      localStorage.setItem('selected_endpoint', JSON.stringify(item));

      state.collectionDetails.endpointName = item?.path;
      state.collectionDetails.selectedEndpoint = item;
    },

    retrieveEndpointDetails: (state) => {
      const endpointName =
        !state.collectionDetails.endpointName &&
        localStorage.getItem('endpoint_name');
      const selectedEndpoint =
        !state.collectionDetails.selectedEndpoint &&
        localStorage.getItem('selected_endpoint');

      if (endpointName) state.collectionDetails.endpointName = endpointName;

      if (selectedEndpoint)
        state.collectionDetails.selectedEndpoint = selectedEndpoint;
    },
  },
});

export const {
  setCollectionDetails,
  retrieveCollectionDetails,
  setEndpointDetails,
  retrieveEndpointDetails,
} = collectionSlice.actions;
