import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import ToolTip from "../../../shared/Tooltip.js";
import { useUpdateCollectionMutation } from "../../../../redux/services/collectionApi.js";

function UploadCollection({ setDisabled }) {
  const [fileName, setFileName] = useState("Upload Valid API Collection (.json)");
  const [file, setFile] = useState(null);
  const [JSONtype, setJSONType] = useState("openapi");
  const [postman_version, setPostmanVersion] = useState("v2");
  const [updateCollection, { isSuccess, isError, error }] =
    useUpdateCollectionMutation();
  const collectionId = localStorage.getItem("collection_id");
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB file size limit
  const [loading, setLoading] = useState(false);

  const showFile = (file) => {
    if (file) {
      setFileName(file.name);
      setFile(file);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!file) {
      toast.error("Please upload a valid OpenAPI JSON file");
      setLoading(false);
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setLoading(false);
      toast.error(
        "File size is too large. Please upload a file smaller than 5MB."
      );
      return;
    }

    const formData = new FormData();
    formData.append("content", file);
    formData.append("JSONType", JSONtype);
    formData.append("version", postman_version);

    try {
      const response = await updateCollection({
        collectionId: collectionId,
        formData: formData,
      }).unwrap();

      console.log("File Uploaded Successfully", response);
      toast.success("File Uploaded Successfully");
      setLoading(false);
      setDisabled(false);
    } catch (uploadError) {
      setLoading(false);
      console.error(uploadError);
      toast.error(uploadError?.data?.message || "Failed to upload");
    }
  };

  return (
    <div className="bg-white rounded-[10px] my-8">
      <div className="card-body">
        <Form.Group>
          <label>File upload</label>
          <div className="custom-file">
            <Form.Control
              type="file"
              className="form-control visibility-hidden"
              id="customFileLang"
              lang="es"
              accept=".json"
              onChange={(e) => showFile(e.target.files[0])}
            />
            <label className="custom-file-label" htmlFor="customFileLang">
              {fileName}
            </label>
          </div>
        </Form.Group>
        <Form.Group className="mt-4">
          <label htmlFor="collectiontype">
            Collection Type <span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            id="collectiontype"
            required
            onChange={(e) => setJSONType(e.target.value)}
          >
            <option value="openapi">OpenAPI Collection</option>
            <option value="postman">Postman</option>
          </select>
        </Form.Group>
        {JSONtype === "postman" && (
          <Form.Group className="mt-4">
            <label htmlFor="collectionversion">
              Postman Version{" "}
              <span className="text-danger">
                <ToolTip description="Ensure to select the correct Postman Version if your collection is Postman" />
              </span>
            </label>
            <select
              className="form-control"
              id="collectionversion"
              required
              onChange={(e) => setPostmanVersion(e.target.value)}
            >
              <option value="v2" className="py-4">
                V2
              </option>
              <option value="v2.1" className="py-4">
                V2.1
              </option>
            </select>
          </Form.Group>
        )}
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <button
              type="button"
              className="btn btn-primary btn-icon-text float-right mt-4"
              onClick={handleUpload}
            >
              {loading ? (
                <Spinner animation="border" role="status" />
              ) : (
                <>
                  <i className="mdi mdi-file-check btn-icon-prepend"></i>
                  Submit
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadCollection;
