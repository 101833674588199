import React from "react";
import { Badge } from "react-bootstrap";
import "./index.css";
import ScanDetails from "../../modals/details";
import EditDetails from "../../modals/editDetails";

const PerformanceTable = ({
  data,
  env,
  collectionId,
  runOneScan,
  automatedScanIsLoading,
  scanDataIsLoading,
}) => {

  const handleScan = async (endpoint_id) => {
    try {
      await runOneScan({
        endpoint_id: endpoint_id,
        environment: env,
      });
    } catch (error) {
      console.log("Scan error", error);
    }
  };

  return (
    <div style={{ overflowY: 'auto' }}>
      <table className="w-full bg-white overflow-x">
        <thead>
          <tr className="text-[#09294d] bg-[#00000010]">
            <th scope="col" className="px-6 py-3 text-nowrap">
              Test Case Title
            </th>
            <th scope="col" className="px-6 py-3 text-nowrap table-col-max-width">
              Path
            </th>
            <th scope="col" className="px-6 py-3 text-nowrap">
              Request Method
            </th>
            <th scope="col" className="px-6 py-3 text-nowrap">
              Elapsed Time
            </th>
            <th scope="col" className="px-6 py-3 text-nowrap">
              Response Code
            </th>
            <th scope="col" className="px-6 py-3 text-nowrap">
              Test Status
            </th>
            <th scope="col" className="px-6 py-3 text-nowrap">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white text-[14px]">
          {data?.map((item, key) => (
            <tr className="text-[#09294d]">
              <td className="px-6 py-[10px] text-nowrap">{item.name}</td>
              <td className="px-6 py-[10px] text-nowrap table-col-max-width">
                <span className="text-secondary">{item?.path?.[0]?.path}</span>
              </td>
              <td className="px-6 py-[10px] text-nowrap font-bold">
                <span>
                  <Badge className="text-white p-2 w-[50px]">
                    {item.methods}
                  </Badge>
                </span>
              </td>
              <td className="px-6 py-[10px] text-nowrap">
                <span className="text-secondary">
                  {parseFloat(item.result?.[0]?.elapsed_time).toFixed(3) === "NaN" ? "--" : parseFloat(item.result?.[0]?.elapsed_time).toFixed(3)}
                </span>
              </td>
              <td className="px-6 py-[10px] text-nowrap">
                {item?.result?.length === 0 ? (
                  "--"
                ) : (
                  <span>
                    <Badge
                      pill
                      className="text-white p-2"
                      bg={
                        item?.result?.[0]?.response_code?.startsWith("5")
                          ? "danger"
                          : item?.result?.[0]?.response_code?.startsWith("4")
                          ? "danger"
                          : item?.result?.[0]?.response_code?.startsWith("3")
                          ? "info"
                          : item?.result?.[0]?.response_code?.startsWith("2")
                          ? "success"
                          : "primary"
                      }
                    >
                      <i className="mdi mdi-security"> </i>
                      {item?.result?.[0]?.response_code}
                    </Badge>
                  </span>
                )}
              </td>
              <td className="px-6 py-[10px] text-nowrap font-bold">
                {item?.result?.length === 0 ? (
                  "--"
                ) : (
                  <span>
                    <Badge
                      pill
                      className="text-white p-2"
                      bg={
                        item?.result?.[0]?.test_status === "failed"
                          ? "danger"
                          : item?.result?.[0]?.test_status === "skipped"
                          ? "warning"
                          : item?.result?.[0]?.test_status === "passed"
                          ? "success"
                          : "info"
                      }
                    >
                      <i
                        className={
                          item?.result?.[0]?.test_status === "passed"
                            ? "mdi mdi-check-circle-outline"
                            : "mdi mdi-alert-circle-outline"
                        }
                      >
                      </i>
                      {item?.result?.[0]?.test_status}
                    </Badge>
                  </span>
                )}
              </td>
              <td className="px-6 py-[10px] text-nowrap font-bold">
                <div className="flex justify-content-between">
                  <EditDetails data={item} />
                  <ScanDetails data={item} />
                  {automatedScanIsLoading ? (
                    <i
                      className={`mdi mdi-reload cursor-pointer${
                        automatedScanIsLoading ? " spin-icon" : ""
                      }`}
                      title="Run Scan"
                      // onClick={handleScan}
                    ></i>
                  ) : (
                    <i
                      className={`mdi mdi-reload cursor-pointer`}
                      title="Run Scan"
                      onClick={() => handleScan(item?.id)}
                    ></i>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PerformanceTable;
