import React, { useState , useEffect} from "react";
import { useAuth } from "../../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Spinner } from "react-bootstrap";
import { useCreateProjectMutation,useCreateProjectAutoMutation  } from "../../../../redux/services/collectionApi";

export const CreateProject = ({ setDisabled, setManualUpload }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [key, setKey] = useState("");
  const [collection, setCollection] = useState("");
  const [errMessage, setErrMsg] = useState("");
  const [manual, setManuel] = useState(false);
  const [createProject, isSuccess] = useCreateProjectMutation();
  const [createProjectAuto] = useCreateProjectAutoMutation();
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push("/login");
  }
  useEffect(() => {
    if (errMessage) {
        const timer = setTimeout(() => {
            setErrMsg("");
        }, 3000);
        return () => clearTimeout(timer);
    }
}, [errMessage]);
  // create project
  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response;
    if (manual) {
      response = await createProject({
        name: name,
        description: description,
      });
    } else {
      response = await createProjectAuto({
        key: key,
        collection: collection,
    })
  }
  if (response?.error) {
    toast.error(response?.error?.data?.error || "Project Creation Failed, Ensure Your Entries Are Correct" );
  }
  else{
    toast("Project Created Successfully");
    setDisabled(false);
    setManualUpload(manual)
    localStorage.setItem('collection_id', response?.data?.id)
  }
   
    if (isSuccess) {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-[10px] my-8">
      <ToastContainer />
      <div className="card-body">
        <h2 className="card-title text-secondary">Create Project! </h2>
        <p className="card-description">
          Empower Your Collection Management Journey with Your Project as the
          First Essential Step
        </p>
        <form className="forms-sample" onSubmit={(e) => handleUpload(e)}>
          <div className="form-check text-primary">
            <label className="form-check-label">
              <input
                type="checkbox"
                checked={!manual}
                defaultChecked
                className="form-check-input"
                onChange={() => setManuel(false)}
              />
              <i className="input-helper"></i>
              Create Automatically With Postman Keys
            </label>
          </div>
          <div className="form-check text-primary">
            <label className="form-check-label">
              <input
                type="checkbox"
                checked={manual}
                className="form-check-input"
                onChange={() => setManuel(true)}
              />
              <i className="input-helper"></i>
              Create Manually By Uploading Collection
            </label>
          </div>
          {!manual ? (
            <div className="mt-4">
              <Form.Group className="col-12">
                <label htmlFor="postman_key" className="text-primary">
                  Postman API Key<span className="text-danger">*</span>{"     "}
                  <a className="font-[8px]" href="https://learning.postman.com/docs/developer/postman-api/authentication/" rel="noreferrer" target="_blank">Generate API Key</a>
                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="postman_key"
                  placeholder="Enter a valid postman key"
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!value.startsWith("PMAK")) {
                      setErrMsg("Postman Key should start with PMAK");
                      return;
                    }
                    setErrMsg("");
                    setKey(value);
                  }}
                />
                {errMessage ? (
                  <label className="mt-1 text-danger">{errMessage}</label>
                ) : null}
              </Form.Group>
              <Form.Group className="col-12 mt-4">
                <label htmlFor="description" className="text-primary">
                  Postman Collection UID<span className="text-danger">*</span>
                  <a className="font-[8px]" href="https://community.postman.com/t/where-do-you-see-your-own-uid-for-collection/3537/3" rel="noreferrer" target="_blank">Generate API Key</a>

                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="collection"
                  placeholder="Enter your collection UID from postman"
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length < 16) {
                      setErrMsg(
                        "Postman Collection ID Should Be A Valid UID"
                      );
                      return;
                    }
                    setErrMsg("");
                    setCollection(value);
                  }}
                />
                {errMessage ? (
                  <label className="mt-1 text-danger">{errMessage}</label>
                ) : null}
              </Form.Group>
            </div>
          ) : (
            <div className="mt-4">
              <Form.Group className="col-12">
                <label htmlFor="project_name" className="text-primary">
                  Project Name<span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="project_name"
                  placeholder="Enter a name for your new project"
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 50) {
                      setErrMsg("Collection name cannot exceed 50 characters");
                      return;
                    }
                    setErrMsg("");
                    setName(value);
                  }}
                />
                {errMessage ? (
                  <label className="mt-1 text-danger">{errMessage}</label>
                ) : null}
              </Form.Group>
              <Form.Group className="col-12 mt-4">
                <label htmlFor="description" className="text-primary">
                  Project Description<span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Enter a short description for your project"
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 500) {
                      setErrMsg(
                        "Collection Description cannot exceed 500 characters"
                      );
                      return;
                    }
                    setErrMsg("");
                    setDescription(value);
                  }}
                />
                {errMessage ? (
                  <label className="mt-1 text-danger">{errMessage}</label>
                ) : null}
              </Form.Group>
            </div>
          )}
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 mt-4">
              <button
                type="submit"
                className="btn btn-primary mr-2 float-right w-[75px]"
              >
                {loading ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
