import React, { useState, useEffect, useContext } from "react";
import { GetCollections } from "../API";
import { useHistory } from "react-router-dom";

const CollectionContext = React.createContext();

export const CollectionProvider = ({ children }) => {
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [selectedCollectionName, setSelectedCollectionName] = useState(null);
  const [selectedCollectionToken, setSelectedCollectionToken] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const [collections, setCollections] = useState([]);
  const [errMessage, setErrMsg] = useState("");
  const history = useHistory();

  const setCollectionId = (id) => {
    setSelectedCollectionId(id);
    localStorage.setItem("collection_id", id);
  };

  const setCollectionName = (name) => {
    setSelectedCollectionName(name);
    localStorage.setItem("collection_name", name);
  };

  const setCollectionToken = (token) => {
    setSelectedCollectionToken(token);
    localStorage.setItem("collection_token", token);
  };

  const selectCollection = (collection) => {
    localStorage.setItem("collection_id", collection?.id);
    localStorage.setItem("collection_name", collection?.name);
    localStorage.setItem("collection_token", collection?.collection_token);
    setCollectionId(collection?.id);
    setCollectionName(collection?.name);
    setCollectionToken(collection?.collection_token);
    setSelectedCollection(collection);
    history.push('/endpoints')

  };

  // useEffect(() => {
    const RetrieveCollections = async () => {
      // check if collection only selection
      const set_id = localStorage.getItem("collection_id");
      const set_name = localStorage.getItem("collection_name");
      const set_token = localStorage.getItem("collection_token");
      const { message, data, success } = await GetCollections();
      if (!set_id || !set_name || !set_token) {
        if (success) {
          setCollections(data);
          setCollectionId(data?.[0]?.document_id);
          setCollectionName(data?.[0]?.collections?.name);
          setCollectionToken(data?.[0]?.collection_token);
          setSelectedCollection(
            data.filter((i) => i?.collections?.id === data?.[0]?.document_id)
          );
        } else {
          setErrMsg(message);
        }
      } else {
        setCollections(data);
        setCollectionId(set_id);
        setCollectionName(set_name);
        setCollectionToken(set_token);
        setSelectedCollection(
          data.filter((i) => i?.collections?.id === set_id)
        );
      }
    };
    // RetrieveCollections();
  // }, []);

  const contextValue = {
    selectedCollectionId,
    setCollectionId,
    collections,
    errMessage,
    selectedCollectionName,
    selectedCollectionToken,
    setCollectionName,
    setCollectionToken,
    selectedCollection,
    selectCollection,
    RetrieveCollections
  };

  return (
    <CollectionContext.Provider value={contextValue}>
      {children}
    </CollectionContext.Provider>
  );
};

export const useCollectionContext = () => {
  const context = useContext(CollectionContext);
  if (context === undefined) {
    throw new Error(
      "useCollectionContext must be used within a CollectionProvider"
    );
  }
  return context;
};
