import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RecentRunStat } from './component/RecentRunStat';
import TestReports from './component/TestReports';
import TestCases from './component/TestCases';
import {
  useGetRecentRunsQuery,
  useGetTestCaseQuery,
  useGetTestReportQuery,
} from '../../../redux/services/endPointScansApi';
import ErrorDisplay from '../../components/Error';

const PerformanceChart = () => {
  const history = useHistory();
  const testCaseNumberOfDays = useSelector(
    (store) => store?.performanceTestDays.testCaseNumberOfDays
  );

  const testReportNumberOfDays = useSelector(
    (store) => store?.performanceTestDays.testReportNumberOfDays
  );

  const collectionId = localStorage.getItem('collection_id');

  const {
    data: recentRunsData,
    isLoading: recentRunsIsLoading,
    isFetching: recentRunsIsFetching,
    error: recentRunsError,
  } = useGetRecentRunsQuery(collectionId);

  const {
    data: testReportData,
    isLoading: testReportIsLoading,
    isFetching: testReportIsFetching,
    error: testReportError,
  } = useGetTestReportQuery({
    collectionId,
    duration: testReportNumberOfDays ? testReportNumberOfDays : 30,
  });

  const {
    data: testCaseData,
    isLoading: testCaseIsLoading,
    isFetching: testCaseDataIsFetching,
    error: testCaseError,
  } = useGetTestCaseQuery({
    collectionId,
    duration: testCaseNumberOfDays ? testCaseNumberOfDays : 30,
  });

  useEffect(() => {
    if (!collectionId) {
      const redirectTimeout = setTimeout(() => {
        history.push('/dashboard/collections');
      }, 4000);
      return () => clearInterval(redirectTimeout);
    }
  }, [collectionId, history]);

  if (!collectionId)
    return (
      <div className="flex flex-col items-center mb-1">
        <ErrorDisplay errorMessage="No Project Selected" errorCode={404} />
        <h4 className="text-primary mt-4 mb-4">Redirecting Shortly</h4>
      </div>
    );

  return (
    <div className="w-full mb-7">
      <RecentRunStat
        recentRunsData={recentRunsData}
        recentRunsIsLoading={recentRunsIsLoading}
        recentRunsError={recentRunsError}
        recentRunsIsFetching={recentRunsIsFetching}
        title="Recent Runs"
        details={true}
      />
      <TestReports
        testReportIsLoading={testReportIsLoading}
        testReportIsFetching={testReportIsFetching}
        testReportError={testReportError}
        testReportData={testReportData}
      />
      <TestCases
        testCaseIsLoading={testCaseIsLoading}
        testCaseDataIsFetching={testCaseDataIsFetching}
        testCaseError={testCaseError}
        testCaseData={testCaseData}
      />
    </div>
  );
};

export default PerformanceChart;
