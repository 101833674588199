import {useState, useEffect} from 'react';

import { LogTable } from './LogTable';
import { usePaginateSourceCodeReviewQuery } from "../../../redux/services/codeAnalysisApi";

const Analytics = (props) => {
  const [data, setData] = useState(props?.data);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextLink, setNextLink] = useState(false);
  const [prevLink, setPrevLink] = useState(false);
  const collection_id = localStorage.getItem("collection_id");
  const { data: paginatedData } = usePaginateSourceCodeReviewQuery({
    collectionId: collection_id,
    pageNumber: pageNumber,
  });
  const result = paginatedData?.result;

  const getNextPage = () => {
    setPageNumber(pageNumber + 1);
    setNextLink(true);
  };

  const getPrevPage = () => {
    setPageNumber(pageNumber - 1);
    setPrevLink(true);
  };

  useEffect(() => {
    if (nextLink || prevLink) {
      setData(result);
    }
  }, [nextLink, prevLink, result, data]);
  const CodeReview = () => {
    props.runCodeReview({ collection: props.collectionId });
  };
  return (
    <>
      <>
        <div className="flex justify-between w-full mb-4">
          <h4 className="text-secondary">Source Code Vulnerabilities</h4>
          <button
            className="btn btn-info float-right"
            onClick={CodeReview}
            disabled={props.runCodeReviewIsLoading}
          >
            Run New Analysis
          </button>
        </div>
        <LogTable
          setID={props?.setID}
          setView={props?.setView}
          setdata={props?.setdata}
          data={data}
        />
        <div className="flex justify-between mt-4 mb-8">
          <button
            className="btn text-white bg-primary py-2 px-4"
            disabled={paginatedData?.previous === null}
            onClick={getPrevPage}
          >
            Prev
          </button>
          <button
            className="btn text-white bg-primary py-2 px-4"
            disabled={paginatedData?.next === null}
            onClick={getNextPage}
          >
            Next
          </button>
        </div>
      </>
    </>
  );
};

export default Analytics;
