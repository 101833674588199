import React, { useEffect, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SignIn } from "./views/user-pages/auth/SignIn";
import { ResetPassword } from "./views/user-pages/auth/ResetPassword";
import { FirstLoginPasswordReset } from "./views/user-pages/auth/FirstLoginPasswordReset";
import { NewPassword } from "./views/user-pages/auth/NewPassword";
// import { Register } from './views/user-pages/Register';
import { Onboarding } from "./views/user-pages/onboarding";
import { Profile } from "./views/user-pages/profile";
import Threatv2 from "../app/views/threatv2";
import Spinner from "../app/shared/Spinner";
// import Banner from "./banner/pro-banner";
import { Billing } from "./views/user-pages/billing";
import { NewProjectv2 } from "./views/v2/Projects/new_project";
import { ThreatSetup } from "./views/user-pages/threat_set_up";
// import { Endpoints } from "./views/endpoints";
import Code_Analysis from "./views/static_code";
// import { AutomatedScanV2 } from './views/automated_v2';
import LogbyIP from "./views/threatv2/LogIP";
// import  SecurityScanV2  from "./views/securityScanv2";
// import AnalyticsPage from "./views/analytics/AnalyticsPage";
import Error404 from "./error-pages/Error404";
import BillingPage from "./views/user-pages/BillingPage";
// import AllCourses from './views/training';
// import SingleCourse from './views/training/singleCourse';
// import CourseDetails from './views/training/courseDetails';
// import { StaticCode } from './views/securityScanv2/static_code';
// import DetectedThreats from "./views/securityScanv2/detected";
import ProjectConfiguration from "./views/configuration";
import DependencyAlert from "./views/dependency_review";
import ViewEndpoint from "./views/endpoints/tabs/viewEndPoint";
import RequestService from "./views/requestService";
import { EndpointTable } from "./views/v2/endpoints/endpoint_table";
import ThreatMap from "./views/threatMap";
import { Register } from "./views/user-pages/Register";
import { useAuthProvider } from "./providers/auth.provider";
import PrivateRoute from "../utils/privateRoute";
import { ProceedWithMultifactorAuthentication } from "./views/user-pages/auth/MultifactorAuthentication/ProceedWithMultifactorAuthentication";
import { ConfirmMultifactorAuthentication } from "./views/user-pages/auth/MultifactorAuthentication/ConfirmMultifactorAuthentication";
import { ScanQrCode } from "./views/user-pages/auth/MultifactorAuthentication/ScanQrCode";
import PerformanceTest from "./views/performance-test/PerformanceTest";
import PerformanceTable from "./views/performance-test/component/PerformanceTable";
import SourceCodeReview from "./views/source-code-review";
import PerformanceChart from "./views/performance-test";
import NewProject from "./views/v2/Projects/new_project";
import SecurityChecklist from "./views/security_checklist";
import ConfigurePerformanceTests from "./views/configure-performance-tests";
import Vulnerability from "./views/vulnerability";
import ApiSecurity from "./views/api-security";
import AllEndpoints from "./views/all_endpoints";
import ApiAbuseDetails from "./views/api-abuse";
import VulnerabilityDetails from "./views/vulnerability-details";
import FuzzingTest from "./views/fuzzingTest";
import ConformanceScan from "./views/conformance-scan";
import SourceCodeAnalysis from "./views/source-code-analysis";
import DependencyReviewAnalysis from "./views/dependency-review-analysis";
import ViewRules from "./views/view-rules";

// import Table from './views/performance-test/component/Table';

// import { AutomatedScan12 } from './views/analytics/automated-scan';

// const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Collections = lazy(() => import("./views/v2/Projects/all_projects"));
// const ConformanceScan = lazy(() => import("./views/conformance"));
// const SecurityScan = lazy(() => import("./to_delete/security"));
// const FunctionScan = lazy(() => import("./views/functional"));
const PenetrationTest = lazy(() => import("./views/penetration"));
const Users = lazy(() => import("./views/users"));
const Terms = lazy(() => import("./views/user-pages/terms"));
const PdfViewer = lazy(() => import("./views/user-pages/uploads"));
const Environments = lazy(() => import("./views/environments"));
// const Vulnerability = lazy(() => import('./views/vulnerability'));

// const Login = lazy(() => import('./user-pages/Login'));
// const Register1 = lazy(() => import('./views/user-pages/Register'));

export const privateRoutes = [
  "/dashboard/collections",
  "/dashboard/penetration-test",
  "/dashboard/new_onboarding",
  "/dashboard/profile",
  "/dashboard/environments",
  "/dashboard/request-service",
  "/dashboard/threat-map",
  "/dashboard/new-project",
  "/dashboard/threat-set-up",
  "/dashboard/threats",
  "/dashboard/monitor/:id",
  "/dashboard/endpoints/:id",
  "/dashboard/endpoints",
  "/dashboard/automated-scan",
  "/dashboard/code-reviews",
  "/dashboard/configuration",
  "/dashboard/dependencies",
  "/dashboard/performance-tests",
  "/dashboard/demo",
  "/dashboard/security-checklist",
  "/dashboard/api-security",
  "/dashboard/all-endpoints",
  "/dashboard/api-abuse-details",
  "/dashboard/conformance-scan",
  "/dashboard/view-rules",
];

export const otherRoutes = [
  "/login",
  "/new-password",
  "/reset-password",
  "/register",
  "/first-login-reset-password",
  "/proceed-2fa",
  "/confirm-2fa",
  "/activate-2fa",
  "/new_onboarding",
];

const AppRoutes = () => {
  const { isLoading } = useAuthProvider();

  return (
    <>
      {isLoading && <Spinner />}
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* Main Routes */}
          {/* <Route exact path="/" component={Login} /> */}
          {/* <Route exact path="/" component={Auth} /> */}
          {/* <Route exact path="/login" component={SignIn} /> */}
          <Route path="/login" component={SignIn} />
          <Route path="/new-password" component={NewPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/register" component={Register} />
          <Route
            path="/first-login-reset-password"
            component={FirstLoginPasswordReset}
          />
          <Route
            path="/proceed-2fa"
            component={ProceedWithMultifactorAuthentication}
          />
          <Route
            path="/confirm-2fa"
            component={ConfirmMultifactorAuthentication}
          />

          {/* <Route
            path="/activate-2fa"
            component={ActivateMultifactorAuthentication}
          /> */}
          <Route path="/activate-2fa" component={ScanQrCode} />
          <Route path="/new_onboarding" component={Onboarding} />
          {/* <Route path="/" component={NewPassword} /> */}
          {/* <Route exact path="/dashboard" component={Dashboard} /> */}
          <PrivateRoute path="/dashboard/collections" component={Collections} />
          {/* <Route path="/all_courses" component={AllCourses} /> 
          <Route path="/course" component={SingleCourse} />
           <Route path="/course-details" component={CourseDetails} /> */}
          {/* <Route path="/security-scan" component={SecurityScanV2} /> */}
          <PrivateRoute
            path="/dashboard/penetration-test"
            component={PenetrationTest}
          />

          {/* <Route path="/login" component={Login} /> */}
          {/* <Route path="/register" component={Register} /> */}
          <PrivateRoute path="/dashboard/terms_conditions" component={Terms} />
          <PrivateRoute
            path="/dashboard/new_onboarding"
            component={Onboarding}
          />
          <PrivateRoute path="/dashboard/profile" component={Profile} />
          {/* <Route path="/analytics-page" component={AnalyticsPage} /> */}
          {/* <Route path="/uploads" component={PdfViewer} /> */}
          <PrivateRoute
            path="/dashboard/environments"
            component={Environments}
          />
          {/* <Route path="/upgrade" component={Banner} /> */}
          <Route path="/billing" component={Billing} />
          <Route path="/billing-page" component={BillingPage} />
          <Route path="/request-service" component={RequestService} />
          <Route path="/new-project" component={NewProject} />
          <Route path="/threat-set-up" component={ThreatSetup} />
          {/* <Route path="/threats" component={Threatv2} /> */}
          {/* <Route path="/dashboard/threat-map" component={ThreatMap} /> */}
          <Route path="/monitor/:id" component={LogbyIP} />
          <PrivateRoute
            path="/dashboard/request-service"
            component={RequestService}
          />
          <PrivateRoute path="/dashboard/threat-map" component={ThreatMap} />
          <PrivateRoute path="/dashboard/new-project" component={NewProject} />
          <PrivateRoute
            path="/dashboard/threat-set-up"
            component={ThreatSetup}
          />
          <PrivateRoute path="/dashboard/threats" component={Threatv2} />
          <PrivateRoute path="/dashboard/monitor/:id" component={LogbyIP} />
          {/* <Route path="/endpoints/:id" component={ViewEndpoint} /> */}
          <PrivateRoute
            path="/dashboard/endpoints/:id"
            component={ViewEndpoint}
          />
          <PrivateRoute path="/dashboard/view-rules" component={ViewRules} />
          <PrivateRoute
            path="/dashboard/security-checklist"
            component={SecurityChecklist}
          />
          <PrivateRoute
            path="/dashboard/api-security"
            component={ApiSecurity}
          />
          <PrivateRoute
            path="/dashboard/api-abuse-details"
            component={ApiAbuseDetails}
          />
          <PrivateRoute
            path="/dashboard/conformance-scan"
            component={ConformanceScan}
          />
          <PrivateRoute
            path="/dashboard/all-endpoints"
            component={AllEndpoints}
          />
          <PrivateRoute path="/dashboard/endpoints" component={EndpointTable} />
          {/* <PrivateRoute
            path="/dashboard/automated-scan"
            component={AutomatedScanV2}
          /> */}
          <PrivateRoute
            path="/dashboard/automated-scan"
            component={PerformanceTest}
          />

          <PrivateRoute
            path="/dashboard/performance-tests"
            component={PerformanceChart}
          />
          <PrivateRoute
            path="/dashboard/configure-performance-tests"
            component={ConfigurePerformanceTests}
          />
          <PrivateRoute
            path="/dashboard/vulnerability"
            component={Vulnerability}
          />
          <PrivateRoute
            path="/dashboard/vulnerability-details"
            component={VulnerabilityDetails}
          />
          <PrivateRoute
            path="/dashboard/fuzzing-test"
            component={FuzzingTest}
          />

          {/* <Route path="/static-code-analysis" component={StaticCode} /> */}
          {/* <Route path="/detected_threats" component={DetectedThreats} /> */}
          {/* <PrivateRoute
            path="/dashboard/code-reviews"
            component={Code_Analysis}
          /> */}
          <PrivateRoute
            path="/dashboard/code-reviews"
            component={SourceCodeReview}
          />
          <PrivateRoute
            path="/dashboard/configuration"
            component={ProjectConfiguration}
          />
          <PrivateRoute
            path="/dashboard/dependencies"
            component={DependencyAlert}
          />
          <PrivateRoute
            path="/dashboard/source-code-analysis"
            component={SourceCodeAnalysis}
          />
          <PrivateRoute
            path="/dashboard/dependency-review-analysis"
            component={DependencyReviewAnalysis}
          />
          <Route path="*" component={Error404} />
          <Route to="/not-found" component={Error404} />
          <Redirect to="/not-found" component={Error404} />
        </Switch>
      </Suspense>
    </>
  );
};

export default AppRoutes;
