import React from "react";

// import "./style.scss";

const Input = props => {
  const { label, type, name, id, value, onChange, ...otherProps } = props;
  return (
    <label htmlFor={id} className="flex flex-column">
      <span className="font-bold">
        {label} {otherProps.required && <span className="asterisk text-danger">*</span>}
      </span>
      {type !== "textarea" ? (
        <input
          className="border border-[#ccd4da] p-2 mt-1 md:mb-3 mb-2 rounded"  
          type={type || "text"}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          {...otherProps}
        />
      ) : (
        <textarea
          className="border border-[#ccd4da] p-2 mt-1 rounded"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          {...otherProps}
        />
      )}
    </label>
  );
};

export default Input;
