import InfiniteScroll from 'react-infinite-scroll-component';

const InfiniteScrollComp = ({
  data,
  hasMore,
  fetchMoreData,
  content: Content,
  loader: Loader,
  ...rest
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMoreData}
      hasMore={hasMore}
      scrollableTarget="scrollableDiv"
      loader={<div>Loading...</div>}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>End</b>
        </p>
      }
    >
      {
        <div>
          <div>{data && <Content data={data} {...rest} />}</div>
        </div>
      }
    </InfiniteScroll>
  );
};

export default InfiniteScrollComp;
