import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { DeleteUserAccess } from "../../../API";
import { toast } from "react-toastify";

function DeleteUserModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteUserAccess = async () => {
    await DeleteUserAccess(props?.user_id);
    setShow(true);
    window.location.reload();
  };
  return (
    <div>
      <button
        type="button"
        className="btn btn-outline-primary btn-icon"
        disabled={props?.user.access_level === "owner"}
        onClick={handleShow}
      >
        <i className="mdi mdi-delete"></i>
      </button>{" "}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="red-test">
            Share Collection With User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-secondary">
            Are you sure you want to remove user access?
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" type="submit" onClick={deleteUserAccess}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteUserModal;
