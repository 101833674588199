import React, { useEffect, useState } from 'react';
import {
  useGetRecentRunsQuery,
  useGetSingleVulnerabilityDetailsQuery,
  useGetTestCaseQuery,
  useGetTestReportQuery,
} from '../../../redux/services/endPointScansApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import ErrorDisplay from '../../components/Error';
import TestReports from './component/TestReports';
import { RecentRunStat } from './component/RecentRunStat';
import TableColumnName from './component/TableColumnName';
import TableItems from './component/TableItems';

const SourceCodeAnalysis = () => {
  const history = useHistory();
  const [tablePage, setTablePage] = useState(1);

  const testReportNumberOfDays = useSelector(
    (store) => store?.performanceTestDays.testReportNumberOfDays
  );

  const collectionId = localStorage.getItem('collection_id');
  const retrievedThreatName = localStorage.getItem('threat_name');

  const {
    data: recentRunsData,
    isLoading: recentRunsIsLoading,
    isFetching: recentRunsIsFetching,
    error: recentRunsError,
  } = useGetRecentRunsQuery(collectionId);

  const {
    data: testReportData,
    isLoading: testReportIsLoading,
    isFetching: testReportIsFetching,
    error: testReportError,
  } = useGetTestReportQuery({
    collectionId,
    duration: testReportNumberOfDays ? 200 : 200,
  });

  const { data, error, isLoading } = useGetSingleVulnerabilityDetailsQuery({
    collectionId,
    threatName: retrievedThreatName,
    page: tablePage,
  });

  useEffect(() => {
    if (!collectionId) {
      const redirectTimeout = setTimeout(() => {
        history.push('/dashboard/collections');
      }, 4000);
      return () => clearInterval(redirectTimeout);
    }
  }, [collectionId, history]);

  if (!collectionId)
    return (
      <div className="flex flex-col items-center mb-1">
        <ErrorDisplay errorMessage="No Project Selected" errorCode={404} />
        <h4 className="text-primary mt-4 mb-4">Redirecting Shortly</h4>
      </div>
    );

  return (
    <div className="w-full mb-7">
      <RecentRunStat
        recentRunsData={recentRunsData}
        recentRunsIsLoading={recentRunsIsLoading}
        recentRunsError={recentRunsError}
        recentRunsIsFetching={recentRunsIsFetching}
        title="Recent"
        details={true}
      />
      <TestReports
        testReportIsLoading={testReportIsLoading}
        testReportIsFetching={testReportIsFetching}
        testReportError={testReportError}
        testReportData={testReportData}
      />

      {/* <table className="table card mt-4">
        <thead className="flex items-center py-3 bg-[#d9d9d980]">
          <TableColumnName name={'Files'} />
          <TableColumnName name={'Number of Issues'} />
          <TableColumnName name={'Severity Level'} />
          <TableColumnName name={'Repository'} />
          <TableColumnName name={'Last Scanned'} />
        </thead>
        <div className="w-full ">
          <TableItems
            loading={isLoading}
            error={error}
            data={data}
            setPage={setTablePage}
          />
        </div>
      </table> */}
    </div>
  );
};

export default SourceCodeAnalysis;
