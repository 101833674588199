import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import scanSvgLeftBottom from '../../../../../assets/images/auth/scan-svg-left-buttom.svg';
import scanSvgMiddleBottom from '../../../../../assets/images/auth/scan-svg-middle-buttom.svg';
import scanSvgMiddleLeft from '../../../../../assets/images/auth/scan-svg-middle-left.svg';
import scanSvgMiddleTop from '../../../../../assets/images/auth/scan-svg-middle-top.svg';
import { QrCode } from './component/QrCode';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export const ScanQrCode = () => {
  const history = useHistory();
  const qrCodeUrl = useSelector((store) => store?.mfa?.qrCodeUrl);
  // const result = localStorage.getItem('qrCodeUrl');
  // const qrCodeUrl = result ? result : null;

  const [qrCodeBoxSize, setQrCodeSize] = useState({ width: 350, height: 350 });

  useEffect(() => {
    if (!qrCodeUrl) {
      toast('An error occured, please select otp  again');
      history.replace('/proceed-2fa');
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newSize = Math.min(window.innerWidth, window.innerHeight) * 0.7;
      setQrCodeSize({ width: newSize, height: newSize });
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNavigateToConfermToken = () => {
    history.push('/confirm-2fa');
  };

  const { width, height } = qrCodeBoxSize;

  return (
    <main className="w-full h-full flex justify-center bg-white">
      <div className="w-[900px] h-full bg-neutral-900 relative">
        <div className="flex flex-col items-center h-full w-full  md:px-7">
          <h1 className="text-center mx-3 my-4 text-white text-[22px] md:text-[25px] font-medium font-['Poppins']">
            Open Authenticator App and Scan this code
          </h1>

          <div
            style={{ width: width, height: height }}
            className={` z-[1]  my-auto  py-auto bg-gradient-to-br from-white to-white rounded-[20px] border border-white`}
          >
            <img
              src={scanSvgMiddleLeft}
              alt=""
              className="absolute z-[1] top-0 left-0 w-[235px] h-[235px]"
            />
            <img
              src={scanSvgMiddleTop}
              alt=""
              className="absolute z-[1] top-0 right-10 w-[235px] h-[235px]"
            />
            <img
              src={scanSvgMiddleBottom}
              alt=""
              className="absolute z-[1] bottom-0 left-0  w-[235px] h-[235px]"
            />
            <img
              src={scanSvgLeftBottom}
              alt=""
              className="  absolute z-[1] bottom-0 right-0  w-[100px] h-[100px]"
            />
            <QrCode />
          </div>

          <button
            onClick={handleNavigateToConfermToken}
            className="w-[150.31px] h-[50.26px] my-auto  bg-black rounded-[20px] border border-white"
          >
            <div className="text-center text-white text-[18px] md:text-[20px] font-medium font-['Poppins']">
              Continue
            </div>
          </button>
        </div>
      </div>
    </main>
  );
};
