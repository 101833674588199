import React, { useState } from 'react';
import './component/index.css';
import TableColumnName from './component/TableColumnName';
import TableItems from './component/TableItems';
import {
  useGetSingleVulnerabilityDetailsQuery,
  useGetVulnerabilityCountsQuery,
} from '../../../redux/services/endPointScansApi';

import { TotalScans } from './component/TotalScans';
import Heading from './component/Heading';
import DependencyLevelProgressChart from './component/DependencyLevelProgressChart';

const DependencyReviewAnalysis = () => {
  const [tablePage, setTablePage] = useState(1);
  const collectionId = localStorage.getItem('collection_id');
  const retrievedThreatName = localStorage.getItem('threat_name');

  const { data, error, isLoading } = useGetSingleVulnerabilityDetailsQuery({
    collectionId,
    threatName: retrievedThreatName,
    page: tablePage,
  });

  const {
    data: vulnerabilityChartData,
    isLoading: vulnerabilityChartIsLoading,
    error: vulnerabilityChartError,
  } = useGetVulnerabilityCountsQuery(collectionId);

  return (
    <div className="w-full mb-7">
      <div>
        <Heading />
        <div className="charts-container">
          <section className="total_scan">
            <TotalScans value={12} />
          </section>
          <section className="dependency-chart__container">
            {/* <VulnerabilityHeading name={'Vulnerabilities '} /> */}
            <div className="dependency-chart">
              <div className="dependency-chart__high">
                <DependencyLevelProgressChart
                  isLoading={vulnerabilityChartIsLoading}
                  error={vulnerabilityChartError}
                  data={vulnerabilityChartData}
                  bcg={'#DB252530'}
                  color={'#F90808'}
                  dataLabelsColors={'#F90808'}
                  name={'High'}
                />
              </div>
              <div className="dependency-chart__medium">
                <DependencyLevelProgressChart
                  isLoading={vulnerabilityChartIsLoading}
                  error={vulnerabilityChartError}
                  data={vulnerabilityChartData}
                  bcg={'#fff2c580'}
                  color={'#f8d146 '}
                  name={'Medium'}
                  dataLabelsColors={'#ffca09'}
                />
              </div>
              <div className="dependency-chart__low">
                <DependencyLevelProgressChart
                  isLoading={vulnerabilityChartIsLoading}
                  error={vulnerabilityChartError}
                  data={vulnerabilityChartData}
                  bcg={'#652CB340'}
                  color={'#652CB3'}
                  dataLabelsColors={'#652CB3'}
                  name={'Low'}
                />
              </div>
            </div>
          </section>
        </div>
      </div>

      <table className="table card mt-4">
        <thead className="flex items-center py-3 bg-[#d9d9d980]">
          <TableColumnName name={'Dependency'} />
          <TableColumnName name={'Number of Issues'} />
          <TableColumnName name={'Severity Level'} />
          <TableColumnName name={'Repository'} />
          <TableColumnName name={'Details'} />
        </thead>
        <div className="w-full ">
          <TableItems
            loading={isLoading}
            error={error}
            data={data}
            setPage={setTablePage}
          />
        </div>
      </table>
    </div>
  );
};

export default DependencyReviewAnalysis;
