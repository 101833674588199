import React from 'react';
import FormInput from './FormInput';

const Schedule = ({ handleInputChange, formData }) => (
  <FormInput
    label={'Schedule'}
    value={formData.schedule}
    type="number"
    handleInputChange={(event) =>
      handleInputChange(
        'schedule',
        event.target.value < 0 ? 0 : event.target.value
      )
    }
    placeholder={'enter number'}
  />
);

export default Schedule;
