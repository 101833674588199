import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart } from "chart.js";

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var text = chart.config.data.text;

    var sidePadding = 20;
    var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);

    ctx.font = "30px " + "Arial";

    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    var stringWidth = ctx.measureText(text).width;
    var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

    // Find out how much the font can grow in width.
    var widthRatio = elementWidth / stringWidth;
    var newFontSize = Math.floor(30 * widthRatio);
    var elementHeight = chart.innerRadius * 2;

    // Pick a new font size so it will not be larger than the height of label.
    var fontSizeToUse = Math.min(newFontSize, elementHeight);

    var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

    var fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em Verdana";
    ctx.textBaseline = "middle";

    ctx.textAlign = "center";
    ctx.font = fontSizeToUse + "px " + "Arial";
    ctx.fillText(text, centerX, centerY);
  },
});

const IssueType = ({ analytics }) => {
  const [label, setLabel] = useState([]);
  const [count, setCount] = useState([]);

  useEffect(() => {
    if (analytics) {
      const issue = analytics?.map((item) => item?.issue);
      const issueCount = analytics?.map((item) => item?.count);
      setLabel(issue);
      setCount(issueCount);
    }
  }, [analytics]);

  const totalScore = count?.reduce((x, y) => x + y, 0);

  const data = {
    labels: label,
    datasets: [
      {
        label: "My First Dataset",
        data: count,
        backgroundColor: ["#51DF5F", "#ABB6C2"],
        hoverOffset: 4,
      },
    ],
    text: `${totalScore} total`,
  };

  const options = {
    legend: {
      display: false,
      position: "right",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0, // Adjust to move the chart to the right
        right: 100,
        top: 0,
        bottom: 0,
      },
    },
  };

  return (
    <div className="mb-4 md:mb-0 custom-chart">
      <p className="font-bold text-black">Issue Type</p>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {label.length > 0 && count.length > 0 ? (
          <Doughnut data={data} options={options} />
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default IssueType;
