import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Spinner from 'react-bootstrap/Spinner';
import { Badge } from 'react-bootstrap';
import React, { useEffect } from 'react';
import DarkLoader from '../../../components/loader/DarkLoader';

const ThreatTable = ({ data, isLoading, error }) => {
  const history = useHistory();

  const handleNavigateToLogDataPage = (param) => {
    localStorage.setItem('filterThreatDataBy', param);
    history.push('/dashboard/threats');
  };

  const formatEndpoint = (endpoint) => {
    let extractedSubstring;
    if (endpoint && endpoint?.length > 10)
      extractedSubstring = `${endpoint?.substring(0, 9)}` + '...';
    else extractedSubstring = endpoint;

    return extractedSubstring;
  };

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  return (
    <div className="h-[400px]">
      <div className="flex h-full lg:my-0 flex-col justify-between ">
        <div className="table w-full">
          <h4 className="text-primary mb-4">Recent Request Logs</h4>

          <table className="table table-hover">
            <thead className="bg-white text-primary">
              <tr>
                <th scope="col" className="px-6 py-3">
                  <h6 className="mb-0">Endpoint</h6>
                </th>
                <th scope="col" className="px-6 py-3">
                  <h6 className="mb-0">Threat level</h6>
                </th>
                <th scope="col" className="px-6 py-3">
                  <h6 className="mb-0">Code</h6>
                </th>
              </tr>
            </thead>
            <tbody className="relative bg-white w-full border">
              {isLoading ? (
                <div className="absolute bg-white  text-center mb-0  inset-0  h-[230px]  w-full  flex items-center justify-center">
                  <DarkLoader />
                </div>
              ) : null}

              {error ? (
                <div className="absolute text-base bg-white text-black text-center mb-0   inset-0  h-[230px]  w-full  flex items-center justify-center">
                  An error occured
                </div>
              ) : null}
              {data &&
                data?.length > 0 &&
                data?.map((item) => (
                  <tr key={item?.id} className=" w-full  border-b  ">
                    <td className="px-6 py-4 text-black ">
                      {formatEndpoint(item?.path)}
                    </td>
                    <td className="px-6 py-4 ">
                      <Badge
                        className="h-7 px-2 flex items-center w-16 rounded-full"
                        bg={
                          item.threat_level === 'critical'
                            ? 'danger'
                            : item.threat_level === 'medium'
                            ? 'warning'
                            : item.threat_level === 'low'
                            ? 'primary'
                            : 'info'
                        }
                      >
                        <i className="mdi mdi-security mr-[5px] text-white"></i>
                        <i className="text-white not-italic">
                          {item?.threat_level}
                        </i>
                      </Badge>
                    </td>
                    <td
                      className={`
                      ${
                        item?.response_code?.toString()?.startsWith('5')
                          ? ' btn-outline-danger'
                          : item?.response_code?.toString()?.startsWith('4')
                          ? '  btn-outline-danger'
                          : item?.response_code?.toString()?.startsWith('3')
                          ? '  btn-outline-info'
                          : item?.response_code?.toString()?.startsWith('2')
                          ? ' btn-outline-success'
                          : 'text-primary'
                      }`}
                    >
                      {item?.response_code}
                    </td>
                  </tr>
                ))}

              {!isLoading && !error && data && data?.length === 0 && (
                <div className="absolute bg-white  inset-0  py-28  w-full  flex items-center justify-center">
                  <p className="px-6  text-base  text-black">No threat</p>
                </div>
              )}
            </tbody>
          </table>
        </div>

        {!isLoading ? (
          <div className="text-right pt-10 ">
            <button
              onClick={() => handleNavigateToLogDataPage('all')}
              className=" bg-primary px-2 py-2 text-white border-0 outline-0 rounded"
            >
              {data && data?.length > 0 ? 'View All Logs' : 'Log Table'}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ThreatTable;
