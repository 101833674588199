import React from 'react';
const headerLegend = [
  {
    legendName: 'Critical',
    color: '#ff0000',
  },
  {
    legendName: 'Medium',
    color: '#8884d8',
  },
  {
    legendName: 'Low',
    color: '#82ca9d',
  },
];

const ChartLegend = () => (
  <div className="flex items-center mx-3">
    {headerLegend.map((item, index) => (
      <div
        key={index}
        className="flex mr-1 w-max items-center border-[#E5E5EF]  py-[2px] px-[12px]"
      >
        <div
          className=" mr-1 w-2 h-2 rounded-full"
          style={{
            backgroundColor: `${item.color}`,
          }}
        />
        <div
          style={{
            color: `${item.color}`,
            fontSize: 13,
          }}
        >
          {item.legendName}
        </div>
      </div>
    ))}
  </div>
);

export default ChartLegend;
