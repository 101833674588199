import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes, { otherRoutes, privateRoutes } from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from 'react-i18next';
import { AuthProvider } from './providers/AuthProvider';
import { FetchContextProvider } from './context';
import { AutomatedTestProvider } from './context/automated_test';
import { VulProvider } from './providers/VulProvider';
import { LogProvider } from './providers/LogProvider';
import { CollectionProvider } from './providers/Collection';
import useIdleTimer from '../hooks/useIdleTimer';

const App = ({ location, i18n }) => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);

  useEffect(() => {
    onRouteChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useIdleTimer();

  const onRouteChanged = () => {
    const body = document.querySelector('body');
    if (location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    } else {
      body.classList.remove('rtl');
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      '/',
      '/login',
      '/reset-password',
      '/new-password',
      '/first-login-reset-password',
      '/proceed-2fa',
      '/confirm-2fa',
      '/activate-2fa',
      '/new_onboarding',
      '/user-pages/login-2',
      '/register',
      '/user-pages/register-2',
      '/user-pages/lockscreen',
      '/error-pages/error-404',
      '/error-pages/error-500',
      '/general-pages/landing-page',
      '/terms_conditions',
      '/new_onboarding',
      'uploads',
      '/new-project',
      '/threat-set-up',
      '/not-found',
    ];
    for (let route of fullPageLayoutRoutes) {
      if (location.pathname === route) {
        setIsFullPageLayout(true);
        document
          .querySelector('.page-body-wrapper')
          .classList.add('full-page-wrapper');
        return;
      }
    }
    setIsFullPageLayout(false);
    document
      .querySelector('.page-body-wrapper')
      .classList.remove('full-page-wrapper');
  };

  const isPrivateRoute = privateRoutes.includes(location.pathname);
  const isOtherRoute = otherRoutes.includes(location.pathname);

  let navbarComponent = !isOtherRoute ? <Navbar /> : null;
  let sidebarComponent = !isOtherRoute ? <Sidebar /> : null;
  let footerComponent = !isOtherRoute ? <Footer /> : null;

  return (
    <AuthProvider>
      <FetchContextProvider>
        <CollectionProvider>
          <AutomatedTestProvider>
            <VulProvider>
              <LogProvider>
                <div className="container-scroller">
                  {navbarComponent}
                  <div className="container-fluid page-body-wrapper">
                    {sidebarComponent}
                    <div className="main-panel">
                      <div className="content-wrapper">
                        <AppRoutes />
                      </div>
                      {footerComponent}
                    </div>
                  </div>
                </div>
              </LogProvider>
            </VulProvider>
          </AutomatedTestProvider>
        </CollectionProvider>
      </FetchContextProvider>
    </AuthProvider>
  );
};

export default withTranslation()(withRouter(App));
