import React from 'react';
import FormInput from './FormInput';
import UpdateButton from './UpdateButton';
import Schedule from './Schedule';
import ExpiresAt from './ExpiresAt';

const ApiKeyForm = ({
  handleSubmit,
  handleInputChange,
  formData,
  handleCheckboxChange,
  isLoading,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        label={'Enter Api Key'}
        value={formData.auth_api_key}
        handleInputChange={(event) =>
          handleInputChange('auth_api_key', event.target.value)
        }
        placeholder={'your-api-key'}
      />
      <Schedule handleInputChange={handleInputChange} formData={formData} />
      <ExpiresAt
        handleCheckboxChange={handleCheckboxChange}
        formData={formData}
      />

      <div className="w-full my-7 flex justify-end">
        <UpdateButton isLoading={isLoading} />
      </div>
    </form>
  );
};

export default ApiKeyForm;
