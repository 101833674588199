import React, { useEffect, useState } from 'react';
import { PaginationWithButton } from '../../../components/pagination/PaginationWithButton';
import useButtonPagination from '../../../../hooks/useButtonPagination';
import DarkLoader from '../../../components/loader/DarkLoader';
const btnBcg =
  'linear-gradient(0deg, #023673, #023673),linear-gradient(0deg, rgba(2, 54, 115, 0.4), rgba(2, 54, 115, 0.4))';

const TableItems = ({
  data,
  loading,
  error,
  setPage,
  setShowModal,
  setModalData,
}) => {
  const [items, setItems] = useState([]);
  const { handlePageClick, pageCount, setPageCount } =
    useButtonPagination(setPage);

  const handleOpenModal = (item) => {
    setModalData({
      requestHeaderContent: item?.request_header,
      requestBodyContent: item?.request_payload,
      responseHeaderContent: item?.response_header,
      responseBodyContent: item?.response_payload,
      attack_group: item?.attack_group,
      threat_name: item?.threat_name,
      severity: item?.severity,
      description: item?.description,
      remediation: item?.remediation,
      references: item?.references
    });
    setShowModal(true);
  };

  useEffect(() => {
    if (data) {
      setItems(data.test_results);
      setPageCount(data.total_pages);
    }
  }, [data, setPageCount]);

  if (loading) return <DarkLoader />;

  if (error) {
    return (
      <div className="w-full  h-full py-28 rounded-[14px] flex justify-center items-center text-base md:text-xl font-serif   text-[#000]">
        An error occurred
      </div>
    );
  }

  return (
    <>
              {items.length > 0 ?
                items?.map((item, index) => (
                  <tr className="bg-white border-b " key={index}>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {item?.endpoint}
                    </th>
                    <td className="px-6 py-4">{item?.cve_score}</td>
                    <td className="px-6 py-4">{item?.cve_id}</td>
                    <td className="px-6 py-4">
                    <button
                  onClick={() => handleOpenModal(item)}
                  style={{ background: btnBcg }}
                  className="btn btn-primary"
                >
                 View
                </button>
                    </td>
                   
                    <td
                      className="px-6 py-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        // height: "30vh",
                        justifyContent: 'center',
                      }}
                    >
                    </td>
                    <td className="px-6 py-2"></td>
                  </tr>
                ))
              : null}
      {/* {items.length > 0
        ? items.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-items-center py-3 border-bottom"
            >
              <div className="w-[25%] ml-2">{item?.endpoint}</div>
              <div className="w-[25%] flex justify-center  text-sm">
                {item?.cve_score}
              </div>
              <div className="w-[25%] flex justify-center text-sm">
                {item?.cve_id}
              </div>
              <div className="w-[25%] flex justify-center">
                <button
                  onClick={() => handleOpenModal(item)}
                  style={{ background: btnBcg }}
                  className="flex w-max items-center  py-1  md:py-[7px] px-3 md:px-[20px]  border rounded-[8px]"
                >
                  <div className="text-[#FFFFFF]  leading-[20.27px] text-[10px]  lg:text-sm ">
                    View
                  </div>
                </button>
              </div>
            </div>
          ))
        : null} */}

      {items.length > 0 ? (
        <div className="mt-3">
          <PaginationWithButton
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />
        </div>
      ) : null}
    </>
  );
};

export default TableItems;
