import React, { useEffect, useState } from 'react';
import DependencyAlertModal from '../modals/dependencies';
import {
  GetDependenciesAlerts,
  ScheduleDependency,
  TestDependency,
  PatchCollection,
  RunCodeReview,
  CreateGitTokem,
} from '../../API';

import { useCollectionContext } from '../../providers/Collection';
import { useAuth } from '../../providers/AuthProvider';

import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../../shared/Spinner';
import ToolTip from '../../shared/Tooltip.js';
import { Form } from 'react-bootstrap';
import ErrorDisplay from '../../components/Error.js';

const DependencyAlert = () => {
  const [data, setdata] = useState([]);
  const [isDependencyScanRun, setIsDependencyScanRun] = useState(true);
  const [runAnalysisIsLoading, setRunAnalysisIsLoading] = useState(false);

  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const { selectedCollectionId } = useCollectionContext();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [errMessage, setErrMsg] = useState('');
  const [git_url, setGit] = useState('');
  const [github_token, setGithubToken] = useState('');
  const [submit, setSubmit] = useState(false);

  // Redirect to dashboard if user is authenticated
  if (!isAuthenticated()) {
    history.push('/login');
  }

  const codeReview = async () => {
    setRunAnalysisIsLoading(false);
    try {
      const response = await RunCodeReview();
      if (response.success === false) {
        const error = response?.error;
        toast.error(error ? error : 'Something went, please try again');
        return;
      }

      toast.success('Dependency scan ran successfully');
    } catch (error) {
      toast.error('An error occurred');
    } finally {
      setRunAnalysisIsLoading(false);
    }
  };

  const updateCollection = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const formData = new FormData();
    formData.append('git_url', git_url);
    const { success, error, message } = await PatchCollection(
      formData,
      selectedCollectionId
    );
    if (success) {
      const { success } = await CreateGitTokem(git_url, github_token);
      if (success) {
        await ScheduleDependency();
        const { error } = await RunCodeReview();
        if (error) {
          toast.error(
            'Please review your Github Token or URL, it is either incorrect or does not have enough access'
          );
        }
      }
    }
    setSubmit(false);
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await GetDependenciesAlerts();
        if (!data?.success) {
          setIsDependencyScanRun(false);
          // toast.error('Dependency scan has not been ran on this collection');
          setConfig('invalid');
          setLoading(false);
        } else {
          if (data?.data.length === 0) {
            // confirm that it is working
            const { response } = await TestDependency();
            if (response?.status !== 204) {
              toast.error(response?.data?.error);
              if (response?.status === 404) {
                setConfig('disabled');
                setLoading(false);
              } else if (response?.status === 401) {
                setConfig('invalid');
                setLoading(false);
              } else {
                setLoading(false);
              }
            } else {
              setdata(data?.data);
            }
          }
          setdata(data?.data);
        }
      } catch (err) {
        console.log('err');
      }
    };
    getData();
    setLoading(false);
  }, []);

  const testDep = async () => {
    setActive(false);
    const { response } = await TestDependency();
    if (response?.status === 204) {
      toast('Enabled Successfully');
      setConfig('enabled');
      ScheduleDependency();
    } else {
      if (response?.status === 404) {
        setConfig('disabled');
      } else if (response?.status === 401) {
        setConfig('invalid');
      } else {
        setConfig('other');
      }
    }
    toast(response?.data?.error);
  };

  if (isDependencyScanRun === false)
    return (
      <div className="w-full">
        <button
          className="btn btn-info float-right"
          onClick={codeReview}
          disabled={runAnalysisIsLoading}
        >
          Run New Analysis
        </button>
        <div className="flex flex-col items-center mb-1">
          <ErrorDisplay
            errorMessage="Dependency scan has not been ran on this collection"
            errorCode={404}
          />
        </div>
      </div>
    );

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {config !== null ? (
            config === 'disabled' ? (
              <div className="card">
                <div className="w-[50%] m-auto p-4 text-center">
                  <h4 className="text-danger mt-4">
                    Github Dependency Bots are disabled on your repository
                  </h4>
                  <div>
                    <label className=" text-primary">
                      <input
                        type="checkbox"
                        required
                        onChange={(e) => setActive(e.target.value)}
                      />
                      {'  '}I have turned it on
                    </label>
                  </div>
                  <button
                    disabled={!active}
                    className="btn btn-primary"
                    onClick={testDep}
                  >
                    Confirm Status
                  </button>
                </div>
              </div>
            ) : config === 'invalid' ? (
              <div className="card p-4">
                <div className="w-[50%] m-auto">
                  <h4 className="text-primary pb-4">
                    We need access to Github Dependabots
                  </h4>
                  <Form.Group>
                    <label htmlFor="git_link" className="text-primary">
                      Git URL <span className="text-danger">*</span>
                      <span className="text-danger">
                        <ToolTip description="Required for Source Code Review" />
                      </span>
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="git_link"
                      placeholder="https://github.com/owner/repository.git"
                      required
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!value.endsWith('.git')) {
                          setErrMsg('Github URL must end with a .git');
                          return;
                        }
                        setErrMsg('');
                        setGit(value);
                      }}
                    />
                    {errMessage ? (
                      <label className="mt-1 text-danger">{errMessage}</label>
                    ) : null}
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="document_url" className="text-primary pt-4">
                      Github Token
                      <span className="text-danger">
                        <ToolTip description="Will be encrypted at rest" />
                      </span>
                      <span
                        className="btn text-secondary ml-2 pointer-cursor"
                        onClick={() =>
                          window.open(
                            'https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens',
                            '_blank'
                          )
                        }
                      >
                        <i className="mdi mdi-circle"></i>manage tokens
                      </span>
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="document_url"
                      placeholder="ghp_0a0b0c0d0e0f0g0h0i"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!value.startsWith('ghp_')) {
                          setErrMsg('Github Token Must Begin With A GHP');
                          return;
                        }
                        setErrMsg('');
                        setGithubToken(value);
                      }}
                    />
                    {errMessage ? (
                      <label className="mt-1 text-danger">{errMessage}</label>
                    ) : null}
                  </Form.Group>
                  <button
                    className="btn btn-primary float-right mt-4"
                    onClick={updateCollection}
                  >
                    {submit ? <Spinner /> : 'Submit'}
                  </button>
                </div>
              </div>
            ) : (
              <div className="card">
                <h4 className="text-primary m-4">
                  We are scanning your dependencies
                </h4>
              </div>
            )
          ) : (
            <div className="flex justify-between flex-wrap">
              {data?.map((i, index) => (
                <div className="w-[30%] mb-4">
                  <DependencyAlertModal data={data[index]} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DependencyAlert;
