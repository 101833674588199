import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Badge, Spinner } from "react-bootstrap";
import { useGetThreatActivityQuery } from "../../../../redux/services/endPointScansApi";
import { useReportThreatMutation } from "../../../../redux/services/collectionApi";
import { toast } from "react-toastify";

function RealtimeMonitoringModal({ data }) {
  const [show, setShow] = useState(false);
  const [activity_data, setData] = useState({});
  const [isQuerySkip, setIsQuerySkip] = useState(true);

  const handleClose = () => {
    setShow(false);
    setIsQuerySkip(true);
  };

  const handleShow = () => {
    setIsQuerySkip(false);
    setShow(true);
  };
  let activityId = data?.id;

  const {
    data: theatActivityData,
    isLoading,
    isError,
  } = useGetThreatActivityQuery(activityId, { skip: isQuerySkip });

  useEffect(() => {
    if (theatActivityData) {
      theatActivityData && setData(theatActivityData?.[0]);
    }
  }, [theatActivityData]);

  const [reportThreat] = useReportThreatMutation()

  const report = async () =>{
    try{

      await reportThreat({
        id: data?.id,
        body: {
          "reported": "True"
        },
      }).unwrap()
      toast.success("Threat reported successfully");
    }catch(error){
      console.error("Failed:", error);
      toast.error("Failed to update");
    }
  }
  return (
    <div>
      <button
        type="button"
        className="btn btn-outline-secondary btn-sm text-[8px] w-[70px]"
        onClick={handleShow}
        style={{ fontSize: "8px" }}
      >
        View Log
        {/* <i className="mdi mdi-eye cursor-pointer" onClick={handleShow}></i> */}
      </button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-primary">IP Detail: </span>
            {data?.path}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-primary">
            Request Id: <span>{data?.id}</span>{" "}
          </p>
          {isLoading ? <Spinner /> : null}

          {theatActivityData && theatActivityData?.length && (
            <div>
              <div className="mt-4 mb-4 w-[100%] flex justify-between items-center">
                <h4 className="text-secondary">Threat Information</h4>
                <button
                className = {activity_data?.reported  ? "btn btn-danger" : "btn btn-info"}
                onClick={report}>
                  {activity_data?.reported  ? "Reported" : "Report"}
                </button>
              </div>
              <table className="table table-hover mb-4">
                <tbody>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Threat Level
                    </th>
                    <td className="px-6 py-4 text-primary">
                      <Badge
                        bg={
                          activity_data?.threat_level === "critical"
                            ? "danger text-white p-2"
                            : "info text-white p-2"
                        }
                      >
                        {activity_data?.threat_level}
                      </Badge>
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Suspicious IP
                    </th>
                    <td
                      className={
                        activity_data?.suspicious === true
                          ? "px-6 py-4 text-danger"
                          : "px-6 py-4 text-primary"
                      }
                    >
                      {activity_data?.suspicious === true ? "True" : "False"}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Suspicious Requests
                    </th>
                    <td>
                      {activity_data?.detection !== undefined
                        ? activity_data?.detection[0]?.suspicious_requests
                        : null}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Attack Category
                    </th>
                    <td>
                      {activity_data?.detection !== undefined
                        ? activity_data?.detection[0]?.attack_type
                        : null}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      IP Risk Level
                    </th>
                    <td className="px-6 py-4 text-primary">
                      {activity_data?.ipRisk === null
                        ? 0
                        : activity_data?.ipRisk}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Request Status
                    </th>
                    <td className="px-6 py-4 text-primary">
                      <Badge
                        bg={
                          activity_data?.request_status === "blocked"
                            ? "danger text-white p-2"
                            : "info text-white p-2"
                        }
                      >
                        {activity_data?.request_status}
                      </Badge>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="mt-4 mb-4 w-[100%]">
                <h4 className="text-secondary">Identified Threats</h4>
              </div>
              <table className="table table-hover mb-4  w-[100%]">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th>Attack Type</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {activity_data?.detection?.length > 0
                    ? activity_data?.detection?.map((threat) => (
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                          >
                            <p className="whitespace-normal">
                              {threat?.attack_type}
                            </p>
                            <p className="whitespace-normal">
                              {threat?.category} Category
                            </p>
                            <p>{threat?.ipaddress}</p>
                          </th>
                          <td className="px-6 py-4 text-primary">
                            <p className="whitespace-normal">
                              {threat?.message}
                            </p>
                            <p>{threat?.risk}</p>
                            <p>
                              {" "}
                              <Badge
                                pill
                                className="text-white p-2"
                                bg={
                                  threat?.severity === "critical"
                                    ? "danger"
                                    : threat?.severity === "medium"
                                    ? "warning"
                                    : threat?.severity === "low"
                                    ? "primary"
                                    : "info"
                                }
                              >
                                <i className="mdi mdi-security"> </i>
                                {threat?.severity}
                              </Badge>
                            </p>
                            <p>{threat?.status}</p>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>

              <div className="mt-4 mb-4 w-[100%]">
                <h4 className="text-secondary">Event Information</h4>
              </div>
              <table className="table table-hover mb-4 ">
                <tbody>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Request URL
                    </th>
                    <td className="px-6 py-4 text-primary">
                      {activity_data?.full_url}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Request Method
                    </th>
                    <td className="px-6 py-4 text-primary">
                      {activity_data?.method}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Host Domain
                    </th>
                    <td className="px-6 py-4 text-primary">
                      {activity_data?.path}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Timestamp
                    </th>
                    <td className="px-6 py-4 text-primary whitespace-normal">
                      {activity_data?.timestamp}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-normal "
                    >
                      TimeZone
                    </th>
                    <td className="px-6 py-4 text-primary whitespace-normal">
                      {activity_data?.timezone}
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Response Code
                    </th>
                    <td className="px-6 py-4 text-primary whitespace-normal">
                      <Badge
                        bg={
                          activity_data?.response_code
                            ?.toString()
                            ?.startsWith("5")
                            ? "danger text-white p-2"
                            : activity_data?.response_code
                                ?.toString()
                                ?.startsWith("4")
                            ? "danger text-white p-2"
                            : activity_data?.response_code
                                ?.toString()
                                ?.startsWith("3")
                            ? "info text-white p-2"
                            : activity_data?.response_code
                                ?.toString()
                                ?.startsWith("2")
                            ? "success text-white p-2"
                            : "text-primary p-2"
                        }
                        disabled
                      >
                        {activity_data?.response_code}
                      </Badge>
                    </td>
                  </tr>
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                    >
                      Response Time
                    </th>
                    <td className="px-6 py-4 text-primary whitespace-normal">
                      {activity_data?.response_time}
                    </td>
                  </tr>
                  {data?.request_status === "blocked" && (
                    <>
                      <tr className="bg-white border-b ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                        >
                          Request Header
                        </th>
                        <td className="px-6 py-4 text-primary">
                          {activity_data?.request_header}
                        </td>
                      </tr>
                      <tr className="bg-white border-b ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                        >
                          Request Payload
                        </th>
                        <td className="px-6 py-4 text-primary">
                          {activity_data?.request_payload}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <div>
                {/* <div className="mt-4 mb-4 w-[100%]">
              <button className="btn btn-danger float-right">Block IP</button>
            </div> */}

                <div className="mt-4 mb-4 w-[100%]">
                  <h4 className="text-secondary">Organization Information</h4>
                </div>
                <table className="table table-hover mb-4">
                  <tbody>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        ISP
                      </th>
                      <td className="px-6 py-4 text-primary">
                        {activity_data.isp}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Organization
                      </th>
                      <td className="px-6 py-4 text-primary">
                        {activity_data?.organization}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        ASN
                      </th>
                      <td className="px-6 py-4 text-primary">
                        {activity_data?.ASN}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Host
                      </th>
                      <td className="px-6 py-4 text-primary">
                        {activity_data?.hostnames}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Domain
                      </th>
                      <td className="px-6 py-4 text-primary">
                        {activity_data?.domain}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <h4 className="text-secondary">Fraud Detection</h4>
                <table className="table table-hover mb-4">
                  <tbody>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Abuse Score
                      </th>
                      <td className="px-6 py-4 text-primary">
                        {activity_data.abuseConfidenceScore ||
                          0 + activity_data.fraud_score ||
                          0}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Suspicious
                      </th>
                      <td
                        className={
                          activity_data?.suspicious
                            ? "px-6 py-4 text-danger"
                            : "px-6 py-4 text-primary"
                        }
                      >
                        {activity_data?.suspicious ? "True" : "False"}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Recent Abuse
                      </th>
                      <td
                        className={
                          activity_data?.recent_abuse
                            ? "px-6 py-4 text-danger"
                            : "px-6 py-4 text-primary"
                        }
                      >
                        {activity_data?.recent_abuse ? "True" : "False"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <h4 className="text-secondary">VPN Usage</h4>
                <table className="table table-hover mb-4">
                  <tbody>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Is Public IP
                      </th>
                      <td className="px-6 py-4 text-primary">
                        {activity_data.isPublic ? "True" : "False"}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Is Tor
                      </th>
                      <td
                        className={
                          activity_data?.isTor
                            ? "px-6 py-4 text-danger"
                            : "px-6 py-4 text-primary"
                        }
                      >
                        {activity_data?.isTor ? "True" : "False"}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Is Crawler
                      </th>
                      <td
                        className={
                          activity_data?.is_crawler
                            ? "px-6 py-4 text-danger"
                            : "px-6 py-4 text-primary"
                        }
                      >
                        {activity_data?.is_crawler ? "True" : "False"}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Is Proxy
                      </th>
                      <td
                        className={
                          activity_data?.proxy
                            ? "px-6 py-4 text-danger"
                            : "px-6 py-4 text-primary"
                        }
                      >
                        {activity_data?.proxy ? "True" : "False"}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Bot Status
                      </th>
                      <td
                        className={
                          activity_data?.bot_status
                            ? "px-6 py-4 text-danger"
                            : "px-6 py-4 text-primary"
                        }
                      >
                        {activity_data?.bot_status ? "True" : "False"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <h4 className="text-secondary">Reports</h4>
                <table className="table table-hover mb-4 ">
                  <tbody>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Is WhiteListed
                      </th>
                      <td className="px-6 py-4 text-primary">
                        {activity_data?.isWhiteListed ? "True" : "False"}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Total Reports
                      </th>
                      <td
                        className={
                          activity_data?.totalReports > 0
                            ? "px-6 py-4 text-danger"
                            : "px-6 py-4 text-primary"
                        }
                      >
                        {activity_data?.totalReports}
                      </td>
                    </tr>
                    <tr className="bg-white border-b ">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                      >
                        Unique Countries Reported From
                      </th>
                      <td
                        className={
                          activity_data?.totalReports > 0
                            ? "px-6 py-4 text-danger"
                            : "px-6 py-4 text-primary"
                        }
                      >
                        {activity_data?.total_reports?.unique_country_count}
                      </td>
                    </tr>
                    {activity_data?.lastReportedAt ? (
                      <tr className="bg-white border-b ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                        >
                          Last Reported At
                        </th>
                        <td className="px-6 py-4 text-primary">
                          {activity_data?.lastReportedAt}
                        </td>
                      </tr>
                    ) : null}
                    {activity_data?.reports?.map((report) => (
                      <tr className="bg-white border-b  ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                        >
                          Report Comment
                        </th>
                        <td
                          className={
                            activity_data?.recent_abuse
                              ? "px-6 py-4 text-danger "
                              : "px-6 py-4 text-primary "
                          }
                        >
                          <p className="whitespace-normal">{report.comment}</p>
                          <p className="whitespace-normal">
                            {report.reportedAt}
                          </p>
                          <p className="whitespace-normal">
                            {report.reporterCountryName}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div>
                  <h4 className="text-secondary">GeoLocation</h4>
                  <table className="table table-hover mb-4">
                    <tbody>
                      <tr className="bg-white border-b ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                        >
                          Country Name and Code
                        </th>
                        <td className="px-6 py-4 text-primary">
                          {activity_data.countryName}{" "}
                          {activity_data?.countryCode}
                        </td>
                      </tr>
                      <tr className="bg-white border-b ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                        >
                          Region
                        </th>
                        <td className="px-6 py-4 text-primary">
                          {activity_data?.region}
                        </td>
                      </tr>
                      <tr className="bg-white border-b ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-primary whitespace-nowrap "
                        >
                          City
                        </th>
                        <td className="px-6 py-4 text-primary">
                          {activity_data?.city}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  {/* <button className="btn btn-primary float-right">
                Reveal Request Data
              </button> */}
                </div>
              </div>
            </div>
          )}

          {!isLoading && isError ? <div>An error occured</div> : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RealtimeMonitoringModal;
