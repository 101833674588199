import React from "react";
import BlockedIps from "./blockedIps";
import CustomRegex from "./customRegex";

const ViewRules = () => {
  return (
    <>
      <BlockedIps />
      <CustomRegex />
    </>
  );
};

export default ViewRules;
