import React, { useEffect, useState } from "react";
import {
  useGetIssueTypeQuery,
  useGetSeverityCountsQuery,
  useGetEndpointCountsQuery,
  useGetLastScanDateQuery,
  useGetFilterIssuesQuery,
} from "../../../redux/services/collectionApi";
import IssueType from "./IssueType";
import ThreatLevel from "./ThreatLevel";
import Summary from "./Summary";
import TableData from "./TableData";

const ConformanceScan = () => {
  const collection_id = localStorage.getItem("collection_id");
  const [issueType, setIssueType] = useState([]);
  const [severityCount, setSeverityCount] = useState([]);
  const [endpointCount, setEndpointCount] = useState({});
  const [lastScanDate, setLastScanDate] = useState({});
  const [filterIssues, setFilterIssues] = useState([]);
  const [filterIssueType, setFilterIssueType] = useState("");
  const [threatLevel, setThreatLevel] = useState("");
  const [path, setPath] = useState("");
  const [input, setInput] = useState("");

  const { data: issue } = useGetIssueTypeQuery({
    collection_id: collection_id,
  });
  const { data: severity } = useGetSeverityCountsQuery({
    collection_id: collection_id,
  });
  const { data: endpoint } = useGetEndpointCountsQuery({
    collection_id: collection_id,
  });
  const { data: scanDate } = useGetLastScanDateQuery({
    collection_id: collection_id,
  });
  const { data: filteredIssue, isLoading } = useGetFilterIssuesQuery({
    collection_id: collection_id,
    issue: filterIssueType,
    threatLevel: threatLevel,
    path: path,
  });

  useEffect(() => {
    setIssueType(issue);
    setSeverityCount(severity);
    setEndpointCount(endpoint);
    setLastScanDate(scanDate);
    setFilterIssueType(filterIssueType);
    setThreatLevel(threatLevel);
    setFilterIssues(filteredIssue);
  }, [issue, severity, endpoint, scanDate, filteredIssue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPath(input);
    }, 1000);
    return () => clearTimeout(timer);
  }, [input]);

  return (
    <>
      <section className="card rounded-lg px-[10px] py-[14px]  border-teal-800">
        {/* <div className="text-right">
          <button className="btn btn-primary px-10">Run Scan</button>
        </div> */}
        <div className="grid lg:grid-cols-3 gap-4 mt-4 bg-[#09294D0D] px-4 pt-4 rounded">
          <IssueType analytics={issueType} />
          <ThreatLevel analytics={severityCount} />
          <Summary count={endpointCount} lastDate={lastScanDate} />
        </div>
      </section>
      <section className="card rounded-lg px-[10px] py-[14px] mt-6  border-teal-800">
        <TableData
          analytics={filterIssues}
          setFilterIssueType={setFilterIssueType}
          setThreatLevel={setThreatLevel}
          setInput={setInput}
          isLoading={isLoading}
        />
      </section>
    </>
  );
};

export default ConformanceScan;
