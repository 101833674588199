import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFunc } from '../baseQueryFunc';

export const mfaAuthApi = createApi({
  reducerPath: 'mfaAuthApi',
  // baseQuery: fetchBaseQuery({
  //   baseUrl: `${process.env.REACT_APP_API_URL}/account/`,
  //   prepareHeaders: (headers) => {
  //     headers.set('Authorization', `Token ${user()}`);
  //     return headers;
  //   },
  // }),

  baseQuery: (args, api, extraOptions) =>
    baseQueryFunc('account', args, api, extraOptions),

  endpoints: (builder) => ({
    updatePasswordDuringFirstLogin: builder.mutation({
      query: (body) => {
        return {
          url: 'reset/',
          method: 'PATCH',
          body,
        };
      },
    }),

    activateMfa: builder.mutation({
      query: (body) => {
        return {
          url: 'mfa/',
          method: 'POST',
          body,
        };
      },
    }),

    resetPassword: builder.mutation({
      query: (body) => {
        return {
          url: 'password_reset/',
          method: 'POST',
          body,
        };
      },
    }),

    onboardUser: builder.mutation({
      query: (body) => {
        return {
          url: 'register_user/',
          method: 'POST',
          body,
        };
      },
    }),

    confirmMfaOnboarding: builder.mutation({
      query: (body) => {
        return {
          url: 'confirm-mfa/',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useActivateMfaMutation,
  useOnboardUserMutation,
  useResetPasswordMutation,
  useUpdatePasswordDuringFirstLoginMutation,
  useConfirmMfaOnboardingMutation,
} = mfaAuthApi;
