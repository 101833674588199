import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import warning from "../../../assets/images/warning.png";

function ActionModal({ icon, iconColor, title, onSubmit, item, setId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <i onClick={() => {
        setId(item.id)
        handleShow()
      }} title={title} className={`cursor-pointer text-${iconColor}`}>{icon}</i>

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Body>
          <div className="md:w-[10%] w-[30%] mx-auto">
            <img src={warning} alt="warning alert" width="100%" />
          </div>
          <h2 className="text-center text-primary">Are you sure?</h2>
          <h4 className="text-center text-primary">
            This action cannot be undone
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              onSubmit();
              handleClose();
            }}
          >
            Proceed
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ActionModal;
