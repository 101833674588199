import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const history = useHistory();

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${apiUrl}/accounts/login/`, {
        username,
        password,
      });
      if (response.status === 200) {
        const token = response.data.key;
        const user = {
          company_name: response.data.user.company_name,
          email: response.data.user.email,
          username: response.data.user.username,
          first_name: response.data.user.first_name,
          last_name: response.data.user.last_name,
        };
        localStorage.setItem('watoken', token);
        localStorage.setItem('wauser', JSON.stringify(user));
        setUser(response.data.user);
        return { success: true, error: '' };
      }
      if (response.status === 400) {
        return { success: false, error: 'Invalid Username or Password' };
      }
      if (response.status === 500) {
        return {
          success: false,
          error: 'Something went wrong with signing you in',
        };
      }
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          error: error.response?.data['non_field_errors']?.[0],
        };
      }
      return {
        success: false,
        error: 'Something went wrong with signing you in',
      };
    }
  };

  const register = async (username, email, password1, password2) => {
    try {
      const response = await axios.post(`${apiUrl}/accounts/registration/`, {
        username,
        email,
        password1,
        password2,
        account_type: 'Client',
      });
      const token = response.data.key;
      localStorage.setItem('watoken', token);
      localStorage.setItem('wauser', JSON.stringify(user));
      history.push('/new_onboarding');
      setUser(jwtDecode(token));
      return { success: true, error: '' };
    } catch (error) {
      const res = JSON.stringify(error?.response?.data);
      return { success: false, error: res };
    }
  };

  const logout = () => {
    localStorage.removeItem('watoken');
    localStorage.removeItem('wauser');
    localStorage.removeItem('collection_id');
    localStorage.removeItem('collection_name');
    localStorage.removeItem('collection_token');
    localStorage.removeItem('tempToken');
    localStorage.removeItem('tempId');
    localStorage.removeItem('token');

    localStorage.removeItem('step');
    localStorage.removeItem('selected_endpoint');
    localStorage.removeItem('user_details');
    localStorage.removeItem('session_id');
    localStorage.removeItem('endpoint_name');
    window.location.reload();
    setUser(null);
    history.push('/login');
  };

  const userToken = localStorage.getItem('watoken');

  const isAuthenticated = () => {
    const token = localStorage.getItem('watoken');
    if (token) {
      return true;
    }
    return false;
  };

  const value = {
    user,
    login,
    logout,
    isAuthenticated,
    register,
    userToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
