import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './component/index.css';
import LineChart from './component/chart/LineChart';
import VulnerabilityHeading from './component/VulnerabilityHeading';
import { PageHeader } from './component/PageHeader';
import {
  useGetCriticalVulnerabilityQuery,
  useGetHighVulnerabilityQuery,
  useGetLowVulnerabilityQuery,
  useGetMediumVulnerabilityQuery,
  useGetSuspiciousDynamicPerYearQuery,
  useGetVulnerabilityCountsQuery,
} from '../../../redux/services/endPointScansApi';
import { NoCollectionId } from '../../components/NoCollectionId';
import VulnerabilityLevelProgressChart from './component/chart/VulnerabilityLevelProgressChart';
import VulnerabilityItems from './component/VulnerabilityItems';
import ErrorDisplay from '../../components/Error';

const Vulnerability = () => {
  const history = useHistory();
  const collectionId = localStorage.getItem('collection_id');
  const [criticalPageNum, setCriticalPageNum] = useState(1);
  const [highPageNum, setHighPageNum] = useState(1);
  const [mediumPageNum, setMediumPageNum] = useState(1);
  const [lowPageNum, setLowPageNum] = useState(1);

  const {
    data: suspiciousData,
    isLoading: suspiciousIsLoading,
    error: suspiciousError,
  } = useGetSuspiciousDynamicPerYearQuery(collectionId);

  const {
    data: criticalData,
    isLoading: criticalIsLoading,
    error: criticalError,
  } = useGetCriticalVulnerabilityQuery({
    collectionId,
    criticalPageNum,
  });

  const {
    data: highData,
    isLoading: highIsLoading,
    error: highError,
  } = useGetHighVulnerabilityQuery({ collectionId, highPageNum });
  const {
    data: mediumData,
    isLoading: mediumIsLoading,
    error: mediumError,
  } = useGetMediumVulnerabilityQuery({ collectionId, mediumPageNum });
  const {
    data: lowData,
    isLoading: lowIsLoading,
    error: lowError,
  } = useGetLowVulnerabilityQuery({ collectionId, lowPageNum });

  const {
    data: vulnerabilityChartData,
    isLoading: vulnerabilityChartIsLoading,
    error: vulnerabilityChartError,
  } = useGetVulnerabilityCountsQuery(collectionId);

  useEffect(() => {
    if (!collectionId) {
      const redirectTimeout = setTimeout(() => {
        history.push('/dashboard/collections');
      }, 4000);
      return () => clearInterval(redirectTimeout);
    }
  }, [collectionId, history]);

  if (!collectionId)
    return (
      <div className="flex flex-col items-center mb-1">
        <ErrorDisplay errorMessage="No Project Selected" errorCode={404} />
        <h4 className="text-primary mt-4 mb-4">Redirecting Shortly</h4>
      </div>
    );

  return (
    <main className="mb-16 w-full  card pb-6 px-3 rounded-lg">
      <PageHeader />
      <div className="charts-container ">
        <section className="line-chart">
          <VulnerabilityHeading name={'Monthly Summary'} />
          <LineChart
            data={suspiciousData}
            isLoading={suspiciousIsLoading}
            error={suspiciousError}
          />
        </section>
        <section className="progress-chart__container">
          <VulnerabilityHeading name={'Vulnerabilities '} />
          <div className="progress-chart">
            <div className="progress-chart__high">
              <VulnerabilityLevelProgressChart
                isLoading={vulnerabilityChartIsLoading}
                error={vulnerabilityChartError}
                data={vulnerabilityChartData}
                bcg={'#DB252530'}
                color={'#F90808'}
                dataLabelsColors={'#F90808'}
                name={'High'}
              />
            </div>
            <div className="progress-chart__medium">
              <VulnerabilityLevelProgressChart
                isLoading={vulnerabilityChartIsLoading}
                error={vulnerabilityChartError}
                data={vulnerabilityChartData}
                bcg={'#fff2c580'}
                color={'#f8d146 '}
                name={'Medium'}
                dataLabelsColors={'#ffca09'}
              />
            </div>
            <div className="progress-chart__low">
              <VulnerabilityLevelProgressChart
                isLoading={vulnerabilityChartIsLoading}
                error={vulnerabilityChartError}
                data={vulnerabilityChartData}
                bcg={'#652CB340'}
                color={'#652CB3'}
                dataLabelsColors={'#652CB3'}
                name={'Low'}
              />
            </div>
          </div>
        </section>
      </div>
      <div className="grid grid-cols-1 mt-10 ">
        <section className="border-bottom border-top  pt-9 pb-2">
          <VulnerabilityHeading name={'Critical Vulnerabilities '} />
          <VulnerabilityItems
            titleBcg={'#DB252530'}
            btnBcg={'#DB2525'}
            borderColor={'#D9080880'}
            data={criticalData}
            isLoading={criticalIsLoading}
            error={criticalError}
            setPage={setCriticalPageNum}
          />
        </section>

        <section className="border-bottom pt-6 pb-2">
          <VulnerabilityHeading name={'Medium Vulnerabilities'} />
          <VulnerabilityItems
            titleBcg={'#E2B20780'}
            btnBcg={'#E2B207'}
            borderColor={'#FAC7104D'}
            data={highData}
            isLoading={highIsLoading}
            error={highError}
            setPage={setHighPageNum}
          />
        </section>
        <section className="border-bottom pt-6 pb-2">
          <VulnerabilityHeading name={'Low Vulnerabilities'} />
          <VulnerabilityItems
            titleBcg={'#652CB340'}
            btnBcg={'#420793CC'}
            borderColor={'#420793CC'}
            data={mediumData}
            isLoading={mediumIsLoading}
            error={mediumError}
            setPage={setMediumPageNum}
          />
        </section>
        <section className="pt-6">
          <VulnerabilityHeading name={'Undetected Vulnerabilities'} />
          <VulnerabilityItems
            titleBcg={'#E7E6E6AB'}
            btnBcg={'#D0CDCD80'}
            isUnderatedVulnerability={true}
            borderColor={'#D0CDCD80'}
            data={lowData}
            isLoading={lowIsLoading}
            error={lowError}
            setPage={setLowPageNum}
          />
        </section>
      </div>
    </main>
  );
};

export default Vulnerability;
