import React from "react";
import "./style.css";
import { useGetEndpointStatusCountQuery } from "../../../redux/services/endPointScansApi";
const ApiInventory = () => {
  const collection_id = localStorage.getItem("collection_id");

  const { data } = useGetEndpointStatusCountQuery({
    collection_id: collection_id,
  });
  
  return (
    <div>
      <h4 className="text-primary">API Inventory</h4>
      <div class="flex md:justify-between mt-4 overflow-x-auto space-x-2 md:space-x-0">
        <div className="md:w-[18%] w-[100%] mb-4">
          {/* <div class="box" data-info={`${data.name} : ${data.value}`}> */}
          <div
            className="box bg-[#09294d]"
            data-info={`Scanned : ${data?.scanned}`}
          ></div>
          <div className="flex space-x-1 items-center mt-4">
            <div className="w-[15px] h-[15px] rounded-full bg-[#09294d]"></div>{" "}
            <span>Scanned : {data?.scanned}</span>
          </div>
        </div>
        <div className="md:w-[18%] w-[100%] mb-4">
          <div
            class="box bg-[#078AFD]"
            data-info={`Discovered : ${data?.discovered}`}
          ></div>
          <div className="flex space-x-1 items-center mt-4">
            <div className="w-[15px] h-[15px] rounded-full bg-[#078AFD]"></div>{" "}
            <span>Discovered  : {data?.discovered}</span>
          </div>
        </div>
        <div className="md:w-[18%] w-[100%] mb-4">
          <div class="box bg-[#a8dadc]" data-info={`New : ${data?.new}`}></div>
          <div className="flex space-x-1 items-center mt-4">
            <div className="w-[15px] h-[15px] rounded-full bg-[#a8dadc]"></div>{" "}
            <span>New : {data?.new}</span>
          </div>
        </div>
        <div className="md:w-[18%] w-[100%] mb-4">
          <div
            class="box bg-[#FD8901]"
            data-info={`Duplicates : ${data?.duplicates}`}
          ></div>
          <div className="flex space-x-1 items-center mt-4">
            <div className="w-[15px] h-[15px] rounded-full bg-[#FD8901]"></div>{" "}
            <span>Duplicates : {data?.duplicates}</span>
          </div>
        </div>
        <div className="md:w-[18%] w-[100%] mb-4">
          <div
            class="box bg-[#e63946]"
            data-info={`To be deleted : ${data?.to_be_deleted}`}
          ></div>
          <div className="flex space-x-1 items-center mt-4 text-nowrap">
            <div className="w-[15px] h-[15px] rounded-full bg-[#e63946]"></div>{" "}
            <span>To be deleted : {data?.to_be_deleted}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiInventory;
