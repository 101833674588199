import React, { useEffect, useState } from 'react';

import contriesArray from '../../../../data/countriesFlag.json';
import { useHistory } from 'react-router-dom';
import DarkLoader from '../../../components/loader/DarkLoader';

const TopAttachers = ({
  topCountriesAttachersData,
  topCountriesAttachersIsLoading: isLoading,
  topCountriesAttachersError: error,
}) => {
  const [countryObject, setCountryObject] = useState(null);
  const history = useHistory();

  const initCountryObject = {};

  contriesArray.forEach(
    (country) => (initCountryObject[country.country] = country)
  );

  const formatCountryName = (name) => {
    let countryName;

    if (!name || name === 'null') return '';

    if (name.split(' ')?.length > 1) {
      const splitName = name.split(' ');
      const firstTwoWords = splitName.slice(0, 2);
      countryName = firstTwoWords.join(' ');
    } else countryName = name;

    return countryName;
  };

  const handleNavigateToLogDataPage = (countryName) => {
    if (!countryName || countryName === 'null') return;

    localStorage.setItem('filterThreatDataCountry', countryName);
    localStorage.setItem('filterThreatDataBy', 'countryName');
    history.push('/dashboard/threats');
  };

  const isNoDataFound = (obj) => {
    if (!isLoading && !error && obj && Object.keys(obj).length === 0)
      return true;

    return false;
  };

  useEffect(() => {
    contriesArray.forEach(
      (country) => (initCountryObject[country?.country] = country)
    );

    setCountryObject(initCountryObject);
  }, []);

  return (
    <div
      className={`lg:w-[33%] border mb-4 ${
        isLoading || error ? 'h-[350px]' : ''
      } ${
        topCountriesAttachersData && topCountriesAttachersData.length < 1
          ? 'hidden'
          : 'block'
      }`}
    >
      <h4 className="text-lg text-black text-center mb-0 bg-[#d9d9d980] py-2">
        Top Attackers
      </h4>
      <table className="table table-hover relative">
        <tbody>
          {isLoading ? (
            <div className="absolute  text-center mb-0  inset-0  h-[230px]  w-full  flex items-center justify-center">
              <DarkLoader />
            </div>
          ) : null}

          {error ? (
            <div className="absolute text-base  text-black text-center mb-0   inset-0  h-[230px]  w-full  flex items-center justify-center">
              An error occured
            </div>
          ) : null}

          {countryObject && topCountriesAttachersData
            ? Object.entries(topCountriesAttachersData)?.map(([key, value]) => (
                <tr
                  key={key}
                  className="bg-white border-b h-[53px] cursor-pointer"
                >
                  <td
                    onClick={() => handleNavigateToLogDataPage(key)}
                    className={`w-min h-full ${
                      key === 'null' ? 'cursor-none' : 'cursor-pointer'
                    }`}
                  >
                    <img
                      src={countryObject[key]?.flag}
                      alt=""
                      style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '0px',
                      }}
                      className={`w-full h-full object-cover ${
                        key === 'null' ? 'cursor-none' : 'cursor-pointer'
                      }`}
                    />
                  </td>

                  <td
                    className={`h-3 w-full  ${
                      key === 'null' ? 'cursor-none' : 'cursor-pointer'
                    }`}
                  >
                    <div
                      onClick={() => handleNavigateToLogDataPage(key)}
                      pill
                      className={`h-full w-min md:w-min items-center flex justify-start  px-2 py-2 ${
                        key === 'null' ? 'cursor-none' : 'cursor-pointer'
                      }`}
                    >
                      {formatCountryName(key)}
                    </div>
                  </td>

                  <td>{value?.toFixed(3)}%</td>
                </tr>
              ))
            : null}

          {isNoDataFound(topCountriesAttachersData) ? (
            <div className="absolute  inset-0  py-28  w-full  flex items-center justify-center">
              <p className="px-6  text-base  text-black">No threat</p>
            </div>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default TopAttachers;
